import React from "react";
import { PSmall } from "@tempoplatform/tpds/elements/typography";
import clsx from "clsx";

const classOption = clsx(
  `select-none rounded border-2 border-grey-dark-scale-500 w-auto h-[34px] min-w-[36px]`,
  `flex items-center px-2`,
  `transition-all duration-300`,
  `leading-none font-medium capitalize`,
  `cursor-pointer`,
);

interface Props {
  values: any;
  variantName: string;
  handleSelectValueIndex: any;
  selectedValueIndex: number;
}

const VariantSelector: React.FC<Props> = ({
  values,
  variantName,
  handleSelectValueIndex,
  selectedValueIndex,
}) => {
  return (
    <div>
      <PSmall isMedium className="leading-none capitalize mb-2">
        {variantName}
      </PSmall>
      <div className="flex gap-1 flex-wrap">
        {!values ||
          (values.length === 0 && (
            <PSmall className="text-tertiary">
              (No {`"${variantName}"` || ""} values added yet)
            </PSmall>
          ))}
        {values.map((value: any, index: number) => (
          <PSmall
            className={clsx(classOption, index === selectedValueIndex && `text-white bg-black`)}
            key={`${index}${value.title}`}
            onClick={() => {
              handleSelectValueIndex(index);
            }}
          >
            {value.title}
          </PSmall>
        ))}
      </div>
    </div>
  );
};

export default VariantSelector;
