import React from "react";
import { useAppSelector } from "../../../../app/hooks";
import { H6, P, PSmall } from "@tempoplatform/tpds/elements/typography";
import StarsRating from "@tempoplatform/tpds/components/stars-rating";
import AdTypes from "../../../../constants/ad_types";
import { simplifyLongNumber } from "../../../helpers/number";

interface ComponentProps {
  productIndex: number;
}

const TitleArea: React.FC<ComponentProps> = ({ productIndex }) => {
  const product = useAppSelector(state => state.campaignCreate.products)[productIndex];
  const adType = useAppSelector(state => state.campaignCreate.ad_type);

  const hasPrice = product && product.price;
  const hasName = product && product.name && product.name !== "";
  const price = hasPrice ? `$${product.price}` : "Price";
  const name = hasName ? product.name : "Product name";
  const omitPrice = adType === AdTypes.brand_awareness || adType === AdTypes.images_form;
  const omitRating = adType === AdTypes.images_form;

  const storedReviewCount = product && product.review_count ? product.review_count : 0;
  const reviewCount = storedReviewCount ? storedReviewCount : 1400;
  const simplifiedCount = String(simplifyLongNumber(reviewCount));
  const overallScore = product && product.review_overall_score ? product.review_overall_score : 4.5;
  return (
    <div className="w-full flex flex-col justify-center items-center mt-10 mb-2">
      {!omitPrice && (
        <H6 isMedium className="select-none" style={{ opacity: hasPrice ? "1" : "0.3" }}>
          {price}
        </H6>
      )}
      <P isMedium className="mt-2 mb-2.5 select-none" style={{ opacity: hasName ? "1" : "0.3" }}>
        {name}
      </P>
      {!omitRating && (
        <div className="flex items-center justify-center">
          <StarsRating rating={overallScore} width={86} lightOnly />{" "}
          <PSmall className="leading-none ml-1 select-none">{simplifiedCount}</PSmall>
        </div>
      )}
    </div>
  );
};
export default TitleArea;
