import {configureStore} from '@reduxjs/toolkit';
import campaignCreateReducer from '../components/campaignCreate/campaignCreateSlice';

export const store = configureStore({
    reducer: {
        campaignCreate: campaignCreateReducer,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware();
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
