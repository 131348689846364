import { PTiny } from "@tempoplatform/tpds/elements/typography";

export const tableColumns = [
  {
    label: "Id",
    propName: "campaign_id",
    enableSort: true,
    render: (item: any) => <PTiny>{item.campaign_id}</PTiny>,
  },
  {
    label: "Name",
    propName: "campaign_name",
    enableSort: true,
    //sortFunction: (a: any, b: any) => (a.impressions * 25) / 1000 - (b.impressions * 25) / 1000,
    render: (item: any) => <PTiny>{item.campaign_name}</PTiny>,
  },
  {
    label: "Ad Type",
    propName: "ad_type",
    enableSort: true,
    render: (item: any) => <PTiny>{item.ad_type}</PTiny>,
  },
  {
    label: "Spend",
    propName: "spend",
    enableSort: true,
    render: (item: any) => <PTiny>{item.spend}</PTiny>,
  },
  {
    label: "CPM",
    propName: "cpm",
    enableSort: true,
    render: (item: any) => <PTiny>{item.cpm}</PTiny>,
  },
  {
    label: "Daily Budget",
    propName: "daily_budget",
    enableSort: true,
    render: (item: any) => <PTiny>{item.daily_budget}</PTiny>,
  },
  {
    label: "Impressions",
    propName: "impressions",
    enableSort: true,
    render: (item: any) => <PTiny>{item.daily_budget}</PTiny>,
  },
  // {
  //   label: "Sessions with click",
  //   propName: "sessions_with_click",
  //   enableSort: true,
  //   render: (item: any) => <PTiny>{item.sessions_with_click}</PTiny>,
  // }
];

export const generateTableData = (data: any) => {
  const uniqueIds = data
    .map((item: any) => item.campaign_id)
    .filter((value: any, index: any, self: any) => self.indexOf(value) === index);
  // sum the values of each item in data that has the same date
  const sumOfValues = uniqueIds.map((campaign_id: any) => {
    const filteredData = data.filter((item: any) => item.campaign_id === campaign_id);
    //console.log("filteredData", filteredData);
    const campaign_name = filteredData[0].campaign_name;
    const ad_type = filteredData[0].ad_type;
    const sumSpend = filteredData.reduce((acc: any, item: any) => {
      return acc + item.spend;
    }, 0);
    const sumDailyBudget = filteredData.reduce((acc: any, item: any) => {
      return acc + item.daily_budget;
    }, 0);
    const sumImpressions = filteredData.reduce((acc: any, item: any) => {
      return acc + item.impressions;
    }, 0);

    return {
      campaign_id,
      campaign_name,
      spend: sumSpend,
      daily_budget: sumDailyBudget,
      impressions: sumImpressions,
      ad_type: ad_type,
      cpm: (sumSpend / (1000 * sumImpressions)).toFixed(2),
    };
  });
  //console.log("sumOfValues", sumOfValues);

  return sumOfValues;

  // return sumOfValues.map((item: any) => {
  //   return {
  //     ...item,
  //   };
  // });
};

// export const campaignColumns = [
//   {
//     label: "Campaign",
//     propName: "campaignName",
//   },
//   {
//     label: "Start Date",
//     propName: "date",
//     enableSort: true,
//   },
// ];

// export const gameColumns = [
//   {
//     label: "Game",
//     propName: "appName",
//   },
// ];
