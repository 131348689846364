import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { PLarge } from "@tempoplatform/tpds/elements/typography";
import { ArrowLeftIcon } from "@tempoplatform/tpds/assets/svgs/arrows";
import { deselectPreviewProduct } from "../../campaignCreateSlice";

const TopHeader: React.FC = () => {
  const dispatch = useAppDispatch();
  let brandName = useAppSelector(state => state.campaignCreate.brandName);

  const previewCampaignData = useAppSelector(state => state.campaignCreate.previewCampaignData);
  // if we are in campaign preview mode, use the preview data
  if (previewCampaignData) {
    brandName = previewCampaignData.brand_name;
  }

  const name = brandName || "Brand name";

  return (
    <div className="w-full h-[48px] flex justify-between items-center px-4 border-b border-window">
      <div
        className="inline-flex items-center cursor-pointer"
        onClick={() => dispatch(deselectPreviewProduct())}
      >
        <ArrowLeftIcon />
        <PLarge isMedium className="select-none mr-auto ml-3">
          {name}
        </PLarge>
      </div>
      <ShareIcon />
    </div>
  );
};

export default TopHeader;

function ShareIcon({ ...props }) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
      className="cursor-pointer w-[24px]"
    >
      <path
        fill="#000"
        d="M11 46c-.8 0-1.5-.3-2.1-.9-.6-.6-.9-1.3-.9-2.1V17.55c0-.8.3-1.5.9-2.1.6-.6 1.3-.9 2.1-.9h8.45v3H11V43h26V17.55h-8.55v-3H37c.8 0 1.5.3 2.1.9.6.6.9 1.3.9 2.1V43c0 .8-.3 1.5-.9 2.1-.6.6-1.3.9-2.1.9H11zm11.45-15.35V7.8l-4.4 4.4-2.15-2.15L23.95 2 32 10.05l-2.15 2.15-4.4-4.4v22.85h-3z"
      ></path>
    </svg>
  );
}
