import { ResponsiveLine } from "@nivo/line";
import { getChartConfig } from "./chartConfig";
import { generateTooltipForDayMainChart, generateTooltipForDaySWCChart } from "./generateTooltip";

export const generateMainChart = (
  data: any,
  chartData: any,
  legendsState: any,
  tooltipGenerator: any,
  currentTheme: string,
) => {
  return (
    <ResponsiveLine
      data={chartData}
      margin={{ top: 30, right: 10, bottom: 70, left: 30 }}
      xScale={{ type: "point" }}
      colors={d => d.color}
      yScale={{
        type: "linear",
        min: 0,
        max: "auto",
        stacked: false,
        reverse: false,
      }}
      yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 45,
        legend: "Day",
        legendOffset: 63,
        legendPosition: "middle",
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        // legend: "value",
        // legendOffset: -40,
        // legendPosition: "middle",
      }}
      pointSize={10}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      tooltip={input => {
        return tooltipGenerator(String(input.point.data.x), data, legendsState);
      }}
      theme={getChartConfig(currentTheme)}
      layers={["grid", "markers", "axes", "crosshair", "lines", "mesh"]}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
    />
  );
};
export const generateSessionsWithClickChart = (
  data: any,
  chartData: any,
  legendsState: any,
  tooltipGenerator: any,
  currentTheme: string,
) => {
  return (
    <ResponsiveLine
      data={chartData}
      margin={{ top: 30, right: 10, bottom: 70, left: 30 }}
      xScale={{ type: "point" }}
      colors={d => d.color}
      yScale={{
        type: "linear",
        min: 0,
        max: "auto",
        stacked: false,
        reverse: false,
      }}
      yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 45,
        legend: "Day",
        legendOffset: 63,
        legendPosition: "middle",
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        // legend: "value",
        // legendOffset: -40,
        // legendPosition: "middle",
      }}
      pointSize={10}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      tooltip={input => {
        return tooltipGenerator(String(input.point.data.x), data, legendsState);
      }}
      theme={getChartConfig(currentTheme)}
      layers={["grid", "markers", "axes", "crosshair", "lines", "mesh"]}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
    />
  );
};
