import React from "react";
import { useAppSelector } from "../../../../app/hooks";
import { PSmall } from "@tempoplatform/tpds/elements/typography";
import QuantitySelect from "./sub_components/QuantitySelect";
import SelectedOptionsRow from "./sub_components/SelectedOptionsRow";

interface ComponentProps {
  productIndex: number;
}
const SelectionResume: React.FC<ComponentProps> = ({ productIndex }) => {
  const product = useAppSelector(state => state.campaignCreate.products)[productIndex];
  const brandName = useAppSelector(state => state.campaignCreate.brandName);
  const [selectedQuantity, setSelectedQuantity] = React.useState(1);

  const handleQuantitySelectChange = (event: any) => {
    setSelectedQuantity(event.target.value);
  };
  const productName = product && product.name ? product.name : `(Product ${productIndex})`;
  let firstTextLine = brandName || "";
  firstTextLine += productName ? ` • ${productName}` : "";

  return (
    <div className="w-full px-4 mb-4 !mt-2.5 flex flex-col gap-y-2">
      <PSmall isMedium className="leading-none">
        Amount
      </PSmall>
      <div className="flex gap-x-2 gap-y-2 items-start">
        <QuantitySelect
          maxQuantity={10}
          selectedQuantity={selectedQuantity}
          handleSelectChange={handleQuantitySelectChange}
        />
        <div className="flex gap-x-2 gap-y-2 items-start">
          <div className="flex flex-col gap-y-1">
            <PSmall isMedium className="leading-none capitalize">
              {firstTextLine}
            </PSmall>
            <div className="flex gap-x-1">
              <SelectedOptionsRow productIndex={productIndex} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SelectionResume;
