import React from "react";
import { Button } from "@tempoplatform/tpds/elements/buttons";
import { PlusOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { createNewShippingOption } from "./campaignCreateSlice";
import LabelWithTooltip from "../../shared/LabelWithTooltip";
import ShippingOption from "./sub_components/ShippingOption";

interface Props {
  id: number;
}

const ProductShippingOptions = (props: Props) => {
  const dispatch = useAppDispatch();
  let productIndex = props.id - 1;
  const shippingOptions = useAppSelector(state => state.campaignCreate.shippingOptions);

  return (
    <>
      <LabelWithTooltip
        required={true}
        label="Shipping options"
        tooltipText="Add shipping options for your product."
      />
      {shippingOptions.map((shippingOption, index) => {
        return (
          <ShippingOption
            key={`shipping-option${index}`}
            productIndex={productIndex}
            shippingOptionIndex={index}
          />
        );
      })}
      <Button
        variant="secondary"
        onClick={() => dispatch(createNewShippingOption({ productIndex: productIndex }))}
        style={{
          width: "100%",
          borderStyle: "dashed",
          borderWidth: "2px",
          borderRadius: "4px",
        }}
      >
        <PlusOutlined /> Add shipping option
      </Button>
    </>
  );
};
export default ProductShippingOptions;
