import { Upload } from "antd";
import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  setProductImageList,
  setProductName,
  setProductPrice,
  updateAddReviews,
  addProductImage,
} from "./campaignCreateSlice";
//import { Switch } from "antd";
import ProductInfoBox from "./ProductInfoBox";
import VariantBox from "./VariantBox";
import ProductShippingOptions from "./ProductShippingOptions";
import ProductReviews from "./sub_components/ProductReviews";
import ReviewsBaseInfo from "./sub_components/ReviewsBaseInfo";
// @ts-ignore
import { v4 as uuidv4 } from "uuid";
import { TextInput } from "@tempoplatform/tpds/elements/input";
import { Separator } from "@tempoplatform/tpds/elements/layout";
import { FormLabelSmall, Red } from "@tempoplatform/tpds/elements/typography";
import PriceInput from "@tempoplatform/tpds/components/input/price";
import Switch from "@tempoplatform/tpds/components/switch";
import LabelWithTooltip from "../../shared/LabelWithTooltip";
import CloudUploadDisplay from "../../shared/CloudUploadDisplay";
import AdTypes from "../../constants/ad_types";
import AWS from "aws-sdk";
import {
  REACT_APP_AWS_CLOUDFRONT_URL,
  REACT_APP_AWS_S3_BUCKET_NAME,
  REACT_APP_AWS_S3_REGION,
  REACT_APP_AWS_ACCESS_ID,
  REACT_APP_AWS_ACCESS_KEY,
} from "../helpers/getS3Config";
import { getFileExtension } from "../helpers/string";

const { Dragger } = Upload;

//AWS config
AWS.config.update({
  accessKeyId: REACT_APP_AWS_ACCESS_ID,
  secretAccessKey: REACT_APP_AWS_ACCESS_KEY,
});
const s3 = new AWS.S3({
  params: { Bucket: REACT_APP_AWS_S3_BUCKET_NAME },
  region: REACT_APP_AWS_S3_REGION,
});

interface Props {
  id: number;
  isExpanded: boolean;
}

const Product = (props: Props) => {
  const { id, isExpanded } = props;
  const productIndex = id - 1;
  const dispatch = useAppDispatch();
  const imageList = useAppSelector(state => state.campaignCreate.productImages)[productIndex];
  const addReviews = useAppSelector(state => state.campaignCreate.products)[productIndex]
    .add_reviews;
  const adTypeState = useAppSelector(state => state.campaignCreate.ad_type);
  const productName = useProductName(productIndex) || "";
  const productPrice = useProductPrice(productIndex) || "";
  const [isProductImageUploading, setIsProductImageUploading] = React.useState(false);

  // @ts-ignore
  const handleImagesChange = async ({ file, fileList }) => {
    if (file.status === "removed") {
      console.log("file removed");
      let serializableFileList = fileList.map((file: any) => {
        return {
          uid: file.uid,
          url: file.url,
        };
      });
      dispatch(
        setProductImageList({
          productIndex: productIndex,
          imageList: serializableFileList,
        }),
      );
      // stop here if we're just removing an image
      return;
    }

    if (!file.type.includes("image")) {
      alert("Please upload an image file");
      return;
    }

    setIsProductImageUploading(true);

    const fileExtension = getFileExtension(file.name);
    const newFileName = `${uuidv4()}.${fileExtension}`;
    const newFileURL = `https://${REACT_APP_AWS_CLOUDFRONT_URL}/images/${newFileName}`;

    const params = {
      Bucket: REACT_APP_AWS_S3_BUCKET_NAME,
      Key: `images/${newFileName}`,
      Body: file,
    };
    var upload = s3
      // @ts-ignore
      .putObject(params)
      .promise();

    await upload.then((data: any) => {
      setIsProductImageUploading(false);
      if (data.$response.httpResponse.statusMessage === "OK") {
        console.log("Upload successful. New file URL:", newFileURL);
        dispatch(
          addProductImage({
            productIndex: productIndex,
            newImage: {
              uid: newFileName,
              url: newFileURL,
            },
          }),
        );
      } else {
        alert("Upload failed");
      }
    });
  };

  function useProductName(index: number) {
    try {
      return useAppSelector(state => state.campaignCreate.products[index].name);
    } catch (e) {
      console.log("Product not created yet:", index);
    }
  }

  function useProductPrice(index: number) {
    try {
      return useAppSelector(state => state.campaignCreate.products[index].price);
    } catch (e) {
      console.log("Product not created yet:", index);
    }
  }

  if (!isExpanded) {
    return null;
  }

  return (
    <div>
      <FormLabelSmall className="!mb-2">
        <Red>*</Red> Product {` ${props.id}`} Name
      </FormLabelSmall>
      <TextInput
        className="mb-6"
        type="text"
        placeholder="Name"
        value={productName}
        onChange={(e: any) =>
          dispatch(
            setProductName({
              productIndex: productIndex,
              name: e.target.value,
            }),
          )
        }
      />
      {adTypeState !== AdTypes.brand_awareness && adTypeState !== AdTypes.images_form && (
        <>
          <FormLabelSmall className="!my-2">
            <Red>*</Red> Product {` ${props.id}`} price
          </FormLabelSmall>
          <PriceInput
            initialValue={productPrice}
            currencySymbol="$"
            onChangeCallback={(value: any) =>
              dispatch(
                setProductPrice({
                  productIndex: productIndex,
                  price: value,
                }),
              )
            }
          />
        </>
      )}
      <FormLabelSmall className="mt-6 !mb-2">
        <Red>*</Red> Upload up to four images for product {props.id}
      </FormLabelSmall>
      <Dragger
        beforeUpload={() => false}
        style={{ marginBottom: "5px" }}
        multiple={true}
        listType="picture-card"
        fileList={imageList}
        className="!bg-window !border-grey-light-scale-400 dark:!border-grey-dark-scale-200"
        showUploadList={{
          showPreviewIcon: false,
          showRemoveIcon: true,
        }}
        onChange={handleImagesChange}
      >
        {isProductImageUploading && <LoadingOutlined />}
        <CloudUploadDisplay multiple={true} />
      </Dragger>
      <Separator className="mt-6 mb-4" />
      {adTypeState !== AdTypes.brand_awareness && adTypeState !== AdTypes.images_form && (
        <>
          <ProductShippingOptions id={props.id} />
          <Separator className="mt-6 mb-4" />
        </>
      )}
      {adTypeState !== AdTypes.images_form && (
        <>
          <VariantBox id={props.id} />
          <Separator className="mt-6 mb-4" />
          <ProductInfoBox id={props.id} />
          <Separator className="mt-6 mb-4" />
          <div className="flex items-center justify-between my-4">
            <LabelWithTooltip
              label="Product reviews"
              tooltipText="Optional. Here you can manually add product reviews and stats."
            />
            <div className="relative top-[-6px]">
              <Switch
                active={addReviews}
                onClick={() =>
                  dispatch(
                    updateAddReviews({
                      productIndex: productIndex,
                      value: !addReviews,
                    }),
                  )
                }
              />
            </div>
          </div>
          {addReviews && (
            <>
              <ReviewsBaseInfo productIndex={props.id - 1} />
              <ProductReviews id={props.id} />
              <Separator className="mt-6 mb-4" />
            </>
          )}
        </>
      )}
    </div>
  );
};
export default Product;
