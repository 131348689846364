import React from "react";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { FormLabelSmall, Red } from "@tempoplatform/tpds/elements/typography";

export const LabelWithTooltip = ({ label, tooltipText = "", required = false, ...props }) => {
  if (!label) {
    console.error("Label is required");
    return null;
  }
  return (
    <div className="flex gap-2" {...props}>
      <FormLabelSmall>
        {required ? <Red>* </Red> : null}
        {label}
      </FormLabelSmall>
      {tooltipText && tooltipText !== "" && (
        <Tooltip title={tooltipText}>
          <InfoCircleOutlined className="!text-info" />
        </Tooltip>
      )}
    </div>
  );
};

export default LabelWithTooltip;
