import React from "react";
import { PSmall } from "@tempoplatform/tpds/elements/typography";
import clsx from "clsx";

const classSwatchNoise = clsx(
  `absolute top-0 left-0 z-1`,
  `w-[32px] h-[32px]`,
  `rounded-lg overflow-hidden opacity-75`,
);

interface Props {
  values: any;
  handleSelectValueIndex: any;
  selectedColorIndex: any;
}

const VariantsColorSelector: React.FC<Props> = ({
  values,
  handleSelectValueIndex,
  selectedColorIndex,
}) => {
  return (
    <div>
      <PSmall isMedium className="leading-none mb-1">
        Color
      </PSmall>
      {!values ||
        (values.length === 0 && (
          <PSmall className="text-tertiary">(No "Color" values added yet)</PSmall>
        ))}
      <div className="flex gap-1 flex-wrap">
        {values.map((color: any, index: number) => {
          const isSelected = index === selectedColorIndex;
          return (
            <div
              className="select-none relative w-[34px] h-[34px] cursor-pointer"
              key={`${index}${color.hex_color}`}
              onClick={() => {
                handleSelectValueIndex(index);
              }}
            >
              <div
                className={clsx(
                  `opacity-0 border-2 border-black z-10`,
                  `absolute w-[34px] h-[34px] rounded`,
                  `transition-opacity duration-300`,
                  isSelected && `opacity-100`,
                )}
              />
              <img
                className={classSwatchNoise}
                src={"/assets/images/color-swatch-noise.png"}
                alt="swatch-noise"
                style={{ zIndex: 1 }}
              />
              <div
                className={clsx(
                  `absolute top-0 left-0`,
                  isSelected && `rounded-sm top-[4px] left-[4px] w-[26px] h-[26px]`,
                  !isSelected && `rounded top-[0] left-[0] w-[34px] h-[34px]`,
                )}
                style={{
                  backgroundColor: color.hex_color,
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default VariantsColorSelector;
