import * as React from "react";
import Loading from "../loading/Loading";
import EmailVerification from "../onboarding/EmailVerification";
import Campaigns from "../campaign/Campaign";
import OnBoarding from "../onboarding/OnBoarding";
import PublisherApps from "../publisher/PublisherApps";
import { useAuth0 } from "@auth0/auth0-react";
import { toGetAccount } from "../api/api";
import { useEffect, useState } from "react";
import { FormLabelSmall } from "@tempoplatform/tpds/elements/typography";

const Entry: React.FC<{}> = () => {
  const userId = useAuth0().user?.sub as string;
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>();
  const [error, setError] = useState<any>();
  const { getIdTokenClaims } = useAuth0();

  useEffect(() => {
    getAccountInformation();
  }, []);

  const getAccountInformation = async () => {
    const claims = await getIdTokenClaims();
    const id_token = claims?.__raw;
    try {
      const response = await toGetAccount(userId, id_token);
      setData(response);
      setLoading(false);
    } catch (error: any) {
      setError(error);
      setLoading(false);
    }
  };

  // Display if loading
  if (loading) {
    return <Loading />;
  }

  // TODO: UX error handling
  if (error) {
    return <FormLabelSmall isMedium>ERROR</FormLabelSmall>;
  }

  // Display additional fields for new users (i.e. empty account)
  if (!data) {
    return <OnBoarding />;
  }

  // Update display for publisher/advertiser
  if (data.account_type_id === "PUBLISHER") {
    return <PublisherApps accountId={data.id} />;
  } else {
    return <Campaigns accountId={data.id} />;
  }
};

export default Entry;
