import React from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { PNano } from "@tempoplatform/tpds/elements/typography";
import { updateProductReviewChartBar } from "../campaignCreateSlice";

interface Props {
  productIndex: number;
  barNumber: number;
}

const ChartBarSlider = (props: Props) => {
  const { productIndex, barNumber } = props;
  const dispatch = useAppDispatch();
  const product = useAppSelector(state => state.campaignCreate.products[productIndex]);
  // @ts-ignore
  const percent = product[`review_chart_bar_${barNumber}_percent`];

  const handleClick = (e: any) => {
    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const width = rect.width;
    const percent = Math.round((x / width) * 100);
    dispatch(updateProductReviewChartBar({ productIndex, barNumber: barNumber, value: percent }));
  };

  return (
    <div className="w-full cursor-pointer flex relative items-center justify-between h-[6px] mb-2">
      <PNano isMedium style={{ width: "8px", textAlign: "center" }}>
        {barNumber}
      </PNano>
      <div
        style={{ width: "calc(100% - 20px)" }}
        className="bg-grey-light-scale-200 dark:bg-grey-dark-scale-300 h-[6px] rounded relative"
        onClick={handleClick}
      >
        <div
          className="bg-yellow h-[6px] rounded absolute top-0 left-0 transition-all duration-300"
          style={{ width: `${percent}%`, pointerEvents: "none" }}
        />
      </div>
    </div>
  );
};

export default ChartBarSlider;
