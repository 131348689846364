import React from "react";
import { PSmall } from "@tempoplatform/tpds/elements/typography";
import { Separator } from "@tempoplatform/tpds/elements/layout";

interface Props {
  currentStep: number;
  errors: Array<any>;
  submittedStep1: boolean;
  submittedStep2: boolean;
  submittedStep3: boolean;
  currentStepValidationSuccessful: boolean;
}

const ErrorsDisplay = (props: Props) => {
  const {
    currentStep,
    errors,
    submittedStep1,
    submittedStep2,
    submittedStep3,
    currentStepValidationSuccessful,
  } = props;
  if (currentStepValidationSuccessful) return null;
  if (!errors || errors.length === 0) return null;
  if (currentStep === 0 && !submittedStep1) return null;
  if (currentStep === 1 && !submittedStep2) return null;
  if (currentStep === 2 && !submittedStep3) return null;
  return (
    <div className="mt-4 shadow rounded bg-yellow-scale-100/50 dark:bg-yellow-scale-100 p-4 flex flex-col gap-2.5">
      <PSmall isMedium className="text-black dark:text-black">
        Step {currentStep + 1} - Please verify the following items:
      </PSmall>
      <Separator className="!my-2 border-black/10" />
      {currentStep === 1 && errors.length > 0
        ? errors.map((errorGroup: any, index: any) => {
            if (errorGroup.length === 0) {
              return null;
            } else {
              return (
                <div key={`Error ${index}`}>
                  <PSmall isMedium className="pb-1.5 text-black dark:text-black">
                    Product {index + 1}
                  </PSmall>
                  {errorGroup.map((error: string, errorIndex: any) => (
                    <PSmall
                      className="py-[1px] text-black dark:text-black"
                      key={error + errorIndex}
                    >
                      {error}
                    </PSmall>
                  ))}
                </div>
              );
            }
          })
        : errors.map((error: string) => (
            <PSmall key={error} className="text-black dark:text-black">
              {error}
            </PSmall>
          ))}
    </div>
  );
};
export default ErrorsDisplay;
