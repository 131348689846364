export function KeyIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
        className="text-primary fill-current"
        d="M16 5.531C16 8.563 13.531 11 10.5 11a5.26 5.26 0 01-1.031-.094l-.75.844c-.125.156-.375.25-.563.25H7v1.25a.74.74 0 01-.75.75H5v1.25a.74.74 0 01-.75.75H.75a.722.722 0 01-.75-.75v-2.438c0-.187.063-.374.219-.53L5.25 7.25A6.4 6.4 0 015 5.5C5 2.469 7.438.031 10.469 0A5.521 5.521 0 0116 5.531zM10.5 4c0 .844.656 1.5 1.5 1.5A1.5 1.5 0 0013.5 4c0-.813-.688-1.5-1.5-1.5A1.5 1.5 0 0010.5 4z"
      ></path>
    </svg>
  );
}

export function CopyIcon({ ...props }) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      fill="none"
      viewBox="0 0 33 32"
    >
      <path
        className="text-primary fill-current"
        d="M32.375 4.438c.375.375.625.875.625 1.437V20c0 2.25-1.813 4-4 4H17c-2.25 0-4-1.75-4-3.938v-16C12.937 1.813 14.75 0 17 0h10.125c.563 0 1.063.25 1.438.625l3.812 3.813zM30 20V8h-3c-1.125 0-2-.875-2-2V3.062h-8c-.563 0-1 .438-1 1V20c0 .563.438 1 1 1h12c.5 0 1-.438 1-1zm-12 8v-2h3v2c0 2.25-1.813 4-4 4H5c-2.25 0-4-1.75-4-3.938v-16C.937 9.813 2.75 8 5 8h6v3.063H5c-.563 0-1 .437-1 1V28c0 .563.438 1 1 1h12c.5 0 1-.438 1-1z"
      ></path>
    </svg>
  );
}

export function TrashIcon({ ...props }) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="16"
      fill="none"
      viewBox="0 0 14 16"
    >
      <path
        className="text-secondary fill-current"
        d="M13.25 2.5a.76.76 0 01.75.75.74.74 0 01-.75.75H13l-.688 10.594c-.03.812-.687 1.406-1.5 1.406H3.157c-.812 0-1.469-.594-1.5-1.406L1 4H.75A.722.722 0 010 3.25a.74.74 0 01.75-.75h2.313L4.125.75C4.406.281 4.875 0 5.406 0h3.157c.53 0 1 .281 1.28.75l1.063 1.75h2.344zm-7.844-1l-.593 1h4.343l-.594-1H5.406zm5.407 13L11.469 4H2.5l.656 10.5h7.656z"
      ></path>
    </svg>
  );
}
