import React from "react";
import { PSmall } from "@tempoplatform/tpds/elements/typography";
import StarsRating from "@tempoplatform/tpds/components/stars-rating";

interface Props {
  rating: number;
  username: string;
  date: string;
  title?: string;
  description: string;
}

const ReviewBox: React.FC<Props> = ({ rating, username, date, title, description }) => {
  return (
    <div className="bg-grey-light-scale-200 rounded px-4 pt-2.5 pb-1.5 mb-2.5">
      <div className="border-b border-grey-light-scale-400 flex justify-between w-auto pb-2.5">
        <StarsRating rating={rating} />
        <div className="flex gap-x-2 w-auto">
          <PSmall
            isMedium
            style={{
              maxWidth: "120px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {username}
          </PSmall>
          <PSmall className="tracking-tight">{date}</PSmall>
        </div>
      </div>
      <div className="py-2.5">
        {title && (
          <PSmall isMedium className="mb-2">
            {title}
          </PSmall>
        )}
        <PSmall className="leading-normal">{description}</PSmall>
      </div>
    </div>
  );
};

export default ReviewBox;
