import React from "react";
import { PNano, H3, H5, H6 } from "@tempoplatform/tpds/elements/typography";
import { useAppSelector } from "../../../../../app/hooks";

interface RowProps {
  index: number;
  percentage: number;
  addSpace: boolean;
}

const RowComponent: React.FC<RowProps> = ({ index, percentage, addSpace }) => {
  return (
    <>
      <div className="w-full flex relative items-center justify-between h-[6px] mb-2">
        <PNano isMedium style={{ width: "8px", textAlign: "center" }}>
          {index}
        </PNano>
        <div
          className="bg-grey-light-scale-200 h-[6px] rounded relative"
          style={{ width: "calc(100% - 20px)" }}
        >
          <div
            className="bg-yellow h-[6px] rounded absolute top-0 left-0 transition-all duration-300"
            style={{ width: `${percentage}%` }}
          />
        </div>
      </div>
      {addSpace && <div className="h-2.5" />}
    </>
  );
};

interface ComponentProps {
  productIndex: number;
  formattedReviewCount: any;
  percentFiveStars: number;
  percentFourStars: number;
  percentThreeStars: number;
  percentTwoStars: number;
  percentOneStar: number;
}

const ReviewsSummary: React.FC<ComponentProps> = ({
  productIndex,
  formattedReviewCount,
  percentFiveStars,
  percentFourStars,
  percentThreeStars,
  percentTwoStars,
  percentOneStar,
}) => {
  const products = useAppSelector(state => state.campaignCreate.products);
  const product = products[productIndex];
  const overallScore = product && product.review_overall_score ? product.review_overall_score : 4.5;
  const percentRecommended =
    product && product.review_percent_recommended ? product.review_percent_recommended : 81;

  return (
    <div>
      <div
        style={{
          display: "flex",
          marginBottom: "20px",
          justifyContent: "space-between",
        }}
      >
        <div className="w-full mr-4">
          <RowComponent index={5} percentage={percentFiveStars} addSpace={true} />
          <RowComponent index={4} percentage={percentFourStars} addSpace={true} />
          <RowComponent index={3} percentage={percentThreeStars} addSpace={true} />
          <RowComponent index={2} percentage={percentTwoStars} addSpace={true} />
          <RowComponent index={1} percentage={percentOneStar} addSpace={false} />
        </div>
        <div className="w-[130px] flex justify-center">
          <div style={{ width: "auto", position: "relative", top: "-10px" }}>
            <div className="flex items-baseline relative top-[-2px]">
              <H3 className="tracking-tight leading-none !text-[36px]">{overallScore}</H3>
              &thinsp;
              <H6 className="tracking-tight">/5</H6>
            </div>
            <PNano>{formattedReviewCount} reviews</PNano>
            <div className="flex items-baseline relative mt-0">
              <H3 className="tracking-tight leading-none !text-[36px]">{percentRecommended}</H3>
              <H5 className="leading-none">&thinsp;%</H5>
            </div>
            <PNano>Recommended</PNano>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewsSummary;
