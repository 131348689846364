import styled from "@emotion/styled";
import clsx from "clsx";

export const GreyBox = props => {
  const finalClass = clsx(
    `bg-grey-light-scale-200 dark:bg-grey-dark-scale-500 p-4 rounded border-2 border-window`,
    `flex flex-col gap-2`,
    props.className,
  );
  return <Div className={finalClass}>{props.children}</Div>;
};

const Div = styled.div`
  animation-name: fadein;
  animation-timing-function: linear;
  animation-duration: 3s;
  animation-iteration-count: 1;
  @keyframes fadein {
    0% {
      border-color: var(--bg-info-hover);
    }
    20% {
      border-color: var(--border-window);
    }
    40% {
      border-color: var(--bg-info-hover);
    }
    60% {
      border-color: var(--border-window);
    }
    80% {
      border-color: var(--bg-info-hover);
    }
    100% {
      border-color: var(--border-window);
    }
  }
`;
