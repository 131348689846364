import React from "react";
import InitialScreenContent from "./InitialScreenContent";
import InitialScreenMainMedia from "./InitialScreenMainMedia";

const InitialScreen: React.FC = () => {
  const [soundOn, setSoundOn] = React.useState(false);
  return (
    <div className="w-full h-full absolute top-0 left-0 translate-x-0 transition-transform duration-300 ease-in-out">
      <InitialScreenContent soundOn={soundOn} setSoundOn={setSoundOn} />
      <InitialScreenMainMedia soundOn={soundOn} />
    </div>
  );
};
export default InitialScreen;
