import React from "react";
import { Button } from "@tempoplatform/tpds/elements/buttons";
import { PlusOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { createNewProductReview } from "../campaignCreateSlice";
import ProductReview from "./ProductReview";
import moment from "moment";

interface Props {
  id: number;
}

const ProductReviews = (props: Props) => {
  const dispatch = useAppDispatch();
  let productIndex = props.id - 1;
  const productReviews = useAppSelector(state => state.campaignCreate.productReviews);
  const thisProductReviews = productReviews[productIndex];
  return (
    <div className="flex flex-col gap-y-2">
      {thisProductReviews.map((productReview, index) => {
        return (
          <ProductReview
            key={`shipping-option${index}`}
            productIndex={productIndex}
            productReview={productReview}
            reviewIndex={index}
          />
        );
      })}
      <Button
        className="mt-2"
        variant="secondary"
        onClick={() =>
          dispatch(
            createNewProductReview({
              productIndex: productIndex,
              date: moment.utc().toISOString(),
            }),
          )
        }
        style={{
          width: "100%",
          borderStyle: "dashed",
          borderWidth: "2px",
          borderRadius: "4px",
        }}
      >
        <PlusOutlined /> Add user review
      </Button>
    </div>
  );
};
export default ProductReviews;
