import moment from "moment";
import AdTypes from "../../../constants/ad_types";
import { CustomField } from "../../../constants/interfaces";

const prepareData = (accountId: number, state: any) => {
  const enumsData = state.enumsData;
  const locationData = enumsData.location;
  const interestData = enumsData.interest;
  const languageData = enumsData.language;
  const genderData = enumsData.gender;

  const productsData = [];
  const generateInfoBoxesObject = (infoBoxes: any) => {
    const infoBoxesObject = {};
    infoBoxes.forEach((infoBox: any) => {
      // @ts-ignore
      infoBoxesObject[infoBox.title] = infoBox.description;
    });
    return infoBoxesObject;
  };
  const getEnumId = (dataArray: any, itemName: string) => {
    const item = dataArray.find((item: any) => item.value === itemName);
    return item ? item.id : null;
  };
  const generateReviewScoresData = (product: any) => {
    return {
      bar_1: product.review_chart_bar_1_percent,
      bar_2: product.review_chart_bar_2_percent,
      bar_3: product.review_chart_bar_3_percent,
      bar_4: product.review_chart_bar_4_percent,
      bar_5: product.review_chart_bar_5_percent,
      count: product.review_count,
      score: product.review_overall_score,
      percent_recommended: product.review_percent_recommended,
    };
  };
  const generateIndividualReviewsData = (productReviews: any) => {
    return productReviews.map((review: any) => {
      return {
        created_timestamp: moment(review.date).toISOString(),
        star_rating: review.stars,
        title: review.title,
        content: review.description,
        user_name: review.username,
      };
    });
  };
  const generateShippingOptionsObject = (shippingOptions: any) => {
    const processedShippingOptions = [];
    for (let w = 0; w < shippingOptions.length; w++) {
      // @ts-ignore
      const currentShippingOption = structuredClone(shippingOptions[w]);
      const unprocessedRegionalAvailabilities = currentShippingOption.regional_availabilities;
      currentShippingOption.regional_availabilities = unprocessedRegionalAvailabilities.map(
        (region: any) => {
          return { region: getEnumId(locationData, region) };
        },
      );
      processedShippingOptions.push(currentShippingOption);
    }

    return processedShippingOptions;
  };
  for (let i = 0; i < 4; i++) {
    const newProductData = {
      image_urls: state.productImages[i].map((image: any) => image.url),
      name: state.products[i].name,
      url: "",
      info_boxes: generateInfoBoxesObject(state.infoBoxes[i]),
      shipping_options: generateShippingOptionsObject(state.shippingOptions[i]),
      price: state.products[i].price || 0,
      product_variants: state.variantBoxes[i].map((variant: any) => {
        const lcVariantName = String(variant.title).toLowerCase();
        const isColorVariant = lcVariantName === "color" || lcVariantName === "colour";
        return {
          name: variant.title,
          values: variant.values.map((value: any) => value.title),
          hex_colors: isColorVariant
            ? variant.values.map((value: any) => String(value.hex_color).replace("#", ""))
            : variant.values.map((value: any) => ""),
          image_indices: variant.set_indices
            ? variant.values.map((value: any) => value.image_index)
            : variant.values.map((value: any) => 0),
        };
      }),
    };
    if (state.products[i].add_reviews) {
      // @ts-ignore
      newProductData.review_scores = generateReviewScoresData(state.products[i]);
      if (
        state.productReviews[i] &&
        state.productReviews[i].length &&
        state.productReviews[i].length > 0
      ) {
        // @ts-ignore
        newProductData.reviews = generateIndividualReviewsData(state.productReviews[i]);
      }
    }
    productsData.push(newProductData);
  }

  const campaignLocations = state.targeting_locations.map((item: any) => {
    return getEnumId(locationData, item);
  });

  const campaignInterests = state.targeting_interests.map((item: any) => {
    return getEnumId(interestData, item);
  });

  const campaignLanguages = state.targeting_languages.map((item: any) => {
    return getEnumId(languageData, item);
  });

  let adType;
  if (state.ad_type === AdTypes.brand_awareness) {
    adType = "brand_awareness";
  } else if (state.ad_type === AdTypes.shoppable) {
    adType = "shoppable";
  } else if (state.ad_type === AdTypes.images_form) {
    adType = "images_and_form";
  }

  const getFormProperties = () => {
    const properties: any[] = [];
    state.formFields.custom_fields.forEach((field: CustomField, index: number) => {
      properties.push({
        property_name: field.label,
        property_type: "STR",
        property_default: field.label,
        property_order: index,
        property_values: field.values,
        is_mandatory: field.mandatory,
      });
    });
    return properties;
  };

  return {
    account_id: accountId,
    name: state.campaignName,
    ad_type: adType,
    cta: state.cta_label,
    headline: state.headline,
    brand_name: state.brandName,
    image_url: state.mainImage || "",
    logo_url: state.logoImage,
    video_url: state.video || "",
    is_advanced_config: true,
    products: productsData,
    top_hex_color: state.topHexColor,
    bottom_hex_color: state.bottomHexColor,
    start_date: moment(state.start_end_dates[0]).toISOString(),
    end_date: moment(state.start_end_dates[1]).toISOString(),
    max_age: state.targeting_age.min,
    min_age: state.targeting_age.max,
    gender: getEnumId(genderData, state.targeting_gender),
    budget: state.budget_daily,
    form_properties: state.ad_type !== AdTypes.images_form ? null : getFormProperties(),
    campaign_interests: campaignInterests,
    campaign_languages: campaignLanguages,
    campaign_locations: campaignLocations,
    cpm: state.budget_cpm,
    is_interstitial: String(state.rewarded_interstitial).toLowerCase() === "interstitial",
    ad_style: {
      top_island: {
        background_color: state.topIslandBackgroundColor,
        text_color: state.topIslandTextColor,
      },
      bottom_island: {
        background_color: state.bottomIslandBackgroundColor,
        thumbnails_background_color: state.bottomIslandThumbnailsBackgroundColor,
        thumbnails_padding: state.bottomIslandThumbnailsPadding,
      },
    },
  };
};

export default prepareData;
