import validator from "validator";
import AdTypes from "../../../constants/ad_types";
import moment from "moment";

const validate = (state: any) => {
  const errors = [Array<string>(), Array<string>(), Array<string>(), Array<string>()];
  for (let i = 0; i < 4; i++) {
    const product = state.products[i];
    const productImages = state.productImages[i];
    // product name
    if (!product.name || product.name.length < 2) {
      errors[i].push(`- Missing product name`);
    }
    // product price
    if (state.ad_type !== AdTypes.brand_awareness && state.ad_type !== AdTypes.images_form) {
      if (!product.price || product.price.length < 2) {
        errors[i].push(`- Missing product price`);
      }
    }
    // check for number of images
    if (!productImages || productImages.length === 0) {
      errors[i].push(`- Needs at least one image`);
    }
    if (state.ad_type !== AdTypes.images_form && product.add_reviews) {
      if (!product.review_count) {
        errors[i].push(`- Missing review count`);
      } else {
        if (isNaN(product.review_count) || product.review_count === 0) {
          errors[i].push(`- Review count must be a number greater than 0`);
        }
      }
      if (!product.review_overall_score) {
        errors[i].push(`- Missing overall score`);
      } else {
        if (isNaN(product.review_overall_score) || product.review_overall_score === 0) {
          errors[i].push(`- Overall score must be a number greater than 0`);
        }
      }
    }
  }
  if(state.ad_type !== AdTypes.images_form) {
    for (let i = 0; i < 4; i++) {
      const productVariants = state.variantBoxes[i];
      const productReviews = state.productReviews[i];
      const productInfoBoxes = state.infoBoxes[i];
  
      // uncomment next conditional if we want to require at least one product variant
      // if (productVariants.length === 0) {
      //   errors[i].push(`- Needs at least one product variant`);
      // }
      // check if any info box description is missing
      for (const infoBox of productInfoBoxes) {
        if (!infoBox || !infoBox.description || infoBox.description === "") {
          errors[i].push(`Info box "${infoBox.title}" is empty`);
        }
      }
      if (state.ad_type !== AdTypes.brand_awareness) {
        const shippingOptions = state.shippingOptions[i];
        if (shippingOptions.length === 0) {
          errors[i].push(`- Needs at least one shipping option`);
        }
  
        // check for incomplete shippingn options
        let shippingOptionCount = 1;
        for (const shippingOption of shippingOptions) {
          let displayName: any = `"${shippingOption.display_name}"`;
          if (!shippingOption.display_name || shippingOption.display_name === "") {
            displayName = shippingOptionCount;
            errors[i].push(`- Shipping option ${displayName} is missing a display name`);
          }
          if (
            (isNaN(shippingOption.amount) ||
              !shippingOption.amount ||
              shippingOption.amount === "") &&
            shippingOption.amount !== 0
          ) {
            errors[i].push(`- Shipping option ${displayName} is missing a shipping cost`);
          }
          if (shippingOption.regional_availabilities.length === 0) {
            errors[i].push(`- Shipping option ${displayName} is missing regional availability`);
          }
          if (shippingOption.del_est_min_value >= shippingOption.del_est_max_value) {
            errors[i].push(
              `- Shipping option ${displayName} min. delivery time must be less than max. delivery time`,
            );
          }
          shippingOptionCount++;
        }
      }
      // validate reviews
      for (let r = 0; r < productReviews.length; r++) {
        const reviewHasUsername = productReviews[r].username && productReviews[r].username.length > 1;
        if (!reviewHasUsername) {
          errors[i].push(`- Product review ${r + 1} needs a Username`);
        }
        const reviewStars = parseFloat(productReviews[r].stars);
        if (isNaN(reviewStars) || reviewStars < 0 || reviewStars > 5) {
          errors[i].push(`- Product review ${r + 1} stars must be between 0 and 5`);
        }
        const reviewHasDescription =
          productReviews[r].description && productReviews[r].description.length > 1;
        if (!reviewHasDescription) {
          errors[i].push(`- Product review ${r + 1} needs a Description`);
        }
        const reviewHasTitle = productReviews[r].title && productReviews[r].title.length > 1;
        if (!reviewHasTitle) {
          errors[i].push(`- Product review ${r + 1} needs a Title`);
        }
        const reviewDate = productReviews[r].date;
        // check if review date is not in the past
        if (moment.utc(reviewDate).isAfter(moment.utc())) {
          errors[i].push(`- Product review ${r + 1} date must be in the past`);
        }
      }
      // validate variants
      for (let w = 0; w < productVariants.length; w++) {
        const variantHasTitle = productVariants[w].title && productVariants[w].title.length > 1;
        if (!variantHasTitle) {
          errors[i].push(`- ${i + 1} Variant ${w + 1} needs a title`);
        }
        // check if variant has no values
        if (!productVariants[w].values || productVariants[w].values.length === 0) {
          errors[i].push(
            `- Variant "${variantHasTitle ? productVariants[w].title : w + 1}" needs values`,
          );
        }
  
        for (let k = 0; k < productVariants[w].values.length; k++) {
          const value = productVariants[w].values[k];
          // check if variant has no title
          const missingTitle = !value.title || value.title.length < 1;
          const valueReference = missingTitle ? k + 1 : `"${value.title}"`;
          if (missingTitle) {
            errors[i].push(`- Variant "${productVariants[w].title}" value ${k + 1} needs a name`);
          }
          if (
            productVariants[w].title &&
            String(productVariants[w].title).toLowerCase() === "color"
          ) {
            if (!value.hex_color || value.hex_color === "") {
              errors[i].push(
                `- Variant "${productVariants[w].title}" value ${valueReference} hex color code has not been set`,
              );
            } else {
              if (!validator.isHexColor(value.hex_color)) {
                errors[i].push(
                  `- Variant "${productVariants[w].title}" value ${valueReference} hex color isn't valid`,
                );
              }
            }
          }
          if (productVariants[w].set_indices) {
            // TODO: assure relationship between indexes and uploaded images match
            if (!value.image_index && value.image_index !== 0) {
              errors[i].push(
                `- Variant "${productVariants[w].title}" value ${valueReference} is missing image index`,
              );
            }
          }
        }
      }
    }
  }

  return {
    validated:
      errors[0].length === 0 &&
      errors[1].length === 0 &&
      errors[2].length === 0 &&
      errors[3].length === 0,
    errors,
  };
};

export default validate;
