import { useState, FC, ReactNode } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import AccessTokenContext from "./AccessTokenContext";

const AccessTokenContextWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const { getIdTokenClaims } = useAuth0();

  let myInterval: NodeJS.Timeout;

  const getAccessToken = async () => {
    const claims = await getIdTokenClaims();
    if (claims) {
      const id_token = claims?.__raw ?? null;
      setAccessToken(id_token);
      clearInterval(myInterval);
    }
  };

  // this interval is cleared after the token is retrieved
  myInterval = setInterval(getAccessToken, 200);

  return (
    <AccessTokenContext.Provider value={{ accessToken: accessToken }}>
      {children}
    </AccessTokenContext.Provider>
  );
};

export default AccessTokenContextWrapper;
