import React from "react";
import InitialScreen from "./preview/InitialScreen";
import ProductScreen from "./preview/ProductScreen";
import BottomCTA from "./preview/product_screen/BottomCTA";
import BrandAwarenessDialog from "./preview/product_screen/sub_components/BrandAwarenessDialog";
import ReviewsAll from "./preview/product_screen/sub_components/ReviewsAll";

const PhonePreviewRoot: React.FC = () => {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Tab") {
      e.preventDefault();
      e.stopPropagation();
    }
  };
  return (
    <div className="light w-full h-[101%] absolute top-0 left-0" onKeyDown={handleKeyDown}>
      <InitialScreen />
      <ProductScreen />
      <BottomCTA />
      <BrandAwarenessDialog />
      <ReviewsAll />
    </div>
  );
};
export default PhonePreviewRoot;
