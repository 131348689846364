import React from "react";
import { Button } from "@tempoplatform/tpds/elements/buttons";
import { useHistory } from "react-router-dom";
import { H6, PSmall } from "@tempoplatform/tpds/elements/typography";
import { Window, Separator } from "@tempoplatform/tpds/elements/layout";

const PublisherAppsEmpty = () => {
  const history = useHistory();
  return (
    <Window style={{ maxWidth: "400px", margin: "0 auto" }}>
      <H6>My Apps</H6>
      <Separator />
      <br />
      <PSmall>You currently have no Apps. Please create one.</PSmall>
      <br />
      <Button variant="info" onClick={() => history.push("/pub/applications/create")}>
        Add New App
      </Button>
    </Window>
  );
};
export default PublisherAppsEmpty;
