import React from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Button } from "@tempoplatform/tpds/elements/buttons";
import { updateShippingOption, removeShippingOption } from "../campaignCreateSlice";
import { GreyBox } from "../../../shared/atoms";
import { FormLabelSmall, Red } from "@tempoplatform/tpds/elements/typography";
import { TextInput } from "@tempoplatform/tpds/elements/input";
import PriceInput from "@tempoplatform/tpds/components/input/price";
import TPDSMultiSelect from "@tempoplatform/tpds/components/select-multi";

interface Props {
  productIndex: number;
  shippingOptionIndex: number;
}

const ShippingOption = (props: Props) => {
  const { productIndex, shippingOptionIndex } = props;
  const dispatch = useAppDispatch();
  const shippingOption = useAppSelector(state => state.campaignCreate.shippingOptions)[
    productIndex
  ][shippingOptionIndex];
  const enumsData = useAppSelector(state => state.campaignCreate.enumsData);
  const locationsData = enumsData.location;

  if (!shippingOption) return null;

  const callUpdateShippingOption = (updatedShippingOption: any) => {
    dispatch(
      updateShippingOption({
        productIndex: productIndex,
        shippingOptionIndex: shippingOptionIndex,
        value: updatedShippingOption,
      }),
    );
  };
  const updateShippingOptionDisplayName = (value: string) => {
    const updatedShippingOption = {
      ...shippingOption,
      display_name: value,
    };
    callUpdateShippingOption(updatedShippingOption);
  };
  const updateShippingOptionAmount = (value: string) => {
    const parsedValue = parseFloat(value);
    const updatedShippingOption = {
      ...shippingOption,
      amount: !isNaN(parsedValue) ? parsedValue : 0,
    };
    callUpdateShippingOption(updatedShippingOption);
  };
  const updateShippingOptionMinDeliveryTime = (value: string) => {
    const parsedValue = parseInt(value);
    const updatedShippingOption = {
      ...shippingOption,
      del_est_min_value: !isNaN(parsedValue) ? parsedValue : 1,
    };
    callUpdateShippingOption(updatedShippingOption);
  };
  const updateShippingOptionMaxDeliveryTime = (value: string) => {
    const parsedValue = parseInt(value);
    const updatedShippingOption = {
      ...shippingOption,
      del_est_max_value: !isNaN(parsedValue) ? parsedValue : 1,
    };
    callUpdateShippingOption(updatedShippingOption);
  };
  const updateShippingOptionRegionalAvailability = (updatedValues: any) => {
    const items = [];
    for (let i = 0; i < updatedValues.length; i++) {
      items.push(locationsData[updatedValues[i]].value);
    }

    const updatedShippingOption = {
      ...shippingOption,
      regional_availabilities: items,
    };
    callUpdateShippingOption(updatedShippingOption);
  };

  const selectedLocationsIndexes: any = [];
  locationsData.forEach((item: any, index: number) => {
    // @ts-ignore
    if (shippingOption.regional_availabilities.includes(item.value)) {
      selectedLocationsIndexes.push(index);
    }
  });

  return (
    <div>
      <GreyBox className="my-4 bg-transparent">
        <div className="flex items-start justify-between gap-x-4">
          <div>
            <FormLabelSmall className="mb-2">
              <Red>*</Red> Display name
            </FormLabelSmall>
            <TextInput
              value={shippingOption.display_name}
              placeholder="Display name"
              onChange={(e: any) => updateShippingOptionDisplayName(e.target.value)}
            />
          </div>
          <div>
            <FormLabelSmall className="mb-2">
              <Red>*</Red> Cost
            </FormLabelSmall>
            <PriceInput
              initialValue={String(shippingOption.amount)}
              currencySymbol="$"
              onChangeCallback={(value: any) => updateShippingOptionAmount(value)}
            />
          </div>
        </div>
        <FormLabelSmall className="mb-2 mt-4">Estimated delivery time (days)</FormLabelSmall>
        <div className="flex items-start justify-between gap-x-4">
          <div>
            <FormLabelSmall className="mb-2">
              <Red>*</Red> Minimum
            </FormLabelSmall>
            <TextInput
              value={shippingOption.del_est_min_value}
              placeholder="Min. estimated delivery time"
              onChange={(e: any) => updateShippingOptionMinDeliveryTime(e.target.value)}
            />
          </div>
          <div>
            <FormLabelSmall className="mb-2">
              <Red>*</Red> Maximum
            </FormLabelSmall>
            <TextInput
              value={shippingOption.del_est_max_value}
              placeholder="Max. estimated delivery time"
              onChange={(e: any) => updateShippingOptionMaxDeliveryTime(e.target.value)}
            />
          </div>
        </div>
        <div>
          <FormLabelSmall className="mt-2">
            <Red>*</Red> Regional availability
          </FormLabelSmall>
          <TPDSMultiSelect
            options={locationsData}
            placeholder="Select"
            labelProp="value"
            idProp="id"
            selectedIndexes={selectedLocationsIndexes}
            handleSelectionUpdate={(array: any) => {
              updateShippingOptionRegionalAvailability(array);
            }}
          />
        </div>

        <div className="flex justify-end">
          <Button
            variant="secondary"
            onClick={() => {
              dispatch(
                removeShippingOption({
                  productIndex: productIndex,
                  shippingOptionIndex: shippingOptionIndex,
                  value: null,
                }),
              );
            }}
          >
            Remove
          </Button>
        </div>
      </GreyBox>
    </div>
  );
};
export default ShippingOption;
