import React from "react";
import { PSmall } from "@tempoplatform/tpds/elements/typography";
import RadioGroup from "@tempoplatform/tpds/components/radiogroup";
import { useAppSelector } from "../../../../app/hooks";

interface ComponentProps {
  productIndex: number;
}
const ShippingSelector: React.FC<ComponentProps> = () => {
  const preview_selectedProduct = useAppSelector(
    state => state.campaignCreate.preview_selectedProduct,
  );
  let productShippingOptions = useAppSelector(
    state => state.campaignCreate.shippingOptions[preview_selectedProduct - 1],
  );
  const [selectedShippingModeIndex, setSelectedShippingModeIndex] = React.useState<number>(0);

  const previewCampaignData = useAppSelector(state => state.campaignCreate.previewCampaignData);
  if (preview_selectedProduct - 1 === -1) {
    return null;
  }
  if (previewCampaignData) {
    productShippingOptions =
      previewCampaignData.campaign_products[preview_selectedProduct - 1].product
        .campaign_product_shipping_options;
    // @ts-ignore
    productShippingOptions = productShippingOptions.map(o => o.shipping_option);
  }

  let content = null;
  if (!productShippingOptions || productShippingOptions.length === 0) {
    content = (
      <PSmall className="leading-normal mt-2 !mb-0 !pb-0 text-tertiary">
        Your product does not have any shipping options. Please add shipping options in the product
        Shipping section.
      </PSmall>
    );
  } else {
    const shippingTypes = productShippingOptions.map(shippingOption => {
      const shippingColor = shippingOption.amount === 0 ? "#00B612" : "#000";
      const shippingPrice = shippingOption.amount === 0 ? "Free" : `$${shippingOption.amount}`;
      const shippingTime = `${shippingOption.del_est_min_value}-${shippingOption.del_est_max_value}`;
      return {
        title: shippingOption.display_name,
        description: (
          <span style={{ color: "#ADADAD" }}>
            <span style={{ color: shippingColor }}>{shippingPrice}</span> {shippingTime} business
            days
          </span>
        ),
        recommended: false,
      };
    });
    content = (
      <RadioGroup
        lightOnly
        options={shippingTypes}
        selectedIndex={selectedShippingModeIndex}
        handleIndexSelection={(index: number) => {
          setSelectedShippingModeIndex(index);
        }}
      />
    );
  }

  return (
    <div className="w-full p-4 border-t border-window">
      <PSmall isMedium className="leading-none mb-2">
        Shipping
      </PSmall>
      {content}
    </div>
  );
};
export default ShippingSelector;
