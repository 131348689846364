import styled from "@emotion/styled";
import React from "react";
import { P } from "@tempoplatform/tpds/elements/typography";
import { ArrowDownIcon } from "@tempoplatform/tpds/assets/svgs/arrows";

interface Props {
  optionsArray: Array<any>;
  selectedOption: any;
  handleChange: any;
}

const RootDiv = styled.div`
  .custom-select {
    -webkit-appearance: none;
    ::-ms-expand {
      display: none;
    }
  }
`;

const CustomSelect: React.FC<Props> = ({ optionsArray, selectedOption, handleChange }) => {
  const defaultValue = optionsArray.find((option: any) => {
    return option === selectedOption;
  });
  return (
    <RootDiv>
      <div className="relative w-full">
        <select
          className="custom-select absolute w-full h-full opacity-0"
          onChange={handleChange}
          value={defaultValue}
        >
          {optionsArray.map((option: number) => {
            return (
              <option key={option} value={option}>
                {option}
              </option>
            );
          })}
        </select>
        <div className="flex bg-white w-full h-[36px] rounded border-2 border-grey-dark-scale-500">
          <div className="flex h-full items-center px-2" style={{ width: "calc(100% - 36px)" }}>
            <P isMedium className="leading-none relative top-[0px] text-[15px]!">
              {selectedOption}
            </P>
          </div>
          <div className="flex justify-center items-center w-[36px] h-full border-l-2 border-grey-dark-scale-500">
            <ArrowDownIcon />
          </div>
        </div>
      </div>
    </RootDiv>
  );
};

export default CustomSelect;
