export const determineVariantData = (variant: any, product: any) => {
  const product_skus = product.skus;
  const preview_image_indices: any = [];
  const newPreviewVariant: any = {};
  newPreviewVariant.id = variant.id;
  newPreviewVariant.title = variant.name;
  newPreviewVariant.values = [];

  const variant_id = variant.id;

  for (let w = 0; w < variant.product_variant_options.length; w++) {
    const product_variant_option_w = variant.product_variant_options[w];
    const product_variant_option_w_id = product_variant_option_w.id;

    newPreviewVariant.values.push({
      title: product_variant_option_w.name,
      hex_color: `#${product_variant_option_w.hex_color}`,
    });

    for (let k = 0; k < product_skus.length; k++) {
      const sku_k = product_skus[k];
      for (let x = 0; x < sku_k.sku_product_variant_options.length; x++) {
        const product_variant_option_x = sku_k.sku_product_variant_options[x];
        if (
          product_variant_option_x.product_variant_id === variant_id &&
          product_variant_option_x.product_variant_option_id === product_variant_option_w_id
        ) {
          if (!preview_image_indices.includes(sku_k.image_url_index)) {
            preview_image_indices.push(sku_k.image_url_index);
          }
        }
      }
    }
  }

  newPreviewVariant.set_indices = preview_image_indices.length > 0;
  newPreviewVariant.values = newPreviewVariant.values.map((value: any, index: number) => {
    return {
      ...value,
      image_index: preview_image_indices[index],
    };
  });

  return {
    name: newPreviewVariant.title,
    values: newPreviewVariant.values,
    set_indices: newPreviewVariant.set_indices,
  };
};
