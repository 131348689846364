import axios from "axios";
import { baseUrl } from "./baseUrl";

export const toGetAccount = async (userId: string, token: any) => {
  const endPoint = `/accounts/?user_id=${userId}`;
  const response = await axios.get(baseUrl + endPoint, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const toGetApps = async (accountId: number, token: any) => {
  const endPoint = `/apps/?account_id=${accountId}`;
  const response = await axios.get(baseUrl + endPoint, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const toPostOrganization = async (data: any, token: any) => {
  const endPoint = `/organization/`;
  const response = await axios.post(
    baseUrl + endPoint,
    {
      user_id: data.user_id,
      account_name: data.account_name,
      currency: data.currency,
      phone_country_code: data.phone_country_code,
      phone_number: data.phone_number,
      email: data.email,
      organization_name: data.organization_name,
      country: data.country,
      business_type: data.business_type,
      account_type: data.account_type,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};

export const toPostRequestApiKey = async (accountId: any, token: any) => {
  const endPoint = `/apiKey/`;
  const response = await axios.post(
    baseUrl + endPoint + `?account_id=${accountId}`,
    {
      account_id: accountId,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};

export const toPatchRevokeApiKey = async (accountId: string, apiKeyId: any, token: any) => {
  const endPoint = `/apiKey/`;
  const response = await axios.patch(
    baseUrl + endPoint + `?api_key_id=${apiKeyId}`,
    {
      account_id: accountId,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};

export const toPostApp = async (data: any, token: any) => {
  const endPoint = `/apps/`;
  const response = await axios.post(baseUrl + endPoint, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const toPostCampaign = async (data: any, token: any) => {
  const endPoint = `/campaigns/`;
  const response = await axios.post(baseUrl + endPoint, data, {
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const getCampaignEnums = async (token: any) => {
  const endPoint = `${baseUrl}/campaignsEnums`;
  const response = await axios
    .get(endPoint, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .catch(err => {
      console.log("err", err);
      return err;
    });

  if (response) {
    return response;
  }
};

export const getBusinessTypeEnums = async (token: any) => {
  const endPoint = `${baseUrl}/accountEnums`;
  const response = await axios
    .get(endPoint, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .catch(err => {
      console.log("err", err);
      return err;
    });

  if (response) {
    return response.data;
  }
};

export const getCampaigns = async (token: string, accountId: number) => {
  const endPoint = `${baseUrl}/campaigns?account_id=${accountId}`;
  const response = await axios
    .get(endPoint, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .catch(err => {
      console.log("err", err);
      return err;
    });

  if (response) {
    return response.data;
  }
};

export const getCampaignDetails = async (campaignId: number, token: string | undefined) => {
  const endPoint = `${baseUrl}/campaigns/${campaignId}`;
  const response = await axios
    .get(endPoint, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .catch(err => {
      console.log("err", err);
      return err;
    });

  if (response) {
    return response;
  }
};

export const toGetPublisherCharts = async (weeksOfData: number, token: string) => {
  const endPoint = `${baseUrl}/charts/publisher?weeks_of_data=${weeksOfData}`;
  const response = await axios
    .get(endPoint, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .catch(err => {
      console.log("err", err);
      return err;
    });

  if (response) {
    return response;
  }
};

export const toGetAdvertiserCharts = async (weeksOfData: number, token: string | null) => {
  if (!token)
    return console.log("toGetAdvertiserCharts ::: Access token required but not provided");
  const endPoint = `${baseUrl}/charts/advertiser?weeks_of_data=${weeksOfData}`;
  const response = await axios
    .get(endPoint, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .catch(err => {
      console.log("err", err);
      return err;
    });

  if (response) {
    return response;
  }
};

export const patchCampaignIsActive = async (campaignId: number, isActive: boolean) => {
  console.log("We are not allowing this action at this time.");
};

export interface CampaignListItem {
  id: number;
  name: string;
  delivery_status: string;
  is_active: boolean;
  start_date: string;
  end_date: string;
}
