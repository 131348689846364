import styled from "@emotion/styled";
import React from "react";
import Targeting from "./delivery/Targeting";
//import Optimization from "./delivery/Optimization";
import BudgetAndDuration from "./delivery/BudgetAndDuration";

const RootDiv = styled.div`
  .ant-form-item {
    margin: 0;
  }
  .ant-select {
    padding-top: 0;
  }
  .ant-select-selector {
    width: 100%;
  }
  .ant-select-multiple .ant-select-selector {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .ant-select-clear {
    top: 17px !important;
  }
`;

const Delivery = () => {
  return (
    <RootDiv className="flex flex-col gap-4 p-4 pb-0">
      <Targeting />
      {/* <Optimization /> */}
      <BudgetAndDuration />
    </RootDiv>
  );
};
export default Delivery;
