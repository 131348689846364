import React from "react";
import { PSmall } from "@tempoplatform/tpds/elements/typography";
import Callout from "@tempoplatform/tpds/components/callout";
import { Separator } from "@tempoplatform/tpds/elements/layout";

const StaticContent: React.FC = () => {
  return (
    <div className="mb-20">
      <Separator className="my-14" />
      <Callout variant="info" title="What can I do with API Keys?">
        <PSmall className="leading-snug">
          API Keys are used to authenticate your requests to Tempo. You can create multiple API Keys
          and revoke them at any time. With Tempo API Keys, you can access your publisher data
          independently from outside this application. The returned data contains Requests,
          Impressions, Clicks, and Revenue, for the purpose of analysis, visualisation, etc.
        </PSmall>
      </Callout>
    </div>
  );
};

export default StaticContent;
