const validate = (state: any) => {
  const errors = [];
  if (!state.targeting_gender) {
    errors.push("Target gender");
  }
  if (
    !state.targeting_age.min ||
    !state.targeting_age.max ||
    state.targeting_age.min > state.targeting_age.max
  ) {
    errors.push("Age range");
  }
  if (!state.targeting_languages || state.targeting_languages.length === 0) {
    errors.push("Target languages");
  }
  if (!state.targeting_locations || state.targeting_locations.length === 0) {
    errors.push("Target locations");
  }
  if (!state.targeting_interests || state.targeting_interests.length === 0) {
    errors.push("Target interests");
  }
  // if (!state.targeting_goal) {
  //   errors.push("Optimization goal");
  // }
  // if (!state.targeting_bid_type) { // redundant
  //   errors.push("Optimization bid type");
  // }
  if (!state.start_end_dates[0]) {
    errors.push("Duration: start date");
  }
  if (!state.start_end_dates[1]) {
    errors.push("Duration: end date");
  }
  if (!state.budget_daily) {
    errors.push("Daily budget");
  }
  if (!state.budget_cpm) {
    errors.push("CPM budget");
  }
  return {
    validated: errors.length === 0,
    errors,
  };
};

export default validate;
