import { Popconfirm, Tooltip } from "antd";
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { useAppDispatch } from "../../app/hooks";
import { resetCampaignCreate } from "../campaignCreate/campaignCreateSlice";
import { P, PTiny } from "@tempoplatform/tpds/elements/typography";
import Tag from "@tempoplatform/tpds/components/tag";
import Table from "@tempoplatform/tpds/components/table";
import { Button } from "@tempoplatform/tpds/elements/buttons";
import { CampaignListItem, patchCampaignIsActive } from "../api/api";
import Modal from "@tempoplatform/tpds/components/modal";
import { Link } from "react-router-dom";

interface Props {
  data: CampaignListItem[];
}

let renderDate = (timestamp: number) => {
  let date = new Date(timestamp);
  let year = date.getFullYear();
  let day = date.getDate();
  let month = date.toLocaleString("default", { month: "short" });
  return <span>{`${month} ${day}, ${year}`}</span>;
};

const getTagVariant = (campaignState: string) => {
  switch (campaignState) {
    case "ACTIVE":
      return "success";
    case "INACTIVE":
      return "warning";
    case "UPCOMING":
      return "info";
    default:
      // ENDED
      return "default";
  }
};
const getColumns = (history: any, setCampaignToChange: any) => {
  const columns = [
    {
      label: "Id",
      propName: "id",
      key: "id",
    },
    {
      label: "Name",
      propName: "name",
      key: "name",
    },
    {
      label: "State",
      propName: "state",
      key: "state",
      render: (item: any) => (
        <Tag label={item.state} variant={getTagVariant(item.state)} className="h-[19px]" />
      ),
    },
    {
      label: "Start Date",
      propName: "start_date",
      key: "start_date",
      render: (item: any) => <PTiny>{renderDate(item.start_date)}</PTiny>,
    },
    {
      label: "End Date",
      propName: "end_date",
      key: "end_date",
      render: (item: any) => <PTiny>{renderDate(item.end_date)}</PTiny>,
    },
    {
      label: "Actions",
      key: "activate_deactivate",
      align: "right",
      render: (record: CampaignListItem, index: number) => (
        <div className="flex">
          <Link to={`/campaign/view/${record.id}`} className="block">
            <Button className="mr-2 h-full" variant="secondary">
              View
            </Button>
          </Link>
          <Button variant="secondary" onClick={() => setCampaignToChange(record)}>
            {record.is_active ? "Deactivate" : "Activate"}
          </Button>
        </div>
      ),
    },
  ];
  return columns;
};

function getCampaignState(campaign: any) {
  const start = moment(campaign.start_date);
  const end = moment(campaign.end_date);
  if (moment().isAfter(end)) {
    return "ENDED";
  }
  if (moment().isBetween(start, end)) {
    if (campaign.is_active) {
      return "ACTIVE";
    }
    return "INACTIVE";
  }
  if (moment().isBefore(start)) {
    return "UPCOMING";
  }
}

const CampaignList: React.FC<Props> = ({ data }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [campaignToChange, setCampaignToChange] = React.useState<any>(null);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const urlPageParameter = params.get("page");
  const page = urlPageParameter ? parseInt(urlPageParameter) : 1;

  let dataSource = data.map(c => {
    return { key: c.id, state: getCampaignState(c), ...c };
  });

  const confirmModal = !campaignToChange ? null : (
    // @ts-ignore
    <Modal
      showOpen={true}
      title="Campaign created!"
      content={
        <P className="leading-normal">{`Are you sure you want to ${
          campaignToChange.is_active ? "deactivate" : "activate"
        } this campaign?`}</P>
      }
      options={[
        {
          label: "Ok",
          variant: "info",
          callBack: () => {
            patchCampaignIsActive(campaignToChange.id, !campaignToChange.is_active).then(() => {
              //todo: do a soft refresh to improve page load performance. Will need a central Campaigns redux state though.
              window.location.reload();
            });
          },
        },
        {
          label: "Cancel",
          variant: "secondary",
          callBack: () => setCampaignToChange(null),
        },
      ]}
    />
  );

  return (
    <div>
      <Button
        className="mb-8 ml-auto"
        variant="info"
        onClick={() => {
          dispatch(resetCampaignCreate());
          history.push("/campaigns/create");
        }}
      >
        Create Campaign
      </Button>
      <div>
        <Table
          columns={getColumns(history, setCampaignToChange)}
          data={dataSource}
          rowKey="key"
          router={history}
          page={page}
        />
      </div>
      {confirmModal}
    </div>
  );
};

export default CampaignList;
