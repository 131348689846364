import { PTiny, H6 } from "@tempoplatform/tpds/elements/typography";
const statScreenStyle = "w-full bg-window shadow rounded-lg py-5 px-6 flex flex-col gap-y-2";

interface Props {
  label: string;
  color: string;
  value: string | number;
}

const MainStatWindow = (props: Props) => {
  const { label, color, value } = props;
  return (
    <div className={statScreenStyle}>
      <div className="flex gap-x-2">
        <div className="h-[10px] w-[10px] rounded-[50%]" style={{ backgroundColor: color }} />
        <PTiny
          isMedium
          isUppercase
          className="text-grey-light-scale-800 dark:text-grey-light-scale-900"
        >
          {label}
        </PTiny>
      </div>
      <H6>{value}</H6>
    </div>
  );
};

export default MainStatWindow;
