import React from "react";
import { useAppSelector } from "../../../../app/hooks";
import ExpandableSection from "@tempoplatform/tpds/components/expandable-section";
import { PSmall } from "@tempoplatform/tpds/elements/typography";

const generateInfoBoxesContent = (infoBoxes: any) => {
  return infoBoxes.map((infoBox: any, index: number) => {
    return (
      <ExpandableSection
        removeBorderTop={index !== 0}
        addBorderBottomIfOpen={index === infoBoxes.length - 1}
        key={`${index}-${infoBox.title}`}
        title={infoBox.title}
        children={<PSmall className="leading-normal">{infoBox.description}</PSmall>}
      />
    );
  });
};
interface ComponentProps {
  productIndex: number;
}
const InfoBoxes: React.FC<ComponentProps> = ({ productIndex }) => {
  let infoBoxes = useAppSelector(state => state.campaignCreate.infoBoxes)[productIndex];
  const previewCampaignData = useAppSelector(state => state.campaignCreate.previewCampaignData);
  if (productIndex === -1) {
    return null;
  }
  // if we are in campaign preview mode, use the preview data
  if (previewCampaignData) {
    const infoBoxesObject = previewCampaignData.campaign_products[productIndex].product.info_boxes;
    infoBoxes = [];
    for (let i = 0; i < Object.keys(infoBoxesObject).length; i++) {
      const key = Object.keys(infoBoxesObject)[i];
      infoBoxes.push({
        title: key,
        description: infoBoxesObject[Object.keys(infoBoxesObject)[i]],
      });
    }
  }
  const content =
    infoBoxes && infoBoxes.length > 0 ? (
      generateInfoBoxesContent(infoBoxes)
    ) : (
      <PSmall className="px-4 opacity-50 border-b border-t border-window py-4">
        Info boxes you add to product {productIndex + 1} will be displayed here.
      </PSmall>
    );
  return <div className="w-full my-4">{content}</div>;
};
export default InfoBoxes;
