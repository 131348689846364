// #e8c1a0
// #f47560
// #f1e15b
// #e8a838
// #61cdbb
// #97e3d5

// #1b9e77
// #2077b4
// #756fb3
// #e72a8a
// #e376c2
// #66a61e
// #e6ab03
// #6a3c9a
// #d95f02
// #a6761d

export const impressionsColor = "#61cdbb";
export const spendColor = "#e376c2";
export const cpmColor = "#2077b4";
export const dailyBudgetColor = "#756fb3";

export const sessionsWithClickColor = "#e72a8a";
export const sessionsWithClickColorProduct0 = "#f47560";
export const sessionsWithClickColorProduct1 = "#61cdbb";
export const sessionsWithClickColorProduct2 = "#f1e15b";
export const sessionsWithClickColorProduct3 = "#9ecae1";
