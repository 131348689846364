import clsx from "clsx";
import { PNano } from "@tempoplatform/tpds/elements/typography";
import {
  spendColor,
  dailyBudgetColor,
  impressionsColor,
  sessionsWithClickColor,
  sessionsWithClickColorProduct0,
  sessionsWithClickColorProduct1,
  sessionsWithClickColorProduct2,
  sessionsWithClickColorProduct3,
} from "./colors";

const legendsRootStyle = "flex gap-x-4 relative left-[26px] top-[-26px]";
const legendLabelStyle = "text-grey-light-scale-800 dark:text-grey-light-scale-900 select-none";
const legendCircleStyle = "w-[8px] h-[8px] rounded-[50%]";
const circleAndLabelPairStyle = "flex gap-x-1.5 items-center cursor-pointer";

const isOnlyRemainingIndex = (legendStates: any, index: number) => {
  const asas = legendStates.reduce((acc: any, curr: any, index: number) => {
    if (curr) {
      acc.push(index);
    }
    return acc;
  }, []);
  return asas.length === 1 && asas[0] === index;
};

export const getMainChartLegend = (legendsState: any, setLegendsState: any) => {
  return (
    <div className={legendsRootStyle}>
      <div
        className={clsx(circleAndLabelPairStyle, legendsState[0] ? "opacity-100" : "opacity-40")}
        onClick={() => {
          if (!isOnlyRemainingIndex(legendsState, 0)) {
            setLegendsState([!legendsState[0], legendsState[1], legendsState[2]]);
          }
        }}
      >
        <div className={legendCircleStyle} style={{ backgroundColor: dailyBudgetColor }} />
        <PNano isUppercase isBold className={legendLabelStyle}>
          Daily Budget
        </PNano>
      </div>
      <div
        className={clsx(circleAndLabelPairStyle, legendsState[1] ? "opacity-100" : "opacity-40")}
        onClick={() => {
          if (!isOnlyRemainingIndex(legendsState, 1)) {
            setLegendsState([legendsState[0], !legendsState[1], legendsState[2]]);
          }
        }}
      >
        <div className={legendCircleStyle} style={{ backgroundColor: spendColor }} />
        <PNano isUppercase isBold className={legendLabelStyle}>
          Spend
        </PNano>
      </div>
      <div
        className={clsx(circleAndLabelPairStyle, legendsState[2] ? "opacity-100" : "opacity-40")}
        onClick={() => {
          if (!isOnlyRemainingIndex(legendsState, 2)) {
            setLegendsState([legendsState[0], legendsState[1], !legendsState[2]]);
          }
        }}
      >
        <div className={legendCircleStyle} style={{ backgroundColor: impressionsColor }} />
        <PNano isUppercase isBold className={legendLabelStyle}>
          Impressions
        </PNano>
      </div>
    </div>
  );
};

export const getSessionsWithClickLegend = (legendsState: any, setLegendsState: any) => {
  return (
    <div className={legendsRootStyle}>
      <div
        className={clsx(circleAndLabelPairStyle, legendsState[0] ? "opacity-100" : "opacity-40")}
        onClick={() => {
          if (!isOnlyRemainingIndex(legendsState, 0)) {
            setLegendsState([
              !legendsState[0],
              legendsState[1],
              legendsState[2],
              legendsState[3],
              legendsState[4],
            ]);
          }
        }}
      >
        <div className={legendCircleStyle} style={{ backgroundColor: sessionsWithClickColor }} />
        <PNano isUppercase isBold className={legendLabelStyle}>
          Total
        </PNano>
      </div>
      <div
        className={clsx(circleAndLabelPairStyle, legendsState[1] ? "opacity-100" : "opacity-40")}
        onClick={() => {
          if (!isOnlyRemainingIndex(legendsState, 1)) {
            setLegendsState([
              legendsState[0],
              !legendsState[1],
              legendsState[2],
              legendsState[3],
              legendsState[4],
            ]);
          }
        }}
      >
        <div
          className={legendCircleStyle}
          style={{ backgroundColor: sessionsWithClickColorProduct0 }}
        />
        <PNano isUppercase isBold className={legendLabelStyle}>
          Product 1
        </PNano>
      </div>
      <div
        className={clsx(circleAndLabelPairStyle, legendsState[2] ? "opacity-100" : "opacity-40")}
        onClick={() => {
          if (!isOnlyRemainingIndex(legendsState, 2)) {
            setLegendsState([
              legendsState[0],
              legendsState[1],
              !legendsState[2],
              legendsState[3],
              legendsState[4],
            ]);
          }
        }}
      >
        <div
          className={legendCircleStyle}
          style={{ backgroundColor: sessionsWithClickColorProduct1 }}
        />
        <PNano isUppercase isBold className={legendLabelStyle}>
          Product 2
        </PNano>
      </div>
      <div
        className={clsx(circleAndLabelPairStyle, legendsState[3] ? "opacity-100" : "opacity-40")}
        onClick={() => {
          if (!isOnlyRemainingIndex(legendsState, 3)) {
            setLegendsState([
              legendsState[0],
              legendsState[1],
              legendsState[2],
              !legendsState[3],
              legendsState[4],
            ]);
          }
        }}
      >
        <div
          className={legendCircleStyle}
          style={{ backgroundColor: sessionsWithClickColorProduct2 }}
        />
        <PNano isUppercase isBold className={legendLabelStyle}>
          Product 3
        </PNano>
      </div>
      <div
        className={clsx(circleAndLabelPairStyle, legendsState[4] ? "opacity-100" : "opacity-40")}
        onClick={() => {
          if (!isOnlyRemainingIndex(legendsState, 4)) {
            setLegendsState([
              legendsState[0],
              legendsState[1],
              legendsState[2],
              legendsState[3],
              !legendsState[4],
            ]);
          }
        }}
      >
        <div
          className={legendCircleStyle}
          style={{ backgroundColor: sessionsWithClickColorProduct3 }}
        />
        <PNano isUppercase isBold className={legendLabelStyle}>
          Product 4
        </PNano>
      </div>
    </div>
  );
};
