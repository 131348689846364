import React from "react";
import { useAppSelector } from "../../../app/hooks";

interface Props {
  soundOn: boolean;
}
const InitialScreenMainMedia: React.FC<Props> = ({ soundOn }) => {
  const mainImage = useAppSelector(state => state.campaignCreate.mainImage);
  const video = useAppSelector(state => state.campaignCreate.video);

  return (
    <div
      className="w-full h-full absolute top-0 left-0 z-10"
      style={{
        backgroundImage: mainImage ? `url(${mainImage})` : "none",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }}
    >
      {video && (
        <video
          autoPlay
          loop
          muted={soundOn}
          className="w-full h-full top-0 left-0"
          style={{ objectFit: "cover" }}
        >
          <source type="video/mp4" src={video} />
        </video>
      )}
    </div>
  );
};
export default InitialScreenMainMedia;
