import validator from "validator";
import AdTypes from "../../../constants/ad_types";

const validate = (state: any) => {
  const errors = [];
  if (!state.campaignName || state.campaignName.length < 2) {
    errors.push("Name of the campaign");
  }
  if (state.ad_type === AdTypes.images_form) {
    if (!state.formFields.formHeadline || state.formFields.formHeadline === "") {
      errors.push("Form fields: Headline");
    }
    if (state.formFields.model) {
      if (!state.formFields.model_label || state.formFields.model_label === "") {
        errors.push("Form fields: Product selection label");
      }
    }
  }
  if (state.ad_type === AdTypes.brand_awareness) {
    if (!state.cta_label || state.cta_label.length < 2) {
      errors.push("CTA Label");
    }
  }
  if (!state.headline || state.headline.length < 2) {
    errors.push("Headline");
  }
  if (!state.brandName || state.brandName.length < 2) {
    errors.push("Brand name");
  }
  if (!state.video && !state.mainImage) {
    errors.push("Main media (video or image)");
  } else {
    if (state.video) {
      if (!validator.isURL(state.video)) {
        errors.push("Main media (video) was provided but is not a valid URL");
      }
    }
    if (state.mainImage) {
      if (!validator.isURL(state.mainImage)) {
        errors.push("Main media (image) was provided but is not a valid URL");
      }
    }
  }
  if (!state.logoImage || !validator.isURL(state.logoImage)) {
    errors.push("Logo image");
  }
  if (!state.bottomHexColor || !validator.isHexColor(state.bottomHexColor)) {
    errors.push("Brand colors: color 1");
  }
  if (!state.topHexColor || !validator.isHexColor(state.topHexColor)) {
    errors.push("Brand colors: color 2");
  }

  return {
    validated: errors.length === 0,
    errors,
  };
};

export default validate;
