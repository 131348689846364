import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import VariantsColorSelector from "./sub_components/VariantsColorSelector";
import VariantsSelector from "./sub_components/VariantsSelector";
import { updateVariantSelectedValue } from "../../campaignCreateSlice";

interface ComponentProps {
  productIndex: number;
}
const Variants: React.FC<ComponentProps> = ({ productIndex }) => {
  const dispatch = useAppDispatch();
  const variantsList = useAppSelector(state => state.campaignCreate.variantBoxes)[productIndex];

  if (!variantsList || variantsList.length === 0) return null;

  const generateVariantsContent = (variantsList: any) => {
    if (!variantsList) return null;
    return variantsList.map((variant: any, index: number) => {
      const isColorVariant = String(variant.title).toLowerCase() === "color";
      return (
        <span key={`variant${index}`}>
          {!isColorVariant ? (
            <VariantsSelector
              variantName={variant.title}
              values={variant.values}
              selectedValueIndex={variant.preview_selectedValueIndex || 0}
              handleSelectValueIndex={(selectedValueIndex: number) => {
                dispatch(
                  updateVariantSelectedValue({
                    productIndex: productIndex,
                    variantIndex: index,
                    value: selectedValueIndex,
                  }),
                );
              }}
            />
          ) : (
            <VariantsColorSelector
              values={variant.values}
              selectedColorIndex={variant.preview_selectedValueIndex || 0}
              handleSelectValueIndex={(selectedValueIndex: number) => {
                dispatch(
                  updateVariantSelectedValue({
                    productIndex: productIndex,
                    variantIndex: index,
                    value: selectedValueIndex,
                  }),
                );
              }}
            />
          )}
        </span>
      );
    });
  };

  const variantsContent = generateVariantsContent(variantsList);

  return <div className="w-full px-4 mb-2 flex flex-col gap-y-2.5">{variantsContent}</div>;
};

export default Variants;
