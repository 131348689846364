import React from "react";
import { useAppSelector } from "../../../../../app/hooks";
import { PSmall } from "@tempoplatform/tpds/elements/typography";

const getVariantValueName = (variant: any, valueIndex: number | undefined) => {
  try {
    // @ts-ignore
    const value = variant.values[valueIndex].title;
    return value;
  } catch (err) {
    return "";
  }
};
const variantHasValues = (variant: any) => {
  try {
    const values = variant.values;
    return values && values.length > 0;
  } catch (err) {
    return false;
  }
};
interface ComponentProps {
  productIndex: number;
}
const SelectedOptionsRow: React.FC<ComponentProps> = ({ productIndex }) => {
  const variants = useAppSelector(state => state.campaignCreate.variantBoxes)[productIndex];

  const optionsArray = [];
  if (!variants || variants.length === 0) {
    return null;
  }
  for (const variant of variants) {
    if (variantHasValues(variant)) {
      const selectedVariantValueName = getVariantValueName(
        variant,
        variant.preview_selectedValueIndex,
      );
      if (selectedVariantValueName && selectedVariantValueName !== "") {
        optionsArray.push(selectedVariantValueName);
      }
    }
  }

  const optionsDisplay = optionsArray.length > 0 ? optionsArray.join(" • ") : "";

  return (
    <div className="flex gap-x-1">
      <PSmall isMedium className="leading-none text-tertiary">
        {optionsDisplay}
      </PSmall>
    </div>
  );
};
export default SelectedOptionsRow;
