import { Input } from "antd";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Button } from "@tempoplatform/tpds/elements/buttons";
import {
  addNewInfoBoxWithTitle,
  removeInfoBox,
  updateInfoBoxDescription,
} from "./campaignCreateSlice";
import LabelWithTooltip from "../../shared/LabelWithTooltip";
import { TextInput } from "@tempoplatform/tpds/elements/input";
import TPDSSelect from "@tempoplatform/tpds/components/select";
import { GreyBox } from "../../shared/atoms";
import { PSmall, Medium } from "@tempoplatform/tpds/elements/typography";

const { TextArea } = Input;

interface Props {
  id: number;
}

const ProductInfoBox = (props: Props) => {
  const dispatch = useAppDispatch();
  let productIndex = props.id - 1;
  const infoBoxes = useAppSelector(state => state.campaignCreate.infoBoxes)[productIndex];
  const presetInfoBoxSubjects = ["Description", "How to Use", "Ingredients"];
  const [newInfoBoxName, setNewInfoBoxName] = React.useState("");

  const handleCreateNewInfoBoxSubject = (newInfoBoxName: string) => {
    const itemWithSameName = infoBoxes.find(item => item.title === newInfoBoxName);
    if (itemWithSameName) {
      // TODO: show toast notification that info box with same name already exists
      return;
    }
    setNewInfoBoxName("");
    dispatch(
      addNewInfoBoxWithTitle({
        productIndex: productIndex,
        title: newInfoBoxName,
      }),
    );
  };

  const filterUniqueInfoBoxTypesForSelect = presetInfoBoxSubjects.filter(infoBoxType => {
    if (!infoBoxes || infoBoxes.length === 0) return true;
    const found = infoBoxes.find(item => item.title === infoBoxType);
    return !found;
  });

  return (
    <>
      <LabelWithTooltip
        label="Product info boxes"
        tooltipText="Optional. An info box can, for example, explain how to use your product, or describe it's composition or features."
      />
      <div className="flex flex-col gap-y-2">
        {filterUniqueInfoBoxTypesForSelect && filterUniqueInfoBoxTypesForSelect.length > 0 && (
          <TPDSSelect
            options={filterUniqueInfoBoxTypesForSelect}
            placeholder="Select preset info box type"
            selectedIndex={null}
            labelProp={null}
            handleIndexSelection={(index: number) =>
              dispatch(
                addNewInfoBoxWithTitle({
                  productIndex: productIndex,
                  title: filterUniqueInfoBoxTypesForSelect[index],
                }),
              )
            }
          />
        )}
        <div className="flex gap-x-2">
          <TextInput
            placeholder="New info box type"
            value={newInfoBoxName}
            onChange={(e: any) => setNewInfoBoxName(e.target.value)}
          />
          <Button variant="secondary" onClick={() => handleCreateNewInfoBoxSubject(newInfoBoxName)}>
            Create
          </Button>
        </div>
      </div>

      {infoBoxes.map((infoBox, index) => {
        if (!infoBox || !infoBox.title) return null;
        return (
          <GreyBox className="mt-4" key={index}>
            <PSmall className="leading-none">
              Info box: <Medium>{infoBox.title}</Medium>
            </PSmall>
            <textarea
              className="border border-transparent bg-white text-primary dark:bg-grey-dark-scale-300 w-full mt-1 px-2 py-1 min-h-[40px] rounded-[3px]"
              value={infoBox.description}
              placeholder="Add a description"
              onChange={(e: any) =>
                dispatch(
                  updateInfoBoxDescription({
                    productIndex: productIndex,
                    infoBoxIndex: index,
                    value: e.target.value,
                  }),
                )
              }
            />

            <div className="flex justify-end">
              <Button
                variant="secondary"
                onClick={() => {
                  dispatch(
                    removeInfoBox({
                      productIndex: productIndex,
                      infoBoxIndex: index,
                    }),
                  );
                }}
              >
                Remove
              </Button>
            </div>
          </GreyBox>
        );
      })}
    </>
  );
};
export default ProductInfoBox;
