import tpdsColors from "@tempoplatform/tpds/constants/colors";

export const getChartConfig = (currentTheme: string) => {
  return {
    grid: {
      line: {
        stroke:
          currentTheme === "light" ? tpdsColors.grey_light["400"] : tpdsColors.grey_dark["400"],
        strokeWidth: 1,
      },
    },
    legends: {
      text: {
        fill: currentTheme === "light" ? tpdsColors.grey_dark["400"] : tpdsColors.grey_light["400"],
      },
    },
    axis: {
      ticks: {
        line: {
          stroke:
            currentTheme === "light" ? tpdsColors.grey_light["400"] : tpdsColors.grey_dark["400"],
        },
        text: {
          fill:
            currentTheme === "light" ? tpdsColors.grey_dark["400"] : tpdsColors.grey_light["400"],
        },
      },
      legend: {
        text: {
          fill:
            currentTheme === "light" ? tpdsColors.grey_dark["400"] : tpdsColors.grey_light["400"],
        },
      },
    },
    crosshair: {
      line: {
        stroke:
          currentTheme === "light" ? tpdsColors.grey_dark["400"] : tpdsColors.grey_light["400"],
        strokeWidth: 1,
        strokeDasharray: "6 6",
      },
    },
  };
};
