import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@tempoplatform/tpds/components/buttons";
import { Window, Separator } from "@tempoplatform/tpds/elements/layout";
import { P, PSmall, H6 } from "@tempoplatform/tpds/elements/typography";
import { TextInput } from "@tempoplatform/tpds/elements/input";
import RadioTabs from "@tempoplatform/tpds/components/radiotabs";
import { useHistory } from "react-router-dom";
import { toPostApp } from "../api/api";
import Modal from "@tempoplatform/tpds/components/modal";

const platforms = ["iOS", "Android"];
const designedForKids = ["No", "Yes"];

interface Props {
  accountId: number;
}

const required = <span className="text-red font-bold">*</span>;

const PublisherAppCreate: React.FC<Props> = ({ accountId }) => {
  const history = useHistory();
  const [appName, setAppName] = React.useState("");
  const [bundleIdIos, setBundleIdIos] = React.useState("");
  const [bundleIdAndroid, setBundleIdAndroid] = React.useState("");
  const [isForKids, setIsForKids] = React.useState(designedForKids[0]);
  const [isCreatingApp, setIsCreatingApp] = React.useState(false);
  const [submitSuccess, setSubmittSuccess] = React.useState<boolean>(false);
  const [error, setError] = React.useState(null);
  const { getIdTokenClaims } = useAuth0();

  const [appNameInvalid, setAppNameInvalid] = React.useState(false);
  const [bundleIdsInvalid, setBundleIdsInvalid] = React.useState(false);
  const [bundleIdIosInvalid, setBundleIdIosInvalid] = React.useState(false);
  const [bundleIdAndroidInvalid, setBundleIdAndroidInvalid] = React.useState(false);

  const createNewApp = async () => {
    const claims = await getIdTokenClaims();
    const id_token = claims?.__raw;

    const appNameTest = !appName || appName.length < 3;
    const bundleIdsTest = !bundleIdIos && !bundleIdAndroid;
    const bundleIdIosTest = !!bundleIdIos && bundleIdIos.length < 5;
    const bundleIdAndroidTest = !!bundleIdAndroid && bundleIdAndroid.length < 5;
    setAppNameInvalid(appNameTest);
    setBundleIdsInvalid(bundleIdsTest);
    setBundleIdIosInvalid(bundleIdIosTest);
    setBundleIdAndroidInvalid(bundleIdAndroidTest);
    if (appNameTest || bundleIdsTest || bundleIdIosTest || bundleIdAndroidTest) {
      return;
    }

    const appData = {
      account_id: accountId,
      name: appName,
      is_for_kids: isForKids === "Yes",
      bundles: !!bundleIdAndroid
        ? [
            { name: bundleIdIos, platform: String(platforms[0]).toLowerCase() },
            { name: bundleIdAndroid, platform: String(platforms[1]).toLowerCase() },
          ]
        : [{ name: bundleIdIos, platform: String(platforms[0]).toLowerCase() }],
      is_test_mode: true,
    };

    setIsCreatingApp(true);
    try {
      const response = await toPostApp(appData, id_token);
      setIsCreatingApp(false);
      setSubmittSuccess(true);
    } catch (error: any) {
      setIsCreatingApp(false);
      setSubmittSuccess(false);
      setError(error);
    }
  };

  const successModal = (
    // @ts-ignore
    <Modal
      showOpen={true}
      title="App created!"
      content={<P>Awesome! Your App is now created. 🥳</P>}
      options={[
        {
          label: "Ok",
          variant: "info",
          callBack: () => {
            history.push("/pub/applications");
          },
        },
      ]}
    />
  );

  return (
    <div>
      <Window className="bg-white max-w-[400px] mx-auto">
        <H6>Create new App</H6>
        <Separator />
        <br />
        <PSmall className="pb-2">App Name {required}</PSmall>
        <TextInput
          value={appName}
          isInvalid={appNameInvalid}
          placeholder="App Name"
          onChange={(e: any) => setAppName(e.target.value)}
        />
        {appNameInvalid && (
          <PSmall className="pt-1 !text-tertiary">Please verify the App name</PSmall>
        )}

        <br />
        <PSmall className="pb-2">Bundle ID – iOS</PSmall>
        <TextInput
          value={bundleIdIos}
          isInvalid={bundleIdIosInvalid || bundleIdsInvalid}
          placeholder="Bundle ID"
          onChange={(e: any) => setBundleIdIos(e.target.value)}
        />
        {bundleIdIosInvalid && (
          <PSmall className="pt-1 !text-tertiary">Please verify the iOS Bundle ID</PSmall>
        )}

        <br />
        <PSmall className="pb-2">Bundle ID – Android</PSmall>
        <TextInput
          value={bundleIdAndroid}
          isInvalid={bundleIdAndroidInvalid || bundleIdsInvalid}
          placeholder="Bundle ID"
          onChange={(e: any) => setBundleIdAndroid(e.target.value)}
        />
        {bundleIdAndroidInvalid && (
          <PSmall className="pt-1 !text-tertiary">Please verify the Android Bundle ID</PSmall>
        )}
        {bundleIdsInvalid && (
          <PSmall className="pt-1 !text-tertiary">Please input at least one Bundle ID.</PSmall>
        )}

        <br />
        <PSmall className="pb-3">Is the app designated for kids? {required}</PSmall>
        <RadioTabs
          items={designedForKids}
          selectedIndex={designedForKids.indexOf(isForKids)}
          handleIndexSelection={(index: number) => {
            setIsForKids(designedForKids[index]);
          }}
        />
        {/* TODO: Verify error data structure to display appropriate error messages */}
        {error ? (
          <PSmall className="py-2 !text-tertiary">
            An unknown error ocurred: ({JSON.stringify(error)})
          </PSmall>
        ) : null}
        <Separator />
        <br />
        <div className="flex justify-between">
          <PSmall className="pb-3 !text-tertiary">{required} Required</PSmall>
          {/* @ts-ignore */}
          <Button
            className="ml-auto"
            onClick={() => createNewApp()}
            loading={isCreatingApp}
            variant="info"
            label="Create App"
          />
        </div>
      </Window>
      {submitSuccess && successModal}
    </div>
  );
};

export default PublisherAppCreate;
