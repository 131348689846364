import * as React from "react";
import { PageRoot } from "@tempoplatform/tpds/elements/layout";
import { PLarge, PSmall } from "@tempoplatform/tpds/elements/typography";
import { Button as ButtonComponent } from "@tempoplatform/tpds/components/buttons";
import Loading from "../loading/Loading";
import ColorModeControl from "../../color/ThemeControl";
import { useAuth0 } from "@auth0/auth0-react";
import { getCampaignEnums, getCampaignDetails, toGetAccount } from "../api/api";
import { useHistory } from "react-router-dom";
import Cross from "@tempoplatform/tpds/assets/svgs/16x16/Cross";
import { useEffect, useState } from "react";
import PhonePreview from "../campaignCreate/PhonePreview";
import { useAppDispatch } from "../../app/hooks";
import { useParams } from "react-router-dom";
import {
  setEnumsData,
  insertPreviewData,
  resetCampaignCreate,
} from "../campaignCreate/campaignCreateSlice";
import clsx from "clsx";

const classTopBar = clsx(
  `h-[50px] w-full px-4`,
  `justify-between flex items-center`,
  `bg-window border-b border-window`,
);

const classPreviewArea = clsx(
  `h-screen w-full relative bg-grey-light-scale-400 dark:bg-grey-dark-scale-400`,
);

const classPreviewLayout = clsx("flex items-center justify-center");
const classPreviewChild = clsx(
  "w-[380px] aspect-[9/19] relative rounded-[20px] overflow-hidden",
  "bg-grey-light-scale-500 shadow-lg",
);

interface Props {
  accountId: number;
  campaignId: number;
}

const CampaignView: React.FC<Props> = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const userId = useAuth0().user?.sub as string;

  const [loading, setLoading] = useState<boolean>(false);
  const [accessToken, setAccessToken] = useState<string>();
  const [accountData, setAccountData] = useState<any>();
  const [campaignData, setCampaignData] = useState<any>();
  const [error, setError] = useState<any>();
  const { getIdTokenClaims } = useAuth0();
  // @ts-ignore
  let { id } = useParams(); // viewing campaign id

  useEffect(() => {
    if (userId && !accessToken) {
      getAccessToken();
    }
    if (accessToken && !accountData) {
      getAccountInformation();
    }
    if (accountData && !campaignData) {
      getCampaignData();
    }
  }, [userId, accessToken, accountData]);

  const getAccessToken = async () => {
    const claims = await getIdTokenClaims();
    const id_token = claims?.__raw;
    if (id_token) {
      setAccessToken(id_token);
    }
  };

  const getAccountInformation = async () => {
    if (!userId) return;
    setLoading(true);
    try {
      const response = await toGetAccount(userId, accessToken);
      const receivedEnumsData = await getCampaignEnums(accessToken);
      dispatch(setEnumsData(receivedEnumsData.data));
      setAccountData(response);
      setLoading(false);
    } catch (error: any) {
      setError(error);
      setLoading(false);
    }
  };

  const getCampaignData = async () => {
    if (!accountData || !accountData.id) return;
    setLoading(true);
    try {
      const response = await getCampaignDetails(id, accessToken);
      setCampaignData(response.data);
      console.log("response.data", response.data);

      dispatch(insertPreviewData(response.data));
      setLoading(false);
    } catch (error: any) {
      setError(error);
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (error || !accountData || !accountData.id || !campaignData) {
    return <div>ERROR</div>;
  }

  const handleReturnToCampaignsList = () => {
    dispatch(resetCampaignCreate());
    history.push("/campaigns");
  };

  return (
    <PageRoot className="fixed top-0 left-0 z-30">
      <div className={classTopBar}>
        <div className="flex items-center relative top-[-1px]">
          <img className="logo" src={"/logo.png"} alt="Tempo logo" />
          <PLarge isBold className="!ml-2 leading-none">
            TEMPO
          </PLarge>
        </div>
        <PSmall isMedium className="leading-none relative top-[-2px]">
          <span className="text-tertiary">Campaign preview:&nbsp;</span>
          {campaignData.name}
        </PSmall>
        <div className="flex items-center gap-x-4">
          <ColorModeControl />
          <ButtonComponent
            label="Close"
            variant="secondary"
            iconAfter={<Cross />}
            onClick={handleReturnToCampaignsList}
          />
        </div>
      </div>
      <div className={classPreviewArea}>
        <div className={classPreviewLayout} style={{ height: "calc(100vh - 110px)" }}>
          <div className={classPreviewChild}>
            <PhonePreview />
          </div>
        </div>
      </div>
    </PageRoot>
  );
};

export default CampaignView;
