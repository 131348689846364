import moment from "moment";
const dateFormat = "YYYY-MM-DD";

const exampleDataObject = {
  date: "2024-02-01T00:00:00",
  campaign_name: "dfg",
  campaign_id: "22",
  ad_type: "INTERSTITIAL",
  cpm: 25,
  daily_budget: 64,
  spend: 0.025,
  impressions: 1,
  sessions_with_click: 0,
  sessions_with_click_product_0: 0,
  sessions_with_click_product_1: 0,
  sessions_with_click_product_2: 0,
  sessions_with_click_product_3: 0,
};

const adTypes = ["INTERSTITIAL", "REWARDED"];

const dummyCampaigns = [
  { id: "1", name: "Aiohsfsg" },
  { id: "2", name: "Faokhdg" },
  { id: "3", name: "Cspidhg" },
  { id: "4", name: "Hsoihdg" },
  { id: "5", name: "Xsdfjposd" },
];

export const generateDummyData = (weeksOfData: number = 2) => {
  const generatedData = [];
  for (let k = 0; k < dummyCampaigns.length; k++) {
    let count = 1;
    const startDate = new Date();
    for (let i = 0; i < weeksOfData; i++) {
      for (let j = 0; j < 7; j++) {
        //const exampleDataObjectCopy = { ...exampleDataObject };
        const exampleDataObjectCopy: any = {};
        const dateToUse = count === 1 ? startDate : generatedData[generatedData.length - 1].date;
        exampleDataObjectCopy.date = moment(dateToUse).subtract(1, "day").format(dateFormat);
        exampleDataObjectCopy.campaign_name = dummyCampaigns[k].name;
        exampleDataObjectCopy.campaign_id = dummyCampaigns[k].id;
        exampleDataObjectCopy.ad_type = adTypes[Math.round(Math.random() * 1)];
        exampleDataObjectCopy.cpm = 10 + Math.floor(Math.random() * 100);
        exampleDataObjectCopy.daily_budget = 10 + Math.floor(Math.random() * 100);
        exampleDataObjectCopy.spend = 10 + Math.floor(Math.random() * 100);
        exampleDataObjectCopy.impressions = 20 + Math.floor(Math.random() * 100);
        exampleDataObjectCopy.sessions_with_click = 10 + Math.floor(Math.random() * 250);
        exampleDataObjectCopy.sessions_with_click_product_0 = 10 + Math.floor(Math.random() * 100);
        exampleDataObjectCopy.sessions_with_click_product_1 = 10 + Math.floor(Math.random() * 80);
        exampleDataObjectCopy.sessions_with_click_product_2 = 10 + Math.floor(Math.random() * 30);
        exampleDataObjectCopy.sessions_with_click_product_3 = 10 + Math.floor(Math.random() * 40);
        generatedData.push(exampleDataObjectCopy);
        count++;
      }
    }
  }

  // filter by ad type
  // if (adType !== "ALL") {
  //   return generatedData.filter((item: any) => item.ad_type === adType);
  // }
  generatedData.reverse();
  return generatedData;
};

export const oneWeekData = [
  {
    date: "2024-01-31",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 81,
    daily_budget: 14,
    spend: 56,
    impressions: 28,
    sessions_with_click: 120,
    sessions_with_click_product_0: 23,
    sessions_with_click_product_1: 61,
    sessions_with_click_product_2: 23,
    sessions_with_click_product_3: 46,
  },
  {
    date: "2024-02-01",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 21,
    daily_budget: 107,
    spend: 79,
    impressions: 48,
    sessions_with_click: 80,
    sessions_with_click_product_0: 40,
    sessions_with_click_product_1: 57,
    sessions_with_click_product_2: 24,
    sessions_with_click_product_3: 17,
  },
  {
    date: "2024-02-02",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 10,
    daily_budget: 22,
    spend: 15,
    impressions: 82,
    sessions_with_click: 60,
    sessions_with_click_product_0: 70,
    sessions_with_click_product_1: 56,
    sessions_with_click_product_2: 24,
    sessions_with_click_product_3: 13,
  },
  {
    date: "2024-02-03",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 91,
    daily_budget: 81,
    spend: 80,
    impressions: 67,
    sessions_with_click: 137,
    sessions_with_click_product_0: 65,
    sessions_with_click_product_1: 73,
    sessions_with_click_product_2: 21,
    sessions_with_click_product_3: 49,
  },
  {
    date: "2024-02-04",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 57,
    daily_budget: 65,
    spend: 24,
    impressions: 76,
    sessions_with_click: 14,
    sessions_with_click_product_0: 93,
    sessions_with_click_product_1: 45,
    sessions_with_click_product_2: 12,
    sessions_with_click_product_3: 37,
  },
  {
    date: "2024-02-05",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 87,
    daily_budget: 102,
    spend: 17,
    impressions: 111,
    sessions_with_click: 232,
    sessions_with_click_product_0: 37,
    sessions_with_click_product_1: 80,
    sessions_with_click_product_2: 39,
    sessions_with_click_product_3: 44,
  },
  {
    date: "2024-02-06",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 59,
    daily_budget: 92,
    spend: 103,
    impressions: 92,
    sessions_with_click: 238,
    sessions_with_click_product_0: 42,
    sessions_with_click_product_1: 18,
    sessions_with_click_product_2: 36,
    sessions_with_click_product_3: 23,
  },
  {
    date: "2024-01-31",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 56,
    daily_budget: 94,
    spend: 57,
    impressions: 98,
    sessions_with_click: 210,
    sessions_with_click_product_0: 23,
    sessions_with_click_product_1: 29,
    sessions_with_click_product_2: 33,
    sessions_with_click_product_3: 37,
  },
  {
    date: "2024-02-01",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 13,
    daily_budget: 14,
    spend: 30,
    impressions: 94,
    sessions_with_click: 213,
    sessions_with_click_product_0: 65,
    sessions_with_click_product_1: 70,
    sessions_with_click_product_2: 15,
    sessions_with_click_product_3: 23,
  },
  {
    date: "2024-02-02",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 23,
    daily_budget: 84,
    spend: 50,
    impressions: 117,
    sessions_with_click: 237,
    sessions_with_click_product_0: 97,
    sessions_with_click_product_1: 38,
    sessions_with_click_product_2: 22,
    sessions_with_click_product_3: 23,
  },
  {
    date: "2024-02-03",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 72,
    daily_budget: 97,
    spend: 82,
    impressions: 99,
    sessions_with_click: 172,
    sessions_with_click_product_0: 58,
    sessions_with_click_product_1: 46,
    sessions_with_click_product_2: 25,
    sessions_with_click_product_3: 27,
  },
  {
    date: "2024-02-04",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 88,
    daily_budget: 78,
    spend: 44,
    impressions: 118,
    sessions_with_click: 183,
    sessions_with_click_product_0: 77,
    sessions_with_click_product_1: 20,
    sessions_with_click_product_2: 22,
    sessions_with_click_product_3: 47,
  },
  {
    date: "2024-02-05",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 18,
    daily_budget: 108,
    spend: 97,
    impressions: 29,
    sessions_with_click: 238,
    sessions_with_click_product_0: 39,
    sessions_with_click_product_1: 29,
    sessions_with_click_product_2: 16,
    sessions_with_click_product_3: 18,
  },
  {
    date: "2024-02-06",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 30,
    daily_budget: 59,
    spend: 87,
    impressions: 74,
    sessions_with_click: 43,
    sessions_with_click_product_0: 82,
    sessions_with_click_product_1: 57,
    sessions_with_click_product_2: 29,
    sessions_with_click_product_3: 11,
  },
  {
    date: "2024-01-31",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 66,
    daily_budget: 69,
    spend: 93,
    impressions: 46,
    sessions_with_click: 75,
    sessions_with_click_product_0: 48,
    sessions_with_click_product_1: 50,
    sessions_with_click_product_2: 22,
    sessions_with_click_product_3: 17,
  },
  {
    date: "2024-02-01",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 76,
    daily_budget: 85,
    spend: 96,
    impressions: 59,
    sessions_with_click: 51,
    sessions_with_click_product_0: 86,
    sessions_with_click_product_1: 73,
    sessions_with_click_product_2: 23,
    sessions_with_click_product_3: 42,
  },
  {
    date: "2024-02-02",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 55,
    daily_budget: 15,
    spend: 108,
    impressions: 64,
    sessions_with_click: 21,
    sessions_with_click_product_0: 96,
    sessions_with_click_product_1: 83,
    sessions_with_click_product_2: 16,
    sessions_with_click_product_3: 26,
  },
  {
    date: "2024-02-03",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 94,
    daily_budget: 16,
    spend: 101,
    impressions: 62,
    sessions_with_click: 42,
    sessions_with_click_product_0: 77,
    sessions_with_click_product_1: 65,
    sessions_with_click_product_2: 35,
    sessions_with_click_product_3: 26,
  },
  {
    date: "2024-02-04",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 67,
    daily_budget: 102,
    spend: 94,
    impressions: 37,
    sessions_with_click: 86,
    sessions_with_click_product_0: 34,
    sessions_with_click_product_1: 10,
    sessions_with_click_product_2: 19,
    sessions_with_click_product_3: 38,
  },
  {
    date: "2024-02-05",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 57,
    daily_budget: 106,
    spend: 16,
    impressions: 64,
    sessions_with_click: 61,
    sessions_with_click_product_0: 29,
    sessions_with_click_product_1: 53,
    sessions_with_click_product_2: 19,
    sessions_with_click_product_3: 28,
  },
  {
    date: "2024-02-06",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 60,
    daily_budget: 106,
    spend: 63,
    impressions: 111,
    sessions_with_click: 197,
    sessions_with_click_product_0: 79,
    sessions_with_click_product_1: 65,
    sessions_with_click_product_2: 13,
    sessions_with_click_product_3: 12,
  },
  {
    date: "2024-01-31",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 46,
    daily_budget: 45,
    spend: 57,
    impressions: 116,
    sessions_with_click: 111,
    sessions_with_click_product_0: 72,
    sessions_with_click_product_1: 52,
    sessions_with_click_product_2: 22,
    sessions_with_click_product_3: 19,
  },
  {
    date: "2024-02-01",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 54,
    daily_budget: 75,
    spend: 51,
    impressions: 50,
    sessions_with_click: 30,
    sessions_with_click_product_0: 60,
    sessions_with_click_product_1: 69,
    sessions_with_click_product_2: 33,
    sessions_with_click_product_3: 18,
  },
  {
    date: "2024-02-02",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 59,
    daily_budget: 102,
    spend: 59,
    impressions: 101,
    sessions_with_click: 131,
    sessions_with_click_product_0: 39,
    sessions_with_click_product_1: 25,
    sessions_with_click_product_2: 34,
    sessions_with_click_product_3: 16,
  },
  {
    date: "2024-02-03",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 96,
    daily_budget: 62,
    spend: 56,
    impressions: 32,
    sessions_with_click: 35,
    sessions_with_click_product_0: 17,
    sessions_with_click_product_1: 73,
    sessions_with_click_product_2: 26,
    sessions_with_click_product_3: 30,
  },
  {
    date: "2024-02-04",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 74,
    daily_budget: 62,
    spend: 106,
    impressions: 90,
    sessions_with_click: 43,
    sessions_with_click_product_0: 101,
    sessions_with_click_product_1: 63,
    sessions_with_click_product_2: 19,
    sessions_with_click_product_3: 27,
  },
  {
    date: "2024-02-05",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 56,
    daily_budget: 52,
    spend: 43,
    impressions: 62,
    sessions_with_click: 133,
    sessions_with_click_product_0: 56,
    sessions_with_click_product_1: 61,
    sessions_with_click_product_2: 17,
    sessions_with_click_product_3: 11,
  },
  {
    date: "2024-02-06",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 49,
    daily_budget: 28,
    spend: 50,
    impressions: 42,
    sessions_with_click: 224,
    sessions_with_click_product_0: 56,
    sessions_with_click_product_1: 40,
    sessions_with_click_product_2: 37,
    sessions_with_click_product_3: 37,
  },
  {
    date: "2024-01-31",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 107,
    daily_budget: 100,
    spend: 88,
    impressions: 104,
    sessions_with_click: 130,
    sessions_with_click_product_0: 46,
    sessions_with_click_product_1: 74,
    sessions_with_click_product_2: 31,
    sessions_with_click_product_3: 44,
  },
  {
    date: "2024-02-01",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 107,
    daily_budget: 56,
    spend: 23,
    impressions: 87,
    sessions_with_click: 162,
    sessions_with_click_product_0: 47,
    sessions_with_click_product_1: 35,
    sessions_with_click_product_2: 32,
    sessions_with_click_product_3: 22,
  },
  {
    date: "2024-02-02",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 78,
    daily_budget: 11,
    spend: 53,
    impressions: 29,
    sessions_with_click: 251,
    sessions_with_click_product_0: 32,
    sessions_with_click_product_1: 82,
    sessions_with_click_product_2: 28,
    sessions_with_click_product_3: 24,
  },
  {
    date: "2024-02-03",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 23,
    daily_budget: 71,
    spend: 53,
    impressions: 114,
    sessions_with_click: 60,
    sessions_with_click_product_0: 59,
    sessions_with_click_product_1: 23,
    sessions_with_click_product_2: 12,
    sessions_with_click_product_3: 37,
  },
  {
    date: "2024-02-04",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 36,
    daily_budget: 87,
    spend: 84,
    impressions: 21,
    sessions_with_click: 219,
    sessions_with_click_product_0: 99,
    sessions_with_click_product_1: 34,
    sessions_with_click_product_2: 29,
    sessions_with_click_product_3: 43,
  },
  {
    date: "2024-02-05",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 17,
    daily_budget: 58,
    spend: 36,
    impressions: 75,
    sessions_with_click: 211,
    sessions_with_click_product_0: 68,
    sessions_with_click_product_1: 53,
    sessions_with_click_product_2: 10,
    sessions_with_click_product_3: 11,
  },
  {
    date: "2024-02-06",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 12,
    daily_budget: 68,
    spend: 42,
    impressions: 111,
    sessions_with_click: 98,
    sessions_with_click_product_0: 54,
    sessions_with_click_product_1: 18,
    sessions_with_click_product_2: 29,
    sessions_with_click_product_3: 35,
  },
];

export const twoWeeksData = [
  {
    date: "2024-01-24",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 24,
    daily_budget: 41,
    spend: 73,
    impressions: 100,
    sessions_with_click: 90,
    sessions_with_click_product_0: 72,
    sessions_with_click_product_1: 57,
    sessions_with_click_product_2: 34,
    sessions_with_click_product_3: 28,
  },
  {
    date: "2024-01-25",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 67,
    daily_budget: 21,
    spend: 56,
    impressions: 68,
    sessions_with_click: 69,
    sessions_with_click_product_0: 104,
    sessions_with_click_product_1: 68,
    sessions_with_click_product_2: 25,
    sessions_with_click_product_3: 33,
  },
  {
    date: "2024-01-26",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 32,
    daily_budget: 34,
    spend: 49,
    impressions: 86,
    sessions_with_click: 85,
    sessions_with_click_product_0: 93,
    sessions_with_click_product_1: 79,
    sessions_with_click_product_2: 37,
    sessions_with_click_product_3: 25,
  },
  {
    date: "2024-01-27",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 79,
    daily_budget: 107,
    spend: 103,
    impressions: 77,
    sessions_with_click: 214,
    sessions_with_click_product_0: 104,
    sessions_with_click_product_1: 11,
    sessions_with_click_product_2: 39,
    sessions_with_click_product_3: 36,
  },
  {
    date: "2024-01-28",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 59,
    daily_budget: 48,
    spend: 108,
    impressions: 22,
    sessions_with_click: 212,
    sessions_with_click_product_0: 96,
    sessions_with_click_product_1: 36,
    sessions_with_click_product_2: 18,
    sessions_with_click_product_3: 31,
  },
  {
    date: "2024-01-29",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 52,
    daily_budget: 73,
    spend: 65,
    impressions: 25,
    sessions_with_click: 49,
    sessions_with_click_product_0: 44,
    sessions_with_click_product_1: 83,
    sessions_with_click_product_2: 25,
    sessions_with_click_product_3: 43,
  },
  {
    date: "2024-01-30",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 107,
    daily_budget: 26,
    spend: 35,
    impressions: 78,
    sessions_with_click: 46,
    sessions_with_click_product_0: 97,
    sessions_with_click_product_1: 47,
    sessions_with_click_product_2: 26,
    sessions_with_click_product_3: 29,
  },
  {
    date: "2024-01-31",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 100,
    daily_budget: 90,
    spend: 50,
    impressions: 73,
    sessions_with_click: 258,
    sessions_with_click_product_0: 73,
    sessions_with_click_product_1: 53,
    sessions_with_click_product_2: 38,
    sessions_with_click_product_3: 19,
  },
  {
    date: "2024-02-01",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 59,
    daily_budget: 81,
    spend: 70,
    impressions: 86,
    sessions_with_click: 110,
    sessions_with_click_product_0: 97,
    sessions_with_click_product_1: 45,
    sessions_with_click_product_2: 27,
    sessions_with_click_product_3: 29,
  },
  {
    date: "2024-02-02",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 44,
    daily_budget: 72,
    spend: 23,
    impressions: 31,
    sessions_with_click: 11,
    sessions_with_click_product_0: 18,
    sessions_with_click_product_1: 84,
    sessions_with_click_product_2: 13,
    sessions_with_click_product_3: 17,
  },
  {
    date: "2024-02-03",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 40,
    daily_budget: 26,
    spend: 55,
    impressions: 27,
    sessions_with_click: 174,
    sessions_with_click_product_0: 42,
    sessions_with_click_product_1: 79,
    sessions_with_click_product_2: 36,
    sessions_with_click_product_3: 33,
  },
  {
    date: "2024-02-04",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 73,
    daily_budget: 76,
    spend: 12,
    impressions: 113,
    sessions_with_click: 61,
    sessions_with_click_product_0: 15,
    sessions_with_click_product_1: 66,
    sessions_with_click_product_2: 32,
    sessions_with_click_product_3: 26,
  },
  {
    date: "2024-02-05",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 81,
    daily_budget: 17,
    spend: 68,
    impressions: 82,
    sessions_with_click: 128,
    sessions_with_click_product_0: 88,
    sessions_with_click_product_1: 79,
    sessions_with_click_product_2: 22,
    sessions_with_click_product_3: 10,
  },
  {
    date: "2024-02-06",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 73,
    daily_budget: 48,
    spend: 90,
    impressions: 113,
    sessions_with_click: 200,
    sessions_with_click_product_0: 43,
    sessions_with_click_product_1: 47,
    sessions_with_click_product_2: 19,
    sessions_with_click_product_3: 41,
  },
  {
    date: "2024-01-24",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 75,
    daily_budget: 21,
    spend: 30,
    impressions: 111,
    sessions_with_click: 123,
    sessions_with_click_product_0: 87,
    sessions_with_click_product_1: 56,
    sessions_with_click_product_2: 27,
    sessions_with_click_product_3: 34,
  },
  {
    date: "2024-01-25",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 19,
    daily_budget: 22,
    spend: 60,
    impressions: 91,
    sessions_with_click: 186,
    sessions_with_click_product_0: 55,
    sessions_with_click_product_1: 69,
    sessions_with_click_product_2: 34,
    sessions_with_click_product_3: 49,
  },
  {
    date: "2024-01-26",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 72,
    daily_budget: 85,
    spend: 84,
    impressions: 90,
    sessions_with_click: 195,
    sessions_with_click_product_0: 67,
    sessions_with_click_product_1: 13,
    sessions_with_click_product_2: 31,
    sessions_with_click_product_3: 32,
  },
  {
    date: "2024-01-27",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 91,
    daily_budget: 39,
    spend: 39,
    impressions: 46,
    sessions_with_click: 73,
    sessions_with_click_product_0: 70,
    sessions_with_click_product_1: 38,
    sessions_with_click_product_2: 17,
    sessions_with_click_product_3: 14,
  },
  {
    date: "2024-01-28",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 10,
    daily_budget: 42,
    spend: 18,
    impressions: 106,
    sessions_with_click: 129,
    sessions_with_click_product_0: 74,
    sessions_with_click_product_1: 55,
    sessions_with_click_product_2: 38,
    sessions_with_click_product_3: 20,
  },
  {
    date: "2024-01-29",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 45,
    daily_budget: 47,
    spend: 44,
    impressions: 43,
    sessions_with_click: 68,
    sessions_with_click_product_0: 60,
    sessions_with_click_product_1: 31,
    sessions_with_click_product_2: 27,
    sessions_with_click_product_3: 25,
  },
  {
    date: "2024-01-30",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 62,
    daily_budget: 39,
    spend: 79,
    impressions: 64,
    sessions_with_click: 96,
    sessions_with_click_product_0: 87,
    sessions_with_click_product_1: 47,
    sessions_with_click_product_2: 24,
    sessions_with_click_product_3: 28,
  },
  {
    date: "2024-01-31",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 90,
    daily_budget: 77,
    spend: 80,
    impressions: 29,
    sessions_with_click: 102,
    sessions_with_click_product_0: 82,
    sessions_with_click_product_1: 51,
    sessions_with_click_product_2: 31,
    sessions_with_click_product_3: 19,
  },
  {
    date: "2024-02-01",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 73,
    daily_budget: 47,
    spend: 108,
    impressions: 101,
    sessions_with_click: 78,
    sessions_with_click_product_0: 49,
    sessions_with_click_product_1: 45,
    sessions_with_click_product_2: 33,
    sessions_with_click_product_3: 14,
  },
  {
    date: "2024-02-02",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 60,
    daily_budget: 79,
    spend: 71,
    impressions: 47,
    sessions_with_click: 64,
    sessions_with_click_product_0: 12,
    sessions_with_click_product_1: 63,
    sessions_with_click_product_2: 36,
    sessions_with_click_product_3: 10,
  },
  {
    date: "2024-02-03",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 53,
    daily_budget: 82,
    spend: 65,
    impressions: 80,
    sessions_with_click: 63,
    sessions_with_click_product_0: 103,
    sessions_with_click_product_1: 84,
    sessions_with_click_product_2: 14,
    sessions_with_click_product_3: 11,
  },
  {
    date: "2024-02-04",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 72,
    daily_budget: 79,
    spend: 103,
    impressions: 115,
    sessions_with_click: 56,
    sessions_with_click_product_0: 10,
    sessions_with_click_product_1: 79,
    sessions_with_click_product_2: 23,
    sessions_with_click_product_3: 41,
  },
  {
    date: "2024-02-05",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 85,
    daily_budget: 30,
    spend: 99,
    impressions: 91,
    sessions_with_click: 83,
    sessions_with_click_product_0: 34,
    sessions_with_click_product_1: 39,
    sessions_with_click_product_2: 17,
    sessions_with_click_product_3: 27,
  },
  {
    date: "2024-02-06",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 23,
    daily_budget: 67,
    spend: 74,
    impressions: 64,
    sessions_with_click: 66,
    sessions_with_click_product_0: 85,
    sessions_with_click_product_1: 53,
    sessions_with_click_product_2: 10,
    sessions_with_click_product_3: 48,
  },
  {
    date: "2024-01-24",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 99,
    daily_budget: 48,
    spend: 15,
    impressions: 104,
    sessions_with_click: 32,
    sessions_with_click_product_0: 80,
    sessions_with_click_product_1: 26,
    sessions_with_click_product_2: 29,
    sessions_with_click_product_3: 40,
  },
  {
    date: "2024-01-25",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 46,
    daily_budget: 21,
    spend: 38,
    impressions: 93,
    sessions_with_click: 242,
    sessions_with_click_product_0: 87,
    sessions_with_click_product_1: 31,
    sessions_with_click_product_2: 25,
    sessions_with_click_product_3: 37,
  },
  {
    date: "2024-01-26",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 80,
    daily_budget: 10,
    spend: 81,
    impressions: 48,
    sessions_with_click: 179,
    sessions_with_click_product_0: 29,
    sessions_with_click_product_1: 26,
    sessions_with_click_product_2: 27,
    sessions_with_click_product_3: 35,
  },
  {
    date: "2024-01-27",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 95,
    daily_budget: 106,
    spend: 109,
    impressions: 82,
    sessions_with_click: 166,
    sessions_with_click_product_0: 72,
    sessions_with_click_product_1: 74,
    sessions_with_click_product_2: 36,
    sessions_with_click_product_3: 30,
  },
  {
    date: "2024-01-28",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 24,
    daily_budget: 77,
    spend: 52,
    impressions: 77,
    sessions_with_click: 155,
    sessions_with_click_product_0: 46,
    sessions_with_click_product_1: 27,
    sessions_with_click_product_2: 30,
    sessions_with_click_product_3: 10,
  },
  {
    date: "2024-01-29",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 81,
    daily_budget: 55,
    spend: 72,
    impressions: 111,
    sessions_with_click: 231,
    sessions_with_click_product_0: 99,
    sessions_with_click_product_1: 79,
    sessions_with_click_product_2: 15,
    sessions_with_click_product_3: 45,
  },
  {
    date: "2024-01-30",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 82,
    daily_budget: 37,
    spend: 86,
    impressions: 21,
    sessions_with_click: 33,
    sessions_with_click_product_0: 66,
    sessions_with_click_product_1: 38,
    sessions_with_click_product_2: 38,
    sessions_with_click_product_3: 46,
  },
  {
    date: "2024-01-31",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 96,
    daily_budget: 84,
    spend: 56,
    impressions: 81,
    sessions_with_click: 220,
    sessions_with_click_product_0: 90,
    sessions_with_click_product_1: 34,
    sessions_with_click_product_2: 31,
    sessions_with_click_product_3: 17,
  },
  {
    date: "2024-02-01",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 18,
    daily_budget: 53,
    spend: 36,
    impressions: 28,
    sessions_with_click: 237,
    sessions_with_click_product_0: 76,
    sessions_with_click_product_1: 63,
    sessions_with_click_product_2: 11,
    sessions_with_click_product_3: 14,
  },
  {
    date: "2024-02-02",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 21,
    daily_budget: 21,
    spend: 40,
    impressions: 35,
    sessions_with_click: 228,
    sessions_with_click_product_0: 54,
    sessions_with_click_product_1: 35,
    sessions_with_click_product_2: 32,
    sessions_with_click_product_3: 32,
  },
  {
    date: "2024-02-03",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 90,
    daily_budget: 27,
    spend: 60,
    impressions: 25,
    sessions_with_click: 172,
    sessions_with_click_product_0: 79,
    sessions_with_click_product_1: 13,
    sessions_with_click_product_2: 16,
    sessions_with_click_product_3: 45,
  },
  {
    date: "2024-02-04",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 67,
    daily_budget: 87,
    spend: 45,
    impressions: 21,
    sessions_with_click: 172,
    sessions_with_click_product_0: 99,
    sessions_with_click_product_1: 17,
    sessions_with_click_product_2: 38,
    sessions_with_click_product_3: 34,
  },
  {
    date: "2024-02-05",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 43,
    daily_budget: 52,
    spend: 11,
    impressions: 36,
    sessions_with_click: 24,
    sessions_with_click_product_0: 54,
    sessions_with_click_product_1: 81,
    sessions_with_click_product_2: 25,
    sessions_with_click_product_3: 41,
  },
  {
    date: "2024-02-06",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 56,
    daily_budget: 50,
    spend: 63,
    impressions: 108,
    sessions_with_click: 235,
    sessions_with_click_product_0: 76,
    sessions_with_click_product_1: 60,
    sessions_with_click_product_2: 20,
    sessions_with_click_product_3: 41,
  },
  {
    date: "2024-01-24",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 100,
    daily_budget: 33,
    spend: 66,
    impressions: 97,
    sessions_with_click: 172,
    sessions_with_click_product_0: 76,
    sessions_with_click_product_1: 37,
    sessions_with_click_product_2: 36,
    sessions_with_click_product_3: 19,
  },
  {
    date: "2024-01-25",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 35,
    daily_budget: 81,
    spend: 67,
    impressions: 24,
    sessions_with_click: 206,
    sessions_with_click_product_0: 42,
    sessions_with_click_product_1: 84,
    sessions_with_click_product_2: 20,
    sessions_with_click_product_3: 30,
  },
  {
    date: "2024-01-26",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 36,
    daily_budget: 65,
    spend: 37,
    impressions: 26,
    sessions_with_click: 183,
    sessions_with_click_product_0: 25,
    sessions_with_click_product_1: 51,
    sessions_with_click_product_2: 12,
    sessions_with_click_product_3: 28,
  },
  {
    date: "2024-01-27",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 27,
    daily_budget: 84,
    spend: 45,
    impressions: 68,
    sessions_with_click: 43,
    sessions_with_click_product_0: 37,
    sessions_with_click_product_1: 40,
    sessions_with_click_product_2: 12,
    sessions_with_click_product_3: 35,
  },
  {
    date: "2024-01-28",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 107,
    daily_budget: 32,
    spend: 109,
    impressions: 68,
    sessions_with_click: 144,
    sessions_with_click_product_0: 54,
    sessions_with_click_product_1: 43,
    sessions_with_click_product_2: 22,
    sessions_with_click_product_3: 31,
  },
  {
    date: "2024-01-29",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 93,
    daily_budget: 76,
    spend: 11,
    impressions: 93,
    sessions_with_click: 50,
    sessions_with_click_product_0: 104,
    sessions_with_click_product_1: 47,
    sessions_with_click_product_2: 24,
    sessions_with_click_product_3: 45,
  },
  {
    date: "2024-01-30",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 85,
    daily_budget: 61,
    spend: 49,
    impressions: 79,
    sessions_with_click: 15,
    sessions_with_click_product_0: 79,
    sessions_with_click_product_1: 79,
    sessions_with_click_product_2: 33,
    sessions_with_click_product_3: 48,
  },
  {
    date: "2024-01-31",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 72,
    daily_budget: 61,
    spend: 75,
    impressions: 80,
    sessions_with_click: 173,
    sessions_with_click_product_0: 30,
    sessions_with_click_product_1: 81,
    sessions_with_click_product_2: 12,
    sessions_with_click_product_3: 43,
  },
  {
    date: "2024-02-01",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 108,
    daily_budget: 49,
    spend: 48,
    impressions: 109,
    sessions_with_click: 107,
    sessions_with_click_product_0: 82,
    sessions_with_click_product_1: 17,
    sessions_with_click_product_2: 27,
    sessions_with_click_product_3: 21,
  },
  {
    date: "2024-02-02",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 94,
    daily_budget: 42,
    spend: 94,
    impressions: 52,
    sessions_with_click: 136,
    sessions_with_click_product_0: 105,
    sessions_with_click_product_1: 44,
    sessions_with_click_product_2: 17,
    sessions_with_click_product_3: 48,
  },
  {
    date: "2024-02-03",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 60,
    daily_budget: 87,
    spend: 57,
    impressions: 31,
    sessions_with_click: 151,
    sessions_with_click_product_0: 85,
    sessions_with_click_product_1: 79,
    sessions_with_click_product_2: 25,
    sessions_with_click_product_3: 11,
  },
  {
    date: "2024-02-04",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 51,
    daily_budget: 104,
    spend: 53,
    impressions: 70,
    sessions_with_click: 47,
    sessions_with_click_product_0: 107,
    sessions_with_click_product_1: 23,
    sessions_with_click_product_2: 37,
    sessions_with_click_product_3: 34,
  },
  {
    date: "2024-02-05",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 93,
    daily_budget: 51,
    spend: 47,
    impressions: 98,
    sessions_with_click: 93,
    sessions_with_click_product_0: 101,
    sessions_with_click_product_1: 88,
    sessions_with_click_product_2: 29,
    sessions_with_click_product_3: 27,
  },
  {
    date: "2024-02-06",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 65,
    daily_budget: 83,
    spend: 100,
    impressions: 90,
    sessions_with_click: 212,
    sessions_with_click_product_0: 50,
    sessions_with_click_product_1: 48,
    sessions_with_click_product_2: 39,
    sessions_with_click_product_3: 29,
  },
  {
    date: "2024-01-24",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 62,
    daily_budget: 108,
    spend: 45,
    impressions: 47,
    sessions_with_click: 209,
    sessions_with_click_product_0: 99,
    sessions_with_click_product_1: 30,
    sessions_with_click_product_2: 36,
    sessions_with_click_product_3: 30,
  },
  {
    date: "2024-01-25",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 78,
    daily_budget: 75,
    spend: 90,
    impressions: 51,
    sessions_with_click: 156,
    sessions_with_click_product_0: 97,
    sessions_with_click_product_1: 61,
    sessions_with_click_product_2: 28,
    sessions_with_click_product_3: 25,
  },
  {
    date: "2024-01-26",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 81,
    daily_budget: 61,
    spend: 25,
    impressions: 86,
    sessions_with_click: 15,
    sessions_with_click_product_0: 30,
    sessions_with_click_product_1: 14,
    sessions_with_click_product_2: 28,
    sessions_with_click_product_3: 31,
  },
  {
    date: "2024-01-27",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 76,
    daily_budget: 100,
    spend: 29,
    impressions: 33,
    sessions_with_click: 77,
    sessions_with_click_product_0: 84,
    sessions_with_click_product_1: 64,
    sessions_with_click_product_2: 25,
    sessions_with_click_product_3: 15,
  },
  {
    date: "2024-01-28",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 79,
    daily_budget: 40,
    spend: 97,
    impressions: 102,
    sessions_with_click: 67,
    sessions_with_click_product_0: 104,
    sessions_with_click_product_1: 63,
    sessions_with_click_product_2: 15,
    sessions_with_click_product_3: 31,
  },
  {
    date: "2024-01-29",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 18,
    daily_budget: 108,
    spend: 92,
    impressions: 84,
    sessions_with_click: 95,
    sessions_with_click_product_0: 20,
    sessions_with_click_product_1: 71,
    sessions_with_click_product_2: 23,
    sessions_with_click_product_3: 12,
  },
  {
    date: "2024-01-30",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 25,
    daily_budget: 25,
    spend: 14,
    impressions: 99,
    sessions_with_click: 134,
    sessions_with_click_product_0: 39,
    sessions_with_click_product_1: 40,
    sessions_with_click_product_2: 33,
    sessions_with_click_product_3: 32,
  },
  {
    date: "2024-01-31",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 24,
    daily_budget: 29,
    spend: 85,
    impressions: 65,
    sessions_with_click: 11,
    sessions_with_click_product_0: 98,
    sessions_with_click_product_1: 74,
    sessions_with_click_product_2: 11,
    sessions_with_click_product_3: 22,
  },
  {
    date: "2024-02-01",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 40,
    daily_budget: 33,
    spend: 10,
    impressions: 87,
    sessions_with_click: 111,
    sessions_with_click_product_0: 78,
    sessions_with_click_product_1: 14,
    sessions_with_click_product_2: 24,
    sessions_with_click_product_3: 18,
  },
  {
    date: "2024-02-02",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 51,
    daily_budget: 101,
    spend: 60,
    impressions: 106,
    sessions_with_click: 251,
    sessions_with_click_product_0: 43,
    sessions_with_click_product_1: 72,
    sessions_with_click_product_2: 37,
    sessions_with_click_product_3: 44,
  },
  {
    date: "2024-02-03",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 18,
    daily_budget: 73,
    spend: 104,
    impressions: 35,
    sessions_with_click: 249,
    sessions_with_click_product_0: 48,
    sessions_with_click_product_1: 72,
    sessions_with_click_product_2: 13,
    sessions_with_click_product_3: 35,
  },
  {
    date: "2024-02-04",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 64,
    daily_budget: 23,
    spend: 61,
    impressions: 63,
    sessions_with_click: 14,
    sessions_with_click_product_0: 41,
    sessions_with_click_product_1: 28,
    sessions_with_click_product_2: 35,
    sessions_with_click_product_3: 28,
  },
  {
    date: "2024-02-05",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 26,
    daily_budget: 55,
    spend: 28,
    impressions: 27,
    sessions_with_click: 200,
    sessions_with_click_product_0: 57,
    sessions_with_click_product_1: 25,
    sessions_with_click_product_2: 14,
    sessions_with_click_product_3: 40,
  },
  {
    date: "2024-02-06",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 50,
    daily_budget: 16,
    spend: 103,
    impressions: 68,
    sessions_with_click: 46,
    sessions_with_click_product_0: 16,
    sessions_with_click_product_1: 51,
    sessions_with_click_product_2: 11,
    sessions_with_click_product_3: 34,
  },
];

export const threeWeeksData = [
  {
    date: "2024-01-17",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 14,
    daily_budget: 92,
    spend: 61,
    impressions: 21,
    sessions_with_click: 229,
    sessions_with_click_product_0: 95,
    sessions_with_click_product_1: 82,
    sessions_with_click_product_2: 12,
    sessions_with_click_product_3: 13,
  },
  {
    date: "2024-01-18",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 105,
    daily_budget: 18,
    spend: 62,
    impressions: 52,
    sessions_with_click: 174,
    sessions_with_click_product_0: 20,
    sessions_with_click_product_1: 42,
    sessions_with_click_product_2: 15,
    sessions_with_click_product_3: 14,
  },
  {
    date: "2024-01-19",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 39,
    daily_budget: 102,
    spend: 39,
    impressions: 92,
    sessions_with_click: 185,
    sessions_with_click_product_0: 28,
    sessions_with_click_product_1: 64,
    sessions_with_click_product_2: 28,
    sessions_with_click_product_3: 21,
  },
  {
    date: "2024-01-20",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 70,
    daily_budget: 27,
    spend: 12,
    impressions: 30,
    sessions_with_click: 154,
    sessions_with_click_product_0: 10,
    sessions_with_click_product_1: 61,
    sessions_with_click_product_2: 30,
    sessions_with_click_product_3: 14,
  },
  {
    date: "2024-01-21",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 90,
    daily_budget: 65,
    spend: 96,
    impressions: 34,
    sessions_with_click: 117,
    sessions_with_click_product_0: 95,
    sessions_with_click_product_1: 85,
    sessions_with_click_product_2: 15,
    sessions_with_click_product_3: 16,
  },
  {
    date: "2024-01-22",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 88,
    daily_budget: 25,
    spend: 70,
    impressions: 109,
    sessions_with_click: 103,
    sessions_with_click_product_0: 69,
    sessions_with_click_product_1: 40,
    sessions_with_click_product_2: 15,
    sessions_with_click_product_3: 14,
  },
  {
    date: "2024-01-23",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 72,
    daily_budget: 19,
    spend: 50,
    impressions: 83,
    sessions_with_click: 109,
    sessions_with_click_product_0: 23,
    sessions_with_click_product_1: 74,
    sessions_with_click_product_2: 24,
    sessions_with_click_product_3: 37,
  },
  {
    date: "2024-01-24",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 11,
    daily_budget: 79,
    spend: 96,
    impressions: 98,
    sessions_with_click: 97,
    sessions_with_click_product_0: 47,
    sessions_with_click_product_1: 14,
    sessions_with_click_product_2: 16,
    sessions_with_click_product_3: 44,
  },
  {
    date: "2024-01-25",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 106,
    daily_budget: 52,
    spend: 19,
    impressions: 52,
    sessions_with_click: 248,
    sessions_with_click_product_0: 19,
    sessions_with_click_product_1: 30,
    sessions_with_click_product_2: 39,
    sessions_with_click_product_3: 46,
  },
  {
    date: "2024-01-26",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 56,
    daily_budget: 35,
    spend: 51,
    impressions: 73,
    sessions_with_click: 194,
    sessions_with_click_product_0: 23,
    sessions_with_click_product_1: 15,
    sessions_with_click_product_2: 24,
    sessions_with_click_product_3: 45,
  },
  {
    date: "2024-01-27",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 73,
    daily_budget: 100,
    spend: 79,
    impressions: 92,
    sessions_with_click: 102,
    sessions_with_click_product_0: 46,
    sessions_with_click_product_1: 10,
    sessions_with_click_product_2: 13,
    sessions_with_click_product_3: 44,
  },
  {
    date: "2024-01-28",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 67,
    daily_budget: 49,
    spend: 83,
    impressions: 30,
    sessions_with_click: 198,
    sessions_with_click_product_0: 12,
    sessions_with_click_product_1: 49,
    sessions_with_click_product_2: 35,
    sessions_with_click_product_3: 38,
  },
  {
    date: "2024-01-29",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 101,
    daily_budget: 92,
    spend: 86,
    impressions: 79,
    sessions_with_click: 232,
    sessions_with_click_product_0: 28,
    sessions_with_click_product_1: 13,
    sessions_with_click_product_2: 22,
    sessions_with_click_product_3: 48,
  },
  {
    date: "2024-01-30",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 53,
    daily_budget: 101,
    spend: 100,
    impressions: 78,
    sessions_with_click: 56,
    sessions_with_click_product_0: 53,
    sessions_with_click_product_1: 14,
    sessions_with_click_product_2: 31,
    sessions_with_click_product_3: 29,
  },
  {
    date: "2024-01-31",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 59,
    daily_budget: 28,
    spend: 78,
    impressions: 71,
    sessions_with_click: 190,
    sessions_with_click_product_0: 90,
    sessions_with_click_product_1: 49,
    sessions_with_click_product_2: 20,
    sessions_with_click_product_3: 42,
  },
  {
    date: "2024-02-01",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 51,
    daily_budget: 51,
    spend: 94,
    impressions: 51,
    sessions_with_click: 48,
    sessions_with_click_product_0: 12,
    sessions_with_click_product_1: 32,
    sessions_with_click_product_2: 25,
    sessions_with_click_product_3: 35,
  },
  {
    date: "2024-02-02",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 76,
    daily_budget: 93,
    spend: 61,
    impressions: 33,
    sessions_with_click: 63,
    sessions_with_click_product_0: 96,
    sessions_with_click_product_1: 39,
    sessions_with_click_product_2: 14,
    sessions_with_click_product_3: 37,
  },
  {
    date: "2024-02-03",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 11,
    daily_budget: 54,
    spend: 23,
    impressions: 104,
    sessions_with_click: 49,
    sessions_with_click_product_0: 88,
    sessions_with_click_product_1: 17,
    sessions_with_click_product_2: 15,
    sessions_with_click_product_3: 25,
  },
  {
    date: "2024-02-04",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 108,
    daily_budget: 96,
    spend: 35,
    impressions: 85,
    sessions_with_click: 134,
    sessions_with_click_product_0: 107,
    sessions_with_click_product_1: 73,
    sessions_with_click_product_2: 38,
    sessions_with_click_product_3: 20,
  },
  {
    date: "2024-02-05",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 68,
    daily_budget: 39,
    spend: 79,
    impressions: 74,
    sessions_with_click: 208,
    sessions_with_click_product_0: 43,
    sessions_with_click_product_1: 12,
    sessions_with_click_product_2: 25,
    sessions_with_click_product_3: 32,
  },
  {
    date: "2024-02-06",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 12,
    daily_budget: 25,
    spend: 24,
    impressions: 95,
    sessions_with_click: 201,
    sessions_with_click_product_0: 32,
    sessions_with_click_product_1: 10,
    sessions_with_click_product_2: 39,
    sessions_with_click_product_3: 21,
  },
  {
    date: "2024-01-17",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 44,
    daily_budget: 42,
    spend: 42,
    impressions: 114,
    sessions_with_click: 80,
    sessions_with_click_product_0: 67,
    sessions_with_click_product_1: 48,
    sessions_with_click_product_2: 26,
    sessions_with_click_product_3: 40,
  },
  {
    date: "2024-01-18",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 97,
    daily_budget: 97,
    spend: 93,
    impressions: 107,
    sessions_with_click: 91,
    sessions_with_click_product_0: 108,
    sessions_with_click_product_1: 61,
    sessions_with_click_product_2: 26,
    sessions_with_click_product_3: 33,
  },
  {
    date: "2024-01-19",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 11,
    daily_budget: 24,
    spend: 18,
    impressions: 41,
    sessions_with_click: 63,
    sessions_with_click_product_0: 104,
    sessions_with_click_product_1: 71,
    sessions_with_click_product_2: 13,
    sessions_with_click_product_3: 13,
  },
  {
    date: "2024-01-20",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 93,
    daily_budget: 101,
    spend: 97,
    impressions: 30,
    sessions_with_click: 171,
    sessions_with_click_product_0: 13,
    sessions_with_click_product_1: 70,
    sessions_with_click_product_2: 35,
    sessions_with_click_product_3: 35,
  },
  {
    date: "2024-01-21",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 91,
    daily_budget: 69,
    spend: 95,
    impressions: 48,
    sessions_with_click: 176,
    sessions_with_click_product_0: 43,
    sessions_with_click_product_1: 72,
    sessions_with_click_product_2: 38,
    sessions_with_click_product_3: 26,
  },
  {
    date: "2024-01-22",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 103,
    daily_budget: 64,
    spend: 87,
    impressions: 32,
    sessions_with_click: 232,
    sessions_with_click_product_0: 104,
    sessions_with_click_product_1: 74,
    sessions_with_click_product_2: 11,
    sessions_with_click_product_3: 42,
  },
  {
    date: "2024-01-23",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 52,
    daily_budget: 58,
    spend: 15,
    impressions: 103,
    sessions_with_click: 247,
    sessions_with_click_product_0: 102,
    sessions_with_click_product_1: 81,
    sessions_with_click_product_2: 30,
    sessions_with_click_product_3: 43,
  },
  {
    date: "2024-01-24",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 33,
    daily_budget: 36,
    spend: 98,
    impressions: 41,
    sessions_with_click: 79,
    sessions_with_click_product_0: 43,
    sessions_with_click_product_1: 87,
    sessions_with_click_product_2: 17,
    sessions_with_click_product_3: 34,
  },
  {
    date: "2024-01-25",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 29,
    daily_budget: 25,
    spend: 38,
    impressions: 30,
    sessions_with_click: 216,
    sessions_with_click_product_0: 106,
    sessions_with_click_product_1: 30,
    sessions_with_click_product_2: 22,
    sessions_with_click_product_3: 14,
  },
  {
    date: "2024-01-26",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 11,
    daily_budget: 54,
    spend: 30,
    impressions: 81,
    sessions_with_click: 113,
    sessions_with_click_product_0: 18,
    sessions_with_click_product_1: 41,
    sessions_with_click_product_2: 38,
    sessions_with_click_product_3: 27,
  },
  {
    date: "2024-01-27",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 81,
    daily_budget: 103,
    spend: 50,
    impressions: 108,
    sessions_with_click: 13,
    sessions_with_click_product_0: 56,
    sessions_with_click_product_1: 74,
    sessions_with_click_product_2: 22,
    sessions_with_click_product_3: 14,
  },
  {
    date: "2024-01-28",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 90,
    daily_budget: 39,
    spend: 101,
    impressions: 104,
    sessions_with_click: 97,
    sessions_with_click_product_0: 29,
    sessions_with_click_product_1: 69,
    sessions_with_click_product_2: 11,
    sessions_with_click_product_3: 10,
  },
  {
    date: "2024-01-29",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 13,
    daily_budget: 94,
    spend: 36,
    impressions: 33,
    sessions_with_click: 225,
    sessions_with_click_product_0: 18,
    sessions_with_click_product_1: 10,
    sessions_with_click_product_2: 35,
    sessions_with_click_product_3: 32,
  },
  {
    date: "2024-01-30",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 48,
    daily_budget: 25,
    spend: 68,
    impressions: 62,
    sessions_with_click: 27,
    sessions_with_click_product_0: 20,
    sessions_with_click_product_1: 28,
    sessions_with_click_product_2: 24,
    sessions_with_click_product_3: 45,
  },
  {
    date: "2024-01-31",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 51,
    daily_budget: 74,
    spend: 95,
    impressions: 106,
    sessions_with_click: 56,
    sessions_with_click_product_0: 36,
    sessions_with_click_product_1: 34,
    sessions_with_click_product_2: 33,
    sessions_with_click_product_3: 32,
  },
  {
    date: "2024-02-01",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 32,
    daily_budget: 85,
    spend: 77,
    impressions: 29,
    sessions_with_click: 90,
    sessions_with_click_product_0: 97,
    sessions_with_click_product_1: 38,
    sessions_with_click_product_2: 36,
    sessions_with_click_product_3: 30,
  },
  {
    date: "2024-02-02",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 70,
    daily_budget: 100,
    spend: 50,
    impressions: 82,
    sessions_with_click: 154,
    sessions_with_click_product_0: 33,
    sessions_with_click_product_1: 40,
    sessions_with_click_product_2: 39,
    sessions_with_click_product_3: 35,
  },
  {
    date: "2024-02-03",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 108,
    daily_budget: 14,
    spend: 27,
    impressions: 118,
    sessions_with_click: 202,
    sessions_with_click_product_0: 42,
    sessions_with_click_product_1: 42,
    sessions_with_click_product_2: 33,
    sessions_with_click_product_3: 11,
  },
  {
    date: "2024-02-04",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 52,
    daily_budget: 107,
    spend: 100,
    impressions: 107,
    sessions_with_click: 20,
    sessions_with_click_product_0: 106,
    sessions_with_click_product_1: 69,
    sessions_with_click_product_2: 36,
    sessions_with_click_product_3: 17,
  },
  {
    date: "2024-02-05",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 67,
    daily_budget: 26,
    spend: 89,
    impressions: 70,
    sessions_with_click: 142,
    sessions_with_click_product_0: 106,
    sessions_with_click_product_1: 12,
    sessions_with_click_product_2: 34,
    sessions_with_click_product_3: 30,
  },
  {
    date: "2024-02-06",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 61,
    daily_budget: 64,
    spend: 25,
    impressions: 109,
    sessions_with_click: 184,
    sessions_with_click_product_0: 63,
    sessions_with_click_product_1: 18,
    sessions_with_click_product_2: 32,
    sessions_with_click_product_3: 26,
  },
  {
    date: "2024-01-17",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 77,
    daily_budget: 96,
    spend: 100,
    impressions: 119,
    sessions_with_click: 62,
    sessions_with_click_product_0: 96,
    sessions_with_click_product_1: 84,
    sessions_with_click_product_2: 35,
    sessions_with_click_product_3: 49,
  },
  {
    date: "2024-01-18",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 61,
    daily_budget: 24,
    spend: 27,
    impressions: 21,
    sessions_with_click: 119,
    sessions_with_click_product_0: 29,
    sessions_with_click_product_1: 85,
    sessions_with_click_product_2: 23,
    sessions_with_click_product_3: 32,
  },
  {
    date: "2024-01-19",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 99,
    daily_budget: 91,
    spend: 71,
    impressions: 115,
    sessions_with_click: 204,
    sessions_with_click_product_0: 45,
    sessions_with_click_product_1: 73,
    sessions_with_click_product_2: 10,
    sessions_with_click_product_3: 49,
  },
  {
    date: "2024-01-20",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 94,
    daily_budget: 107,
    spend: 33,
    impressions: 69,
    sessions_with_click: 200,
    sessions_with_click_product_0: 78,
    sessions_with_click_product_1: 64,
    sessions_with_click_product_2: 38,
    sessions_with_click_product_3: 32,
  },
  {
    date: "2024-01-21",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 66,
    daily_budget: 22,
    spend: 96,
    impressions: 87,
    sessions_with_click: 72,
    sessions_with_click_product_0: 80,
    sessions_with_click_product_1: 88,
    sessions_with_click_product_2: 20,
    sessions_with_click_product_3: 10,
  },
  {
    date: "2024-01-22",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 77,
    daily_budget: 15,
    spend: 47,
    impressions: 94,
    sessions_with_click: 39,
    sessions_with_click_product_0: 75,
    sessions_with_click_product_1: 40,
    sessions_with_click_product_2: 35,
    sessions_with_click_product_3: 46,
  },
  {
    date: "2024-01-23",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 39,
    daily_budget: 50,
    spend: 73,
    impressions: 36,
    sessions_with_click: 200,
    sessions_with_click_product_0: 107,
    sessions_with_click_product_1: 44,
    sessions_with_click_product_2: 26,
    sessions_with_click_product_3: 38,
  },
  {
    date: "2024-01-24",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 53,
    daily_budget: 45,
    spend: 75,
    impressions: 26,
    sessions_with_click: 196,
    sessions_with_click_product_0: 64,
    sessions_with_click_product_1: 74,
    sessions_with_click_product_2: 29,
    sessions_with_click_product_3: 31,
  },
  {
    date: "2024-01-25",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 43,
    daily_budget: 61,
    spend: 66,
    impressions: 98,
    sessions_with_click: 173,
    sessions_with_click_product_0: 99,
    sessions_with_click_product_1: 45,
    sessions_with_click_product_2: 19,
    sessions_with_click_product_3: 20,
  },
  {
    date: "2024-01-26",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 75,
    daily_budget: 72,
    spend: 100,
    impressions: 26,
    sessions_with_click: 258,
    sessions_with_click_product_0: 86,
    sessions_with_click_product_1: 76,
    sessions_with_click_product_2: 14,
    sessions_with_click_product_3: 14,
  },
  {
    date: "2024-01-27",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 88,
    daily_budget: 105,
    spend: 71,
    impressions: 87,
    sessions_with_click: 238,
    sessions_with_click_product_0: 96,
    sessions_with_click_product_1: 79,
    sessions_with_click_product_2: 33,
    sessions_with_click_product_3: 36,
  },
  {
    date: "2024-01-28",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 100,
    daily_budget: 34,
    spend: 34,
    impressions: 81,
    sessions_with_click: 51,
    sessions_with_click_product_0: 102,
    sessions_with_click_product_1: 89,
    sessions_with_click_product_2: 21,
    sessions_with_click_product_3: 32,
  },
  {
    date: "2024-01-29",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 101,
    daily_budget: 54,
    spend: 40,
    impressions: 49,
    sessions_with_click: 136,
    sessions_with_click_product_0: 45,
    sessions_with_click_product_1: 27,
    sessions_with_click_product_2: 31,
    sessions_with_click_product_3: 43,
  },
  {
    date: "2024-01-30",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 19,
    daily_budget: 17,
    spend: 18,
    impressions: 112,
    sessions_with_click: 47,
    sessions_with_click_product_0: 89,
    sessions_with_click_product_1: 42,
    sessions_with_click_product_2: 33,
    sessions_with_click_product_3: 25,
  },
  {
    date: "2024-01-31",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 62,
    daily_budget: 61,
    spend: 59,
    impressions: 104,
    sessions_with_click: 105,
    sessions_with_click_product_0: 93,
    sessions_with_click_product_1: 58,
    sessions_with_click_product_2: 24,
    sessions_with_click_product_3: 35,
  },
  {
    date: "2024-02-01",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 83,
    daily_budget: 94,
    spend: 78,
    impressions: 57,
    sessions_with_click: 23,
    sessions_with_click_product_0: 42,
    sessions_with_click_product_1: 78,
    sessions_with_click_product_2: 32,
    sessions_with_click_product_3: 44,
  },
  {
    date: "2024-02-02",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 60,
    daily_budget: 62,
    spend: 65,
    impressions: 56,
    sessions_with_click: 32,
    sessions_with_click_product_0: 51,
    sessions_with_click_product_1: 67,
    sessions_with_click_product_2: 13,
    sessions_with_click_product_3: 27,
  },
  {
    date: "2024-02-03",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 106,
    daily_budget: 92,
    spend: 18,
    impressions: 93,
    sessions_with_click: 12,
    sessions_with_click_product_0: 22,
    sessions_with_click_product_1: 67,
    sessions_with_click_product_2: 18,
    sessions_with_click_product_3: 16,
  },
  {
    date: "2024-02-04",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 26,
    daily_budget: 70,
    spend: 82,
    impressions: 118,
    sessions_with_click: 91,
    sessions_with_click_product_0: 94,
    sessions_with_click_product_1: 48,
    sessions_with_click_product_2: 24,
    sessions_with_click_product_3: 33,
  },
  {
    date: "2024-02-05",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 18,
    daily_budget: 51,
    spend: 73,
    impressions: 65,
    sessions_with_click: 188,
    sessions_with_click_product_0: 38,
    sessions_with_click_product_1: 47,
    sessions_with_click_product_2: 36,
    sessions_with_click_product_3: 30,
  },
  {
    date: "2024-02-06",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 58,
    daily_budget: 75,
    spend: 37,
    impressions: 51,
    sessions_with_click: 237,
    sessions_with_click_product_0: 64,
    sessions_with_click_product_1: 25,
    sessions_with_click_product_2: 22,
    sessions_with_click_product_3: 29,
  },
  {
    date: "2024-01-17",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 106,
    daily_budget: 39,
    spend: 56,
    impressions: 84,
    sessions_with_click: 133,
    sessions_with_click_product_0: 64,
    sessions_with_click_product_1: 28,
    sessions_with_click_product_2: 26,
    sessions_with_click_product_3: 18,
  },
  {
    date: "2024-01-18",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 24,
    daily_budget: 92,
    spend: 53,
    impressions: 82,
    sessions_with_click: 104,
    sessions_with_click_product_0: 57,
    sessions_with_click_product_1: 27,
    sessions_with_click_product_2: 31,
    sessions_with_click_product_3: 14,
  },
  {
    date: "2024-01-19",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 49,
    daily_budget: 77,
    spend: 61,
    impressions: 38,
    sessions_with_click: 36,
    sessions_with_click_product_0: 86,
    sessions_with_click_product_1: 80,
    sessions_with_click_product_2: 23,
    sessions_with_click_product_3: 16,
  },
  {
    date: "2024-01-20",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 52,
    daily_budget: 40,
    spend: 40,
    impressions: 40,
    sessions_with_click: 53,
    sessions_with_click_product_0: 64,
    sessions_with_click_product_1: 25,
    sessions_with_click_product_2: 23,
    sessions_with_click_product_3: 24,
  },
  {
    date: "2024-01-21",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 74,
    daily_budget: 43,
    spend: 58,
    impressions: 112,
    sessions_with_click: 205,
    sessions_with_click_product_0: 23,
    sessions_with_click_product_1: 44,
    sessions_with_click_product_2: 11,
    sessions_with_click_product_3: 20,
  },
  {
    date: "2024-01-22",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 63,
    daily_budget: 72,
    spend: 50,
    impressions: 25,
    sessions_with_click: 239,
    sessions_with_click_product_0: 108,
    sessions_with_click_product_1: 21,
    sessions_with_click_product_2: 36,
    sessions_with_click_product_3: 31,
  },
  {
    date: "2024-01-23",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 21,
    daily_budget: 86,
    spend: 17,
    impressions: 32,
    sessions_with_click: 160,
    sessions_with_click_product_0: 87,
    sessions_with_click_product_1: 55,
    sessions_with_click_product_2: 10,
    sessions_with_click_product_3: 13,
  },
  {
    date: "2024-01-24",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 97,
    daily_budget: 70,
    spend: 107,
    impressions: 88,
    sessions_with_click: 37,
    sessions_with_click_product_0: 62,
    sessions_with_click_product_1: 85,
    sessions_with_click_product_2: 17,
    sessions_with_click_product_3: 13,
  },
  {
    date: "2024-01-25",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 95,
    daily_budget: 56,
    spend: 28,
    impressions: 91,
    sessions_with_click: 190,
    sessions_with_click_product_0: 19,
    sessions_with_click_product_1: 80,
    sessions_with_click_product_2: 28,
    sessions_with_click_product_3: 23,
  },
  {
    date: "2024-01-26",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 46,
    daily_budget: 21,
    spend: 86,
    impressions: 101,
    sessions_with_click: 100,
    sessions_with_click_product_0: 34,
    sessions_with_click_product_1: 55,
    sessions_with_click_product_2: 16,
    sessions_with_click_product_3: 10,
  },
  {
    date: "2024-01-27",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 34,
    daily_budget: 69,
    spend: 55,
    impressions: 96,
    sessions_with_click: 245,
    sessions_with_click_product_0: 14,
    sessions_with_click_product_1: 72,
    sessions_with_click_product_2: 31,
    sessions_with_click_product_3: 14,
  },
  {
    date: "2024-01-28",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 26,
    daily_budget: 102,
    spend: 88,
    impressions: 101,
    sessions_with_click: 145,
    sessions_with_click_product_0: 14,
    sessions_with_click_product_1: 85,
    sessions_with_click_product_2: 13,
    sessions_with_click_product_3: 25,
  },
  {
    date: "2024-01-29",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 37,
    daily_budget: 27,
    spend: 50,
    impressions: 64,
    sessions_with_click: 90,
    sessions_with_click_product_0: 31,
    sessions_with_click_product_1: 49,
    sessions_with_click_product_2: 20,
    sessions_with_click_product_3: 45,
  },
  {
    date: "2024-01-30",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 87,
    daily_budget: 68,
    spend: 37,
    impressions: 68,
    sessions_with_click: 69,
    sessions_with_click_product_0: 84,
    sessions_with_click_product_1: 20,
    sessions_with_click_product_2: 24,
    sessions_with_click_product_3: 34,
  },
  {
    date: "2024-01-31",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 30,
    daily_budget: 65,
    spend: 19,
    impressions: 23,
    sessions_with_click: 169,
    sessions_with_click_product_0: 33,
    sessions_with_click_product_1: 75,
    sessions_with_click_product_2: 26,
    sessions_with_click_product_3: 23,
  },
  {
    date: "2024-02-01",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 70,
    daily_budget: 27,
    spend: 13,
    impressions: 76,
    sessions_with_click: 245,
    sessions_with_click_product_0: 27,
    sessions_with_click_product_1: 45,
    sessions_with_click_product_2: 26,
    sessions_with_click_product_3: 35,
  },
  {
    date: "2024-02-02",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 64,
    daily_budget: 68,
    spend: 18,
    impressions: 103,
    sessions_with_click: 64,
    sessions_with_click_product_0: 103,
    sessions_with_click_product_1: 36,
    sessions_with_click_product_2: 36,
    sessions_with_click_product_3: 21,
  },
  {
    date: "2024-02-03",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 28,
    daily_budget: 18,
    spend: 13,
    impressions: 90,
    sessions_with_click: 172,
    sessions_with_click_product_0: 47,
    sessions_with_click_product_1: 86,
    sessions_with_click_product_2: 10,
    sessions_with_click_product_3: 20,
  },
  {
    date: "2024-02-04",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 101,
    daily_budget: 90,
    spend: 98,
    impressions: 60,
    sessions_with_click: 60,
    sessions_with_click_product_0: 19,
    sessions_with_click_product_1: 86,
    sessions_with_click_product_2: 32,
    sessions_with_click_product_3: 25,
  },
  {
    date: "2024-02-05",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 73,
    daily_budget: 85,
    spend: 26,
    impressions: 64,
    sessions_with_click: 178,
    sessions_with_click_product_0: 98,
    sessions_with_click_product_1: 78,
    sessions_with_click_product_2: 34,
    sessions_with_click_product_3: 38,
  },
  {
    date: "2024-02-06",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 94,
    daily_budget: 54,
    spend: 55,
    impressions: 111,
    sessions_with_click: 131,
    sessions_with_click_product_0: 84,
    sessions_with_click_product_1: 16,
    sessions_with_click_product_2: 23,
    sessions_with_click_product_3: 29,
  },
  {
    date: "2024-01-17",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 64,
    daily_budget: 39,
    spend: 81,
    impressions: 38,
    sessions_with_click: 228,
    sessions_with_click_product_0: 78,
    sessions_with_click_product_1: 24,
    sessions_with_click_product_2: 20,
    sessions_with_click_product_3: 20,
  },
  {
    date: "2024-01-18",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 94,
    daily_budget: 14,
    spend: 37,
    impressions: 114,
    sessions_with_click: 110,
    sessions_with_click_product_0: 90,
    sessions_with_click_product_1: 20,
    sessions_with_click_product_2: 28,
    sessions_with_click_product_3: 32,
  },
  {
    date: "2024-01-19",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 81,
    daily_budget: 17,
    spend: 35,
    impressions: 24,
    sessions_with_click: 235,
    sessions_with_click_product_0: 27,
    sessions_with_click_product_1: 45,
    sessions_with_click_product_2: 38,
    sessions_with_click_product_3: 11,
  },
  {
    date: "2024-01-20",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 96,
    daily_budget: 89,
    spend: 101,
    impressions: 110,
    sessions_with_click: 239,
    sessions_with_click_product_0: 44,
    sessions_with_click_product_1: 14,
    sessions_with_click_product_2: 12,
    sessions_with_click_product_3: 46,
  },
  {
    date: "2024-01-21",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 107,
    daily_budget: 27,
    spend: 58,
    impressions: 42,
    sessions_with_click: 138,
    sessions_with_click_product_0: 56,
    sessions_with_click_product_1: 21,
    sessions_with_click_product_2: 10,
    sessions_with_click_product_3: 27,
  },
  {
    date: "2024-01-22",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 38,
    daily_budget: 69,
    spend: 75,
    impressions: 119,
    sessions_with_click: 244,
    sessions_with_click_product_0: 39,
    sessions_with_click_product_1: 29,
    sessions_with_click_product_2: 16,
    sessions_with_click_product_3: 23,
  },
  {
    date: "2024-01-23",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 66,
    daily_budget: 32,
    spend: 92,
    impressions: 75,
    sessions_with_click: 259,
    sessions_with_click_product_0: 26,
    sessions_with_click_product_1: 21,
    sessions_with_click_product_2: 32,
    sessions_with_click_product_3: 32,
  },
  {
    date: "2024-01-24",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 29,
    daily_budget: 49,
    spend: 72,
    impressions: 91,
    sessions_with_click: 164,
    sessions_with_click_product_0: 31,
    sessions_with_click_product_1: 19,
    sessions_with_click_product_2: 11,
    sessions_with_click_product_3: 22,
  },
  {
    date: "2024-01-25",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 63,
    daily_budget: 10,
    spend: 57,
    impressions: 30,
    sessions_with_click: 123,
    sessions_with_click_product_0: 22,
    sessions_with_click_product_1: 82,
    sessions_with_click_product_2: 35,
    sessions_with_click_product_3: 26,
  },
  {
    date: "2024-01-26",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 40,
    daily_budget: 84,
    spend: 107,
    impressions: 90,
    sessions_with_click: 236,
    sessions_with_click_product_0: 109,
    sessions_with_click_product_1: 64,
    sessions_with_click_product_2: 28,
    sessions_with_click_product_3: 47,
  },
  {
    date: "2024-01-27",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 70,
    daily_budget: 18,
    spend: 109,
    impressions: 86,
    sessions_with_click: 248,
    sessions_with_click_product_0: 79,
    sessions_with_click_product_1: 54,
    sessions_with_click_product_2: 38,
    sessions_with_click_product_3: 27,
  },
  {
    date: "2024-01-28",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 91,
    daily_budget: 38,
    spend: 17,
    impressions: 112,
    sessions_with_click: 79,
    sessions_with_click_product_0: 86,
    sessions_with_click_product_1: 80,
    sessions_with_click_product_2: 33,
    sessions_with_click_product_3: 37,
  },
  {
    date: "2024-01-29",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 89,
    daily_budget: 52,
    spend: 46,
    impressions: 53,
    sessions_with_click: 58,
    sessions_with_click_product_0: 59,
    sessions_with_click_product_1: 16,
    sessions_with_click_product_2: 23,
    sessions_with_click_product_3: 35,
  },
  {
    date: "2024-01-30",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 18,
    daily_budget: 45,
    spend: 17,
    impressions: 36,
    sessions_with_click: 57,
    sessions_with_click_product_0: 86,
    sessions_with_click_product_1: 73,
    sessions_with_click_product_2: 28,
    sessions_with_click_product_3: 31,
  },
  {
    date: "2024-01-31",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 38,
    daily_budget: 49,
    spend: 46,
    impressions: 93,
    sessions_with_click: 46,
    sessions_with_click_product_0: 89,
    sessions_with_click_product_1: 88,
    sessions_with_click_product_2: 20,
    sessions_with_click_product_3: 24,
  },
  {
    date: "2024-02-01",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 34,
    daily_budget: 60,
    spend: 47,
    impressions: 42,
    sessions_with_click: 26,
    sessions_with_click_product_0: 76,
    sessions_with_click_product_1: 52,
    sessions_with_click_product_2: 29,
    sessions_with_click_product_3: 27,
  },
  {
    date: "2024-02-02",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 43,
    daily_budget: 59,
    spend: 39,
    impressions: 83,
    sessions_with_click: 236,
    sessions_with_click_product_0: 60,
    sessions_with_click_product_1: 22,
    sessions_with_click_product_2: 19,
    sessions_with_click_product_3: 40,
  },
  {
    date: "2024-02-03",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 36,
    daily_budget: 29,
    spend: 69,
    impressions: 107,
    sessions_with_click: 158,
    sessions_with_click_product_0: 23,
    sessions_with_click_product_1: 16,
    sessions_with_click_product_2: 39,
    sessions_with_click_product_3: 21,
  },
  {
    date: "2024-02-04",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 57,
    daily_budget: 16,
    spend: 63,
    impressions: 92,
    sessions_with_click: 177,
    sessions_with_click_product_0: 50,
    sessions_with_click_product_1: 41,
    sessions_with_click_product_2: 26,
    sessions_with_click_product_3: 20,
  },
  {
    date: "2024-02-05",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 105,
    daily_budget: 104,
    spend: 21,
    impressions: 106,
    sessions_with_click: 256,
    sessions_with_click_product_0: 92,
    sessions_with_click_product_1: 28,
    sessions_with_click_product_2: 17,
    sessions_with_click_product_3: 36,
  },
  {
    date: "2024-02-06",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 21,
    daily_budget: 102,
    spend: 104,
    impressions: 36,
    sessions_with_click: 231,
    sessions_with_click_product_0: 46,
    sessions_with_click_product_1: 80,
    sessions_with_click_product_2: 16,
    sessions_with_click_product_3: 39,
  },
];

export const fourWeeksData = [
  {
    date: "2024-01-10",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 25,
    daily_budget: 13,
    spend: 46,
    impressions: 48,
    sessions_with_click: 241,
    sessions_with_click_product_0: 23,
    sessions_with_click_product_1: 85,
    sessions_with_click_product_2: 19,
    sessions_with_click_product_3: 48,
  },
  {
    date: "2024-01-11",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 55,
    daily_budget: 61,
    spend: 75,
    impressions: 58,
    sessions_with_click: 240,
    sessions_with_click_product_0: 16,
    sessions_with_click_product_1: 55,
    sessions_with_click_product_2: 18,
    sessions_with_click_product_3: 49,
  },
  {
    date: "2024-01-12",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 41,
    daily_budget: 14,
    spend: 21,
    impressions: 71,
    sessions_with_click: 40,
    sessions_with_click_product_0: 44,
    sessions_with_click_product_1: 58,
    sessions_with_click_product_2: 31,
    sessions_with_click_product_3: 23,
  },
  {
    date: "2024-01-13",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 14,
    daily_budget: 91,
    spend: 77,
    impressions: 89,
    sessions_with_click: 49,
    sessions_with_click_product_0: 94,
    sessions_with_click_product_1: 17,
    sessions_with_click_product_2: 23,
    sessions_with_click_product_3: 28,
  },
  {
    date: "2024-01-14",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 34,
    daily_budget: 107,
    spend: 98,
    impressions: 81,
    sessions_with_click: 31,
    sessions_with_click_product_0: 68,
    sessions_with_click_product_1: 35,
    sessions_with_click_product_2: 17,
    sessions_with_click_product_3: 47,
  },
  {
    date: "2024-01-15",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 86,
    daily_budget: 51,
    spend: 31,
    impressions: 111,
    sessions_with_click: 26,
    sessions_with_click_product_0: 53,
    sessions_with_click_product_1: 74,
    sessions_with_click_product_2: 11,
    sessions_with_click_product_3: 40,
  },
  {
    date: "2024-01-16",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 94,
    daily_budget: 38,
    spend: 22,
    impressions: 22,
    sessions_with_click: 15,
    sessions_with_click_product_0: 88,
    sessions_with_click_product_1: 60,
    sessions_with_click_product_2: 10,
    sessions_with_click_product_3: 25,
  },
  {
    date: "2024-01-17",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 32,
    daily_budget: 81,
    spend: 107,
    impressions: 23,
    sessions_with_click: 49,
    sessions_with_click_product_0: 100,
    sessions_with_click_product_1: 58,
    sessions_with_click_product_2: 39,
    sessions_with_click_product_3: 25,
  },
  {
    date: "2024-01-18",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 18,
    daily_budget: 86,
    spend: 46,
    impressions: 73,
    sessions_with_click: 18,
    sessions_with_click_product_0: 14,
    sessions_with_click_product_1: 25,
    sessions_with_click_product_2: 16,
    sessions_with_click_product_3: 36,
  },
  {
    date: "2024-01-19",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 100,
    daily_budget: 109,
    spend: 57,
    impressions: 104,
    sessions_with_click: 25,
    sessions_with_click_product_0: 45,
    sessions_with_click_product_1: 60,
    sessions_with_click_product_2: 18,
    sessions_with_click_product_3: 39,
  },
  {
    date: "2024-01-20",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 53,
    daily_budget: 95,
    spend: 84,
    impressions: 76,
    sessions_with_click: 87,
    sessions_with_click_product_0: 87,
    sessions_with_click_product_1: 26,
    sessions_with_click_product_2: 36,
    sessions_with_click_product_3: 25,
  },
  {
    date: "2024-01-21",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 68,
    daily_budget: 66,
    spend: 41,
    impressions: 77,
    sessions_with_click: 29,
    sessions_with_click_product_0: 24,
    sessions_with_click_product_1: 33,
    sessions_with_click_product_2: 29,
    sessions_with_click_product_3: 31,
  },
  {
    date: "2024-01-22",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 18,
    daily_budget: 13,
    spend: 49,
    impressions: 36,
    sessions_with_click: 12,
    sessions_with_click_product_0: 43,
    sessions_with_click_product_1: 36,
    sessions_with_click_product_2: 27,
    sessions_with_click_product_3: 32,
  },
  {
    date: "2024-01-23",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 81,
    daily_budget: 84,
    spend: 51,
    impressions: 78,
    sessions_with_click: 28,
    sessions_with_click_product_0: 21,
    sessions_with_click_product_1: 73,
    sessions_with_click_product_2: 35,
    sessions_with_click_product_3: 22,
  },
  {
    date: "2024-01-24",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 56,
    daily_budget: 44,
    spend: 24,
    impressions: 24,
    sessions_with_click: 105,
    sessions_with_click_product_0: 109,
    sessions_with_click_product_1: 17,
    sessions_with_click_product_2: 22,
    sessions_with_click_product_3: 31,
  },
  {
    date: "2024-01-25",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 94,
    daily_budget: 95,
    spend: 102,
    impressions: 73,
    sessions_with_click: 128,
    sessions_with_click_product_0: 25,
    sessions_with_click_product_1: 46,
    sessions_with_click_product_2: 16,
    sessions_with_click_product_3: 39,
  },
  {
    date: "2024-01-26",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 29,
    daily_budget: 60,
    spend: 102,
    impressions: 74,
    sessions_with_click: 242,
    sessions_with_click_product_0: 11,
    sessions_with_click_product_1: 83,
    sessions_with_click_product_2: 31,
    sessions_with_click_product_3: 24,
  },
  {
    date: "2024-01-27",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 48,
    daily_budget: 109,
    spend: 103,
    impressions: 100,
    sessions_with_click: 197,
    sessions_with_click_product_0: 44,
    sessions_with_click_product_1: 60,
    sessions_with_click_product_2: 33,
    sessions_with_click_product_3: 19,
  },
  {
    date: "2024-01-28",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 107,
    daily_budget: 59,
    spend: 49,
    impressions: 38,
    sessions_with_click: 84,
    sessions_with_click_product_0: 75,
    sessions_with_click_product_1: 55,
    sessions_with_click_product_2: 15,
    sessions_with_click_product_3: 31,
  },
  {
    date: "2024-01-29",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 30,
    daily_budget: 22,
    spend: 29,
    impressions: 113,
    sessions_with_click: 16,
    sessions_with_click_product_0: 108,
    sessions_with_click_product_1: 72,
    sessions_with_click_product_2: 29,
    sessions_with_click_product_3: 43,
  },
  {
    date: "2024-01-30",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 76,
    daily_budget: 10,
    spend: 56,
    impressions: 118,
    sessions_with_click: 194,
    sessions_with_click_product_0: 84,
    sessions_with_click_product_1: 62,
    sessions_with_click_product_2: 39,
    sessions_with_click_product_3: 31,
  },
  {
    date: "2024-01-31",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 15,
    daily_budget: 106,
    spend: 14,
    impressions: 119,
    sessions_with_click: 198,
    sessions_with_click_product_0: 33,
    sessions_with_click_product_1: 21,
    sessions_with_click_product_2: 31,
    sessions_with_click_product_3: 44,
  },
  {
    date: "2024-02-01",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 58,
    daily_budget: 96,
    spend: 40,
    impressions: 74,
    sessions_with_click: 128,
    sessions_with_click_product_0: 104,
    sessions_with_click_product_1: 59,
    sessions_with_click_product_2: 32,
    sessions_with_click_product_3: 12,
  },
  {
    date: "2024-02-02",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 82,
    daily_budget: 66,
    spend: 107,
    impressions: 75,
    sessions_with_click: 25,
    sessions_with_click_product_0: 11,
    sessions_with_click_product_1: 75,
    sessions_with_click_product_2: 32,
    sessions_with_click_product_3: 20,
  },
  {
    date: "2024-02-03",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 97,
    daily_budget: 31,
    spend: 20,
    impressions: 57,
    sessions_with_click: 91,
    sessions_with_click_product_0: 68,
    sessions_with_click_product_1: 76,
    sessions_with_click_product_2: 35,
    sessions_with_click_product_3: 24,
  },
  {
    date: "2024-02-04",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 98,
    daily_budget: 47,
    spend: 36,
    impressions: 102,
    sessions_with_click: 191,
    sessions_with_click_product_0: 90,
    sessions_with_click_product_1: 19,
    sessions_with_click_product_2: 39,
    sessions_with_click_product_3: 36,
  },
  {
    date: "2024-02-05",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 77,
    daily_budget: 25,
    spend: 17,
    impressions: 59,
    sessions_with_click: 131,
    sessions_with_click_product_0: 83,
    sessions_with_click_product_1: 43,
    sessions_with_click_product_2: 13,
    sessions_with_click_product_3: 21,
  },
  {
    date: "2024-02-06",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 24,
    daily_budget: 96,
    spend: 57,
    impressions: 80,
    sessions_with_click: 157,
    sessions_with_click_product_0: 51,
    sessions_with_click_product_1: 29,
    sessions_with_click_product_2: 30,
    sessions_with_click_product_3: 27,
  },
  {
    date: "2024-01-10",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 61,
    daily_budget: 102,
    spend: 94,
    impressions: 57,
    sessions_with_click: 116,
    sessions_with_click_product_0: 27,
    sessions_with_click_product_1: 26,
    sessions_with_click_product_2: 14,
    sessions_with_click_product_3: 36,
  },
  {
    date: "2024-01-11",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 105,
    daily_budget: 97,
    spend: 84,
    impressions: 30,
    sessions_with_click: 126,
    sessions_with_click_product_0: 82,
    sessions_with_click_product_1: 72,
    sessions_with_click_product_2: 26,
    sessions_with_click_product_3: 24,
  },
  {
    date: "2024-01-12",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 40,
    daily_budget: 78,
    spend: 71,
    impressions: 63,
    sessions_with_click: 83,
    sessions_with_click_product_0: 15,
    sessions_with_click_product_1: 58,
    sessions_with_click_product_2: 23,
    sessions_with_click_product_3: 32,
  },
  {
    date: "2024-01-13",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 95,
    daily_budget: 72,
    spend: 83,
    impressions: 100,
    sessions_with_click: 214,
    sessions_with_click_product_0: 13,
    sessions_with_click_product_1: 64,
    sessions_with_click_product_2: 30,
    sessions_with_click_product_3: 45,
  },
  {
    date: "2024-01-14",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 99,
    daily_budget: 39,
    spend: 48,
    impressions: 111,
    sessions_with_click: 48,
    sessions_with_click_product_0: 41,
    sessions_with_click_product_1: 45,
    sessions_with_click_product_2: 17,
    sessions_with_click_product_3: 14,
  },
  {
    date: "2024-01-15",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 69,
    daily_budget: 93,
    spend: 27,
    impressions: 80,
    sessions_with_click: 12,
    sessions_with_click_product_0: 22,
    sessions_with_click_product_1: 58,
    sessions_with_click_product_2: 32,
    sessions_with_click_product_3: 32,
  },
  {
    date: "2024-01-16",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 101,
    daily_budget: 62,
    spend: 55,
    impressions: 70,
    sessions_with_click: 155,
    sessions_with_click_product_0: 62,
    sessions_with_click_product_1: 68,
    sessions_with_click_product_2: 39,
    sessions_with_click_product_3: 42,
  },
  {
    date: "2024-01-17",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 46,
    daily_budget: 106,
    spend: 67,
    impressions: 34,
    sessions_with_click: 168,
    sessions_with_click_product_0: 107,
    sessions_with_click_product_1: 19,
    sessions_with_click_product_2: 17,
    sessions_with_click_product_3: 20,
  },
  {
    date: "2024-01-18",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 47,
    daily_budget: 43,
    spend: 27,
    impressions: 47,
    sessions_with_click: 195,
    sessions_with_click_product_0: 89,
    sessions_with_click_product_1: 61,
    sessions_with_click_product_2: 12,
    sessions_with_click_product_3: 46,
  },
  {
    date: "2024-01-19",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 66,
    daily_budget: 90,
    spend: 99,
    impressions: 118,
    sessions_with_click: 43,
    sessions_with_click_product_0: 40,
    sessions_with_click_product_1: 59,
    sessions_with_click_product_2: 18,
    sessions_with_click_product_3: 44,
  },
  {
    date: "2024-01-20",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 102,
    daily_budget: 61,
    spend: 34,
    impressions: 36,
    sessions_with_click: 79,
    sessions_with_click_product_0: 105,
    sessions_with_click_product_1: 67,
    sessions_with_click_product_2: 24,
    sessions_with_click_product_3: 31,
  },
  {
    date: "2024-01-21",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 53,
    daily_budget: 35,
    spend: 11,
    impressions: 103,
    sessions_with_click: 135,
    sessions_with_click_product_0: 73,
    sessions_with_click_product_1: 43,
    sessions_with_click_product_2: 15,
    sessions_with_click_product_3: 36,
  },
  {
    date: "2024-01-22",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 91,
    daily_budget: 107,
    spend: 20,
    impressions: 72,
    sessions_with_click: 146,
    sessions_with_click_product_0: 77,
    sessions_with_click_product_1: 12,
    sessions_with_click_product_2: 27,
    sessions_with_click_product_3: 29,
  },
  {
    date: "2024-01-23",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 101,
    daily_budget: 82,
    spend: 100,
    impressions: 113,
    sessions_with_click: 100,
    sessions_with_click_product_0: 24,
    sessions_with_click_product_1: 42,
    sessions_with_click_product_2: 31,
    sessions_with_click_product_3: 31,
  },
  {
    date: "2024-01-24",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 80,
    daily_budget: 38,
    spend: 82,
    impressions: 87,
    sessions_with_click: 71,
    sessions_with_click_product_0: 89,
    sessions_with_click_product_1: 50,
    sessions_with_click_product_2: 32,
    sessions_with_click_product_3: 13,
  },
  {
    date: "2024-01-25",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 100,
    daily_budget: 15,
    spend: 34,
    impressions: 96,
    sessions_with_click: 138,
    sessions_with_click_product_0: 19,
    sessions_with_click_product_1: 11,
    sessions_with_click_product_2: 29,
    sessions_with_click_product_3: 27,
  },
  {
    date: "2024-01-26",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 92,
    daily_budget: 82,
    spend: 94,
    impressions: 75,
    sessions_with_click: 206,
    sessions_with_click_product_0: 105,
    sessions_with_click_product_1: 38,
    sessions_with_click_product_2: 28,
    sessions_with_click_product_3: 26,
  },
  {
    date: "2024-01-27",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 45,
    daily_budget: 41,
    spend: 98,
    impressions: 95,
    sessions_with_click: 65,
    sessions_with_click_product_0: 44,
    sessions_with_click_product_1: 15,
    sessions_with_click_product_2: 18,
    sessions_with_click_product_3: 37,
  },
  {
    date: "2024-01-28",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 58,
    daily_budget: 33,
    spend: 61,
    impressions: 113,
    sessions_with_click: 176,
    sessions_with_click_product_0: 40,
    sessions_with_click_product_1: 54,
    sessions_with_click_product_2: 21,
    sessions_with_click_product_3: 49,
  },
  {
    date: "2024-01-29",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 83,
    daily_budget: 78,
    spend: 62,
    impressions: 46,
    sessions_with_click: 252,
    sessions_with_click_product_0: 56,
    sessions_with_click_product_1: 16,
    sessions_with_click_product_2: 15,
    sessions_with_click_product_3: 24,
  },
  {
    date: "2024-01-30",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 41,
    daily_budget: 50,
    spend: 28,
    impressions: 100,
    sessions_with_click: 173,
    sessions_with_click_product_0: 15,
    sessions_with_click_product_1: 77,
    sessions_with_click_product_2: 30,
    sessions_with_click_product_3: 26,
  },
  {
    date: "2024-01-31",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 69,
    daily_budget: 104,
    spend: 31,
    impressions: 33,
    sessions_with_click: 256,
    sessions_with_click_product_0: 102,
    sessions_with_click_product_1: 80,
    sessions_with_click_product_2: 39,
    sessions_with_click_product_3: 33,
  },
  {
    date: "2024-02-01",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 50,
    daily_budget: 76,
    spend: 42,
    impressions: 115,
    sessions_with_click: 154,
    sessions_with_click_product_0: 53,
    sessions_with_click_product_1: 15,
    sessions_with_click_product_2: 30,
    sessions_with_click_product_3: 36,
  },
  {
    date: "2024-02-02",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 66,
    daily_budget: 109,
    spend: 23,
    impressions: 110,
    sessions_with_click: 54,
    sessions_with_click_product_0: 61,
    sessions_with_click_product_1: 53,
    sessions_with_click_product_2: 27,
    sessions_with_click_product_3: 28,
  },
  {
    date: "2024-02-03",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 65,
    daily_budget: 66,
    spend: 41,
    impressions: 90,
    sessions_with_click: 244,
    sessions_with_click_product_0: 49,
    sessions_with_click_product_1: 27,
    sessions_with_click_product_2: 10,
    sessions_with_click_product_3: 11,
  },
  {
    date: "2024-02-04",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 89,
    daily_budget: 63,
    spend: 35,
    impressions: 45,
    sessions_with_click: 170,
    sessions_with_click_product_0: 72,
    sessions_with_click_product_1: 53,
    sessions_with_click_product_2: 33,
    sessions_with_click_product_3: 27,
  },
  {
    date: "2024-02-05",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 89,
    daily_budget: 13,
    spend: 32,
    impressions: 65,
    sessions_with_click: 50,
    sessions_with_click_product_0: 96,
    sessions_with_click_product_1: 75,
    sessions_with_click_product_2: 29,
    sessions_with_click_product_3: 38,
  },
  {
    date: "2024-02-06",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 107,
    daily_budget: 62,
    spend: 50,
    impressions: 108,
    sessions_with_click: 168,
    sessions_with_click_product_0: 54,
    sessions_with_click_product_1: 19,
    sessions_with_click_product_2: 15,
    sessions_with_click_product_3: 29,
  },
  {
    date: "2024-01-10",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 34,
    daily_budget: 78,
    spend: 29,
    impressions: 78,
    sessions_with_click: 69,
    sessions_with_click_product_0: 52,
    sessions_with_click_product_1: 88,
    sessions_with_click_product_2: 17,
    sessions_with_click_product_3: 23,
  },
  {
    date: "2024-01-11",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 32,
    daily_budget: 26,
    spend: 67,
    impressions: 61,
    sessions_with_click: 154,
    sessions_with_click_product_0: 78,
    sessions_with_click_product_1: 18,
    sessions_with_click_product_2: 33,
    sessions_with_click_product_3: 25,
  },
  {
    date: "2024-01-12",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 40,
    daily_budget: 99,
    spend: 85,
    impressions: 87,
    sessions_with_click: 144,
    sessions_with_click_product_0: 31,
    sessions_with_click_product_1: 15,
    sessions_with_click_product_2: 22,
    sessions_with_click_product_3: 21,
  },
  {
    date: "2024-01-13",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 106,
    daily_budget: 80,
    spend: 71,
    impressions: 77,
    sessions_with_click: 224,
    sessions_with_click_product_0: 30,
    sessions_with_click_product_1: 10,
    sessions_with_click_product_2: 33,
    sessions_with_click_product_3: 14,
  },
  {
    date: "2024-01-14",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 51,
    daily_budget: 44,
    spend: 40,
    impressions: 20,
    sessions_with_click: 153,
    sessions_with_click_product_0: 65,
    sessions_with_click_product_1: 61,
    sessions_with_click_product_2: 13,
    sessions_with_click_product_3: 24,
  },
  {
    date: "2024-01-15",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 93,
    daily_budget: 99,
    spend: 54,
    impressions: 63,
    sessions_with_click: 27,
    sessions_with_click_product_0: 52,
    sessions_with_click_product_1: 70,
    sessions_with_click_product_2: 29,
    sessions_with_click_product_3: 46,
  },
  {
    date: "2024-01-16",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 62,
    daily_budget: 100,
    spend: 77,
    impressions: 47,
    sessions_with_click: 146,
    sessions_with_click_product_0: 50,
    sessions_with_click_product_1: 81,
    sessions_with_click_product_2: 37,
    sessions_with_click_product_3: 37,
  },
  {
    date: "2024-01-17",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 29,
    daily_budget: 23,
    spend: 16,
    impressions: 74,
    sessions_with_click: 211,
    sessions_with_click_product_0: 11,
    sessions_with_click_product_1: 23,
    sessions_with_click_product_2: 32,
    sessions_with_click_product_3: 35,
  },
  {
    date: "2024-01-18",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 92,
    daily_budget: 101,
    spend: 101,
    impressions: 70,
    sessions_with_click: 99,
    sessions_with_click_product_0: 20,
    sessions_with_click_product_1: 61,
    sessions_with_click_product_2: 31,
    sessions_with_click_product_3: 10,
  },
  {
    date: "2024-01-19",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 82,
    daily_budget: 65,
    spend: 10,
    impressions: 94,
    sessions_with_click: 212,
    sessions_with_click_product_0: 59,
    sessions_with_click_product_1: 57,
    sessions_with_click_product_2: 11,
    sessions_with_click_product_3: 46,
  },
  {
    date: "2024-01-20",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 59,
    daily_budget: 38,
    spend: 30,
    impressions: 103,
    sessions_with_click: 218,
    sessions_with_click_product_0: 47,
    sessions_with_click_product_1: 13,
    sessions_with_click_product_2: 15,
    sessions_with_click_product_3: 43,
  },
  {
    date: "2024-01-21",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 69,
    daily_budget: 41,
    spend: 59,
    impressions: 31,
    sessions_with_click: 136,
    sessions_with_click_product_0: 47,
    sessions_with_click_product_1: 43,
    sessions_with_click_product_2: 23,
    sessions_with_click_product_3: 32,
  },
  {
    date: "2024-01-22",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 94,
    daily_budget: 95,
    spend: 14,
    impressions: 116,
    sessions_with_click: 179,
    sessions_with_click_product_0: 28,
    sessions_with_click_product_1: 15,
    sessions_with_click_product_2: 32,
    sessions_with_click_product_3: 32,
  },
  {
    date: "2024-01-23",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 21,
    daily_budget: 89,
    spend: 11,
    impressions: 108,
    sessions_with_click: 94,
    sessions_with_click_product_0: 60,
    sessions_with_click_product_1: 88,
    sessions_with_click_product_2: 33,
    sessions_with_click_product_3: 33,
  },
  {
    date: "2024-01-24",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 29,
    daily_budget: 99,
    spend: 33,
    impressions: 34,
    sessions_with_click: 62,
    sessions_with_click_product_0: 108,
    sessions_with_click_product_1: 40,
    sessions_with_click_product_2: 11,
    sessions_with_click_product_3: 41,
  },
  {
    date: "2024-01-25",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 70,
    daily_budget: 80,
    spend: 30,
    impressions: 82,
    sessions_with_click: 109,
    sessions_with_click_product_0: 66,
    sessions_with_click_product_1: 76,
    sessions_with_click_product_2: 32,
    sessions_with_click_product_3: 29,
  },
  {
    date: "2024-01-26",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 67,
    daily_budget: 102,
    spend: 86,
    impressions: 40,
    sessions_with_click: 146,
    sessions_with_click_product_0: 98,
    sessions_with_click_product_1: 40,
    sessions_with_click_product_2: 25,
    sessions_with_click_product_3: 49,
  },
  {
    date: "2024-01-27",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 43,
    daily_budget: 63,
    spend: 56,
    impressions: 34,
    sessions_with_click: 218,
    sessions_with_click_product_0: 88,
    sessions_with_click_product_1: 15,
    sessions_with_click_product_2: 32,
    sessions_with_click_product_3: 31,
  },
  {
    date: "2024-01-28",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 68,
    daily_budget: 29,
    spend: 96,
    impressions: 90,
    sessions_with_click: 82,
    sessions_with_click_product_0: 66,
    sessions_with_click_product_1: 53,
    sessions_with_click_product_2: 20,
    sessions_with_click_product_3: 42,
  },
  {
    date: "2024-01-29",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 77,
    daily_budget: 71,
    spend: 79,
    impressions: 39,
    sessions_with_click: 216,
    sessions_with_click_product_0: 43,
    sessions_with_click_product_1: 33,
    sessions_with_click_product_2: 18,
    sessions_with_click_product_3: 20,
  },
  {
    date: "2024-01-30",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 99,
    daily_budget: 51,
    spend: 81,
    impressions: 40,
    sessions_with_click: 256,
    sessions_with_click_product_0: 67,
    sessions_with_click_product_1: 70,
    sessions_with_click_product_2: 19,
    sessions_with_click_product_3: 14,
  },
  {
    date: "2024-01-31",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 106,
    daily_budget: 46,
    spend: 30,
    impressions: 97,
    sessions_with_click: 159,
    sessions_with_click_product_0: 48,
    sessions_with_click_product_1: 58,
    sessions_with_click_product_2: 20,
    sessions_with_click_product_3: 42,
  },
  {
    date: "2024-02-01",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 91,
    daily_budget: 71,
    spend: 72,
    impressions: 33,
    sessions_with_click: 154,
    sessions_with_click_product_0: 17,
    sessions_with_click_product_1: 73,
    sessions_with_click_product_2: 25,
    sessions_with_click_product_3: 29,
  },
  {
    date: "2024-02-02",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 97,
    daily_budget: 72,
    spend: 72,
    impressions: 95,
    sessions_with_click: 121,
    sessions_with_click_product_0: 74,
    sessions_with_click_product_1: 29,
    sessions_with_click_product_2: 23,
    sessions_with_click_product_3: 49,
  },
  {
    date: "2024-02-03",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 50,
    daily_budget: 95,
    spend: 30,
    impressions: 90,
    sessions_with_click: 94,
    sessions_with_click_product_0: 76,
    sessions_with_click_product_1: 48,
    sessions_with_click_product_2: 31,
    sessions_with_click_product_3: 23,
  },
  {
    date: "2024-02-04",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 14,
    daily_budget: 68,
    spend: 18,
    impressions: 24,
    sessions_with_click: 246,
    sessions_with_click_product_0: 75,
    sessions_with_click_product_1: 47,
    sessions_with_click_product_2: 28,
    sessions_with_click_product_3: 43,
  },
  {
    date: "2024-02-05",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 37,
    daily_budget: 90,
    spend: 78,
    impressions: 42,
    sessions_with_click: 101,
    sessions_with_click_product_0: 75,
    sessions_with_click_product_1: 67,
    sessions_with_click_product_2: 30,
    sessions_with_click_product_3: 34,
  },
  {
    date: "2024-02-06",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 24,
    daily_budget: 89,
    spend: 87,
    impressions: 87,
    sessions_with_click: 120,
    sessions_with_click_product_0: 93,
    sessions_with_click_product_1: 70,
    sessions_with_click_product_2: 21,
    sessions_with_click_product_3: 18,
  },
  {
    date: "2024-01-10",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 106,
    daily_budget: 72,
    spend: 69,
    impressions: 67,
    sessions_with_click: 233,
    sessions_with_click_product_0: 73,
    sessions_with_click_product_1: 76,
    sessions_with_click_product_2: 20,
    sessions_with_click_product_3: 15,
  },
  {
    date: "2024-01-11",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 22,
    daily_budget: 22,
    spend: 42,
    impressions: 72,
    sessions_with_click: 111,
    sessions_with_click_product_0: 30,
    sessions_with_click_product_1: 84,
    sessions_with_click_product_2: 36,
    sessions_with_click_product_3: 37,
  },
  {
    date: "2024-01-12",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 59,
    daily_budget: 28,
    spend: 95,
    impressions: 111,
    sessions_with_click: 201,
    sessions_with_click_product_0: 60,
    sessions_with_click_product_1: 89,
    sessions_with_click_product_2: 33,
    sessions_with_click_product_3: 40,
  },
  {
    date: "2024-01-13",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 33,
    daily_budget: 51,
    spend: 109,
    impressions: 87,
    sessions_with_click: 216,
    sessions_with_click_product_0: 42,
    sessions_with_click_product_1: 30,
    sessions_with_click_product_2: 12,
    sessions_with_click_product_3: 25,
  },
  {
    date: "2024-01-14",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 55,
    daily_budget: 97,
    spend: 106,
    impressions: 80,
    sessions_with_click: 35,
    sessions_with_click_product_0: 25,
    sessions_with_click_product_1: 88,
    sessions_with_click_product_2: 11,
    sessions_with_click_product_3: 49,
  },
  {
    date: "2024-01-15",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 92,
    daily_budget: 21,
    spend: 12,
    impressions: 64,
    sessions_with_click: 201,
    sessions_with_click_product_0: 91,
    sessions_with_click_product_1: 54,
    sessions_with_click_product_2: 24,
    sessions_with_click_product_3: 28,
  },
  {
    date: "2024-01-16",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 27,
    daily_budget: 83,
    spend: 18,
    impressions: 110,
    sessions_with_click: 39,
    sessions_with_click_product_0: 67,
    sessions_with_click_product_1: 45,
    sessions_with_click_product_2: 23,
    sessions_with_click_product_3: 19,
  },
  {
    date: "2024-01-17",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 13,
    daily_budget: 81,
    spend: 45,
    impressions: 108,
    sessions_with_click: 104,
    sessions_with_click_product_0: 101,
    sessions_with_click_product_1: 58,
    sessions_with_click_product_2: 24,
    sessions_with_click_product_3: 21,
  },
  {
    date: "2024-01-18",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 68,
    daily_budget: 40,
    spend: 82,
    impressions: 105,
    sessions_with_click: 186,
    sessions_with_click_product_0: 82,
    sessions_with_click_product_1: 53,
    sessions_with_click_product_2: 36,
    sessions_with_click_product_3: 29,
  },
  {
    date: "2024-01-19",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 93,
    daily_budget: 91,
    spend: 58,
    impressions: 85,
    sessions_with_click: 71,
    sessions_with_click_product_0: 99,
    sessions_with_click_product_1: 44,
    sessions_with_click_product_2: 12,
    sessions_with_click_product_3: 12,
  },
  {
    date: "2024-01-20",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 10,
    daily_budget: 48,
    spend: 30,
    impressions: 113,
    sessions_with_click: 162,
    sessions_with_click_product_0: 69,
    sessions_with_click_product_1: 30,
    sessions_with_click_product_2: 19,
    sessions_with_click_product_3: 41,
  },
  {
    date: "2024-01-21",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 67,
    daily_budget: 108,
    spend: 50,
    impressions: 22,
    sessions_with_click: 70,
    sessions_with_click_product_0: 93,
    sessions_with_click_product_1: 13,
    sessions_with_click_product_2: 23,
    sessions_with_click_product_3: 47,
  },
  {
    date: "2024-01-22",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 57,
    daily_budget: 13,
    spend: 68,
    impressions: 57,
    sessions_with_click: 107,
    sessions_with_click_product_0: 94,
    sessions_with_click_product_1: 59,
    sessions_with_click_product_2: 29,
    sessions_with_click_product_3: 11,
  },
  {
    date: "2024-01-23",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 102,
    daily_budget: 77,
    spend: 69,
    impressions: 85,
    sessions_with_click: 142,
    sessions_with_click_product_0: 34,
    sessions_with_click_product_1: 55,
    sessions_with_click_product_2: 14,
    sessions_with_click_product_3: 49,
  },
  {
    date: "2024-01-24",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 102,
    daily_budget: 55,
    spend: 42,
    impressions: 81,
    sessions_with_click: 198,
    sessions_with_click_product_0: 94,
    sessions_with_click_product_1: 56,
    sessions_with_click_product_2: 19,
    sessions_with_click_product_3: 16,
  },
  {
    date: "2024-01-25",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 108,
    daily_budget: 18,
    spend: 28,
    impressions: 38,
    sessions_with_click: 234,
    sessions_with_click_product_0: 76,
    sessions_with_click_product_1: 24,
    sessions_with_click_product_2: 23,
    sessions_with_click_product_3: 20,
  },
  {
    date: "2024-01-26",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 102,
    daily_budget: 106,
    spend: 99,
    impressions: 97,
    sessions_with_click: 128,
    sessions_with_click_product_0: 93,
    sessions_with_click_product_1: 21,
    sessions_with_click_product_2: 12,
    sessions_with_click_product_3: 48,
  },
  {
    date: "2024-01-27",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 68,
    daily_budget: 105,
    spend: 77,
    impressions: 106,
    sessions_with_click: 162,
    sessions_with_click_product_0: 91,
    sessions_with_click_product_1: 74,
    sessions_with_click_product_2: 33,
    sessions_with_click_product_3: 20,
  },
  {
    date: "2024-01-28",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 19,
    daily_budget: 72,
    spend: 48,
    impressions: 26,
    sessions_with_click: 165,
    sessions_with_click_product_0: 87,
    sessions_with_click_product_1: 37,
    sessions_with_click_product_2: 34,
    sessions_with_click_product_3: 47,
  },
  {
    date: "2024-01-29",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 78,
    daily_budget: 26,
    spend: 33,
    impressions: 43,
    sessions_with_click: 235,
    sessions_with_click_product_0: 31,
    sessions_with_click_product_1: 67,
    sessions_with_click_product_2: 27,
    sessions_with_click_product_3: 36,
  },
  {
    date: "2024-01-30",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 88,
    daily_budget: 58,
    spend: 52,
    impressions: 83,
    sessions_with_click: 86,
    sessions_with_click_product_0: 68,
    sessions_with_click_product_1: 69,
    sessions_with_click_product_2: 23,
    sessions_with_click_product_3: 13,
  },
  {
    date: "2024-01-31",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 46,
    daily_budget: 64,
    spend: 40,
    impressions: 43,
    sessions_with_click: 259,
    sessions_with_click_product_0: 58,
    sessions_with_click_product_1: 72,
    sessions_with_click_product_2: 26,
    sessions_with_click_product_3: 39,
  },
  {
    date: "2024-02-01",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 94,
    daily_budget: 98,
    spend: 48,
    impressions: 100,
    sessions_with_click: 46,
    sessions_with_click_product_0: 40,
    sessions_with_click_product_1: 50,
    sessions_with_click_product_2: 35,
    sessions_with_click_product_3: 39,
  },
  {
    date: "2024-02-02",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 25,
    daily_budget: 103,
    spend: 103,
    impressions: 78,
    sessions_with_click: 73,
    sessions_with_click_product_0: 55,
    sessions_with_click_product_1: 72,
    sessions_with_click_product_2: 25,
    sessions_with_click_product_3: 23,
  },
  {
    date: "2024-02-03",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 84,
    daily_budget: 12,
    spend: 13,
    impressions: 113,
    sessions_with_click: 87,
    sessions_with_click_product_0: 49,
    sessions_with_click_product_1: 80,
    sessions_with_click_product_2: 12,
    sessions_with_click_product_3: 14,
  },
  {
    date: "2024-02-04",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 16,
    daily_budget: 27,
    spend: 54,
    impressions: 40,
    sessions_with_click: 75,
    sessions_with_click_product_0: 26,
    sessions_with_click_product_1: 77,
    sessions_with_click_product_2: 28,
    sessions_with_click_product_3: 11,
  },
  {
    date: "2024-02-05",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 98,
    daily_budget: 49,
    spend: 48,
    impressions: 109,
    sessions_with_click: 106,
    sessions_with_click_product_0: 60,
    sessions_with_click_product_1: 71,
    sessions_with_click_product_2: 26,
    sessions_with_click_product_3: 11,
  },
  {
    date: "2024-02-06",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 106,
    daily_budget: 54,
    spend: 47,
    impressions: 51,
    sessions_with_click: 27,
    sessions_with_click_product_0: 97,
    sessions_with_click_product_1: 71,
    sessions_with_click_product_2: 10,
    sessions_with_click_product_3: 10,
  },
  {
    date: "2024-01-10",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 57,
    daily_budget: 74,
    spend: 106,
    impressions: 79,
    sessions_with_click: 252,
    sessions_with_click_product_0: 55,
    sessions_with_click_product_1: 66,
    sessions_with_click_product_2: 33,
    sessions_with_click_product_3: 30,
  },
  {
    date: "2024-01-11",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 72,
    daily_budget: 13,
    spend: 29,
    impressions: 58,
    sessions_with_click: 32,
    sessions_with_click_product_0: 76,
    sessions_with_click_product_1: 18,
    sessions_with_click_product_2: 26,
    sessions_with_click_product_3: 15,
  },
  {
    date: "2024-01-12",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 15,
    daily_budget: 23,
    spend: 62,
    impressions: 53,
    sessions_with_click: 53,
    sessions_with_click_product_0: 19,
    sessions_with_click_product_1: 43,
    sessions_with_click_product_2: 11,
    sessions_with_click_product_3: 24,
  },
  {
    date: "2024-01-13",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 104,
    daily_budget: 43,
    spend: 103,
    impressions: 31,
    sessions_with_click: 104,
    sessions_with_click_product_0: 82,
    sessions_with_click_product_1: 54,
    sessions_with_click_product_2: 10,
    sessions_with_click_product_3: 17,
  },
  {
    date: "2024-01-14",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 108,
    daily_budget: 91,
    spend: 53,
    impressions: 84,
    sessions_with_click: 250,
    sessions_with_click_product_0: 20,
    sessions_with_click_product_1: 65,
    sessions_with_click_product_2: 28,
    sessions_with_click_product_3: 34,
  },
  {
    date: "2024-01-15",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 22,
    daily_budget: 68,
    spend: 56,
    impressions: 92,
    sessions_with_click: 47,
    sessions_with_click_product_0: 82,
    sessions_with_click_product_1: 50,
    sessions_with_click_product_2: 16,
    sessions_with_click_product_3: 19,
  },
  {
    date: "2024-01-16",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 23,
    daily_budget: 69,
    spend: 88,
    impressions: 52,
    sessions_with_click: 131,
    sessions_with_click_product_0: 71,
    sessions_with_click_product_1: 60,
    sessions_with_click_product_2: 39,
    sessions_with_click_product_3: 26,
  },
  {
    date: "2024-01-17",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 10,
    daily_budget: 57,
    spend: 71,
    impressions: 92,
    sessions_with_click: 17,
    sessions_with_click_product_0: 74,
    sessions_with_click_product_1: 85,
    sessions_with_click_product_2: 31,
    sessions_with_click_product_3: 34,
  },
  {
    date: "2024-01-18",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 52,
    daily_budget: 98,
    spend: 98,
    impressions: 73,
    sessions_with_click: 165,
    sessions_with_click_product_0: 16,
    sessions_with_click_product_1: 25,
    sessions_with_click_product_2: 30,
    sessions_with_click_product_3: 38,
  },
  {
    date: "2024-01-19",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 69,
    daily_budget: 22,
    spend: 13,
    impressions: 62,
    sessions_with_click: 119,
    sessions_with_click_product_0: 10,
    sessions_with_click_product_1: 62,
    sessions_with_click_product_2: 22,
    sessions_with_click_product_3: 15,
  },
  {
    date: "2024-01-20",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 98,
    daily_budget: 43,
    spend: 59,
    impressions: 39,
    sessions_with_click: 182,
    sessions_with_click_product_0: 45,
    sessions_with_click_product_1: 45,
    sessions_with_click_product_2: 24,
    sessions_with_click_product_3: 16,
  },
  {
    date: "2024-01-21",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 53,
    daily_budget: 78,
    spend: 55,
    impressions: 27,
    sessions_with_click: 64,
    sessions_with_click_product_0: 84,
    sessions_with_click_product_1: 43,
    sessions_with_click_product_2: 32,
    sessions_with_click_product_3: 15,
  },
  {
    date: "2024-01-22",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 100,
    daily_budget: 29,
    spend: 105,
    impressions: 71,
    sessions_with_click: 185,
    sessions_with_click_product_0: 21,
    sessions_with_click_product_1: 41,
    sessions_with_click_product_2: 34,
    sessions_with_click_product_3: 37,
  },
  {
    date: "2024-01-23",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 13,
    daily_budget: 67,
    spend: 27,
    impressions: 45,
    sessions_with_click: 247,
    sessions_with_click_product_0: 100,
    sessions_with_click_product_1: 21,
    sessions_with_click_product_2: 16,
    sessions_with_click_product_3: 49,
  },
  {
    date: "2024-01-24",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 60,
    daily_budget: 102,
    spend: 56,
    impressions: 91,
    sessions_with_click: 246,
    sessions_with_click_product_0: 10,
    sessions_with_click_product_1: 16,
    sessions_with_click_product_2: 30,
    sessions_with_click_product_3: 39,
  },
  {
    date: "2024-01-25",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 16,
    daily_budget: 17,
    spend: 108,
    impressions: 44,
    sessions_with_click: 65,
    sessions_with_click_product_0: 30,
    sessions_with_click_product_1: 86,
    sessions_with_click_product_2: 25,
    sessions_with_click_product_3: 13,
  },
  {
    date: "2024-01-26",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 18,
    daily_budget: 94,
    spend: 62,
    impressions: 66,
    sessions_with_click: 245,
    sessions_with_click_product_0: 105,
    sessions_with_click_product_1: 28,
    sessions_with_click_product_2: 29,
    sessions_with_click_product_3: 37,
  },
  {
    date: "2024-01-27",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 54,
    daily_budget: 23,
    spend: 16,
    impressions: 61,
    sessions_with_click: 138,
    sessions_with_click_product_0: 24,
    sessions_with_click_product_1: 27,
    sessions_with_click_product_2: 27,
    sessions_with_click_product_3: 20,
  },
  {
    date: "2024-01-28",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 48,
    daily_budget: 87,
    spend: 82,
    impressions: 74,
    sessions_with_click: 232,
    sessions_with_click_product_0: 107,
    sessions_with_click_product_1: 47,
    sessions_with_click_product_2: 35,
    sessions_with_click_product_3: 27,
  },
  {
    date: "2024-01-29",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 90,
    daily_budget: 65,
    spend: 23,
    impressions: 74,
    sessions_with_click: 241,
    sessions_with_click_product_0: 98,
    sessions_with_click_product_1: 71,
    sessions_with_click_product_2: 33,
    sessions_with_click_product_3: 31,
  },
  {
    date: "2024-01-30",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 18,
    daily_budget: 11,
    spend: 13,
    impressions: 38,
    sessions_with_click: 25,
    sessions_with_click_product_0: 47,
    sessions_with_click_product_1: 29,
    sessions_with_click_product_2: 19,
    sessions_with_click_product_3: 41,
  },
  {
    date: "2024-01-31",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 66,
    daily_budget: 16,
    spend: 11,
    impressions: 35,
    sessions_with_click: 215,
    sessions_with_click_product_0: 34,
    sessions_with_click_product_1: 64,
    sessions_with_click_product_2: 37,
    sessions_with_click_product_3: 34,
  },
  {
    date: "2024-02-01",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 63,
    daily_budget: 85,
    spend: 33,
    impressions: 60,
    sessions_with_click: 63,
    sessions_with_click_product_0: 29,
    sessions_with_click_product_1: 54,
    sessions_with_click_product_2: 39,
    sessions_with_click_product_3: 14,
  },
  {
    date: "2024-02-02",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 26,
    daily_budget: 43,
    spend: 61,
    impressions: 58,
    sessions_with_click: 84,
    sessions_with_click_product_0: 104,
    sessions_with_click_product_1: 50,
    sessions_with_click_product_2: 22,
    sessions_with_click_product_3: 10,
  },
  {
    date: "2024-02-03",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 51,
    daily_budget: 86,
    spend: 87,
    impressions: 27,
    sessions_with_click: 255,
    sessions_with_click_product_0: 104,
    sessions_with_click_product_1: 51,
    sessions_with_click_product_2: 29,
    sessions_with_click_product_3: 28,
  },
  {
    date: "2024-02-04",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 95,
    daily_budget: 22,
    spend: 13,
    impressions: 96,
    sessions_with_click: 189,
    sessions_with_click_product_0: 80,
    sessions_with_click_product_1: 43,
    sessions_with_click_product_2: 23,
    sessions_with_click_product_3: 37,
  },
  {
    date: "2024-02-05",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 66,
    daily_budget: 92,
    spend: 87,
    impressions: 78,
    sessions_with_click: 225,
    sessions_with_click_product_0: 28,
    sessions_with_click_product_1: 55,
    sessions_with_click_product_2: 25,
    sessions_with_click_product_3: 42,
  },
  {
    date: "2024-02-06",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 76,
    daily_budget: 74,
    spend: 41,
    impressions: 80,
    sessions_with_click: 34,
    sessions_with_click_product_0: 52,
    sessions_with_click_product_1: 42,
    sessions_with_click_product_2: 14,
    sessions_with_click_product_3: 24,
  },
];

export const fiveWeeksData = [
  {
    date: "2024-01-03",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 65,
    daily_budget: 50,
    spend: 12,
    impressions: 75,
    sessions_with_click: 207,
    sessions_with_click_product_0: 50,
    sessions_with_click_product_1: 72,
    sessions_with_click_product_2: 12,
    sessions_with_click_product_3: 22,
  },
  {
    date: "2024-01-04",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 14,
    daily_budget: 49,
    spend: 24,
    impressions: 27,
    sessions_with_click: 93,
    sessions_with_click_product_0: 92,
    sessions_with_click_product_1: 85,
    sessions_with_click_product_2: 23,
    sessions_with_click_product_3: 39,
  },
  {
    date: "2024-01-05",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 48,
    daily_budget: 38,
    spend: 10,
    impressions: 33,
    sessions_with_click: 257,
    sessions_with_click_product_0: 41,
    sessions_with_click_product_1: 10,
    sessions_with_click_product_2: 35,
    sessions_with_click_product_3: 13,
  },
  {
    date: "2024-01-06",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 13,
    daily_budget: 70,
    spend: 21,
    impressions: 22,
    sessions_with_click: 94,
    sessions_with_click_product_0: 66,
    sessions_with_click_product_1: 24,
    sessions_with_click_product_2: 11,
    sessions_with_click_product_3: 17,
  },
  {
    date: "2024-01-07",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 42,
    daily_budget: 80,
    spend: 25,
    impressions: 24,
    sessions_with_click: 253,
    sessions_with_click_product_0: 19,
    sessions_with_click_product_1: 17,
    sessions_with_click_product_2: 31,
    sessions_with_click_product_3: 36,
  },
  {
    date: "2024-01-08",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 80,
    daily_budget: 63,
    spend: 37,
    impressions: 49,
    sessions_with_click: 153,
    sessions_with_click_product_0: 94,
    sessions_with_click_product_1: 62,
    sessions_with_click_product_2: 25,
    sessions_with_click_product_3: 23,
  },
  {
    date: "2024-01-09",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 12,
    daily_budget: 67,
    spend: 11,
    impressions: 41,
    sessions_with_click: 250,
    sessions_with_click_product_0: 92,
    sessions_with_click_product_1: 10,
    sessions_with_click_product_2: 28,
    sessions_with_click_product_3: 16,
  },
  {
    date: "2024-01-10",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 42,
    daily_budget: 87,
    spend: 96,
    impressions: 102,
    sessions_with_click: 19,
    sessions_with_click_product_0: 87,
    sessions_with_click_product_1: 65,
    sessions_with_click_product_2: 28,
    sessions_with_click_product_3: 23,
  },
  {
    date: "2024-01-11",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 21,
    daily_budget: 86,
    spend: 28,
    impressions: 88,
    sessions_with_click: 67,
    sessions_with_click_product_0: 109,
    sessions_with_click_product_1: 30,
    sessions_with_click_product_2: 27,
    sessions_with_click_product_3: 13,
  },
  {
    date: "2024-01-12",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 75,
    daily_budget: 29,
    spend: 104,
    impressions: 118,
    sessions_with_click: 179,
    sessions_with_click_product_0: 23,
    sessions_with_click_product_1: 69,
    sessions_with_click_product_2: 25,
    sessions_with_click_product_3: 28,
  },
  {
    date: "2024-01-13",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 65,
    daily_budget: 87,
    spend: 67,
    impressions: 90,
    sessions_with_click: 69,
    sessions_with_click_product_0: 61,
    sessions_with_click_product_1: 56,
    sessions_with_click_product_2: 11,
    sessions_with_click_product_3: 28,
  },
  {
    date: "2024-01-14",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 104,
    daily_budget: 101,
    spend: 106,
    impressions: 79,
    sessions_with_click: 26,
    sessions_with_click_product_0: 76,
    sessions_with_click_product_1: 64,
    sessions_with_click_product_2: 10,
    sessions_with_click_product_3: 11,
  },
  {
    date: "2024-01-15",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 55,
    daily_budget: 12,
    spend: 101,
    impressions: 57,
    sessions_with_click: 18,
    sessions_with_click_product_0: 21,
    sessions_with_click_product_1: 81,
    sessions_with_click_product_2: 31,
    sessions_with_click_product_3: 36,
  },
  {
    date: "2024-01-16",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 22,
    daily_budget: 103,
    spend: 71,
    impressions: 53,
    sessions_with_click: 51,
    sessions_with_click_product_0: 11,
    sessions_with_click_product_1: 57,
    sessions_with_click_product_2: 19,
    sessions_with_click_product_3: 14,
  },
  {
    date: "2024-01-17",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 54,
    daily_budget: 33,
    spend: 101,
    impressions: 99,
    sessions_with_click: 200,
    sessions_with_click_product_0: 23,
    sessions_with_click_product_1: 25,
    sessions_with_click_product_2: 12,
    sessions_with_click_product_3: 22,
  },
  {
    date: "2024-01-18",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 102,
    daily_budget: 35,
    spend: 107,
    impressions: 51,
    sessions_with_click: 146,
    sessions_with_click_product_0: 72,
    sessions_with_click_product_1: 48,
    sessions_with_click_product_2: 17,
    sessions_with_click_product_3: 30,
  },
  {
    date: "2024-01-19",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 77,
    daily_budget: 79,
    spend: 41,
    impressions: 78,
    sessions_with_click: 107,
    sessions_with_click_product_0: 17,
    sessions_with_click_product_1: 52,
    sessions_with_click_product_2: 15,
    sessions_with_click_product_3: 44,
  },
  {
    date: "2024-01-20",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 53,
    daily_budget: 83,
    spend: 33,
    impressions: 119,
    sessions_with_click: 65,
    sessions_with_click_product_0: 38,
    sessions_with_click_product_1: 16,
    sessions_with_click_product_2: 36,
    sessions_with_click_product_3: 27,
  },
  {
    date: "2024-01-21",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 23,
    daily_budget: 50,
    spend: 41,
    impressions: 78,
    sessions_with_click: 67,
    sessions_with_click_product_0: 107,
    sessions_with_click_product_1: 36,
    sessions_with_click_product_2: 34,
    sessions_with_click_product_3: 31,
  },
  {
    date: "2024-01-22",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 39,
    daily_budget: 70,
    spend: 106,
    impressions: 79,
    sessions_with_click: 128,
    sessions_with_click_product_0: 45,
    sessions_with_click_product_1: 82,
    sessions_with_click_product_2: 24,
    sessions_with_click_product_3: 35,
  },
  {
    date: "2024-01-23",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 37,
    daily_budget: 95,
    spend: 79,
    impressions: 80,
    sessions_with_click: 215,
    sessions_with_click_product_0: 91,
    sessions_with_click_product_1: 46,
    sessions_with_click_product_2: 29,
    sessions_with_click_product_3: 19,
  },
  {
    date: "2024-01-24",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 11,
    daily_budget: 83,
    spend: 84,
    impressions: 80,
    sessions_with_click: 197,
    sessions_with_click_product_0: 61,
    sessions_with_click_product_1: 36,
    sessions_with_click_product_2: 14,
    sessions_with_click_product_3: 45,
  },
  {
    date: "2024-01-25",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 70,
    daily_budget: 98,
    spend: 33,
    impressions: 114,
    sessions_with_click: 247,
    sessions_with_click_product_0: 43,
    sessions_with_click_product_1: 25,
    sessions_with_click_product_2: 24,
    sessions_with_click_product_3: 12,
  },
  {
    date: "2024-01-26",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 80,
    daily_budget: 23,
    spend: 30,
    impressions: 60,
    sessions_with_click: 82,
    sessions_with_click_product_0: 84,
    sessions_with_click_product_1: 82,
    sessions_with_click_product_2: 32,
    sessions_with_click_product_3: 36,
  },
  {
    date: "2024-01-27",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 94,
    daily_budget: 61,
    spend: 62,
    impressions: 66,
    sessions_with_click: 14,
    sessions_with_click_product_0: 46,
    sessions_with_click_product_1: 69,
    sessions_with_click_product_2: 39,
    sessions_with_click_product_3: 27,
  },
  {
    date: "2024-01-28",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 89,
    daily_budget: 64,
    spend: 75,
    impressions: 67,
    sessions_with_click: 45,
    sessions_with_click_product_0: 67,
    sessions_with_click_product_1: 45,
    sessions_with_click_product_2: 31,
    sessions_with_click_product_3: 32,
  },
  {
    date: "2024-01-29",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 40,
    daily_budget: 96,
    spend: 87,
    impressions: 58,
    sessions_with_click: 46,
    sessions_with_click_product_0: 65,
    sessions_with_click_product_1: 23,
    sessions_with_click_product_2: 38,
    sessions_with_click_product_3: 39,
  },
  {
    date: "2024-01-30",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 80,
    daily_budget: 104,
    spend: 102,
    impressions: 22,
    sessions_with_click: 167,
    sessions_with_click_product_0: 77,
    sessions_with_click_product_1: 49,
    sessions_with_click_product_2: 15,
    sessions_with_click_product_3: 41,
  },
  {
    date: "2024-01-31",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 45,
    daily_budget: 16,
    spend: 84,
    impressions: 34,
    sessions_with_click: 118,
    sessions_with_click_product_0: 97,
    sessions_with_click_product_1: 13,
    sessions_with_click_product_2: 18,
    sessions_with_click_product_3: 27,
  },
  {
    date: "2024-02-01",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 73,
    daily_budget: 29,
    spend: 45,
    impressions: 23,
    sessions_with_click: 244,
    sessions_with_click_product_0: 50,
    sessions_with_click_product_1: 88,
    sessions_with_click_product_2: 34,
    sessions_with_click_product_3: 28,
  },
  {
    date: "2024-02-02",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 46,
    daily_budget: 51,
    spend: 91,
    impressions: 64,
    sessions_with_click: 220,
    sessions_with_click_product_0: 28,
    sessions_with_click_product_1: 77,
    sessions_with_click_product_2: 20,
    sessions_with_click_product_3: 30,
  },
  {
    date: "2024-02-03",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 82,
    daily_budget: 63,
    spend: 80,
    impressions: 111,
    sessions_with_click: 116,
    sessions_with_click_product_0: 56,
    sessions_with_click_product_1: 70,
    sessions_with_click_product_2: 37,
    sessions_with_click_product_3: 18,
  },
  {
    date: "2024-02-04",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 12,
    daily_budget: 32,
    spend: 26,
    impressions: 117,
    sessions_with_click: 222,
    sessions_with_click_product_0: 42,
    sessions_with_click_product_1: 76,
    sessions_with_click_product_2: 36,
    sessions_with_click_product_3: 16,
  },
  {
    date: "2024-02-05",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 53,
    daily_budget: 63,
    spend: 98,
    impressions: 67,
    sessions_with_click: 97,
    sessions_with_click_product_0: 15,
    sessions_with_click_product_1: 32,
    sessions_with_click_product_2: 33,
    sessions_with_click_product_3: 45,
  },
  {
    date: "2024-02-06",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 36,
    daily_budget: 96,
    spend: 88,
    impressions: 110,
    sessions_with_click: 204,
    sessions_with_click_product_0: 81,
    sessions_with_click_product_1: 70,
    sessions_with_click_product_2: 12,
    sessions_with_click_product_3: 33,
  },
  {
    date: "2024-01-03",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 22,
    daily_budget: 78,
    spend: 95,
    impressions: 96,
    sessions_with_click: 45,
    sessions_with_click_product_0: 24,
    sessions_with_click_product_1: 32,
    sessions_with_click_product_2: 24,
    sessions_with_click_product_3: 22,
  },
  {
    date: "2024-01-04",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 96,
    daily_budget: 47,
    spend: 69,
    impressions: 32,
    sessions_with_click: 95,
    sessions_with_click_product_0: 89,
    sessions_with_click_product_1: 89,
    sessions_with_click_product_2: 14,
    sessions_with_click_product_3: 40,
  },
  {
    date: "2024-01-05",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 86,
    daily_budget: 94,
    spend: 61,
    impressions: 63,
    sessions_with_click: 140,
    sessions_with_click_product_0: 59,
    sessions_with_click_product_1: 14,
    sessions_with_click_product_2: 29,
    sessions_with_click_product_3: 11,
  },
  {
    date: "2024-01-06",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 27,
    daily_budget: 60,
    spend: 63,
    impressions: 112,
    sessions_with_click: 89,
    sessions_with_click_product_0: 85,
    sessions_with_click_product_1: 54,
    sessions_with_click_product_2: 30,
    sessions_with_click_product_3: 33,
  },
  {
    date: "2024-01-07",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 58,
    daily_budget: 80,
    spend: 30,
    impressions: 64,
    sessions_with_click: 49,
    sessions_with_click_product_0: 70,
    sessions_with_click_product_1: 59,
    sessions_with_click_product_2: 31,
    sessions_with_click_product_3: 11,
  },
  {
    date: "2024-01-08",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 35,
    daily_budget: 38,
    spend: 37,
    impressions: 53,
    sessions_with_click: 30,
    sessions_with_click_product_0: 34,
    sessions_with_click_product_1: 27,
    sessions_with_click_product_2: 13,
    sessions_with_click_product_3: 47,
  },
  {
    date: "2024-01-09",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 49,
    daily_budget: 73,
    spend: 17,
    impressions: 96,
    sessions_with_click: 40,
    sessions_with_click_product_0: 12,
    sessions_with_click_product_1: 58,
    sessions_with_click_product_2: 29,
    sessions_with_click_product_3: 26,
  },
  {
    date: "2024-01-10",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 92,
    daily_budget: 90,
    spend: 54,
    impressions: 51,
    sessions_with_click: 151,
    sessions_with_click_product_0: 65,
    sessions_with_click_product_1: 53,
    sessions_with_click_product_2: 25,
    sessions_with_click_product_3: 11,
  },
  {
    date: "2024-01-11",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 18,
    daily_budget: 10,
    spend: 54,
    impressions: 77,
    sessions_with_click: 217,
    sessions_with_click_product_0: 35,
    sessions_with_click_product_1: 22,
    sessions_with_click_product_2: 14,
    sessions_with_click_product_3: 26,
  },
  {
    date: "2024-01-12",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 64,
    daily_budget: 42,
    spend: 55,
    impressions: 29,
    sessions_with_click: 131,
    sessions_with_click_product_0: 13,
    sessions_with_click_product_1: 24,
    sessions_with_click_product_2: 29,
    sessions_with_click_product_3: 24,
  },
  {
    date: "2024-01-13",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 80,
    daily_budget: 53,
    spend: 105,
    impressions: 72,
    sessions_with_click: 66,
    sessions_with_click_product_0: 61,
    sessions_with_click_product_1: 62,
    sessions_with_click_product_2: 13,
    sessions_with_click_product_3: 39,
  },
  {
    date: "2024-01-14",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 90,
    daily_budget: 75,
    spend: 51,
    impressions: 87,
    sessions_with_click: 127,
    sessions_with_click_product_0: 72,
    sessions_with_click_product_1: 60,
    sessions_with_click_product_2: 38,
    sessions_with_click_product_3: 33,
  },
  {
    date: "2024-01-15",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 43,
    daily_budget: 109,
    spend: 88,
    impressions: 95,
    sessions_with_click: 107,
    sessions_with_click_product_0: 49,
    sessions_with_click_product_1: 43,
    sessions_with_click_product_2: 11,
    sessions_with_click_product_3: 21,
  },
  {
    date: "2024-01-16",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 109,
    daily_budget: 24,
    spend: 76,
    impressions: 32,
    sessions_with_click: 98,
    sessions_with_click_product_0: 90,
    sessions_with_click_product_1: 77,
    sessions_with_click_product_2: 21,
    sessions_with_click_product_3: 20,
  },
  {
    date: "2024-01-17",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 34,
    daily_budget: 52,
    spend: 29,
    impressions: 85,
    sessions_with_click: 243,
    sessions_with_click_product_0: 93,
    sessions_with_click_product_1: 69,
    sessions_with_click_product_2: 30,
    sessions_with_click_product_3: 38,
  },
  {
    date: "2024-01-18",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 101,
    daily_budget: 87,
    spend: 70,
    impressions: 95,
    sessions_with_click: 257,
    sessions_with_click_product_0: 72,
    sessions_with_click_product_1: 66,
    sessions_with_click_product_2: 27,
    sessions_with_click_product_3: 34,
  },
  {
    date: "2024-01-19",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 42,
    daily_budget: 50,
    spend: 79,
    impressions: 36,
    sessions_with_click: 195,
    sessions_with_click_product_0: 76,
    sessions_with_click_product_1: 10,
    sessions_with_click_product_2: 36,
    sessions_with_click_product_3: 45,
  },
  {
    date: "2024-01-20",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 69,
    daily_budget: 22,
    spend: 45,
    impressions: 28,
    sessions_with_click: 45,
    sessions_with_click_product_0: 61,
    sessions_with_click_product_1: 61,
    sessions_with_click_product_2: 26,
    sessions_with_click_product_3: 29,
  },
  {
    date: "2024-01-21",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 46,
    daily_budget: 34,
    spend: 32,
    impressions: 78,
    sessions_with_click: 152,
    sessions_with_click_product_0: 79,
    sessions_with_click_product_1: 44,
    sessions_with_click_product_2: 29,
    sessions_with_click_product_3: 42,
  },
  {
    date: "2024-01-22",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 80,
    daily_budget: 92,
    spend: 10,
    impressions: 112,
    sessions_with_click: 128,
    sessions_with_click_product_0: 73,
    sessions_with_click_product_1: 79,
    sessions_with_click_product_2: 17,
    sessions_with_click_product_3: 44,
  },
  {
    date: "2024-01-23",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 60,
    daily_budget: 73,
    spend: 38,
    impressions: 30,
    sessions_with_click: 201,
    sessions_with_click_product_0: 47,
    sessions_with_click_product_1: 61,
    sessions_with_click_product_2: 32,
    sessions_with_click_product_3: 14,
  },
  {
    date: "2024-01-24",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 90,
    daily_budget: 20,
    spend: 60,
    impressions: 66,
    sessions_with_click: 174,
    sessions_with_click_product_0: 36,
    sessions_with_click_product_1: 25,
    sessions_with_click_product_2: 35,
    sessions_with_click_product_3: 24,
  },
  {
    date: "2024-01-25",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 46,
    daily_budget: 30,
    spend: 52,
    impressions: 82,
    sessions_with_click: 197,
    sessions_with_click_product_0: 82,
    sessions_with_click_product_1: 77,
    sessions_with_click_product_2: 22,
    sessions_with_click_product_3: 33,
  },
  {
    date: "2024-01-26",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 27,
    daily_budget: 62,
    spend: 54,
    impressions: 113,
    sessions_with_click: 203,
    sessions_with_click_product_0: 59,
    sessions_with_click_product_1: 12,
    sessions_with_click_product_2: 30,
    sessions_with_click_product_3: 15,
  },
  {
    date: "2024-01-27",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 66,
    daily_budget: 100,
    spend: 85,
    impressions: 100,
    sessions_with_click: 42,
    sessions_with_click_product_0: 24,
    sessions_with_click_product_1: 12,
    sessions_with_click_product_2: 14,
    sessions_with_click_product_3: 39,
  },
  {
    date: "2024-01-28",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 70,
    daily_budget: 66,
    spend: 27,
    impressions: 76,
    sessions_with_click: 218,
    sessions_with_click_product_0: 18,
    sessions_with_click_product_1: 79,
    sessions_with_click_product_2: 26,
    sessions_with_click_product_3: 37,
  },
  {
    date: "2024-01-29",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 38,
    daily_budget: 29,
    spend: 41,
    impressions: 44,
    sessions_with_click: 243,
    sessions_with_click_product_0: 80,
    sessions_with_click_product_1: 84,
    sessions_with_click_product_2: 14,
    sessions_with_click_product_3: 43,
  },
  {
    date: "2024-01-30",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 44,
    daily_budget: 34,
    spend: 33,
    impressions: 43,
    sessions_with_click: 58,
    sessions_with_click_product_0: 79,
    sessions_with_click_product_1: 14,
    sessions_with_click_product_2: 18,
    sessions_with_click_product_3: 38,
  },
  {
    date: "2024-01-31",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 89,
    daily_budget: 70,
    spend: 72,
    impressions: 98,
    sessions_with_click: 62,
    sessions_with_click_product_0: 72,
    sessions_with_click_product_1: 13,
    sessions_with_click_product_2: 24,
    sessions_with_click_product_3: 34,
  },
  {
    date: "2024-02-01",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 98,
    daily_budget: 83,
    spend: 92,
    impressions: 23,
    sessions_with_click: 241,
    sessions_with_click_product_0: 42,
    sessions_with_click_product_1: 69,
    sessions_with_click_product_2: 12,
    sessions_with_click_product_3: 17,
  },
  {
    date: "2024-02-02",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 15,
    daily_budget: 79,
    spend: 97,
    impressions: 22,
    sessions_with_click: 50,
    sessions_with_click_product_0: 17,
    sessions_with_click_product_1: 53,
    sessions_with_click_product_2: 34,
    sessions_with_click_product_3: 43,
  },
  {
    date: "2024-02-03",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 69,
    daily_budget: 52,
    spend: 84,
    impressions: 29,
    sessions_with_click: 113,
    sessions_with_click_product_0: 59,
    sessions_with_click_product_1: 46,
    sessions_with_click_product_2: 30,
    sessions_with_click_product_3: 26,
  },
  {
    date: "2024-02-04",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 14,
    daily_budget: 41,
    spend: 101,
    impressions: 72,
    sessions_with_click: 32,
    sessions_with_click_product_0: 12,
    sessions_with_click_product_1: 15,
    sessions_with_click_product_2: 21,
    sessions_with_click_product_3: 44,
  },
  {
    date: "2024-02-05",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 43,
    daily_budget: 68,
    spend: 88,
    impressions: 83,
    sessions_with_click: 17,
    sessions_with_click_product_0: 80,
    sessions_with_click_product_1: 81,
    sessions_with_click_product_2: 34,
    sessions_with_click_product_3: 42,
  },
  {
    date: "2024-02-06",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 97,
    daily_budget: 42,
    spend: 30,
    impressions: 42,
    sessions_with_click: 198,
    sessions_with_click_product_0: 90,
    sessions_with_click_product_1: 78,
    sessions_with_click_product_2: 15,
    sessions_with_click_product_3: 41,
  },
  {
    date: "2024-01-03",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 76,
    daily_budget: 77,
    spend: 104,
    impressions: 96,
    sessions_with_click: 65,
    sessions_with_click_product_0: 91,
    sessions_with_click_product_1: 87,
    sessions_with_click_product_2: 13,
    sessions_with_click_product_3: 28,
  },
  {
    date: "2024-01-04",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 91,
    daily_budget: 97,
    spend: 78,
    impressions: 45,
    sessions_with_click: 255,
    sessions_with_click_product_0: 64,
    sessions_with_click_product_1: 60,
    sessions_with_click_product_2: 37,
    sessions_with_click_product_3: 36,
  },
  {
    date: "2024-01-05",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 86,
    daily_budget: 64,
    spend: 80,
    impressions: 103,
    sessions_with_click: 226,
    sessions_with_click_product_0: 82,
    sessions_with_click_product_1: 22,
    sessions_with_click_product_2: 29,
    sessions_with_click_product_3: 11,
  },
  {
    date: "2024-01-06",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 60,
    daily_budget: 14,
    spend: 56,
    impressions: 90,
    sessions_with_click: 209,
    sessions_with_click_product_0: 20,
    sessions_with_click_product_1: 61,
    sessions_with_click_product_2: 26,
    sessions_with_click_product_3: 45,
  },
  {
    date: "2024-01-07",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 102,
    daily_budget: 66,
    spend: 90,
    impressions: 28,
    sessions_with_click: 107,
    sessions_with_click_product_0: 91,
    sessions_with_click_product_1: 30,
    sessions_with_click_product_2: 16,
    sessions_with_click_product_3: 12,
  },
  {
    date: "2024-01-08",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 77,
    daily_budget: 107,
    spend: 66,
    impressions: 87,
    sessions_with_click: 142,
    sessions_with_click_product_0: 74,
    sessions_with_click_product_1: 83,
    sessions_with_click_product_2: 21,
    sessions_with_click_product_3: 20,
  },
  {
    date: "2024-01-09",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 17,
    daily_budget: 72,
    spend: 46,
    impressions: 60,
    sessions_with_click: 220,
    sessions_with_click_product_0: 56,
    sessions_with_click_product_1: 42,
    sessions_with_click_product_2: 39,
    sessions_with_click_product_3: 10,
  },
  {
    date: "2024-01-10",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 35,
    daily_budget: 107,
    spend: 65,
    impressions: 65,
    sessions_with_click: 187,
    sessions_with_click_product_0: 26,
    sessions_with_click_product_1: 11,
    sessions_with_click_product_2: 11,
    sessions_with_click_product_3: 44,
  },
  {
    date: "2024-01-11",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 70,
    daily_budget: 18,
    spend: 109,
    impressions: 101,
    sessions_with_click: 152,
    sessions_with_click_product_0: 30,
    sessions_with_click_product_1: 29,
    sessions_with_click_product_2: 24,
    sessions_with_click_product_3: 47,
  },
  {
    date: "2024-01-12",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 47,
    daily_budget: 41,
    spend: 55,
    impressions: 38,
    sessions_with_click: 92,
    sessions_with_click_product_0: 58,
    sessions_with_click_product_1: 84,
    sessions_with_click_product_2: 24,
    sessions_with_click_product_3: 46,
  },
  {
    date: "2024-01-13",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 47,
    daily_budget: 73,
    spend: 50,
    impressions: 98,
    sessions_with_click: 62,
    sessions_with_click_product_0: 42,
    sessions_with_click_product_1: 66,
    sessions_with_click_product_2: 18,
    sessions_with_click_product_3: 27,
  },
  {
    date: "2024-01-14",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 13,
    daily_budget: 18,
    spend: 48,
    impressions: 80,
    sessions_with_click: 120,
    sessions_with_click_product_0: 36,
    sessions_with_click_product_1: 63,
    sessions_with_click_product_2: 35,
    sessions_with_click_product_3: 38,
  },
  {
    date: "2024-01-15",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 18,
    daily_budget: 15,
    spend: 23,
    impressions: 45,
    sessions_with_click: 234,
    sessions_with_click_product_0: 83,
    sessions_with_click_product_1: 84,
    sessions_with_click_product_2: 38,
    sessions_with_click_product_3: 23,
  },
  {
    date: "2024-01-16",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 36,
    daily_budget: 81,
    spend: 50,
    impressions: 87,
    sessions_with_click: 210,
    sessions_with_click_product_0: 109,
    sessions_with_click_product_1: 34,
    sessions_with_click_product_2: 34,
    sessions_with_click_product_3: 31,
  },
  {
    date: "2024-01-17",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 62,
    daily_budget: 73,
    spend: 36,
    impressions: 20,
    sessions_with_click: 49,
    sessions_with_click_product_0: 85,
    sessions_with_click_product_1: 48,
    sessions_with_click_product_2: 35,
    sessions_with_click_product_3: 48,
  },
  {
    date: "2024-01-18",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 51,
    daily_budget: 34,
    spend: 34,
    impressions: 54,
    sessions_with_click: 26,
    sessions_with_click_product_0: 84,
    sessions_with_click_product_1: 36,
    sessions_with_click_product_2: 10,
    sessions_with_click_product_3: 41,
  },
  {
    date: "2024-01-19",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 85,
    daily_budget: 59,
    spend: 69,
    impressions: 32,
    sessions_with_click: 65,
    sessions_with_click_product_0: 90,
    sessions_with_click_product_1: 27,
    sessions_with_click_product_2: 36,
    sessions_with_click_product_3: 22,
  },
  {
    date: "2024-01-20",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 37,
    daily_budget: 21,
    spend: 87,
    impressions: 112,
    sessions_with_click: 107,
    sessions_with_click_product_0: 85,
    sessions_with_click_product_1: 67,
    sessions_with_click_product_2: 25,
    sessions_with_click_product_3: 15,
  },
  {
    date: "2024-01-21",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 21,
    daily_budget: 42,
    spend: 66,
    impressions: 48,
    sessions_with_click: 135,
    sessions_with_click_product_0: 32,
    sessions_with_click_product_1: 76,
    sessions_with_click_product_2: 33,
    sessions_with_click_product_3: 21,
  },
  {
    date: "2024-01-22",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 45,
    daily_budget: 16,
    spend: 27,
    impressions: 60,
    sessions_with_click: 235,
    sessions_with_click_product_0: 43,
    sessions_with_click_product_1: 51,
    sessions_with_click_product_2: 24,
    sessions_with_click_product_3: 47,
  },
  {
    date: "2024-01-23",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 79,
    daily_budget: 74,
    spend: 39,
    impressions: 100,
    sessions_with_click: 56,
    sessions_with_click_product_0: 61,
    sessions_with_click_product_1: 12,
    sessions_with_click_product_2: 34,
    sessions_with_click_product_3: 22,
  },
  {
    date: "2024-01-24",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 66,
    daily_budget: 15,
    spend: 57,
    impressions: 71,
    sessions_with_click: 108,
    sessions_with_click_product_0: 39,
    sessions_with_click_product_1: 59,
    sessions_with_click_product_2: 11,
    sessions_with_click_product_3: 27,
  },
  {
    date: "2024-01-25",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 80,
    daily_budget: 25,
    spend: 34,
    impressions: 95,
    sessions_with_click: 145,
    sessions_with_click_product_0: 44,
    sessions_with_click_product_1: 60,
    sessions_with_click_product_2: 10,
    sessions_with_click_product_3: 17,
  },
  {
    date: "2024-01-26",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 68,
    daily_budget: 74,
    spend: 40,
    impressions: 35,
    sessions_with_click: 87,
    sessions_with_click_product_0: 54,
    sessions_with_click_product_1: 77,
    sessions_with_click_product_2: 12,
    sessions_with_click_product_3: 13,
  },
  {
    date: "2024-01-27",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 65,
    daily_budget: 84,
    spend: 72,
    impressions: 117,
    sessions_with_click: 175,
    sessions_with_click_product_0: 93,
    sessions_with_click_product_1: 44,
    sessions_with_click_product_2: 25,
    sessions_with_click_product_3: 30,
  },
  {
    date: "2024-01-28",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 84,
    daily_budget: 79,
    spend: 68,
    impressions: 55,
    sessions_with_click: 110,
    sessions_with_click_product_0: 93,
    sessions_with_click_product_1: 18,
    sessions_with_click_product_2: 32,
    sessions_with_click_product_3: 46,
  },
  {
    date: "2024-01-29",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 10,
    daily_budget: 89,
    spend: 15,
    impressions: 39,
    sessions_with_click: 95,
    sessions_with_click_product_0: 96,
    sessions_with_click_product_1: 78,
    sessions_with_click_product_2: 16,
    sessions_with_click_product_3: 38,
  },
  {
    date: "2024-01-30",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 72,
    daily_budget: 11,
    spend: 93,
    impressions: 61,
    sessions_with_click: 80,
    sessions_with_click_product_0: 94,
    sessions_with_click_product_1: 77,
    sessions_with_click_product_2: 36,
    sessions_with_click_product_3: 42,
  },
  {
    date: "2024-01-31",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 49,
    daily_budget: 106,
    spend: 70,
    impressions: 37,
    sessions_with_click: 101,
    sessions_with_click_product_0: 29,
    sessions_with_click_product_1: 55,
    sessions_with_click_product_2: 24,
    sessions_with_click_product_3: 41,
  },
  {
    date: "2024-02-01",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 24,
    daily_budget: 93,
    spend: 79,
    impressions: 111,
    sessions_with_click: 224,
    sessions_with_click_product_0: 70,
    sessions_with_click_product_1: 67,
    sessions_with_click_product_2: 22,
    sessions_with_click_product_3: 12,
  },
  {
    date: "2024-02-02",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 32,
    daily_budget: 82,
    spend: 31,
    impressions: 78,
    sessions_with_click: 220,
    sessions_with_click_product_0: 29,
    sessions_with_click_product_1: 78,
    sessions_with_click_product_2: 28,
    sessions_with_click_product_3: 29,
  },
  {
    date: "2024-02-03",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 73,
    daily_budget: 107,
    spend: 36,
    impressions: 59,
    sessions_with_click: 41,
    sessions_with_click_product_0: 98,
    sessions_with_click_product_1: 48,
    sessions_with_click_product_2: 20,
    sessions_with_click_product_3: 42,
  },
  {
    date: "2024-02-04",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 103,
    daily_budget: 64,
    spend: 12,
    impressions: 31,
    sessions_with_click: 145,
    sessions_with_click_product_0: 21,
    sessions_with_click_product_1: 76,
    sessions_with_click_product_2: 34,
    sessions_with_click_product_3: 21,
  },
  {
    date: "2024-02-05",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 38,
    daily_budget: 17,
    spend: 109,
    impressions: 56,
    sessions_with_click: 117,
    sessions_with_click_product_0: 88,
    sessions_with_click_product_1: 64,
    sessions_with_click_product_2: 10,
    sessions_with_click_product_3: 41,
  },
  {
    date: "2024-02-06",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 99,
    daily_budget: 27,
    spend: 20,
    impressions: 76,
    sessions_with_click: 131,
    sessions_with_click_product_0: 84,
    sessions_with_click_product_1: 50,
    sessions_with_click_product_2: 27,
    sessions_with_click_product_3: 23,
  },
  {
    date: "2024-01-03",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 76,
    daily_budget: 74,
    spend: 30,
    impressions: 41,
    sessions_with_click: 79,
    sessions_with_click_product_0: 73,
    sessions_with_click_product_1: 82,
    sessions_with_click_product_2: 33,
    sessions_with_click_product_3: 25,
  },
  {
    date: "2024-01-04",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 64,
    daily_budget: 89,
    spend: 20,
    impressions: 109,
    sessions_with_click: 194,
    sessions_with_click_product_0: 13,
    sessions_with_click_product_1: 19,
    sessions_with_click_product_2: 18,
    sessions_with_click_product_3: 35,
  },
  {
    date: "2024-01-05",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 12,
    daily_budget: 95,
    spend: 85,
    impressions: 70,
    sessions_with_click: 228,
    sessions_with_click_product_0: 95,
    sessions_with_click_product_1: 84,
    sessions_with_click_product_2: 22,
    sessions_with_click_product_3: 35,
  },
  {
    date: "2024-01-06",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 64,
    daily_budget: 26,
    spend: 11,
    impressions: 67,
    sessions_with_click: 26,
    sessions_with_click_product_0: 43,
    sessions_with_click_product_1: 51,
    sessions_with_click_product_2: 38,
    sessions_with_click_product_3: 35,
  },
  {
    date: "2024-01-07",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 98,
    daily_budget: 102,
    spend: 108,
    impressions: 28,
    sessions_with_click: 90,
    sessions_with_click_product_0: 91,
    sessions_with_click_product_1: 50,
    sessions_with_click_product_2: 36,
    sessions_with_click_product_3: 21,
  },
  {
    date: "2024-01-08",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 87,
    daily_budget: 66,
    spend: 102,
    impressions: 67,
    sessions_with_click: 254,
    sessions_with_click_product_0: 80,
    sessions_with_click_product_1: 15,
    sessions_with_click_product_2: 11,
    sessions_with_click_product_3: 48,
  },
  {
    date: "2024-01-09",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 66,
    daily_budget: 18,
    spend: 24,
    impressions: 34,
    sessions_with_click: 218,
    sessions_with_click_product_0: 25,
    sessions_with_click_product_1: 65,
    sessions_with_click_product_2: 19,
    sessions_with_click_product_3: 39,
  },
  {
    date: "2024-01-10",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 62,
    daily_budget: 86,
    spend: 44,
    impressions: 53,
    sessions_with_click: 48,
    sessions_with_click_product_0: 34,
    sessions_with_click_product_1: 36,
    sessions_with_click_product_2: 14,
    sessions_with_click_product_3: 39,
  },
  {
    date: "2024-01-11",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 19,
    daily_budget: 82,
    spend: 36,
    impressions: 25,
    sessions_with_click: 185,
    sessions_with_click_product_0: 22,
    sessions_with_click_product_1: 16,
    sessions_with_click_product_2: 19,
    sessions_with_click_product_3: 34,
  },
  {
    date: "2024-01-12",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 63,
    daily_budget: 83,
    spend: 83,
    impressions: 41,
    sessions_with_click: 175,
    sessions_with_click_product_0: 23,
    sessions_with_click_product_1: 85,
    sessions_with_click_product_2: 33,
    sessions_with_click_product_3: 14,
  },
  {
    date: "2024-01-13",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 102,
    daily_budget: 62,
    spend: 40,
    impressions: 109,
    sessions_with_click: 141,
    sessions_with_click_product_0: 81,
    sessions_with_click_product_1: 27,
    sessions_with_click_product_2: 35,
    sessions_with_click_product_3: 12,
  },
  {
    date: "2024-01-14",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 56,
    daily_budget: 76,
    spend: 85,
    impressions: 54,
    sessions_with_click: 259,
    sessions_with_click_product_0: 99,
    sessions_with_click_product_1: 35,
    sessions_with_click_product_2: 13,
    sessions_with_click_product_3: 36,
  },
  {
    date: "2024-01-15",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 80,
    daily_budget: 102,
    spend: 92,
    impressions: 115,
    sessions_with_click: 76,
    sessions_with_click_product_0: 75,
    sessions_with_click_product_1: 74,
    sessions_with_click_product_2: 38,
    sessions_with_click_product_3: 21,
  },
  {
    date: "2024-01-16",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 97,
    daily_budget: 105,
    spend: 35,
    impressions: 63,
    sessions_with_click: 100,
    sessions_with_click_product_0: 66,
    sessions_with_click_product_1: 85,
    sessions_with_click_product_2: 37,
    sessions_with_click_product_3: 47,
  },
  {
    date: "2024-01-17",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 74,
    daily_budget: 16,
    spend: 73,
    impressions: 50,
    sessions_with_click: 105,
    sessions_with_click_product_0: 63,
    sessions_with_click_product_1: 48,
    sessions_with_click_product_2: 34,
    sessions_with_click_product_3: 29,
  },
  {
    date: "2024-01-18",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 69,
    daily_budget: 43,
    spend: 61,
    impressions: 99,
    sessions_with_click: 180,
    sessions_with_click_product_0: 93,
    sessions_with_click_product_1: 50,
    sessions_with_click_product_2: 14,
    sessions_with_click_product_3: 18,
  },
  {
    date: "2024-01-19",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 35,
    daily_budget: 70,
    spend: 72,
    impressions: 85,
    sessions_with_click: 207,
    sessions_with_click_product_0: 48,
    sessions_with_click_product_1: 20,
    sessions_with_click_product_2: 36,
    sessions_with_click_product_3: 13,
  },
  {
    date: "2024-01-20",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 35,
    daily_budget: 103,
    spend: 49,
    impressions: 90,
    sessions_with_click: 69,
    sessions_with_click_product_0: 70,
    sessions_with_click_product_1: 72,
    sessions_with_click_product_2: 15,
    sessions_with_click_product_3: 36,
  },
  {
    date: "2024-01-21",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 54,
    daily_budget: 41,
    spend: 94,
    impressions: 45,
    sessions_with_click: 247,
    sessions_with_click_product_0: 19,
    sessions_with_click_product_1: 50,
    sessions_with_click_product_2: 36,
    sessions_with_click_product_3: 22,
  },
  {
    date: "2024-01-22",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 106,
    daily_budget: 53,
    spend: 103,
    impressions: 86,
    sessions_with_click: 175,
    sessions_with_click_product_0: 54,
    sessions_with_click_product_1: 13,
    sessions_with_click_product_2: 18,
    sessions_with_click_product_3: 27,
  },
  {
    date: "2024-01-23",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 29,
    daily_budget: 94,
    spend: 75,
    impressions: 84,
    sessions_with_click: 84,
    sessions_with_click_product_0: 46,
    sessions_with_click_product_1: 76,
    sessions_with_click_product_2: 22,
    sessions_with_click_product_3: 14,
  },
  {
    date: "2024-01-24",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 95,
    daily_budget: 106,
    spend: 46,
    impressions: 43,
    sessions_with_click: 144,
    sessions_with_click_product_0: 79,
    sessions_with_click_product_1: 76,
    sessions_with_click_product_2: 13,
    sessions_with_click_product_3: 31,
  },
  {
    date: "2024-01-25",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 28,
    daily_budget: 86,
    spend: 66,
    impressions: 34,
    sessions_with_click: 217,
    sessions_with_click_product_0: 42,
    sessions_with_click_product_1: 22,
    sessions_with_click_product_2: 15,
    sessions_with_click_product_3: 31,
  },
  {
    date: "2024-01-26",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 47,
    daily_budget: 40,
    spend: 45,
    impressions: 106,
    sessions_with_click: 12,
    sessions_with_click_product_0: 77,
    sessions_with_click_product_1: 37,
    sessions_with_click_product_2: 38,
    sessions_with_click_product_3: 34,
  },
  {
    date: "2024-01-27",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 38,
    daily_budget: 105,
    spend: 68,
    impressions: 73,
    sessions_with_click: 138,
    sessions_with_click_product_0: 31,
    sessions_with_click_product_1: 58,
    sessions_with_click_product_2: 18,
    sessions_with_click_product_3: 43,
  },
  {
    date: "2024-01-28",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 71,
    daily_budget: 107,
    spend: 78,
    impressions: 80,
    sessions_with_click: 41,
    sessions_with_click_product_0: 27,
    sessions_with_click_product_1: 20,
    sessions_with_click_product_2: 14,
    sessions_with_click_product_3: 34,
  },
  {
    date: "2024-01-29",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 34,
    daily_budget: 106,
    spend: 78,
    impressions: 82,
    sessions_with_click: 95,
    sessions_with_click_product_0: 78,
    sessions_with_click_product_1: 74,
    sessions_with_click_product_2: 10,
    sessions_with_click_product_3: 31,
  },
  {
    date: "2024-01-30",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 41,
    daily_budget: 86,
    spend: 99,
    impressions: 72,
    sessions_with_click: 58,
    sessions_with_click_product_0: 92,
    sessions_with_click_product_1: 14,
    sessions_with_click_product_2: 38,
    sessions_with_click_product_3: 20,
  },
  {
    date: "2024-01-31",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 20,
    daily_budget: 70,
    spend: 102,
    impressions: 49,
    sessions_with_click: 34,
    sessions_with_click_product_0: 42,
    sessions_with_click_product_1: 79,
    sessions_with_click_product_2: 31,
    sessions_with_click_product_3: 32,
  },
  {
    date: "2024-02-01",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 71,
    daily_budget: 46,
    spend: 55,
    impressions: 50,
    sessions_with_click: 115,
    sessions_with_click_product_0: 30,
    sessions_with_click_product_1: 46,
    sessions_with_click_product_2: 34,
    sessions_with_click_product_3: 41,
  },
  {
    date: "2024-02-02",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 57,
    daily_budget: 90,
    spend: 47,
    impressions: 107,
    sessions_with_click: 21,
    sessions_with_click_product_0: 26,
    sessions_with_click_product_1: 36,
    sessions_with_click_product_2: 27,
    sessions_with_click_product_3: 29,
  },
  {
    date: "2024-02-03",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 107,
    daily_budget: 95,
    spend: 74,
    impressions: 78,
    sessions_with_click: 155,
    sessions_with_click_product_0: 62,
    sessions_with_click_product_1: 13,
    sessions_with_click_product_2: 27,
    sessions_with_click_product_3: 20,
  },
  {
    date: "2024-02-04",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 103,
    daily_budget: 43,
    spend: 41,
    impressions: 30,
    sessions_with_click: 183,
    sessions_with_click_product_0: 14,
    sessions_with_click_product_1: 25,
    sessions_with_click_product_2: 21,
    sessions_with_click_product_3: 28,
  },
  {
    date: "2024-02-05",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 98,
    daily_budget: 76,
    spend: 84,
    impressions: 114,
    sessions_with_click: 178,
    sessions_with_click_product_0: 16,
    sessions_with_click_product_1: 27,
    sessions_with_click_product_2: 22,
    sessions_with_click_product_3: 42,
  },
  {
    date: "2024-02-06",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 24,
    daily_budget: 21,
    spend: 77,
    impressions: 101,
    sessions_with_click: 34,
    sessions_with_click_product_0: 47,
    sessions_with_click_product_1: 80,
    sessions_with_click_product_2: 19,
    sessions_with_click_product_3: 35,
  },
  {
    date: "2024-01-03",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 34,
    daily_budget: 89,
    spend: 68,
    impressions: 66,
    sessions_with_click: 34,
    sessions_with_click_product_0: 68,
    sessions_with_click_product_1: 46,
    sessions_with_click_product_2: 37,
    sessions_with_click_product_3: 37,
  },
  {
    date: "2024-01-04",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 46,
    daily_budget: 69,
    spend: 102,
    impressions: 63,
    sessions_with_click: 153,
    sessions_with_click_product_0: 81,
    sessions_with_click_product_1: 59,
    sessions_with_click_product_2: 16,
    sessions_with_click_product_3: 31,
  },
  {
    date: "2024-01-05",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 43,
    daily_budget: 25,
    spend: 48,
    impressions: 47,
    sessions_with_click: 41,
    sessions_with_click_product_0: 41,
    sessions_with_click_product_1: 67,
    sessions_with_click_product_2: 28,
    sessions_with_click_product_3: 26,
  },
  {
    date: "2024-01-06",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 72,
    daily_budget: 61,
    spend: 85,
    impressions: 58,
    sessions_with_click: 31,
    sessions_with_click_product_0: 27,
    sessions_with_click_product_1: 89,
    sessions_with_click_product_2: 19,
    sessions_with_click_product_3: 19,
  },
  {
    date: "2024-01-07",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 85,
    daily_budget: 17,
    spend: 32,
    impressions: 29,
    sessions_with_click: 87,
    sessions_with_click_product_0: 72,
    sessions_with_click_product_1: 67,
    sessions_with_click_product_2: 12,
    sessions_with_click_product_3: 42,
  },
  {
    date: "2024-01-08",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 26,
    daily_budget: 69,
    spend: 107,
    impressions: 37,
    sessions_with_click: 141,
    sessions_with_click_product_0: 80,
    sessions_with_click_product_1: 21,
    sessions_with_click_product_2: 11,
    sessions_with_click_product_3: 27,
  },
  {
    date: "2024-01-09",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 107,
    daily_budget: 47,
    spend: 91,
    impressions: 59,
    sessions_with_click: 10,
    sessions_with_click_product_0: 26,
    sessions_with_click_product_1: 84,
    sessions_with_click_product_2: 14,
    sessions_with_click_product_3: 29,
  },
  {
    date: "2024-01-10",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 22,
    daily_budget: 14,
    spend: 40,
    impressions: 116,
    sessions_with_click: 105,
    sessions_with_click_product_0: 67,
    sessions_with_click_product_1: 83,
    sessions_with_click_product_2: 21,
    sessions_with_click_product_3: 19,
  },
  {
    date: "2024-01-11",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 40,
    daily_budget: 28,
    spend: 39,
    impressions: 59,
    sessions_with_click: 100,
    sessions_with_click_product_0: 40,
    sessions_with_click_product_1: 75,
    sessions_with_click_product_2: 20,
    sessions_with_click_product_3: 23,
  },
  {
    date: "2024-01-12",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 20,
    daily_budget: 63,
    spend: 49,
    impressions: 95,
    sessions_with_click: 177,
    sessions_with_click_product_0: 66,
    sessions_with_click_product_1: 30,
    sessions_with_click_product_2: 25,
    sessions_with_click_product_3: 46,
  },
  {
    date: "2024-01-13",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 64,
    daily_budget: 11,
    spend: 61,
    impressions: 91,
    sessions_with_click: 107,
    sessions_with_click_product_0: 92,
    sessions_with_click_product_1: 23,
    sessions_with_click_product_2: 18,
    sessions_with_click_product_3: 12,
  },
  {
    date: "2024-01-14",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 32,
    daily_budget: 66,
    spend: 68,
    impressions: 26,
    sessions_with_click: 140,
    sessions_with_click_product_0: 40,
    sessions_with_click_product_1: 24,
    sessions_with_click_product_2: 26,
    sessions_with_click_product_3: 22,
  },
  {
    date: "2024-01-15",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 15,
    daily_budget: 39,
    spend: 39,
    impressions: 67,
    sessions_with_click: 130,
    sessions_with_click_product_0: 96,
    sessions_with_click_product_1: 60,
    sessions_with_click_product_2: 20,
    sessions_with_click_product_3: 35,
  },
  {
    date: "2024-01-16",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 65,
    daily_budget: 95,
    spend: 64,
    impressions: 95,
    sessions_with_click: 176,
    sessions_with_click_product_0: 86,
    sessions_with_click_product_1: 86,
    sessions_with_click_product_2: 15,
    sessions_with_click_product_3: 34,
  },
  {
    date: "2024-01-17",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 90,
    daily_budget: 47,
    spend: 55,
    impressions: 78,
    sessions_with_click: 138,
    sessions_with_click_product_0: 54,
    sessions_with_click_product_1: 49,
    sessions_with_click_product_2: 20,
    sessions_with_click_product_3: 18,
  },
  {
    date: "2024-01-18",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 36,
    daily_budget: 25,
    spend: 49,
    impressions: 23,
    sessions_with_click: 44,
    sessions_with_click_product_0: 31,
    sessions_with_click_product_1: 31,
    sessions_with_click_product_2: 11,
    sessions_with_click_product_3: 40,
  },
  {
    date: "2024-01-19",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 26,
    daily_budget: 86,
    spend: 59,
    impressions: 82,
    sessions_with_click: 67,
    sessions_with_click_product_0: 68,
    sessions_with_click_product_1: 52,
    sessions_with_click_product_2: 20,
    sessions_with_click_product_3: 23,
  },
  {
    date: "2024-01-20",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 26,
    daily_budget: 51,
    spend: 104,
    impressions: 51,
    sessions_with_click: 259,
    sessions_with_click_product_0: 51,
    sessions_with_click_product_1: 60,
    sessions_with_click_product_2: 12,
    sessions_with_click_product_3: 40,
  },
  {
    date: "2024-01-21",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 39,
    daily_budget: 19,
    spend: 71,
    impressions: 63,
    sessions_with_click: 118,
    sessions_with_click_product_0: 16,
    sessions_with_click_product_1: 65,
    sessions_with_click_product_2: 14,
    sessions_with_click_product_3: 37,
  },
  {
    date: "2024-01-22",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 14,
    daily_budget: 82,
    spend: 92,
    impressions: 116,
    sessions_with_click: 40,
    sessions_with_click_product_0: 48,
    sessions_with_click_product_1: 44,
    sessions_with_click_product_2: 10,
    sessions_with_click_product_3: 35,
  },
  {
    date: "2024-01-23",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 28,
    daily_budget: 36,
    spend: 51,
    impressions: 65,
    sessions_with_click: 22,
    sessions_with_click_product_0: 98,
    sessions_with_click_product_1: 24,
    sessions_with_click_product_2: 10,
    sessions_with_click_product_3: 30,
  },
  {
    date: "2024-01-24",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 63,
    daily_budget: 64,
    spend: 49,
    impressions: 91,
    sessions_with_click: 13,
    sessions_with_click_product_0: 62,
    sessions_with_click_product_1: 66,
    sessions_with_click_product_2: 26,
    sessions_with_click_product_3: 30,
  },
  {
    date: "2024-01-25",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 43,
    daily_budget: 14,
    spend: 95,
    impressions: 49,
    sessions_with_click: 27,
    sessions_with_click_product_0: 61,
    sessions_with_click_product_1: 67,
    sessions_with_click_product_2: 34,
    sessions_with_click_product_3: 14,
  },
  {
    date: "2024-01-26",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 78,
    daily_budget: 27,
    spend: 76,
    impressions: 20,
    sessions_with_click: 226,
    sessions_with_click_product_0: 57,
    sessions_with_click_product_1: 73,
    sessions_with_click_product_2: 29,
    sessions_with_click_product_3: 33,
  },
  {
    date: "2024-01-27",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 95,
    daily_budget: 64,
    spend: 43,
    impressions: 110,
    sessions_with_click: 251,
    sessions_with_click_product_0: 97,
    sessions_with_click_product_1: 67,
    sessions_with_click_product_2: 19,
    sessions_with_click_product_3: 26,
  },
  {
    date: "2024-01-28",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 88,
    daily_budget: 32,
    spend: 37,
    impressions: 53,
    sessions_with_click: 178,
    sessions_with_click_product_0: 69,
    sessions_with_click_product_1: 22,
    sessions_with_click_product_2: 10,
    sessions_with_click_product_3: 31,
  },
  {
    date: "2024-01-29",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 33,
    daily_budget: 94,
    spend: 73,
    impressions: 46,
    sessions_with_click: 195,
    sessions_with_click_product_0: 109,
    sessions_with_click_product_1: 88,
    sessions_with_click_product_2: 17,
    sessions_with_click_product_3: 21,
  },
  {
    date: "2024-01-30",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 35,
    daily_budget: 82,
    spend: 30,
    impressions: 95,
    sessions_with_click: 177,
    sessions_with_click_product_0: 21,
    sessions_with_click_product_1: 73,
    sessions_with_click_product_2: 34,
    sessions_with_click_product_3: 13,
  },
  {
    date: "2024-01-31",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 34,
    daily_budget: 28,
    spend: 90,
    impressions: 117,
    sessions_with_click: 80,
    sessions_with_click_product_0: 105,
    sessions_with_click_product_1: 58,
    sessions_with_click_product_2: 36,
    sessions_with_click_product_3: 33,
  },
  {
    date: "2024-02-01",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 68,
    daily_budget: 45,
    spend: 101,
    impressions: 27,
    sessions_with_click: 193,
    sessions_with_click_product_0: 39,
    sessions_with_click_product_1: 18,
    sessions_with_click_product_2: 10,
    sessions_with_click_product_3: 29,
  },
  {
    date: "2024-02-02",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 91,
    daily_budget: 94,
    spend: 80,
    impressions: 101,
    sessions_with_click: 133,
    sessions_with_click_product_0: 102,
    sessions_with_click_product_1: 70,
    sessions_with_click_product_2: 39,
    sessions_with_click_product_3: 10,
  },
  {
    date: "2024-02-03",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 87,
    daily_budget: 71,
    spend: 14,
    impressions: 86,
    sessions_with_click: 84,
    sessions_with_click_product_0: 68,
    sessions_with_click_product_1: 75,
    sessions_with_click_product_2: 37,
    sessions_with_click_product_3: 29,
  },
  {
    date: "2024-02-04",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 80,
    daily_budget: 99,
    spend: 31,
    impressions: 72,
    sessions_with_click: 254,
    sessions_with_click_product_0: 79,
    sessions_with_click_product_1: 39,
    sessions_with_click_product_2: 16,
    sessions_with_click_product_3: 26,
  },
  {
    date: "2024-02-05",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 64,
    daily_budget: 76,
    spend: 66,
    impressions: 46,
    sessions_with_click: 135,
    sessions_with_click_product_0: 17,
    sessions_with_click_product_1: 55,
    sessions_with_click_product_2: 16,
    sessions_with_click_product_3: 14,
  },
  {
    date: "2024-02-06",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 76,
    daily_budget: 42,
    spend: 103,
    impressions: 43,
    sessions_with_click: 259,
    sessions_with_click_product_0: 56,
    sessions_with_click_product_1: 49,
    sessions_with_click_product_2: 13,
    sessions_with_click_product_3: 39,
  },
];

export const sixWeeksData = [
  {
    date: "2023-12-27",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 17,
    daily_budget: 96,
    spend: 81,
    impressions: 65,
    sessions_with_click: 90,
    sessions_with_click_product_0: 96,
    sessions_with_click_product_1: 56,
    sessions_with_click_product_2: 11,
    sessions_with_click_product_3: 36,
  },
  {
    date: "2023-12-28",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 54,
    daily_budget: 38,
    spend: 20,
    impressions: 49,
    sessions_with_click: 32,
    sessions_with_click_product_0: 57,
    sessions_with_click_product_1: 48,
    sessions_with_click_product_2: 38,
    sessions_with_click_product_3: 21,
  },
  {
    date: "2023-12-29",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 36,
    daily_budget: 45,
    spend: 38,
    impressions: 41,
    sessions_with_click: 75,
    sessions_with_click_product_0: 20,
    sessions_with_click_product_1: 26,
    sessions_with_click_product_2: 24,
    sessions_with_click_product_3: 40,
  },
  {
    date: "2023-12-30",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 28,
    daily_budget: 77,
    spend: 31,
    impressions: 32,
    sessions_with_click: 217,
    sessions_with_click_product_0: 68,
    sessions_with_click_product_1: 45,
    sessions_with_click_product_2: 10,
    sessions_with_click_product_3: 37,
  },
  {
    date: "2023-12-31",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 79,
    daily_budget: 98,
    spend: 54,
    impressions: 64,
    sessions_with_click: 54,
    sessions_with_click_product_0: 109,
    sessions_with_click_product_1: 72,
    sessions_with_click_product_2: 33,
    sessions_with_click_product_3: 10,
  },
  {
    date: "2024-01-01",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 76,
    daily_budget: 12,
    spend: 16,
    impressions: 104,
    sessions_with_click: 141,
    sessions_with_click_product_0: 98,
    sessions_with_click_product_1: 36,
    sessions_with_click_product_2: 35,
    sessions_with_click_product_3: 34,
  },
  {
    date: "2024-01-02",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 73,
    daily_budget: 102,
    spend: 92,
    impressions: 25,
    sessions_with_click: 51,
    sessions_with_click_product_0: 45,
    sessions_with_click_product_1: 84,
    sessions_with_click_product_2: 34,
    sessions_with_click_product_3: 44,
  },
  {
    date: "2024-01-03",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 59,
    daily_budget: 107,
    spend: 12,
    impressions: 65,
    sessions_with_click: 12,
    sessions_with_click_product_0: 86,
    sessions_with_click_product_1: 57,
    sessions_with_click_product_2: 33,
    sessions_with_click_product_3: 20,
  },
  {
    date: "2024-01-04",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 30,
    daily_budget: 44,
    spend: 48,
    impressions: 89,
    sessions_with_click: 13,
    sessions_with_click_product_0: 33,
    sessions_with_click_product_1: 45,
    sessions_with_click_product_2: 20,
    sessions_with_click_product_3: 11,
  },
  {
    date: "2024-01-05",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 71,
    daily_budget: 73,
    spend: 52,
    impressions: 106,
    sessions_with_click: 67,
    sessions_with_click_product_0: 94,
    sessions_with_click_product_1: 14,
    sessions_with_click_product_2: 35,
    sessions_with_click_product_3: 16,
  },
  {
    date: "2024-01-06",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 62,
    daily_budget: 96,
    spend: 14,
    impressions: 64,
    sessions_with_click: 54,
    sessions_with_click_product_0: 81,
    sessions_with_click_product_1: 66,
    sessions_with_click_product_2: 22,
    sessions_with_click_product_3: 11,
  },
  {
    date: "2024-01-07",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 13,
    daily_budget: 74,
    spend: 13,
    impressions: 102,
    sessions_with_click: 29,
    sessions_with_click_product_0: 53,
    sessions_with_click_product_1: 15,
    sessions_with_click_product_2: 31,
    sessions_with_click_product_3: 27,
  },
  {
    date: "2024-01-08",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 106,
    daily_budget: 13,
    spend: 88,
    impressions: 117,
    sessions_with_click: 200,
    sessions_with_click_product_0: 91,
    sessions_with_click_product_1: 77,
    sessions_with_click_product_2: 24,
    sessions_with_click_product_3: 14,
  },
  {
    date: "2024-01-09",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 61,
    daily_budget: 46,
    spend: 103,
    impressions: 44,
    sessions_with_click: 86,
    sessions_with_click_product_0: 51,
    sessions_with_click_product_1: 49,
    sessions_with_click_product_2: 34,
    sessions_with_click_product_3: 36,
  },
  {
    date: "2024-01-10",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 53,
    daily_budget: 35,
    spend: 34,
    impressions: 37,
    sessions_with_click: 207,
    sessions_with_click_product_0: 18,
    sessions_with_click_product_1: 36,
    sessions_with_click_product_2: 10,
    sessions_with_click_product_3: 33,
  },
  {
    date: "2024-01-11",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 87,
    daily_budget: 73,
    spend: 16,
    impressions: 82,
    sessions_with_click: 192,
    sessions_with_click_product_0: 29,
    sessions_with_click_product_1: 87,
    sessions_with_click_product_2: 22,
    sessions_with_click_product_3: 12,
  },
  {
    date: "2024-01-12",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 59,
    daily_budget: 20,
    spend: 36,
    impressions: 44,
    sessions_with_click: 214,
    sessions_with_click_product_0: 61,
    sessions_with_click_product_1: 55,
    sessions_with_click_product_2: 23,
    sessions_with_click_product_3: 12,
  },
  {
    date: "2024-01-13",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 89,
    daily_budget: 71,
    spend: 21,
    impressions: 109,
    sessions_with_click: 208,
    sessions_with_click_product_0: 56,
    sessions_with_click_product_1: 46,
    sessions_with_click_product_2: 23,
    sessions_with_click_product_3: 44,
  },
  {
    date: "2024-01-14",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 81,
    daily_budget: 97,
    spend: 94,
    impressions: 108,
    sessions_with_click: 62,
    sessions_with_click_product_0: 18,
    sessions_with_click_product_1: 20,
    sessions_with_click_product_2: 33,
    sessions_with_click_product_3: 11,
  },
  {
    date: "2024-01-15",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 87,
    daily_budget: 28,
    spend: 53,
    impressions: 104,
    sessions_with_click: 250,
    sessions_with_click_product_0: 25,
    sessions_with_click_product_1: 63,
    sessions_with_click_product_2: 26,
    sessions_with_click_product_3: 45,
  },
  {
    date: "2024-01-16",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 79,
    daily_budget: 15,
    spend: 51,
    impressions: 42,
    sessions_with_click: 192,
    sessions_with_click_product_0: 12,
    sessions_with_click_product_1: 78,
    sessions_with_click_product_2: 23,
    sessions_with_click_product_3: 10,
  },
  {
    date: "2024-01-17",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 107,
    daily_budget: 36,
    spend: 101,
    impressions: 85,
    sessions_with_click: 203,
    sessions_with_click_product_0: 44,
    sessions_with_click_product_1: 46,
    sessions_with_click_product_2: 37,
    sessions_with_click_product_3: 28,
  },
  {
    date: "2024-01-18",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 17,
    daily_budget: 63,
    spend: 76,
    impressions: 51,
    sessions_with_click: 120,
    sessions_with_click_product_0: 99,
    sessions_with_click_product_1: 77,
    sessions_with_click_product_2: 19,
    sessions_with_click_product_3: 29,
  },
  {
    date: "2024-01-19",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 90,
    daily_budget: 77,
    spend: 95,
    impressions: 38,
    sessions_with_click: 191,
    sessions_with_click_product_0: 11,
    sessions_with_click_product_1: 83,
    sessions_with_click_product_2: 25,
    sessions_with_click_product_3: 15,
  },
  {
    date: "2024-01-20",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 24,
    daily_budget: 28,
    spend: 30,
    impressions: 55,
    sessions_with_click: 77,
    sessions_with_click_product_0: 80,
    sessions_with_click_product_1: 64,
    sessions_with_click_product_2: 31,
    sessions_with_click_product_3: 33,
  },
  {
    date: "2024-01-21",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 10,
    daily_budget: 37,
    spend: 56,
    impressions: 26,
    sessions_with_click: 11,
    sessions_with_click_product_0: 96,
    sessions_with_click_product_1: 89,
    sessions_with_click_product_2: 14,
    sessions_with_click_product_3: 18,
  },
  {
    date: "2024-01-22",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 94,
    daily_budget: 89,
    spend: 96,
    impressions: 65,
    sessions_with_click: 162,
    sessions_with_click_product_0: 66,
    sessions_with_click_product_1: 33,
    sessions_with_click_product_2: 14,
    sessions_with_click_product_3: 44,
  },
  {
    date: "2024-01-23",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 68,
    daily_budget: 69,
    spend: 102,
    impressions: 96,
    sessions_with_click: 67,
    sessions_with_click_product_0: 87,
    sessions_with_click_product_1: 64,
    sessions_with_click_product_2: 27,
    sessions_with_click_product_3: 20,
  },
  {
    date: "2024-01-24",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 12,
    daily_budget: 59,
    spend: 70,
    impressions: 118,
    sessions_with_click: 56,
    sessions_with_click_product_0: 95,
    sessions_with_click_product_1: 28,
    sessions_with_click_product_2: 38,
    sessions_with_click_product_3: 30,
  },
  {
    date: "2024-01-25",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 85,
    daily_budget: 26,
    spend: 41,
    impressions: 50,
    sessions_with_click: 14,
    sessions_with_click_product_0: 25,
    sessions_with_click_product_1: 89,
    sessions_with_click_product_2: 36,
    sessions_with_click_product_3: 29,
  },
  {
    date: "2024-01-26",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 31,
    daily_budget: 68,
    spend: 40,
    impressions: 109,
    sessions_with_click: 160,
    sessions_with_click_product_0: 89,
    sessions_with_click_product_1: 24,
    sessions_with_click_product_2: 33,
    sessions_with_click_product_3: 39,
  },
  {
    date: "2024-01-27",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 32,
    daily_budget: 30,
    spend: 51,
    impressions: 59,
    sessions_with_click: 192,
    sessions_with_click_product_0: 97,
    sessions_with_click_product_1: 14,
    sessions_with_click_product_2: 29,
    sessions_with_click_product_3: 35,
  },
  {
    date: "2024-01-28",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 33,
    daily_budget: 109,
    spend: 57,
    impressions: 52,
    sessions_with_click: 171,
    sessions_with_click_product_0: 24,
    sessions_with_click_product_1: 42,
    sessions_with_click_product_2: 15,
    sessions_with_click_product_3: 27,
  },
  {
    date: "2024-01-29",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 87,
    daily_budget: 46,
    spend: 11,
    impressions: 97,
    sessions_with_click: 184,
    sessions_with_click_product_0: 94,
    sessions_with_click_product_1: 50,
    sessions_with_click_product_2: 31,
    sessions_with_click_product_3: 24,
  },
  {
    date: "2024-01-30",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 85,
    daily_budget: 38,
    spend: 17,
    impressions: 85,
    sessions_with_click: 172,
    sessions_with_click_product_0: 86,
    sessions_with_click_product_1: 57,
    sessions_with_click_product_2: 10,
    sessions_with_click_product_3: 27,
  },
  {
    date: "2024-01-31",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 77,
    daily_budget: 64,
    spend: 38,
    impressions: 100,
    sessions_with_click: 226,
    sessions_with_click_product_0: 92,
    sessions_with_click_product_1: 58,
    sessions_with_click_product_2: 20,
    sessions_with_click_product_3: 22,
  },
  {
    date: "2024-02-01",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 94,
    daily_budget: 22,
    spend: 82,
    impressions: 90,
    sessions_with_click: 53,
    sessions_with_click_product_0: 100,
    sessions_with_click_product_1: 59,
    sessions_with_click_product_2: 13,
    sessions_with_click_product_3: 28,
  },
  {
    date: "2024-02-02",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 18,
    daily_budget: 86,
    spend: 58,
    impressions: 53,
    sessions_with_click: 107,
    sessions_with_click_product_0: 17,
    sessions_with_click_product_1: 23,
    sessions_with_click_product_2: 19,
    sessions_with_click_product_3: 12,
  },
  {
    date: "2024-02-03",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 68,
    daily_budget: 48,
    spend: 36,
    impressions: 117,
    sessions_with_click: 199,
    sessions_with_click_product_0: 101,
    sessions_with_click_product_1: 86,
    sessions_with_click_product_2: 24,
    sessions_with_click_product_3: 15,
  },
  {
    date: "2024-02-04",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 16,
    daily_budget: 51,
    spend: 81,
    impressions: 103,
    sessions_with_click: 128,
    sessions_with_click_product_0: 27,
    sessions_with_click_product_1: 79,
    sessions_with_click_product_2: 32,
    sessions_with_click_product_3: 47,
  },
  {
    date: "2024-02-05",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 73,
    daily_budget: 48,
    spend: 75,
    impressions: 104,
    sessions_with_click: 257,
    sessions_with_click_product_0: 97,
    sessions_with_click_product_1: 14,
    sessions_with_click_product_2: 32,
    sessions_with_click_product_3: 12,
  },
  {
    date: "2024-02-06",
    campaign_name: "Xsdfjposd",
    campaign_id: "5",
    ad_type: "REWARDED",
    cpm: 28,
    daily_budget: 25,
    spend: 12,
    impressions: 71,
    sessions_with_click: 138,
    sessions_with_click_product_0: 55,
    sessions_with_click_product_1: 63,
    sessions_with_click_product_2: 20,
    sessions_with_click_product_3: 10,
  },
  {
    date: "2023-12-27",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 15,
    daily_budget: 50,
    spend: 47,
    impressions: 80,
    sessions_with_click: 25,
    sessions_with_click_product_0: 19,
    sessions_with_click_product_1: 15,
    sessions_with_click_product_2: 16,
    sessions_with_click_product_3: 33,
  },
  {
    date: "2023-12-28",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 73,
    daily_budget: 54,
    spend: 36,
    impressions: 42,
    sessions_with_click: 18,
    sessions_with_click_product_0: 87,
    sessions_with_click_product_1: 79,
    sessions_with_click_product_2: 30,
    sessions_with_click_product_3: 27,
  },
  {
    date: "2023-12-29",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 54,
    daily_budget: 92,
    spend: 23,
    impressions: 22,
    sessions_with_click: 126,
    sessions_with_click_product_0: 42,
    sessions_with_click_product_1: 57,
    sessions_with_click_product_2: 20,
    sessions_with_click_product_3: 14,
  },
  {
    date: "2023-12-30",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 96,
    daily_budget: 93,
    spend: 22,
    impressions: 48,
    sessions_with_click: 107,
    sessions_with_click_product_0: 14,
    sessions_with_click_product_1: 81,
    sessions_with_click_product_2: 32,
    sessions_with_click_product_3: 23,
  },
  {
    date: "2023-12-31",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 29,
    daily_budget: 83,
    spend: 10,
    impressions: 39,
    sessions_with_click: 212,
    sessions_with_click_product_0: 105,
    sessions_with_click_product_1: 70,
    sessions_with_click_product_2: 19,
    sessions_with_click_product_3: 43,
  },
  {
    date: "2024-01-01",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 69,
    daily_budget: 41,
    spend: 86,
    impressions: 96,
    sessions_with_click: 93,
    sessions_with_click_product_0: 23,
    sessions_with_click_product_1: 53,
    sessions_with_click_product_2: 34,
    sessions_with_click_product_3: 13,
  },
  {
    date: "2024-01-02",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 98,
    daily_budget: 70,
    spend: 48,
    impressions: 119,
    sessions_with_click: 75,
    sessions_with_click_product_0: 39,
    sessions_with_click_product_1: 36,
    sessions_with_click_product_2: 14,
    sessions_with_click_product_3: 42,
  },
  {
    date: "2024-01-03",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 41,
    daily_budget: 97,
    spend: 23,
    impressions: 39,
    sessions_with_click: 137,
    sessions_with_click_product_0: 64,
    sessions_with_click_product_1: 52,
    sessions_with_click_product_2: 26,
    sessions_with_click_product_3: 11,
  },
  {
    date: "2024-01-04",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 49,
    daily_budget: 108,
    spend: 34,
    impressions: 23,
    sessions_with_click: 97,
    sessions_with_click_product_0: 20,
    sessions_with_click_product_1: 66,
    sessions_with_click_product_2: 28,
    sessions_with_click_product_3: 33,
  },
  {
    date: "2024-01-05",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 94,
    daily_budget: 95,
    spend: 87,
    impressions: 116,
    sessions_with_click: 60,
    sessions_with_click_product_0: 23,
    sessions_with_click_product_1: 30,
    sessions_with_click_product_2: 19,
    sessions_with_click_product_3: 49,
  },
  {
    date: "2024-01-06",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 26,
    daily_budget: 49,
    spend: 38,
    impressions: 80,
    sessions_with_click: 16,
    sessions_with_click_product_0: 41,
    sessions_with_click_product_1: 62,
    sessions_with_click_product_2: 23,
    sessions_with_click_product_3: 40,
  },
  {
    date: "2024-01-07",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 48,
    daily_budget: 14,
    spend: 56,
    impressions: 54,
    sessions_with_click: 133,
    sessions_with_click_product_0: 89,
    sessions_with_click_product_1: 77,
    sessions_with_click_product_2: 30,
    sessions_with_click_product_3: 12,
  },
  {
    date: "2024-01-08",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 63,
    daily_budget: 70,
    spend: 92,
    impressions: 35,
    sessions_with_click: 238,
    sessions_with_click_product_0: 52,
    sessions_with_click_product_1: 29,
    sessions_with_click_product_2: 36,
    sessions_with_click_product_3: 45,
  },
  {
    date: "2024-01-09",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 48,
    daily_budget: 87,
    spend: 67,
    impressions: 48,
    sessions_with_click: 208,
    sessions_with_click_product_0: 108,
    sessions_with_click_product_1: 72,
    sessions_with_click_product_2: 19,
    sessions_with_click_product_3: 35,
  },
  {
    date: "2024-01-10",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 47,
    daily_budget: 79,
    spend: 35,
    impressions: 73,
    sessions_with_click: 97,
    sessions_with_click_product_0: 78,
    sessions_with_click_product_1: 36,
    sessions_with_click_product_2: 29,
    sessions_with_click_product_3: 39,
  },
  {
    date: "2024-01-11",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 104,
    daily_budget: 12,
    spend: 11,
    impressions: 103,
    sessions_with_click: 115,
    sessions_with_click_product_0: 35,
    sessions_with_click_product_1: 28,
    sessions_with_click_product_2: 33,
    sessions_with_click_product_3: 40,
  },
  {
    date: "2024-01-12",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 80,
    daily_budget: 31,
    spend: 56,
    impressions: 89,
    sessions_with_click: 128,
    sessions_with_click_product_0: 102,
    sessions_with_click_product_1: 15,
    sessions_with_click_product_2: 27,
    sessions_with_click_product_3: 38,
  },
  {
    date: "2024-01-13",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 67,
    daily_budget: 16,
    spend: 41,
    impressions: 110,
    sessions_with_click: 81,
    sessions_with_click_product_0: 17,
    sessions_with_click_product_1: 59,
    sessions_with_click_product_2: 33,
    sessions_with_click_product_3: 24,
  },
  {
    date: "2024-01-14",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 77,
    daily_budget: 34,
    spend: 108,
    impressions: 88,
    sessions_with_click: 182,
    sessions_with_click_product_0: 45,
    sessions_with_click_product_1: 20,
    sessions_with_click_product_2: 30,
    sessions_with_click_product_3: 23,
  },
  {
    date: "2024-01-15",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 61,
    daily_budget: 72,
    spend: 106,
    impressions: 74,
    sessions_with_click: 11,
    sessions_with_click_product_0: 42,
    sessions_with_click_product_1: 33,
    sessions_with_click_product_2: 27,
    sessions_with_click_product_3: 11,
  },
  {
    date: "2024-01-16",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 54,
    daily_budget: 82,
    spend: 54,
    impressions: 119,
    sessions_with_click: 218,
    sessions_with_click_product_0: 49,
    sessions_with_click_product_1: 78,
    sessions_with_click_product_2: 38,
    sessions_with_click_product_3: 42,
  },
  {
    date: "2024-01-17",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 51,
    daily_budget: 98,
    spend: 92,
    impressions: 118,
    sessions_with_click: 32,
    sessions_with_click_product_0: 103,
    sessions_with_click_product_1: 24,
    sessions_with_click_product_2: 19,
    sessions_with_click_product_3: 18,
  },
  {
    date: "2024-01-18",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 45,
    daily_budget: 50,
    spend: 87,
    impressions: 119,
    sessions_with_click: 54,
    sessions_with_click_product_0: 35,
    sessions_with_click_product_1: 56,
    sessions_with_click_product_2: 17,
    sessions_with_click_product_3: 39,
  },
  {
    date: "2024-01-19",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 20,
    daily_budget: 95,
    spend: 96,
    impressions: 113,
    sessions_with_click: 59,
    sessions_with_click_product_0: 52,
    sessions_with_click_product_1: 70,
    sessions_with_click_product_2: 32,
    sessions_with_click_product_3: 43,
  },
  {
    date: "2024-01-20",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 21,
    daily_budget: 87,
    spend: 82,
    impressions: 40,
    sessions_with_click: 77,
    sessions_with_click_product_0: 62,
    sessions_with_click_product_1: 58,
    sessions_with_click_product_2: 12,
    sessions_with_click_product_3: 47,
  },
  {
    date: "2024-01-21",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 89,
    daily_budget: 11,
    spend: 100,
    impressions: 43,
    sessions_with_click: 257,
    sessions_with_click_product_0: 53,
    sessions_with_click_product_1: 22,
    sessions_with_click_product_2: 26,
    sessions_with_click_product_3: 17,
  },
  {
    date: "2024-01-22",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 20,
    daily_budget: 33,
    spend: 49,
    impressions: 35,
    sessions_with_click: 88,
    sessions_with_click_product_0: 24,
    sessions_with_click_product_1: 55,
    sessions_with_click_product_2: 28,
    sessions_with_click_product_3: 47,
  },
  {
    date: "2024-01-23",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 81,
    daily_budget: 102,
    spend: 15,
    impressions: 31,
    sessions_with_click: 231,
    sessions_with_click_product_0: 33,
    sessions_with_click_product_1: 60,
    sessions_with_click_product_2: 28,
    sessions_with_click_product_3: 46,
  },
  {
    date: "2024-01-24",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 73,
    daily_budget: 35,
    spend: 47,
    impressions: 24,
    sessions_with_click: 176,
    sessions_with_click_product_0: 47,
    sessions_with_click_product_1: 28,
    sessions_with_click_product_2: 22,
    sessions_with_click_product_3: 13,
  },
  {
    date: "2024-01-25",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 97,
    daily_budget: 55,
    spend: 74,
    impressions: 94,
    sessions_with_click: 153,
    sessions_with_click_product_0: 50,
    sessions_with_click_product_1: 21,
    sessions_with_click_product_2: 34,
    sessions_with_click_product_3: 23,
  },
  {
    date: "2024-01-26",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 91,
    daily_budget: 99,
    spend: 52,
    impressions: 75,
    sessions_with_click: 157,
    sessions_with_click_product_0: 14,
    sessions_with_click_product_1: 80,
    sessions_with_click_product_2: 32,
    sessions_with_click_product_3: 16,
  },
  {
    date: "2024-01-27",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 79,
    daily_budget: 31,
    spend: 66,
    impressions: 36,
    sessions_with_click: 48,
    sessions_with_click_product_0: 68,
    sessions_with_click_product_1: 67,
    sessions_with_click_product_2: 30,
    sessions_with_click_product_3: 33,
  },
  {
    date: "2024-01-28",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 40,
    daily_budget: 97,
    spend: 14,
    impressions: 71,
    sessions_with_click: 189,
    sessions_with_click_product_0: 68,
    sessions_with_click_product_1: 47,
    sessions_with_click_product_2: 22,
    sessions_with_click_product_3: 19,
  },
  {
    date: "2024-01-29",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 93,
    daily_budget: 33,
    spend: 27,
    impressions: 35,
    sessions_with_click: 233,
    sessions_with_click_product_0: 46,
    sessions_with_click_product_1: 42,
    sessions_with_click_product_2: 26,
    sessions_with_click_product_3: 34,
  },
  {
    date: "2024-01-30",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 34,
    daily_budget: 34,
    spend: 70,
    impressions: 88,
    sessions_with_click: 65,
    sessions_with_click_product_0: 82,
    sessions_with_click_product_1: 29,
    sessions_with_click_product_2: 19,
    sessions_with_click_product_3: 30,
  },
  {
    date: "2024-01-31",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 103,
    daily_budget: 82,
    spend: 55,
    impressions: 103,
    sessions_with_click: 165,
    sessions_with_click_product_0: 84,
    sessions_with_click_product_1: 14,
    sessions_with_click_product_2: 16,
    sessions_with_click_product_3: 12,
  },
  {
    date: "2024-02-01",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 78,
    daily_budget: 38,
    spend: 27,
    impressions: 35,
    sessions_with_click: 188,
    sessions_with_click_product_0: 28,
    sessions_with_click_product_1: 33,
    sessions_with_click_product_2: 24,
    sessions_with_click_product_3: 25,
  },
  {
    date: "2024-02-02",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 93,
    daily_budget: 38,
    spend: 44,
    impressions: 39,
    sessions_with_click: 259,
    sessions_with_click_product_0: 76,
    sessions_with_click_product_1: 72,
    sessions_with_click_product_2: 13,
    sessions_with_click_product_3: 31,
  },
  {
    date: "2024-02-03",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 40,
    daily_budget: 20,
    spend: 10,
    impressions: 110,
    sessions_with_click: 233,
    sessions_with_click_product_0: 94,
    sessions_with_click_product_1: 46,
    sessions_with_click_product_2: 13,
    sessions_with_click_product_3: 12,
  },
  {
    date: "2024-02-04",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 68,
    daily_budget: 23,
    spend: 104,
    impressions: 63,
    sessions_with_click: 86,
    sessions_with_click_product_0: 44,
    sessions_with_click_product_1: 66,
    sessions_with_click_product_2: 14,
    sessions_with_click_product_3: 27,
  },
  {
    date: "2024-02-05",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 69,
    daily_budget: 50,
    spend: 35,
    impressions: 62,
    sessions_with_click: 212,
    sessions_with_click_product_0: 48,
    sessions_with_click_product_1: 50,
    sessions_with_click_product_2: 14,
    sessions_with_click_product_3: 38,
  },
  {
    date: "2024-02-06",
    campaign_name: "Hsoihdg",
    campaign_id: "4",
    ad_type: "REWARDED",
    cpm: 69,
    daily_budget: 39,
    spend: 52,
    impressions: 117,
    sessions_with_click: 143,
    sessions_with_click_product_0: 104,
    sessions_with_click_product_1: 67,
    sessions_with_click_product_2: 23,
    sessions_with_click_product_3: 24,
  },
  {
    date: "2023-12-27",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 12,
    daily_budget: 97,
    spend: 27,
    impressions: 51,
    sessions_with_click: 82,
    sessions_with_click_product_0: 37,
    sessions_with_click_product_1: 89,
    sessions_with_click_product_2: 20,
    sessions_with_click_product_3: 14,
  },
  {
    date: "2023-12-28",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 13,
    daily_budget: 94,
    spend: 75,
    impressions: 57,
    sessions_with_click: 154,
    sessions_with_click_product_0: 69,
    sessions_with_click_product_1: 43,
    sessions_with_click_product_2: 29,
    sessions_with_click_product_3: 12,
  },
  {
    date: "2023-12-29",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 21,
    daily_budget: 18,
    spend: 34,
    impressions: 45,
    sessions_with_click: 115,
    sessions_with_click_product_0: 46,
    sessions_with_click_product_1: 33,
    sessions_with_click_product_2: 31,
    sessions_with_click_product_3: 42,
  },
  {
    date: "2023-12-30",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 15,
    daily_budget: 29,
    spend: 87,
    impressions: 92,
    sessions_with_click: 56,
    sessions_with_click_product_0: 25,
    sessions_with_click_product_1: 39,
    sessions_with_click_product_2: 27,
    sessions_with_click_product_3: 44,
  },
  {
    date: "2023-12-31",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 98,
    daily_budget: 48,
    spend: 18,
    impressions: 43,
    sessions_with_click: 222,
    sessions_with_click_product_0: 108,
    sessions_with_click_product_1: 49,
    sessions_with_click_product_2: 24,
    sessions_with_click_product_3: 17,
  },
  {
    date: "2024-01-01",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 74,
    daily_budget: 30,
    spend: 55,
    impressions: 82,
    sessions_with_click: 78,
    sessions_with_click_product_0: 52,
    sessions_with_click_product_1: 73,
    sessions_with_click_product_2: 10,
    sessions_with_click_product_3: 26,
  },
  {
    date: "2024-01-02",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 24,
    daily_budget: 93,
    spend: 32,
    impressions: 99,
    sessions_with_click: 146,
    sessions_with_click_product_0: 95,
    sessions_with_click_product_1: 62,
    sessions_with_click_product_2: 21,
    sessions_with_click_product_3: 13,
  },
  {
    date: "2024-01-03",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 83,
    daily_budget: 57,
    spend: 27,
    impressions: 103,
    sessions_with_click: 79,
    sessions_with_click_product_0: 37,
    sessions_with_click_product_1: 14,
    sessions_with_click_product_2: 36,
    sessions_with_click_product_3: 14,
  },
  {
    date: "2024-01-04",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 25,
    daily_budget: 60,
    spend: 82,
    impressions: 77,
    sessions_with_click: 162,
    sessions_with_click_product_0: 35,
    sessions_with_click_product_1: 27,
    sessions_with_click_product_2: 12,
    sessions_with_click_product_3: 30,
  },
  {
    date: "2024-01-05",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 83,
    daily_budget: 45,
    spend: 49,
    impressions: 32,
    sessions_with_click: 78,
    sessions_with_click_product_0: 99,
    sessions_with_click_product_1: 65,
    sessions_with_click_product_2: 31,
    sessions_with_click_product_3: 41,
  },
  {
    date: "2024-01-06",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 45,
    daily_budget: 106,
    spend: 21,
    impressions: 89,
    sessions_with_click: 56,
    sessions_with_click_product_0: 41,
    sessions_with_click_product_1: 69,
    sessions_with_click_product_2: 23,
    sessions_with_click_product_3: 19,
  },
  {
    date: "2024-01-07",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 17,
    daily_budget: 91,
    spend: 92,
    impressions: 54,
    sessions_with_click: 117,
    sessions_with_click_product_0: 17,
    sessions_with_click_product_1: 15,
    sessions_with_click_product_2: 28,
    sessions_with_click_product_3: 10,
  },
  {
    date: "2024-01-08",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 72,
    daily_budget: 71,
    spend: 63,
    impressions: 39,
    sessions_with_click: 112,
    sessions_with_click_product_0: 52,
    sessions_with_click_product_1: 57,
    sessions_with_click_product_2: 16,
    sessions_with_click_product_3: 16,
  },
  {
    date: "2024-01-09",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 38,
    daily_budget: 31,
    spend: 74,
    impressions: 33,
    sessions_with_click: 120,
    sessions_with_click_product_0: 43,
    sessions_with_click_product_1: 28,
    sessions_with_click_product_2: 30,
    sessions_with_click_product_3: 30,
  },
  {
    date: "2024-01-10",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 85,
    daily_budget: 55,
    spend: 92,
    impressions: 80,
    sessions_with_click: 198,
    sessions_with_click_product_0: 84,
    sessions_with_click_product_1: 43,
    sessions_with_click_product_2: 16,
    sessions_with_click_product_3: 49,
  },
  {
    date: "2024-01-11",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 106,
    daily_budget: 21,
    spend: 17,
    impressions: 88,
    sessions_with_click: 196,
    sessions_with_click_product_0: 99,
    sessions_with_click_product_1: 73,
    sessions_with_click_product_2: 39,
    sessions_with_click_product_3: 36,
  },
  {
    date: "2024-01-12",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 86,
    daily_budget: 78,
    spend: 82,
    impressions: 86,
    sessions_with_click: 163,
    sessions_with_click_product_0: 64,
    sessions_with_click_product_1: 73,
    sessions_with_click_product_2: 24,
    sessions_with_click_product_3: 20,
  },
  {
    date: "2024-01-13",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 60,
    daily_budget: 42,
    spend: 10,
    impressions: 117,
    sessions_with_click: 186,
    sessions_with_click_product_0: 97,
    sessions_with_click_product_1: 75,
    sessions_with_click_product_2: 33,
    sessions_with_click_product_3: 49,
  },
  {
    date: "2024-01-14",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 33,
    daily_budget: 16,
    spend: 33,
    impressions: 40,
    sessions_with_click: 235,
    sessions_with_click_product_0: 73,
    sessions_with_click_product_1: 39,
    sessions_with_click_product_2: 26,
    sessions_with_click_product_3: 32,
  },
  {
    date: "2024-01-15",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 61,
    daily_budget: 82,
    spend: 83,
    impressions: 98,
    sessions_with_click: 229,
    sessions_with_click_product_0: 17,
    sessions_with_click_product_1: 70,
    sessions_with_click_product_2: 34,
    sessions_with_click_product_3: 10,
  },
  {
    date: "2024-01-16",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 86,
    daily_budget: 55,
    spend: 63,
    impressions: 110,
    sessions_with_click: 184,
    sessions_with_click_product_0: 97,
    sessions_with_click_product_1: 65,
    sessions_with_click_product_2: 22,
    sessions_with_click_product_3: 18,
  },
  {
    date: "2024-01-17",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 54,
    daily_budget: 82,
    spend: 83,
    impressions: 77,
    sessions_with_click: 11,
    sessions_with_click_product_0: 28,
    sessions_with_click_product_1: 63,
    sessions_with_click_product_2: 28,
    sessions_with_click_product_3: 38,
  },
  {
    date: "2024-01-18",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 94,
    daily_budget: 28,
    spend: 67,
    impressions: 66,
    sessions_with_click: 93,
    sessions_with_click_product_0: 25,
    sessions_with_click_product_1: 15,
    sessions_with_click_product_2: 12,
    sessions_with_click_product_3: 37,
  },
  {
    date: "2024-01-19",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 62,
    daily_budget: 64,
    spend: 40,
    impressions: 39,
    sessions_with_click: 22,
    sessions_with_click_product_0: 99,
    sessions_with_click_product_1: 27,
    sessions_with_click_product_2: 22,
    sessions_with_click_product_3: 49,
  },
  {
    date: "2024-01-20",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 27,
    daily_budget: 48,
    spend: 25,
    impressions: 28,
    sessions_with_click: 150,
    sessions_with_click_product_0: 52,
    sessions_with_click_product_1: 71,
    sessions_with_click_product_2: 10,
    sessions_with_click_product_3: 44,
  },
  {
    date: "2024-01-21",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 64,
    daily_budget: 96,
    spend: 14,
    impressions: 75,
    sessions_with_click: 245,
    sessions_with_click_product_0: 23,
    sessions_with_click_product_1: 70,
    sessions_with_click_product_2: 10,
    sessions_with_click_product_3: 23,
  },
  {
    date: "2024-01-22",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 51,
    daily_budget: 102,
    spend: 88,
    impressions: 70,
    sessions_with_click: 105,
    sessions_with_click_product_0: 35,
    sessions_with_click_product_1: 53,
    sessions_with_click_product_2: 29,
    sessions_with_click_product_3: 44,
  },
  {
    date: "2024-01-23",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 34,
    daily_budget: 40,
    spend: 42,
    impressions: 75,
    sessions_with_click: 48,
    sessions_with_click_product_0: 76,
    sessions_with_click_product_1: 59,
    sessions_with_click_product_2: 37,
    sessions_with_click_product_3: 28,
  },
  {
    date: "2024-01-24",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 53,
    daily_budget: 12,
    spend: 71,
    impressions: 102,
    sessions_with_click: 68,
    sessions_with_click_product_0: 21,
    sessions_with_click_product_1: 33,
    sessions_with_click_product_2: 14,
    sessions_with_click_product_3: 23,
  },
  {
    date: "2024-01-25",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 29,
    daily_budget: 107,
    spend: 104,
    impressions: 26,
    sessions_with_click: 251,
    sessions_with_click_product_0: 107,
    sessions_with_click_product_1: 39,
    sessions_with_click_product_2: 26,
    sessions_with_click_product_3: 35,
  },
  {
    date: "2024-01-26",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 46,
    daily_budget: 32,
    spend: 42,
    impressions: 97,
    sessions_with_click: 165,
    sessions_with_click_product_0: 32,
    sessions_with_click_product_1: 45,
    sessions_with_click_product_2: 11,
    sessions_with_click_product_3: 20,
  },
  {
    date: "2024-01-27",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 69,
    daily_budget: 108,
    spend: 15,
    impressions: 48,
    sessions_with_click: 219,
    sessions_with_click_product_0: 36,
    sessions_with_click_product_1: 29,
    sessions_with_click_product_2: 17,
    sessions_with_click_product_3: 48,
  },
  {
    date: "2024-01-28",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 84,
    daily_budget: 82,
    spend: 33,
    impressions: 85,
    sessions_with_click: 131,
    sessions_with_click_product_0: 45,
    sessions_with_click_product_1: 80,
    sessions_with_click_product_2: 13,
    sessions_with_click_product_3: 49,
  },
  {
    date: "2024-01-29",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 109,
    daily_budget: 20,
    spend: 68,
    impressions: 61,
    sessions_with_click: 49,
    sessions_with_click_product_0: 75,
    sessions_with_click_product_1: 65,
    sessions_with_click_product_2: 33,
    sessions_with_click_product_3: 12,
  },
  {
    date: "2024-01-30",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 11,
    daily_budget: 77,
    spend: 41,
    impressions: 97,
    sessions_with_click: 198,
    sessions_with_click_product_0: 20,
    sessions_with_click_product_1: 56,
    sessions_with_click_product_2: 10,
    sessions_with_click_product_3: 34,
  },
  {
    date: "2024-01-31",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 61,
    daily_budget: 81,
    spend: 48,
    impressions: 54,
    sessions_with_click: 39,
    sessions_with_click_product_0: 86,
    sessions_with_click_product_1: 79,
    sessions_with_click_product_2: 39,
    sessions_with_click_product_3: 15,
  },
  {
    date: "2024-02-01",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 41,
    daily_budget: 55,
    spend: 48,
    impressions: 51,
    sessions_with_click: 57,
    sessions_with_click_product_0: 98,
    sessions_with_click_product_1: 28,
    sessions_with_click_product_2: 14,
    sessions_with_click_product_3: 45,
  },
  {
    date: "2024-02-02",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 69,
    daily_budget: 37,
    spend: 75,
    impressions: 59,
    sessions_with_click: 216,
    sessions_with_click_product_0: 88,
    sessions_with_click_product_1: 10,
    sessions_with_click_product_2: 25,
    sessions_with_click_product_3: 19,
  },
  {
    date: "2024-02-03",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 99,
    daily_budget: 102,
    spend: 26,
    impressions: 88,
    sessions_with_click: 18,
    sessions_with_click_product_0: 59,
    sessions_with_click_product_1: 82,
    sessions_with_click_product_2: 22,
    sessions_with_click_product_3: 34,
  },
  {
    date: "2024-02-04",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 78,
    daily_budget: 42,
    spend: 104,
    impressions: 116,
    sessions_with_click: 229,
    sessions_with_click_product_0: 103,
    sessions_with_click_product_1: 35,
    sessions_with_click_product_2: 19,
    sessions_with_click_product_3: 40,
  },
  {
    date: "2024-02-05",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 82,
    daily_budget: 17,
    spend: 75,
    impressions: 105,
    sessions_with_click: 13,
    sessions_with_click_product_0: 18,
    sessions_with_click_product_1: 48,
    sessions_with_click_product_2: 21,
    sessions_with_click_product_3: 43,
  },
  {
    date: "2024-02-06",
    campaign_name: "Cspidhg",
    campaign_id: "3",
    ad_type: "REWARDED",
    cpm: 63,
    daily_budget: 106,
    spend: 54,
    impressions: 24,
    sessions_with_click: 199,
    sessions_with_click_product_0: 92,
    sessions_with_click_product_1: 35,
    sessions_with_click_product_2: 31,
    sessions_with_click_product_3: 39,
  },
  {
    date: "2023-12-27",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 79,
    daily_budget: 20,
    spend: 79,
    impressions: 46,
    sessions_with_click: 199,
    sessions_with_click_product_0: 41,
    sessions_with_click_product_1: 19,
    sessions_with_click_product_2: 22,
    sessions_with_click_product_3: 36,
  },
  {
    date: "2023-12-28",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 85,
    daily_budget: 72,
    spend: 60,
    impressions: 68,
    sessions_with_click: 62,
    sessions_with_click_product_0: 44,
    sessions_with_click_product_1: 37,
    sessions_with_click_product_2: 30,
    sessions_with_click_product_3: 48,
  },
  {
    date: "2023-12-29",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 25,
    daily_budget: 41,
    spend: 39,
    impressions: 80,
    sessions_with_click: 93,
    sessions_with_click_product_0: 37,
    sessions_with_click_product_1: 66,
    sessions_with_click_product_2: 38,
    sessions_with_click_product_3: 43,
  },
  {
    date: "2023-12-30",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 42,
    daily_budget: 62,
    spend: 90,
    impressions: 107,
    sessions_with_click: 41,
    sessions_with_click_product_0: 45,
    sessions_with_click_product_1: 39,
    sessions_with_click_product_2: 28,
    sessions_with_click_product_3: 24,
  },
  {
    date: "2023-12-31",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 52,
    daily_budget: 64,
    spend: 10,
    impressions: 115,
    sessions_with_click: 192,
    sessions_with_click_product_0: 22,
    sessions_with_click_product_1: 57,
    sessions_with_click_product_2: 29,
    sessions_with_click_product_3: 39,
  },
  {
    date: "2024-01-01",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 50,
    daily_budget: 76,
    spend: 94,
    impressions: 74,
    sessions_with_click: 130,
    sessions_with_click_product_0: 91,
    sessions_with_click_product_1: 79,
    sessions_with_click_product_2: 34,
    sessions_with_click_product_3: 13,
  },
  {
    date: "2024-01-02",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 68,
    daily_budget: 82,
    spend: 26,
    impressions: 67,
    sessions_with_click: 137,
    sessions_with_click_product_0: 44,
    sessions_with_click_product_1: 37,
    sessions_with_click_product_2: 14,
    sessions_with_click_product_3: 39,
  },
  {
    date: "2024-01-03",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 14,
    daily_budget: 50,
    spend: 87,
    impressions: 72,
    sessions_with_click: 244,
    sessions_with_click_product_0: 96,
    sessions_with_click_product_1: 56,
    sessions_with_click_product_2: 19,
    sessions_with_click_product_3: 40,
  },
  {
    date: "2024-01-04",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 25,
    daily_budget: 84,
    spend: 56,
    impressions: 54,
    sessions_with_click: 36,
    sessions_with_click_product_0: 107,
    sessions_with_click_product_1: 63,
    sessions_with_click_product_2: 11,
    sessions_with_click_product_3: 13,
  },
  {
    date: "2024-01-05",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 22,
    daily_budget: 10,
    spend: 32,
    impressions: 99,
    sessions_with_click: 219,
    sessions_with_click_product_0: 73,
    sessions_with_click_product_1: 20,
    sessions_with_click_product_2: 39,
    sessions_with_click_product_3: 17,
  },
  {
    date: "2024-01-06",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 86,
    daily_budget: 46,
    spend: 99,
    impressions: 56,
    sessions_with_click: 19,
    sessions_with_click_product_0: 66,
    sessions_with_click_product_1: 81,
    sessions_with_click_product_2: 34,
    sessions_with_click_product_3: 24,
  },
  {
    date: "2024-01-07",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 23,
    daily_budget: 55,
    spend: 81,
    impressions: 111,
    sessions_with_click: 10,
    sessions_with_click_product_0: 16,
    sessions_with_click_product_1: 34,
    sessions_with_click_product_2: 22,
    sessions_with_click_product_3: 18,
  },
  {
    date: "2024-01-08",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 18,
    daily_budget: 44,
    spend: 11,
    impressions: 113,
    sessions_with_click: 246,
    sessions_with_click_product_0: 86,
    sessions_with_click_product_1: 21,
    sessions_with_click_product_2: 39,
    sessions_with_click_product_3: 41,
  },
  {
    date: "2024-01-09",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 62,
    daily_budget: 92,
    spend: 83,
    impressions: 72,
    sessions_with_click: 47,
    sessions_with_click_product_0: 71,
    sessions_with_click_product_1: 88,
    sessions_with_click_product_2: 17,
    sessions_with_click_product_3: 18,
  },
  {
    date: "2024-01-10",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 25,
    daily_budget: 19,
    spend: 27,
    impressions: 68,
    sessions_with_click: 200,
    sessions_with_click_product_0: 55,
    sessions_with_click_product_1: 36,
    sessions_with_click_product_2: 15,
    sessions_with_click_product_3: 49,
  },
  {
    date: "2024-01-11",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 93,
    daily_budget: 10,
    spend: 96,
    impressions: 32,
    sessions_with_click: 46,
    sessions_with_click_product_0: 21,
    sessions_with_click_product_1: 52,
    sessions_with_click_product_2: 31,
    sessions_with_click_product_3: 37,
  },
  {
    date: "2024-01-12",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 104,
    daily_budget: 85,
    spend: 47,
    impressions: 115,
    sessions_with_click: 229,
    sessions_with_click_product_0: 27,
    sessions_with_click_product_1: 42,
    sessions_with_click_product_2: 31,
    sessions_with_click_product_3: 39,
  },
  {
    date: "2024-01-13",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 29,
    daily_budget: 28,
    spend: 39,
    impressions: 45,
    sessions_with_click: 85,
    sessions_with_click_product_0: 45,
    sessions_with_click_product_1: 87,
    sessions_with_click_product_2: 19,
    sessions_with_click_product_3: 16,
  },
  {
    date: "2024-01-14",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 46,
    daily_budget: 95,
    spend: 46,
    impressions: 51,
    sessions_with_click: 50,
    sessions_with_click_product_0: 48,
    sessions_with_click_product_1: 47,
    sessions_with_click_product_2: 19,
    sessions_with_click_product_3: 34,
  },
  {
    date: "2024-01-15",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 39,
    daily_budget: 35,
    spend: 78,
    impressions: 69,
    sessions_with_click: 115,
    sessions_with_click_product_0: 101,
    sessions_with_click_product_1: 30,
    sessions_with_click_product_2: 22,
    sessions_with_click_product_3: 48,
  },
  {
    date: "2024-01-16",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 98,
    daily_budget: 51,
    spend: 67,
    impressions: 61,
    sessions_with_click: 145,
    sessions_with_click_product_0: 101,
    sessions_with_click_product_1: 41,
    sessions_with_click_product_2: 34,
    sessions_with_click_product_3: 21,
  },
  {
    date: "2024-01-17",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 30,
    daily_budget: 73,
    spend: 72,
    impressions: 81,
    sessions_with_click: 127,
    sessions_with_click_product_0: 58,
    sessions_with_click_product_1: 62,
    sessions_with_click_product_2: 10,
    sessions_with_click_product_3: 23,
  },
  {
    date: "2024-01-18",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 56,
    daily_budget: 103,
    spend: 105,
    impressions: 106,
    sessions_with_click: 113,
    sessions_with_click_product_0: 70,
    sessions_with_click_product_1: 15,
    sessions_with_click_product_2: 35,
    sessions_with_click_product_3: 32,
  },
  {
    date: "2024-01-19",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 33,
    daily_budget: 84,
    spend: 94,
    impressions: 60,
    sessions_with_click: 58,
    sessions_with_click_product_0: 63,
    sessions_with_click_product_1: 86,
    sessions_with_click_product_2: 20,
    sessions_with_click_product_3: 34,
  },
  {
    date: "2024-01-20",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 49,
    daily_budget: 53,
    spend: 75,
    impressions: 86,
    sessions_with_click: 56,
    sessions_with_click_product_0: 41,
    sessions_with_click_product_1: 14,
    sessions_with_click_product_2: 32,
    sessions_with_click_product_3: 11,
  },
  {
    date: "2024-01-21",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 86,
    daily_budget: 29,
    spend: 82,
    impressions: 45,
    sessions_with_click: 149,
    sessions_with_click_product_0: 72,
    sessions_with_click_product_1: 53,
    sessions_with_click_product_2: 13,
    sessions_with_click_product_3: 39,
  },
  {
    date: "2024-01-22",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 19,
    daily_budget: 20,
    spend: 68,
    impressions: 35,
    sessions_with_click: 62,
    sessions_with_click_product_0: 16,
    sessions_with_click_product_1: 45,
    sessions_with_click_product_2: 29,
    sessions_with_click_product_3: 25,
  },
  {
    date: "2024-01-23",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 39,
    daily_budget: 15,
    spend: 70,
    impressions: 116,
    sessions_with_click: 250,
    sessions_with_click_product_0: 88,
    sessions_with_click_product_1: 64,
    sessions_with_click_product_2: 19,
    sessions_with_click_product_3: 20,
  },
  {
    date: "2024-01-24",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 77,
    daily_budget: 26,
    spend: 26,
    impressions: 35,
    sessions_with_click: 101,
    sessions_with_click_product_0: 70,
    sessions_with_click_product_1: 72,
    sessions_with_click_product_2: 12,
    sessions_with_click_product_3: 19,
  },
  {
    date: "2024-01-25",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 85,
    daily_budget: 69,
    spend: 22,
    impressions: 117,
    sessions_with_click: 158,
    sessions_with_click_product_0: 63,
    sessions_with_click_product_1: 58,
    sessions_with_click_product_2: 13,
    sessions_with_click_product_3: 19,
  },
  {
    date: "2024-01-26",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 60,
    daily_budget: 63,
    spend: 108,
    impressions: 99,
    sessions_with_click: 132,
    sessions_with_click_product_0: 93,
    sessions_with_click_product_1: 38,
    sessions_with_click_product_2: 32,
    sessions_with_click_product_3: 23,
  },
  {
    date: "2024-01-27",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 77,
    daily_budget: 67,
    spend: 16,
    impressions: 111,
    sessions_with_click: 59,
    sessions_with_click_product_0: 28,
    sessions_with_click_product_1: 88,
    sessions_with_click_product_2: 35,
    sessions_with_click_product_3: 39,
  },
  {
    date: "2024-01-28",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 29,
    daily_budget: 39,
    spend: 86,
    impressions: 56,
    sessions_with_click: 94,
    sessions_with_click_product_0: 82,
    sessions_with_click_product_1: 68,
    sessions_with_click_product_2: 19,
    sessions_with_click_product_3: 31,
  },
  {
    date: "2024-01-29",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 28,
    daily_budget: 38,
    spend: 103,
    impressions: 38,
    sessions_with_click: 22,
    sessions_with_click_product_0: 103,
    sessions_with_click_product_1: 78,
    sessions_with_click_product_2: 30,
    sessions_with_click_product_3: 14,
  },
  {
    date: "2024-01-30",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 89,
    daily_budget: 83,
    spend: 19,
    impressions: 30,
    sessions_with_click: 160,
    sessions_with_click_product_0: 106,
    sessions_with_click_product_1: 48,
    sessions_with_click_product_2: 19,
    sessions_with_click_product_3: 44,
  },
  {
    date: "2024-01-31",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 67,
    daily_budget: 33,
    spend: 58,
    impressions: 33,
    sessions_with_click: 192,
    sessions_with_click_product_0: 92,
    sessions_with_click_product_1: 75,
    sessions_with_click_product_2: 24,
    sessions_with_click_product_3: 20,
  },
  {
    date: "2024-02-01",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 63,
    daily_budget: 54,
    spend: 51,
    impressions: 42,
    sessions_with_click: 29,
    sessions_with_click_product_0: 98,
    sessions_with_click_product_1: 78,
    sessions_with_click_product_2: 14,
    sessions_with_click_product_3: 11,
  },
  {
    date: "2024-02-02",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 81,
    daily_budget: 64,
    spend: 66,
    impressions: 112,
    sessions_with_click: 191,
    sessions_with_click_product_0: 68,
    sessions_with_click_product_1: 50,
    sessions_with_click_product_2: 35,
    sessions_with_click_product_3: 15,
  },
  {
    date: "2024-02-03",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 15,
    daily_budget: 62,
    spend: 54,
    impressions: 84,
    sessions_with_click: 217,
    sessions_with_click_product_0: 92,
    sessions_with_click_product_1: 39,
    sessions_with_click_product_2: 26,
    sessions_with_click_product_3: 19,
  },
  {
    date: "2024-02-04",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 68,
    daily_budget: 76,
    spend: 46,
    impressions: 49,
    sessions_with_click: 28,
    sessions_with_click_product_0: 55,
    sessions_with_click_product_1: 64,
    sessions_with_click_product_2: 36,
    sessions_with_click_product_3: 42,
  },
  {
    date: "2024-02-05",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 45,
    daily_budget: 85,
    spend: 11,
    impressions: 26,
    sessions_with_click: 194,
    sessions_with_click_product_0: 82,
    sessions_with_click_product_1: 58,
    sessions_with_click_product_2: 14,
    sessions_with_click_product_3: 44,
  },
  {
    date: "2024-02-06",
    campaign_name: "Faokhdg",
    campaign_id: "8",
    ad_type: "INTERSTITIAL",
    cpm: 68,
    daily_budget: 99,
    spend: 19,
    impressions: 80,
    sessions_with_click: 170,
    sessions_with_click_product_0: 14,
    sessions_with_click_product_1: 86,
    sessions_with_click_product_2: 22,
    sessions_with_click_product_3: 28,
  },
  {
    date: "2023-12-27",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 74,
    daily_budget: 92,
    spend: 40,
    impressions: 60,
    sessions_with_click: 119,
    sessions_with_click_product_0: 63,
    sessions_with_click_product_1: 63,
    sessions_with_click_product_2: 30,
    sessions_with_click_product_3: 29,
  },
  {
    date: "2023-12-28",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 21,
    daily_budget: 26,
    spend: 54,
    impressions: 90,
    sessions_with_click: 163,
    sessions_with_click_product_0: 22,
    sessions_with_click_product_1: 72,
    sessions_with_click_product_2: 24,
    sessions_with_click_product_3: 42,
  },
  {
    date: "2023-12-29",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 25,
    daily_budget: 99,
    spend: 107,
    impressions: 113,
    sessions_with_click: 121,
    sessions_with_click_product_0: 58,
    sessions_with_click_product_1: 82,
    sessions_with_click_product_2: 24,
    sessions_with_click_product_3: 35,
  },
  {
    date: "2023-12-30",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 75,
    daily_budget: 104,
    spend: 95,
    impressions: 70,
    sessions_with_click: 104,
    sessions_with_click_product_0: 43,
    sessions_with_click_product_1: 42,
    sessions_with_click_product_2: 25,
    sessions_with_click_product_3: 13,
  },
  {
    date: "2023-12-31",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 34,
    daily_budget: 41,
    spend: 79,
    impressions: 108,
    sessions_with_click: 249,
    sessions_with_click_product_0: 36,
    sessions_with_click_product_1: 76,
    sessions_with_click_product_2: 23,
    sessions_with_click_product_3: 41,
  },
  {
    date: "2024-01-01",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 82,
    daily_budget: 31,
    spend: 103,
    impressions: 34,
    sessions_with_click: 96,
    sessions_with_click_product_0: 75,
    sessions_with_click_product_1: 34,
    sessions_with_click_product_2: 37,
    sessions_with_click_product_3: 25,
  },
  {
    date: "2024-01-02",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 104,
    daily_budget: 55,
    spend: 55,
    impressions: 59,
    sessions_with_click: 151,
    sessions_with_click_product_0: 45,
    sessions_with_click_product_1: 45,
    sessions_with_click_product_2: 27,
    sessions_with_click_product_3: 41,
  },
  {
    date: "2024-01-03",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 46,
    daily_budget: 38,
    spend: 11,
    impressions: 66,
    sessions_with_click: 159,
    sessions_with_click_product_0: 15,
    sessions_with_click_product_1: 22,
    sessions_with_click_product_2: 22,
    sessions_with_click_product_3: 18,
  },
  {
    date: "2024-01-04",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 60,
    daily_budget: 27,
    spend: 16,
    impressions: 78,
    sessions_with_click: 247,
    sessions_with_click_product_0: 30,
    sessions_with_click_product_1: 78,
    sessions_with_click_product_2: 24,
    sessions_with_click_product_3: 44,
  },
  {
    date: "2024-01-05",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 98,
    daily_budget: 60,
    spend: 80,
    impressions: 102,
    sessions_with_click: 52,
    sessions_with_click_product_0: 67,
    sessions_with_click_product_1: 10,
    sessions_with_click_product_2: 13,
    sessions_with_click_product_3: 23,
  },
  {
    date: "2024-01-06",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 102,
    daily_budget: 33,
    spend: 94,
    impressions: 31,
    sessions_with_click: 51,
    sessions_with_click_product_0: 45,
    sessions_with_click_product_1: 50,
    sessions_with_click_product_2: 21,
    sessions_with_click_product_3: 44,
  },
  {
    date: "2024-01-07",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 51,
    daily_budget: 45,
    spend: 62,
    impressions: 52,
    sessions_with_click: 232,
    sessions_with_click_product_0: 16,
    sessions_with_click_product_1: 81,
    sessions_with_click_product_2: 14,
    sessions_with_click_product_3: 22,
  },
  {
    date: "2024-01-08",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 94,
    daily_budget: 16,
    spend: 103,
    impressions: 94,
    sessions_with_click: 229,
    sessions_with_click_product_0: 23,
    sessions_with_click_product_1: 31,
    sessions_with_click_product_2: 35,
    sessions_with_click_product_3: 15,
  },
  {
    date: "2024-01-09",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 84,
    daily_budget: 28,
    spend: 33,
    impressions: 20,
    sessions_with_click: 30,
    sessions_with_click_product_0: 26,
    sessions_with_click_product_1: 41,
    sessions_with_click_product_2: 19,
    sessions_with_click_product_3: 46,
  },
  {
    date: "2024-01-10",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 17,
    daily_budget: 28,
    spend: 109,
    impressions: 82,
    sessions_with_click: 184,
    sessions_with_click_product_0: 24,
    sessions_with_click_product_1: 35,
    sessions_with_click_product_2: 18,
    sessions_with_click_product_3: 38,
  },
  {
    date: "2024-01-11",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 20,
    daily_budget: 44,
    spend: 62,
    impressions: 53,
    sessions_with_click: 80,
    sessions_with_click_product_0: 70,
    sessions_with_click_product_1: 27,
    sessions_with_click_product_2: 20,
    sessions_with_click_product_3: 13,
  },
  {
    date: "2024-01-12",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 102,
    daily_budget: 89,
    spend: 93,
    impressions: 46,
    sessions_with_click: 162,
    sessions_with_click_product_0: 15,
    sessions_with_click_product_1: 22,
    sessions_with_click_product_2: 24,
    sessions_with_click_product_3: 29,
  },
  {
    date: "2024-01-13",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 21,
    daily_budget: 53,
    spend: 78,
    impressions: 90,
    sessions_with_click: 12,
    sessions_with_click_product_0: 86,
    sessions_with_click_product_1: 31,
    sessions_with_click_product_2: 31,
    sessions_with_click_product_3: 31,
  },
  {
    date: "2024-01-14",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 21,
    daily_budget: 105,
    spend: 37,
    impressions: 98,
    sessions_with_click: 208,
    sessions_with_click_product_0: 107,
    sessions_with_click_product_1: 65,
    sessions_with_click_product_2: 13,
    sessions_with_click_product_3: 36,
  },
  {
    date: "2024-01-15",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 107,
    daily_budget: 101,
    spend: 91,
    impressions: 32,
    sessions_with_click: 46,
    sessions_with_click_product_0: 65,
    sessions_with_click_product_1: 77,
    sessions_with_click_product_2: 11,
    sessions_with_click_product_3: 24,
  },
  {
    date: "2024-01-16",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 101,
    daily_budget: 51,
    spend: 74,
    impressions: 80,
    sessions_with_click: 159,
    sessions_with_click_product_0: 104,
    sessions_with_click_product_1: 79,
    sessions_with_click_product_2: 13,
    sessions_with_click_product_3: 14,
  },
  {
    date: "2024-01-17",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 34,
    daily_budget: 106,
    spend: 76,
    impressions: 114,
    sessions_with_click: 119,
    sessions_with_click_product_0: 36,
    sessions_with_click_product_1: 21,
    sessions_with_click_product_2: 37,
    sessions_with_click_product_3: 11,
  },
  {
    date: "2024-01-18",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 78,
    daily_budget: 22,
    spend: 17,
    impressions: 76,
    sessions_with_click: 15,
    sessions_with_click_product_0: 10,
    sessions_with_click_product_1: 82,
    sessions_with_click_product_2: 39,
    sessions_with_click_product_3: 35,
  },
  {
    date: "2024-01-19",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 37,
    daily_budget: 54,
    spend: 73,
    impressions: 22,
    sessions_with_click: 244,
    sessions_with_click_product_0: 92,
    sessions_with_click_product_1: 33,
    sessions_with_click_product_2: 14,
    sessions_with_click_product_3: 49,
  },
  {
    date: "2024-01-20",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 38,
    daily_budget: 89,
    spend: 52,
    impressions: 69,
    sessions_with_click: 201,
    sessions_with_click_product_0: 107,
    sessions_with_click_product_1: 32,
    sessions_with_click_product_2: 14,
    sessions_with_click_product_3: 18,
  },
  {
    date: "2024-01-21",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 85,
    daily_budget: 71,
    spend: 23,
    impressions: 82,
    sessions_with_click: 160,
    sessions_with_click_product_0: 58,
    sessions_with_click_product_1: 84,
    sessions_with_click_product_2: 25,
    sessions_with_click_product_3: 37,
  },
  {
    date: "2024-01-22",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 67,
    daily_budget: 66,
    spend: 50,
    impressions: 96,
    sessions_with_click: 190,
    sessions_with_click_product_0: 68,
    sessions_with_click_product_1: 54,
    sessions_with_click_product_2: 28,
    sessions_with_click_product_3: 15,
  },
  {
    date: "2024-01-23",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 35,
    daily_budget: 81,
    spend: 48,
    impressions: 114,
    sessions_with_click: 176,
    sessions_with_click_product_0: 68,
    sessions_with_click_product_1: 56,
    sessions_with_click_product_2: 36,
    sessions_with_click_product_3: 34,
  },
  {
    date: "2024-01-24",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 101,
    daily_budget: 83,
    spend: 42,
    impressions: 88,
    sessions_with_click: 252,
    sessions_with_click_product_0: 89,
    sessions_with_click_product_1: 18,
    sessions_with_click_product_2: 34,
    sessions_with_click_product_3: 41,
  },
  {
    date: "2024-01-25",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 27,
    daily_budget: 28,
    spend: 50,
    impressions: 103,
    sessions_with_click: 129,
    sessions_with_click_product_0: 83,
    sessions_with_click_product_1: 79,
    sessions_with_click_product_2: 30,
    sessions_with_click_product_3: 17,
  },
  {
    date: "2024-01-26",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 75,
    daily_budget: 80,
    spend: 56,
    impressions: 27,
    sessions_with_click: 236,
    sessions_with_click_product_0: 60,
    sessions_with_click_product_1: 36,
    sessions_with_click_product_2: 29,
    sessions_with_click_product_3: 10,
  },
  {
    date: "2024-01-27",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 101,
    daily_budget: 94,
    spend: 41,
    impressions: 82,
    sessions_with_click: 143,
    sessions_with_click_product_0: 64,
    sessions_with_click_product_1: 21,
    sessions_with_click_product_2: 18,
    sessions_with_click_product_3: 28,
  },
  {
    date: "2024-01-28",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 63,
    daily_budget: 22,
    spend: 61,
    impressions: 41,
    sessions_with_click: 74,
    sessions_with_click_product_0: 15,
    sessions_with_click_product_1: 55,
    sessions_with_click_product_2: 35,
    sessions_with_click_product_3: 13,
  },
  {
    date: "2024-01-29",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 59,
    daily_budget: 97,
    spend: 105,
    impressions: 37,
    sessions_with_click: 134,
    sessions_with_click_product_0: 108,
    sessions_with_click_product_1: 88,
    sessions_with_click_product_2: 10,
    sessions_with_click_product_3: 21,
  },
  {
    date: "2024-01-30",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 63,
    daily_budget: 83,
    spend: 45,
    impressions: 38,
    sessions_with_click: 27,
    sessions_with_click_product_0: 17,
    sessions_with_click_product_1: 24,
    sessions_with_click_product_2: 33,
    sessions_with_click_product_3: 48,
  },
  {
    date: "2024-01-31",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 26,
    daily_budget: 16,
    spend: 108,
    impressions: 73,
    sessions_with_click: 187,
    sessions_with_click_product_0: 101,
    sessions_with_click_product_1: 26,
    sessions_with_click_product_2: 21,
    sessions_with_click_product_3: 37,
  },
  {
    date: "2024-02-01",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 34,
    daily_budget: 75,
    spend: 41,
    impressions: 95,
    sessions_with_click: 213,
    sessions_with_click_product_0: 35,
    sessions_with_click_product_1: 36,
    sessions_with_click_product_2: 33,
    sessions_with_click_product_3: 31,
  },
  {
    date: "2024-02-02",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 68,
    daily_budget: 100,
    spend: 14,
    impressions: 35,
    sessions_with_click: 80,
    sessions_with_click_product_0: 18,
    sessions_with_click_product_1: 81,
    sessions_with_click_product_2: 16,
    sessions_with_click_product_3: 12,
  },
  {
    date: "2024-02-03",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 97,
    daily_budget: 25,
    spend: 85,
    impressions: 50,
    sessions_with_click: 47,
    sessions_with_click_product_0: 63,
    sessions_with_click_product_1: 81,
    sessions_with_click_product_2: 34,
    sessions_with_click_product_3: 43,
  },
  {
    date: "2024-02-04",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 24,
    daily_budget: 21,
    spend: 92,
    impressions: 106,
    sessions_with_click: 145,
    sessions_with_click_product_0: 12,
    sessions_with_click_product_1: 43,
    sessions_with_click_product_2: 27,
    sessions_with_click_product_3: 32,
  },
  {
    date: "2024-02-05",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 46,
    daily_budget: 32,
    spend: 23,
    impressions: 116,
    sessions_with_click: 41,
    sessions_with_click_product_0: 15,
    sessions_with_click_product_1: 16,
    sessions_with_click_product_2: 24,
    sessions_with_click_product_3: 17,
  },
  {
    date: "2024-02-06",
    campaign_name: "Aiohsfsg",
    campaign_id: "777",
    ad_type: "REWARDED",
    cpm: 42,
    daily_budget: 73,
    spend: 69,
    impressions: 38,
    sessions_with_click: 35,
    sessions_with_click_product_0: 38,
    sessions_with_click_product_1: 59,
    sessions_with_click_product_2: 32,
    sessions_with_click_product_3: 40,
  },
];

export const getDataForNumWeeks = (numWeeks: number) => {
  if (numWeeks === 1) {
    return oneWeekData;
  } else if (numWeeks === 2) {
    return twoWeeksData;
  } else if (numWeeks === 3) {
    return threeWeeksData;
  } else if (numWeeks === 4) {
    return fourWeeksData;
  } else if (numWeeks === 5) {
    return fiveWeeksData;
  } else if (numWeeks === 6) {
    return sixWeeksData;
  }
};
