import { PTiny } from "@tempoplatform/tpds/elements/typography";
import {
  spendColor,
  dailyBudgetColor,
  impressionsColor,
  sessionsWithClickColor,
  sessionsWithClickColorProduct0,
  sessionsWithClickColorProduct1,
  sessionsWithClickColorProduct2,
  sessionsWithClickColorProduct3,
} from "./colors";

export const generateTooltipForDayMainChart = (date: string, data: any, legendState: any) => {
  const dataForDay = data.filter((item: any) => item.date === date);

  const dailyBudgetForDay = dataForDay.reduce((acc: number, curr: any) => {
    return acc + curr.daily_budget;
  }, 0);
  const totalSpendForDay = dataForDay.reduce((acc: number, curr: any) => {
    return acc + curr.spend;
  }, 0);
  const totalImpressionsForDay = dataForDay.reduce((acc: number, curr: any) => {
    return acc + curr.impressions;
  }, 0);

  return (
    <div className="bg-white dark:bg-grey-dark-scale-800 p-4 shadow rounded-lg border border-window">
      <div className="flex gap-x-4">
        <div className="w-full">
          <div className="flex flex-col gap-y-4">
            {legendState[0] && (
              <div className="flex gap-x-2">
                <div
                  className="h-[10px] w-[10px] rounded-[50%]"
                  style={{ backgroundColor: dailyBudgetColor }}
                />
                <PTiny
                  isBold
                  isUppercase
                  className="text-grey-dark-scale-500 dark:text-grey-light-scale-500 opacity-60"
                >
                  Daily Budget
                </PTiny>
                <PTiny isBold>{dailyBudgetForDay.toLocaleString()}</PTiny>
              </div>
            )}
            {legendState[1] && (
              <div className="flex gap-x-2">
                <div
                  className="h-[10px] w-[10px] rounded-[50%]"
                  style={{ backgroundColor: spendColor }}
                />
                <PTiny
                  isBold
                  isUppercase
                  className="text-grey-dark-scale-500 dark:text-grey-light-scale-500 opacity-60"
                >
                  Spend
                </PTiny>
                <PTiny isBold>
                  ${totalSpendForDay.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                </PTiny>
              </div>
            )}
            {legendState[2] && (
              <div className="flex gap-x-2">
                <div
                  className="h-[10px] w-[10px] rounded-[50%]"
                  style={{ backgroundColor: impressionsColor }}
                />
                <PTiny
                  isBold
                  isUppercase
                  className="text-grey-dark-scale-500 dark:text-grey-light-scale-500 opacity-60"
                >
                  Impressions
                </PTiny>
                <PTiny isBold>{totalImpressionsForDay.toLocaleString()}</PTiny>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const generateTooltipForDaySWCChart = (date: string, data: any, legendState: any) => {
  const dataForDay = data.filter((item: any) => item.date === date);

  const sessionsWithClickForDay = dataForDay.reduce((acc: number, curr: any) => {
    return acc + curr.sessions_with_click;
  }, 0);
  const sessionsWithClickForDayProduct0 = dataForDay.reduce((acc: number, curr: any) => {
    return acc + curr.sessions_with_click_product_0;
  }, 0);
  const sessionsWithClickForDayProduct1 = dataForDay.reduce((acc: number, curr: any) => {
    return acc + curr.sessions_with_click_product_1;
  }, 0);
  const sessionsWithClickForDayProduct2 = dataForDay.reduce((acc: number, curr: any) => {
    return acc + curr.sessions_with_click_product_2;
  }, 0);
  const sessionsWithClickForDayProduct3 = dataForDay.reduce((acc: number, curr: any) => {
    return acc + curr.sessions_with_click_product_3;
  }, 0);

  return (
    <div className="bg-white dark:bg-grey-dark-scale-800 p-4 shadow rounded-lg border border-window">
      <div className="flex gap-x-4">
        <div className="w-full">
          <div className="flex flex-col gap-y-4">
            {legendState[0] && (
              <div className="flex gap-x-2">
                <div
                  className="h-[10px] w-[10px] rounded-[50%]"
                  style={{ backgroundColor: sessionsWithClickColor }}
                />
                <PTiny
                  isBold
                  isUppercase
                  className="text-grey-dark-scale-500 dark:text-grey-light-scale-500 opacity-60"
                >
                  Sessions with click
                </PTiny>
                <PTiny isBold>{sessionsWithClickForDay}</PTiny>
              </div>
            )}
            {legendState[1] && (
              <div className="flex gap-x-2">
                <div
                  className="h-[10px] w-[10px] rounded-[50%]"
                  style={{ backgroundColor: sessionsWithClickColorProduct0 }}
                />
                <PTiny
                  isBold
                  isUppercase
                  className="text-grey-dark-scale-500 dark:text-grey-light-scale-500 opacity-60"
                >
                  Product 1
                </PTiny>
                <PTiny isBold>{sessionsWithClickForDayProduct0}</PTiny>
              </div>
            )}
            {legendState[2] && (
              <div className="flex gap-x-2">
                <div
                  className="h-[10px] w-[10px] rounded-[50%]"
                  style={{ backgroundColor: sessionsWithClickColorProduct1 }}
                />
                <PTiny
                  isBold
                  isUppercase
                  className="text-grey-dark-scale-500 dark:text-grey-light-scale-500 opacity-60"
                >
                  Product 2
                </PTiny>
                <PTiny isBold>{sessionsWithClickForDayProduct1}</PTiny>
              </div>
            )}
            {legendState[3] && (
              <div className="flex gap-x-2">
                <div
                  className="h-[10px] w-[10px] rounded-[50%]"
                  style={{ backgroundColor: sessionsWithClickColorProduct2 }}
                />
                <PTiny
                  isBold
                  isUppercase
                  className="text-grey-dark-scale-500 dark:text-grey-light-scale-500 opacity-60"
                >
                  Product 3
                </PTiny>
                <PTiny isBold>{sessionsWithClickForDayProduct2}</PTiny>
              </div>
            )}
            {legendState[4] && (
              <div className="flex gap-x-2">
                <div
                  className="h-[10px] w-[10px] rounded-[50%]"
                  style={{ backgroundColor: sessionsWithClickColorProduct3 }}
                />
                <PTiny
                  isBold
                  isUppercase
                  className="text-grey-dark-scale-500 dark:text-grey-light-scale-500 opacity-60"
                >
                  Product 4
                </PTiny>
                <PTiny isBold>{sessionsWithClickForDayProduct3}</PTiny>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
