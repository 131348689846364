import * as React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./app/store";
import "@tempoplatform/tpds/system/tpds.css";
import "./tailwindbase.css";
import "@tempoplatform/tpds/system/colors.css";
import "@tempoplatform/tpds/system/basestyles.css";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import config from "./config";
import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";
import { QueryClient, QueryClientProvider } from "react-query";
// eslint-disable-next-line
import Fonts from "@tempoplatform/tpds/system/Fonts";
import "swiper/css";
import "swiper/css/pagination";

// Create a client
const queryClient = new QueryClient();

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <Router basename={config.app.basename}>
        <Auth0ProviderWithHistory>
          <App />
        </Auth0ProviderWithHistory>
      </Router>
    </QueryClientProvider>
  </Provider>,
  document.getElementById("root"),
);
