import styled from "@emotion/styled";
import React from "react";
import Product from "./Product";
import { PNano } from "@tempoplatform/tpds/elements/typography";
import { useAppDispatch } from "../../app/hooks";
import { selectPreviewProduct } from "./campaignCreateSlice";
import clsx from "clsx";

const RootDiv = styled.div`
  .ant-form-item {
    margin: 0px;
  }
`;

const Products = () => {
  const dispatch = useAppDispatch();
  const [openTab, setOpenTab] = React.useState<number>(0);

  const handleOpenTab = (tab: number) => {
    setOpenTab(tab - 1);
    dispatch(selectPreviewProduct(tab));
  };

  const baseTabClass = clsx(
    `w-[25%] py-3`,
    `flex items-center justify-center cursor-pointer`,
    `bg-grey-light-scale-200 dark:bg-black`,
    `select-none`,
  );

  return (
    <RootDiv>
      <div className="flex mb-4 gap-x-[1px]">
        <div
          onClick={() => handleOpenTab(1)}
          className={clsx(baseTabClass, openTab === 0 && `bg-white dark:!bg-grey-dark-scale-800`)}
        >
          <PNano
            isMedium
            className={clsx(
              `text-tertiary !text-[11px]`,
              openTab === 0 && `!text-black dark:!text-white !opacity-100`,
            )}
          >
            PRODUCT 1
          </PNano>
        </div>
        <div
          onClick={() => handleOpenTab(2)}
          className={clsx(baseTabClass, openTab === 1 && `bg-white dark:!bg-grey-dark-scale-800`)}
        >
          <PNano
            isMedium
            className={clsx(
              `text-tertiary !text-[11px]`,
              openTab === 1 && `!text-black dark:!text-white !opacity-100`,
            )}
          >
            PRODUCT 2
          </PNano>
        </div>
        <div
          onClick={() => handleOpenTab(3)}
          className={clsx(baseTabClass, openTab === 2 && `bg-white dark:!bg-grey-dark-scale-800`)}
        >
          <PNano
            isMedium
            className={clsx(
              `text-tertiary !text-[11px]`,
              openTab === 2 && `!text-black dark:!text-white !opacity-100`,
            )}
          >
            PRODUCT 3
          </PNano>
        </div>
        <div
          onClick={() => handleOpenTab(4)}
          className={clsx(baseTabClass, openTab === 3 && `bg-white dark:!bg-grey-dark-scale-800`)}
        >
          <PNano
            isMedium
            className={clsx(
              `text-tertiary !text-[11px]`,
              openTab === 3 && `!text-black dark:!text-white !opacity-100`,
            )}
          >
            PRODUCT 4
          </PNano>
        </div>
      </div>
      <div className="p-2 px-4">
        <div className="flex flex-col gap-2">
          <Product id={1} isExpanded={openTab === 0} />
          <Product id={2} isExpanded={openTab === 1} />
          <Product id={3} isExpanded={openTab === 2} />
          <Product id={4} isExpanded={openTab === 3} />
        </div>
      </div>
    </RootDiv>
  );
};
export default Products;
