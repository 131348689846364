type KeyData = {
  id: string;
  hidden_id: string;
  valid_from: string;
  valid_to: string;
  has_expired: boolean;
};

export const sortApiKeyByState = (a: any, b: any) => {
  if (a.has_expired && !b.has_expired) {
    return 1;
  }
  if (!a.has_expired && b.has_expired) {
    return -1;
  }
  return 0;
};

export const sortApiKeysByExpiryDate = (a: any, b: any) => {
  const dateA = new Date(a.valid_to);
  const dateB = new Date(b.valid_to);
  return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
};

export const sortKeys = (keys: any) => {
  let sortedKeys = keys.sort(sortApiKeysByExpiryDate);
  sortedKeys = sortedKeys.sort(sortApiKeyByState);
  return sortedKeys.reverse();
};

const removeKeysExpiredMoreThanOneMonthAgo = (keys: any) => {
  const now = new Date();
  const oneMonthAgo = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
  return keys.filter((key: any) => {
    const keyExpiryDate = new Date(key.valid_to);
    return keyExpiryDate > oneMonthAgo;
  });
};

export const prepareKeysData = (keysData: any) => {
  keysData = removeKeysExpiredMoreThanOneMonthAgo(keysData);
  return keysData.map((key: KeyData) => {
    return {
      id: key.id,
      hidden_id: `********-****-****-****-${getLastCharactersFromString(key.id)}`,
      valid_from: simplifyDate(key.valid_from),
      valid_to: simplifyDate(key.valid_to),
      has_expired: isDateBeforeNow(key.valid_to),
    };
  });
};

export const isDateBeforeNow = (date: any) => {
  const dateObj = new Date(date);
  const now = new Date();
  return dateObj < now;
};

export const getLastCharactersFromString = (str: string) => {
  const lastCharacters = str.slice(-4);
  return lastCharacters;
};

export const simplifyDate = (date: any) => {
  const dateObj = new Date(date);
  const formattedDate = `${dateObj.getDate()}/${dateObj.getMonth() + 1}/${dateObj.getFullYear()}`;
  return formattedDate;
};

export const handleKeyCopy = (key: string) => {
  navigator.clipboard.writeText(key);
};
