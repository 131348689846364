import React from "react";
import Modal from "@tempoplatform/tpds/components/modal";
import { P, PSmall } from "@tempoplatform/tpds/elements/typography";
import Callout from "@tempoplatform/tpds/components/callout";
import { getLastCharactersFromString, simplifyDate, handleKeyCopy } from "./helpers";
import { KeyIcon, CopyIcon } from "./Icons";
import { Tooltip } from "antd";

interface ErrorModalProps {
  error: any;
  setError: any;
}

export const ErrorModal: React.FC<ErrorModalProps> = ({ error, setError }) => {
  return (
    // @ts-ignore
    <Modal
      showOpen={error !== null}
      title="The request failed"
      content={
        <div className="flex flex-col gap-y-4">
          <PSmall className="leading-normal">
            An error ocurred while submitting the form. Please review your data or contact support.
          </PSmall>
          <PSmall className="leading-normal">{error?.message}</PSmall>
        </div>
      }
      options={[
        {
          label: "Return to form",
          variant: "info",
          callBack: () => {
            setError(null);
          },
        },
      ]}
    />
  );
};

interface SuccessModalProps {
  submitSuccess: boolean;
  apiKey: string;
  apiKeyValidTo: string;
  setApiKeyData: any;
  setSubmitSuccess: any;
  getAccountInformation: any;
}

export const SuccessModal: React.FC<SuccessModalProps> = ({
  submitSuccess,
  apiKey,
  apiKeyValidTo,
  setApiKeyData,
  setSubmitSuccess,
  getAccountInformation,
}) => {
  return (
    // @ts-ignore
    <Modal
      showOpen={submitSuccess}
      title="API Key created!"
      description="Awesome! Your key was created successfully. 🥳"
      content={
        <>
          <div className="mt-6 mb-3 flex gap-2">
            <KeyIcon />
            <P className="text-secondary">Your secret API Key:</P>
          </div>
          <div className="mb-4 w-full bg-grey-light-scale-200 dark:bg-grey-dark-scale-500 justify-between px-6 py-4 rounded-lg flex gap-2 items-center">
            <PSmall className="font-mono font-medium !text-clip !leading-relaxed !break-all !w-[80%]">
              {apiKey}
            </PSmall>
            <Tooltip title="Copy">
              <div className="p-4 bg-grey-light-scale-400 dark:bg-transparent border dark:border-grey-dark-scale-300 rounded-lg cursor-pointer hover:bg-grey-light-scale-500 active:bg-grey-light-scale-600 dark:hover:bg-grey-dark-scale-600 dark:active:bg-grey-dark-scale-700">
                <CopyIcon onClick={() => handleKeyCopy(apiKey)} />
              </div>
            </Tooltip>
          </div>
          <Callout variant="danger" title="Important!" omitIcon={true}>
            <PSmall isRed isMedium className="leading-normal">
              ● This key is only displayed once!
            </PSmall>
            <PSmall isRed isMedium className="leading-normal">
              ● Do not share it publicly.
            </PSmall>
            <PSmall isRed isMedium className="leading-normal">
              ● This key expires on {simplifyDate(apiKeyValidTo)}
            </PSmall>
          </Callout>
        </>
      }
      dialogOnClose={() => {
        setApiKeyData(null);
        setSubmitSuccess(false);
        getAccountInformation(); // fetch updated list of api keys in account
      }}
      options={[
        {
          label: "OK",
          variant: "info",
        },
      ]}
    />
  );
};

interface DeleteApiKeyModalProps {
  deletingKey: string;
  setDeletingKey: any;
  handleRevokeApiKey: any;
}

export const DeleteApiKeyModal: React.FC<DeleteApiKeyModalProps> = ({
  deletingKey,
  setDeletingKey,
  handleRevokeApiKey,
}) => {
  return (
    // @ts-ignore
    <Modal
      showOpen={deletingKey !== null}
      title="Delete API Key"
      content={
        <>
          <P className="mb-4">Are you sure you want to revoke this API Key?</P>
          <PSmall className="mb-8 text-tertiary">
            Key Id: {`********-****-****-****-${getLastCharactersFromString(deletingKey)}`}
          </PSmall>
          <Callout variant="danger" title="Important!" omitIcon={true}>
            <PSmall isRed isMedium className="leading-normal">
              ● This action cannot be undone
            </PSmall>
            <PSmall isRed isMedium className="leading-normal">
              ● Future requests using this key will be rejected
            </PSmall>
          </Callout>
        </>
      }
      dialogOnClose={() => {
        setDeletingKey(null);
      }}
      options={[
        {
          label: "Cancel",
          variant: "secondary",
        },
        {
          label: "Yes",
          variant: "info",
          callBack: () => {
            handleRevokeApiKey();
          },
        },
      ]}
    />
  );
};

interface DeletedApiKeySuccessModalProps {
  deletedApiKeySuccess: any;
  setDeletedApiKeySuccess: any;
  getAccountInformation: any;
}

export const DeletedApiKeySuccessModal: React.FC<DeletedApiKeySuccessModalProps> = ({
  deletedApiKeySuccess,
  setDeletedApiKeySuccess,
  getAccountInformation,
}) => {
  return (
    // @ts-ignore
    <Modal
      showOpen={deletedApiKeySuccess}
      title="API Key revoked"
      description="Your API Key was successfully revoked."
      dialogOnClose={() => {
        setDeletedApiKeySuccess(false);
        getAccountInformation(); // fetch updated list of api keys in account
      }}
      options={[
        {
          label: "OK",
          variant: "info",
        },
      ]}
    />
  );
};
