import {
  spendColor,
  dailyBudgetColor,
  impressionsColor,
  cpmColor,
  sessionsWithClickColor,
  sessionsWithClickColorProduct0,
  sessionsWithClickColorProduct1,
  sessionsWithClickColorProduct2,
  sessionsWithClickColorProduct3,
} from "./colors";

export const generateMainChartData = (data: any, legendsState: any) => {
  // get all unique dates in data into one array without repreating any
  const uniqueDates = data
    .map((item: any) => item.date)
    .filter((value: any, index: any, self: any) => self.indexOf(value) === index);
  // sum the values of each item in data that has the same date
  const sumOfValues = uniqueDates.map((date: any) => {
    const filteredData = data.filter((item: any) => item.date === date);
    const sumSpend = filteredData.reduce((acc: any, item: any) => {
      return acc + item.spend;
    }, 0);
    const sumDailyBudget = filteredData.reduce((acc: any, item: any) => {
      return acc + item.daily_budget;
    }, 0);
    const sumImpressions = filteredData.reduce((acc: any, item: any) => {
      return acc + item.impressions;
    }, 0);
    return {
      date,
      spend: sumSpend,
      daily_budget: sumDailyBudget,
      impressions: sumImpressions,
    };
  });

  const dailyBudgetChartLineData = sumOfValues.map((item: any) => {
    return {
      x: item.date,
      y: item.daily_budget,
    };
  });

  const spendChartLineData = sumOfValues.map((item: any) => {
    return {
      x: item.date,
      y: item.spend,
    };
  });

  const impressionsChartLineData = sumOfValues.map((item: any) => {
    return {
      x: item.date,
      y: item.impressions * 1.4,
    };
  });
  // const cpmChartLineData = data.map((item: any) => {
  //   return {
  //     x: item.date,
  //     y: item.cpm,
  //   };
  // });

  const dataSpend = {
    id: "spend",
    color: spendColor,
    data: spendChartLineData,
  };

  const dataDailyBudget = {
    id: "daily_budget",
    color: dailyBudgetColor,
    data: dailyBudgetChartLineData,
  };

  const dataImpressions = {
    id: "impressions",
    color: impressionsColor,
    data: impressionsChartLineData,
  };

  // const dataCpm = {
  //   id: "cpm",
  //   color: cpmColor,
  //   data: cpmChartLineData,
  // };

  const dataToReturn = [];
  if (legendsState[0]) {
    dataToReturn.push(dataDailyBudget);
  }
  if (legendsState[1]) {
    dataToReturn.push(dataSpend);
  }
  if (legendsState[2]) {
    dataToReturn.push(dataImpressions);
  }
  return dataToReturn;
};

export const generateSessionsWithClickChartData = (data: any, legendsState: any) => {
  // get all unique dates in data into one array without repreating any
  const uniqueDates = data
    .map((item: any) => item.date)
    .filter((value: any, index: any, self: any) => self.indexOf(value) === index);
  // sum the values of each item in data that has the same date
  const sumOfValues = uniqueDates.map((date: any) => {
    const filteredData = data.filter((item: any) => item.date === date);
    const sumSessionsWithClick = filteredData.reduce((acc: any, item: any) => {
      return acc + item.sessions_with_click;
    }, 0);
    const sumSessionsWithClickProduct0 = filteredData.reduce((acc: any, item: any) => {
      return acc + item.sessions_with_click_product_0;
    }, 0);
    const sumSessionsWithClickProduct1 = filteredData.reduce((acc: any, item: any) => {
      return acc + item.sessions_with_click_product_1;
    }, 0);
    const sumSessionsWithClickProduct2 = filteredData.reduce((acc: any, item: any) => {
      return acc + item.sessions_with_click_product_2;
    }, 0);
    const sumSessionsWithClickProduct3 = filteredData.reduce((acc: any, item: any) => {
      return acc + item.sessions_with_click_product_3;
    }, 0);
    const sumSessionsWithClickProduct4 = filteredData.reduce((acc: any, item: any) => {
      return acc + item.sessions_with_click_product_4;
    }, 0);
    return {
      date,
      sessions_with_click: sumSessionsWithClick,
      sessions_with_click_product_0: sumSessionsWithClickProduct0,
      sessions_with_click_product_1: sumSessionsWithClickProduct1,
      sessions_with_click_product_2: sumSessionsWithClickProduct2,
      sessions_with_click_product_3: sumSessionsWithClickProduct3,
      sessions_with_click_product_4: sumSessionsWithClickProduct4,
    };
  });

  const totalSessionsWithClickChartLineData = sumOfValues.map((item: any) => {
    return {
      x: item.date,
      y: item.sessions_with_click,
    };
  });
  const sessionsWithClickProduct0ChartLineData = sumOfValues.map((item: any) => {
    return {
      x: item.date,
      y: item.sessions_with_click_product_0,
    };
  });
  const sessionsWithClickProduct1ChartLineData = sumOfValues.map((item: any) => {
    return {
      x: item.date,
      y: item.sessions_with_click_product_1,
    };
  });
  const sessionsWithClickProduct2ChartLineData = sumOfValues.map((item: any) => {
    return {
      x: item.date,
      y: item.sessions_with_click_product_2,
    };
  });
  const sessionsWithClickProduct3ChartLineData = sumOfValues.map((item: any) => {
    return {
      x: item.date,
      y: item.sessions_with_click_product_3,
    };
  });

  const dataTotalSessionsWithClick = {
    id: "total_sessions_with_click",
    color: sessionsWithClickColor,
    data: totalSessionsWithClickChartLineData,
  };
  const dataSessionsWithClickProduct0 = {
    id: "sessions_with_click_product_0",
    color: sessionsWithClickColorProduct0,
    data: sessionsWithClickProduct0ChartLineData,
  };
  const dataSessionsWithClickProduct1 = {
    id: "sessions_with_click_product_1",
    color: sessionsWithClickColorProduct1,
    data: sessionsWithClickProduct1ChartLineData,
  };
  const dataSessionsWithClickProduct2 = {
    id: "sessions_with_click_product_2",
    color: sessionsWithClickColorProduct2,
    data: sessionsWithClickProduct2ChartLineData,
  };
  const dataSessionsWithClickProduct3 = {
    id: "sessions_with_click_product_3",
    color: sessionsWithClickColorProduct3,
    data: sessionsWithClickProduct3ChartLineData,
  };

  const dataToReturn = [];
  if (legendsState[0]) {
    dataToReturn.push(dataTotalSessionsWithClick);
  }
  if (legendsState[1]) {
    dataToReturn.push(dataSessionsWithClickProduct0);
  }
  if (legendsState[2]) {
    dataToReturn.push(dataSessionsWithClickProduct1);
  }
  if (legendsState[3]) {
    dataToReturn.push(dataSessionsWithClickProduct2);
  }
  if (legendsState[4]) {
    dataToReturn.push(dataSessionsWithClickProduct3);
  }
  return dataToReturn;
};
