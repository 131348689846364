import React from "react";
import styled from "@emotion/styled";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Slider } from "antd";
import {
  updateTopIslandBackgroundColor,
  updateTopIslandTextColor,
  updateBottomIslandBackgroundColor,
  updateBottomIslandThumbnailsBackgroundColor,
  updateBottomIslandThumbnailsPadding,
} from "../campaignCreateSlice";
import { FormLabelTiny } from "@tempoplatform/tpds/elements/typography";
import ColorPickerInput from "../../../shared/ColorPickerInput";

const RootDiv = styled.div`
  .react-colorful {
    width: 100%;
    height: auto;
  }
  .ant-slider-rail {
    background-color: #555;
  }
  .react-colorful__hue {
    height: 12px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .react-colorful__saturation {
    height: 40px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom: 0;
  }
  .react-colorful__alpha {
    height: 12px;
  }
  .react-colorful__alpha-pointer {
    width: 10px !important;
    height: 10px !important;
  }
  .react-colorful__saturation-pointer {
    width: 16px;
    height: 16px;
    border-radius: 3px;
  }
  .react-colorful__hue-pointer,
  .react-colorful__alpha-pointer {
    width: 10px !important;
    height: 10px !important;
    border-radius: 3px;
  }
`;

const IslandStyleConfigurator = () => {
  const dispatch = useAppDispatch();
  const topIslandBackgroundColor = useAppSelector(
    state => state.campaignCreate.topIslandBackgroundColor,
  );
  const topIslandTextColor = useAppSelector(state => state.campaignCreate.topIslandTextColor);
  const bottomIslandBackgroundColor = useAppSelector(
    state => state.campaignCreate.bottomIslandBackgroundColor,
  );
  const bottomIslandThumbnailsBackgroundColor = useAppSelector(
    state => state.campaignCreate.bottomIslandThumbnailsBackgroundColor,
  );
  const bottomIslandThumbnailsPadding = useAppSelector(
    state => state.campaignCreate.bottomIslandThumbnailsPadding,
  );

  return (
    <RootDiv className="relative">
      <FormLabelTiny className="mb-2 mt-3">Top island background color</FormLabelTiny>
      <ColorPickerInput
        hex_color={topIslandBackgroundColor}
        onChangeHandler={(value: string) => dispatch(updateTopIslandBackgroundColor(value))}
      />
      <FormLabelTiny className="mb-2 mt-3">Top island text color</FormLabelTiny>
      <ColorPickerInput
        hex_color={topIslandTextColor}
        onChangeHandler={(value: string) => dispatch(updateTopIslandTextColor(value))}
      />
      <FormLabelTiny className="mb-2 mt-3">Bottom island background color</FormLabelTiny>
      <ColorPickerInput
        hex_color={bottomIslandBackgroundColor}
        onChangeHandler={(value: string) => dispatch(updateBottomIslandBackgroundColor(value))}
      />
      <FormLabelTiny className="mb-2 mt-3">Thumbnails background color</FormLabelTiny>
      <ColorPickerInput
        hex_color={bottomIslandThumbnailsBackgroundColor}
        onChangeHandler={(value: string) =>
          dispatch(updateBottomIslandThumbnailsBackgroundColor(value))
        }
      />
      <FormLabelTiny className="mb-2 mt-3">Thumbnails padding</FormLabelTiny>
      <Slider
        defaultValue={bottomIslandThumbnailsPadding}
        min={0}
        max={10}
        onChange={(value: number) => dispatch(updateBottomIslandThumbnailsPadding(value))}
      />
    </RootDiv>
  );
};

export default IslandStyleConfigurator;
