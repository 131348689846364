import { Tooltip } from "antd";
import Tag from "@tempoplatform/tpds/components/tag";
import { TrashIcon } from "./Icons";

export const getTableColumns = (setDeletingKey: any) => {
  return [
    {
      label: "Id",
      propName: "hidden_id",
      key: "hidden_id",
    },
    {
      label: "Created on",
      propName: "valid_from",
      key: "valid_from",
    },
    {
      label: "Expiry date",
      propName: "valid_to",
      key: "valid_to",
      //addClass: 'font-bold',
    },
    {
      label: "State",
      propName: "has_expired",
      key: "has_expired",
      render: (item: any, index: number) => {
        return item.has_expired ? (
          <Tag variant="danger" label="EXPIRED" />
        ) : (
          <Tag variant="success" label="ACTIVE" />
        );
      },
    },
    {
      label: "Actions",
      propName: null,
      key: "actions",
      align: "right",
      render: (item: any, index: number) => {
        return item.has_expired ? null : (
          <Tooltip title="Revoke">
            <div
              className="bg-white dark:bg-grey-dark-scale-600 hover:bg-grey-light-scale-200 dark:hover:bg-grey-dark-scale-500 rounded p-2 cursor-pointer"
              onClick={() => setDeletingKey(item.id)}
            >
              <TrashIcon key={index} className="cursor-pointer text-primary" />
            </div>
          </Tooltip>
        );
      },
    },
  ];
};
