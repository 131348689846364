import React from "react";
import { CloudUploadOutlined } from "@ant-design/icons";
import { PSmall } from "@tempoplatform/tpds/elements/typography";

function CloudUploadDisplay({ images = true, videos = false, multiple = false, ...props }) {
  const textForImagesOnly = "Drop your image(s) here";
  const textForVideosOnly = "Drop your video(s) here";
  const textForImagesAndVideos = "Drop your image(s) or video(s) here";
  const imagesOnly = images && !videos;
  const videosOnly = videos && !images;
  let textToDisplay = imagesOnly
    ? textForImagesOnly
    : videosOnly
    ? textForVideosOnly
    : textForImagesAndVideos;

  if (!multiple) {
    textToDisplay = textToDisplay.replaceAll("(s)", "");
  }

  return (
    <div {...props} style={{ userSelect: "none" }}>
      <span
        style={{
          display: "block",
          fontSize: "30px",
          lineHeight: "100%",
          marginBottom: "10px",
          opacity: "0.3",
        }}
      >
        <CloudUploadOutlined color="red" className="!text-primary" />
      </span>
      <PSmall isMedium className="leading-[130%] !text-secondary">
        {textToDisplay}, <br />
        <span style={{ color: "#1990ff" }}>or click to upload</span>
      </PSmall>
    </div>
  );
}

export default CloudUploadDisplay;
