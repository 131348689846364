import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { setPreviewBrandAwarenessDialogOpen } from "../../../campaignCreateSlice";
import AdTypes from "../../../../../constants/ad_types";
import clsx from "clsx";

const classInitialButtonsBox = clsx(
  `shadow-[2px 16px 29px 9px rgba(0, 0, 0, 0.5)]`,
  `gap-x-2 bg-white px-4 py-3 rounded-lg`,
  `flex justify-between items-center`,
  `transition-all duration-300`,
  `relative bottom-2 left-[4px]`,
);
const classButtonBase = clsx(
  `flex justify-center items-center gap-x-1`,
  `w-[50%] py-2 rounded-lg outline-none border-none font-medium`,
);
const classMainCTA = clsx(classButtonBase, `bg-blue-scale-400 text-white`);
const classShareCTA = clsx(classButtonBase, `bg-grey-light-scale-400 text-black`);

const BrandAwarenessCTA: React.FC = () => {
  const dispatch = useAppDispatch();
  const preview_selectedProduct = useAppSelector(
    state => state.campaignCreate.preview_selectedProduct,
  );
  const cta_label = useAppSelector(state => state.campaignCreate.cta_label);
  const preview_brandAwarenessDialogOpen = useAppSelector(
    state => state.campaignCreate.preview_brandAwarenessDialogOpen,
  );
  const adType = useAppSelector(state => state.campaignCreate.ad_type);
  const showCta =
    preview_selectedProduct > 0 &&
    adType === AdTypes.brand_awareness &&
    !preview_brandAwarenessDialogOpen;

  const handleOpenDialog = () => {
    dispatch(setPreviewBrandAwarenessDialogOpen(true));
  };

  const classRootDiv = clsx(
    `w-full absolute left-0 z-50`,
    `transition-all duration-300`,
    showCta ? `bottom-1` : `bottom-[-120px]`,
  );

  return (
    <div className={classRootDiv}>
      <div
        className={classInitialButtonsBox}
        style={{ width: "calc(100% - 8px)", boxShadow: "2px 16px 29px 9px rgba(0, 0, 0, 0.5)" }}
      >
        <button className={classMainCTA} onClick={() => handleOpenDialog()} tabIndex={-1}>
          {cta_label || "CTA Label"}
        </button>
        <button tabIndex={-1} className={classShareCTA}>
          <ShareIcon /> Share
        </button>
      </div>
    </div>
  );
};

export default BrandAwarenessCTA;

function ShareIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 48 48"
      style={{ position: "relative", top: "-1px" }}
    >
      <path
        fill="#000"
        d="M11 46c-.8 0-1.5-.3-2.1-.9-.6-.6-.9-1.3-.9-2.1V17.55c0-.8.3-1.5.9-2.1.6-.6 1.3-.9 2.1-.9h8.45v3H11V43h26V17.55h-8.55v-3H37c.8 0 1.5.3 2.1.9.6.6.9 1.3.9 2.1V43c0 .8-.3 1.5-.9 2.1-.6.6-1.3.9-2.1.9H11zm11.45-15.35V7.8l-4.4 4.4-2.15-2.15L23.95 2 32 10.05l-2.15 2.15-4.4-4.4v22.85h-3z"
      ></path>
    </svg>
  );
}
