import React from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  updateTargetingAge,
  updateTargetingGender,
  updateTargetingLocations,
  updateTargetingLanguages,
  updateTargetingInterests,
} from "../campaignCreateSlice";
import { Separator } from "@tempoplatform/tpds/elements/layout";
import { PLarge, FormLabelSmall, Red } from "@tempoplatform/tpds/elements/typography";
import RadioTabs from "@tempoplatform/tpds/components/radiotabs";
import TPDSSelect from "@tempoplatform/tpds/components/select";
import TPDSMultiSelect from "@tempoplatform/tpds/components/select-multi";

// @ts-ignore
const age = [];
for (let i = 13; i < 51; i++) {
  i !== 50 ? age.push(`${i}`) : age.push(`${i}+`);
}

const Targeting = () => {
  const dispatch = useAppDispatch();
  const enumsData = useAppSelector(state => state.campaignCreate.enumsData);
  const gendersData = enumsData.gender;
  const languagesData = enumsData.language;
  const locationsData = enumsData.location;
  const interestsData = enumsData.interest;

  const targetingGender = useAppSelector(state => state.campaignCreate.targeting_gender);
  const targetingAgeRange = useAppSelector(state => state.campaignCreate.targeting_age);
  const targetingLanguages = useAppSelector(state => state.campaignCreate.targeting_languages);
  const targetingInterests = useAppSelector(state => state.campaignCreate.targeting_interests);
  const targetingLocations = useAppSelector(state => state.campaignCreate.targeting_locations);

  const selectedGenderIndex =
    gendersData.findIndex((gender: any) => gender.value === targetingGender) || 0;

  const handleMinAgeSelectChange = (index: number) => {
    dispatch(
      updateTargetingAge({
        ...targetingAgeRange,
        // @ts-ignore
        min: parseInt(age[index]),
      }),
    );
  };

  const handleMaxAgeSelectChange = (index: number) => {
    dispatch(
      updateTargetingAge({
        ...targetingAgeRange,
        // @ts-ignore
        max: parseInt(age[index]),
      }),
    );
  };

  const handleLocationsSelectChange = (updatedValues: any) => {
    const items = [];
    for (let i = 0; i < updatedValues.length; i++) {
      items.push(locationsData[updatedValues[i]].value);
    }
    dispatch(updateTargetingLocations(items));
  };
  const handleInterestsSelectChange = (updatedValues: any) => {
    const items = [];
    for (let i = 0; i < updatedValues.length; i++) {
      items.push(interestsData[updatedValues[i]].value);
    }
    dispatch(updateTargetingInterests(items));
  };
  const handleLanguagesSelectChange = (updatedValues: any) => {
    const items = [];
    for (let i = 0; i < updatedValues.length; i++) {
      items.push(languagesData[updatedValues[i]].value);
    }
    dispatch(updateTargetingLanguages(items));
  };
  // @ts-ignore
  let minAgeIndex = age.indexOf(String(targetingAgeRange.min));
  if (minAgeIndex === -1) {
    minAgeIndex = 0;
  }
  // @ts-ignore
  let maxAgeIndex = age.indexOf(String(targetingAgeRange.max));
  if (maxAgeIndex === -1) {
    maxAgeIndex = age.length - 1;
  }

  const selectedtargetingLanguagesIndexes: any = [];
  languagesData.forEach((item: any, index: number) => {
    if (targetingLanguages.includes(item.value)) {
      selectedtargetingLanguagesIndexes.push(index);
    }
  });

  const selectedtargetingLocationsIndexes: any = [];
  locationsData.forEach((item: any, index: number) => {
    if (targetingLocations.includes(item.value)) {
      selectedtargetingLocationsIndexes.push(index);
    }
  });

  const selectedtargetingInterestsIndexes: any = [];
  interestsData.forEach((item: any, index: number) => {
    if (targetingInterests.includes(item.value)) {
      selectedtargetingInterestsIndexes.push(index);
    }
  });

  return (
    <>
      <div className="mb-2">
        <PLarge isMedium className="p-0">
          Targeting
        </PLarge>
        <Separator className="mt-4" />
      </div>
      <div>
        <FormLabelSmall className="mb-2">
          <Red>*</Red> Gender
        </FormLabelSmall>
        <RadioTabs
          items={gendersData}
          selectedIndex={selectedGenderIndex}
          handleIndexSelection={(index: number) => {
            dispatch(updateTargetingGender(gendersData[index].value));
          }}
        />
      </div>
      <div>
        <FormLabelSmall className="mb-2">Age range</FormLabelSmall>
        <div className="flex items-center gap-x-2">
          <TPDSSelect
            // @ts-ignore
            options={age}
            labelProp={null}
            selectedIndex={minAgeIndex}
            handleIndexSelection={(index: number) => handleMinAgeSelectChange(index)}
          />
          <TPDSSelect
            // @ts-ignore
            options={age}
            labelProp={null}
            selectedIndex={maxAgeIndex}
            handleIndexSelection={(index: number) => handleMaxAgeSelectChange(index)}
          />
        </div>
      </div>
      <div>
        <FormLabelSmall className="mb-2">
          <Red>*</Red> Language
        </FormLabelSmall>
        <TPDSMultiSelect
          options={languagesData}
          idProp="id"
          labelProp="value"
          selectedIndexes={selectedtargetingLanguagesIndexes}
          handleSelectionUpdate={(values: any) => handleLanguagesSelectChange(values)}
        />
      </div>
      <div>
        <FormLabelSmall className="mb-2">
          <Red>*</Red> Location
        </FormLabelSmall>
        <TPDSMultiSelect
          options={locationsData}
          idProp="id"
          labelProp="value"
          selectedIndexes={selectedtargetingLocationsIndexes}
          handleSelectionUpdate={(values: any) => handleLocationsSelectChange(values)}
        />
      </div>
      <div>
        <FormLabelSmall className="mb-2">
          <Red>*</Red> Interests
        </FormLabelSmall>
        <TPDSMultiSelect
          options={interestsData}
          idProp="id"
          labelProp="value"
          selectedIndexes={selectedtargetingInterestsIndexes}
          handleSelectionUpdate={(values: any) => handleInterestsSelectChange(values)}
        />
      </div>
    </>
  );
};
export default Targeting;
