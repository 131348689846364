import React from "react";
import { Button } from "@tempoplatform/tpds/elements/buttons";
import { useHistory } from "react-router-dom";
import { H6, PSmall } from "@tempoplatform/tpds/elements/typography";
import { Window, Separator } from "@tempoplatform/tpds/elements/layout";

const CampaignEmpty = () => {
  const history = useHistory();
  return (
    <Window style={{ maxWidth: "400px", margin: "0 auto" }}>
      <H6>Your Campaigns</H6>
      <Separator />
      <br />
      <PSmall>You currently have no ads. Please create one.</PSmall>
      <br />
      <Button variant="info" onClick={() => history.push("/campaigns/create")}>
        Launch an Ad
      </Button>
    </Window>
  );
};
export default CampaignEmpty;
