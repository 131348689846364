import { useAppSelector, useAppDispatch } from "../../../../../app/hooks";
import { ArrowLeftIcon } from "@tempoplatform/tpds/assets/svgs/arrows";
import { H3, PLarge, H5 } from "@tempoplatform/tpds/elements/typography";
import clsx from "clsx";
import StarsRating from "@tempoplatform/tpds/components/stars-rating";

import ReviewBox from "./ReviewBox";
import dayjs from "dayjs";
import { simplifyLongNumber } from "../../../../helpers/number";
import { selectPreviewProductReviews } from "../../../campaignCreateSlice";

const ReviewsAll: React.FC = () => {
  const dispatch = useAppDispatch();
  const preview_selectedProductReviews = useAppSelector(
    state => state.campaignCreate.preview_selectedProductReviews,
  );
  const products = useAppSelector(state => state.campaignCreate.products);
  const reviews = useAppSelector(state => state.campaignCreate.productReviews);
  let product = products[preview_selectedProductReviews - 1];
  let thisProductReviews = reviews[preview_selectedProductReviews - 1];

  return (
    <div
      className={clsx(
        `w-full h-full absolute top-0 left-0 bg-white overflow-y-scroll`,
        `translate-x-full transition-transform duration-300 ease-in-out`,
        preview_selectedProductReviews !== 0 && `!translate-x-0`,
      )}
    >
      <div className="px-4 py-2 border-b border-window flex items-center h-[48px]">
        <div
          className="inline-flex items-center cursor-pointer"
          onClick={() => dispatch(selectPreviewProductReviews(0))}
        >
          <ArrowLeftIcon />
          <PLarge isMedium className="select-none mr-auto ml-3">
            Back
          </PLarge>
        </div>
      </div>
      <div
        style={{
          position: "relative",
          zIndex: 2,
          height: "auto",
          width: "100%",
          display: "flex",
          paddingTop: "20px",
          paddingBottom: "20px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span
          style={{
            textAlign: "center",
          }}
        >
          <div className="flex items-end justify-center gap-x-[1px]">
            {product && (
              <>
                <H3 isMedium>{product ? `${product.review_overall_score}` : "?"}</H3>
                <H5 isMedium className="relative top-[-7px] text-tertiary">
                  {"/"}
                </H5>
                <H5 isMedium className="relative top-[-3px] text-tertiary">
                  {"5"}
                </H5>
              </>
            )}
          </div>
          <div
            style={{
              height: "40%",
            }}
          >
            <StarsRating
              rating={product && product.review_overall_score ? product.review_overall_score : 0}
            />
          </div>
          <div
            style={{
              color: "#565959",
              fontSize: 14,
              fontWeight: 500,
              height: "20%",
            }}
          >
            {simplifyLongNumber(product && product.review_count ? product.review_count : 0)} Reviews
          </div>
        </span>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginBottom: "50px",
        }}
      >
        <div
          style={{
            padding: "1%",
            height: "100%",
            width: "90%",
          }}
        >
          {thisProductReviews &&
            thisProductReviews.map((review: any, index: number) => {
              return (
                <ReviewBox
                  key={index}
                  title={review.title}
                  rating={review.star_rating}
                  username={review.user_name}
                  date={dayjs(review.date).format("YYYY/MM/DD")}
                  description={review.description}
                />
              );
            })}
          <div style={{ height: "1em" }} />
        </div>
      </div>
    </div>
  );
};

export default ReviewsAll;
