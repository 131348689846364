import * as React from "react";
import CampaignList from "../campaignList/CampaignList";
import CampaignEmpty from "../campaignEmpty/CampaignEmpty";
import Loading from "../loading/Loading";
import { getCampaigns } from "../api/api";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

interface Props {
  accountId: number;
}

const Campaigns: React.FC<Props> = ({ accountId }) => {
  const location = useLocation();
  const history = useHistory();
  const { getIdTokenClaims } = useAuth0();
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    // ensure we only call getCampaigns if we are at the appropriate path
    if (location.pathname === "/") {
      history.push("/campaigns");
    } else {
      callGetCampaigns();
    }
  }, [accountId]);

  // render null if we aren't at the component's specific route
  if (location.pathname === "/") return null;

  const callGetCampaigns = async () => {
    const claims = await getIdTokenClaims();
    const id_token = claims?.__raw;
    setLoading(true);
    try {
      const response = await getCampaigns(id_token || "", accountId);
      setData(response);
      setLoading(false);
    } catch (error: any) {
      setError(error);
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (error || !data) {
    return <div>ERROR</div>;
  }

  if (data.length === 0) {
    return <CampaignEmpty />;
  }

  if (!data[0].id) {
    return <div>ERROR</div>;
  }
  return <CampaignList data={data} />;
};

export default Campaigns;
