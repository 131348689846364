import React from "react";
import TopHeader from "./product_screen/TopHeader";
import SlideshowArea from "./product_screen/SlideshowArea";
import TitleArea from "./product_screen/TitleArea";
import Variants from "./product_screen/Variants";
import SelectionResume from "./product_screen/SelectionResume";
import ShippingSelector from "./product_screen/ShippingSelector";
import InfoBoxes from "./product_screen/InfoBoxes";
import RatingsAndReviews from "./product_screen/RatingsAndReviews";
import DynamicFormPreview from "./product_screen/DynamicFormPreview";
import { useAppSelector } from "../../../app/hooks";
import AdTypes from "../../../constants/ad_types";
import clsx from "clsx";

const ProductScreen: React.FC = () => {
  const preview_selectedProduct = useAppSelector(
    state => state.campaignCreate.preview_selectedProduct,
  );
  const adType = useAppSelector(state => state.campaignCreate.ad_type);

  const omitPrice = adType === AdTypes.brand_awareness;
  const productIndex = preview_selectedProduct - 1;

  return (
    <div
      className={clsx(
        `w-full h-full absolute top-0 left-0 bg-white overflow-y-scroll`,
        `translate-x-full transition-transform duration-300 ease-in-out`,
        preview_selectedProduct !== 0 && `!translate-x-0`,
      )}
    >
      <TopHeader />
      <SlideshowArea />
      <TitleArea productIndex={productIndex} />
      {adType !== AdTypes.images_form && (
        <>
          <Variants productIndex={productIndex} />
          {!omitPrice && <SelectionResume productIndex={productIndex} />}
          {!omitPrice && <ShippingSelector productIndex={productIndex} />}
          <InfoBoxes productIndex={productIndex} />
          <RatingsAndReviews productIndex={productIndex} />
        </>
      )}
      {adType === AdTypes.images_form && <DynamicFormPreview />}
      <div className="w-full h-24" />
    </div>
  );
};
export default ProductScreen;
