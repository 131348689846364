import { H5, P } from "@tempoplatform/tpds/elements/typography";
import Spinner from "@tempoplatform/tpds/components/spinner";
import tokens from "@tempoplatform/tpds/tokens/index.json";
const blueColorPalette = tokens.colors.color_palettes.blue;

interface Props {
  isLoading: boolean;
}

const PageTitle: React.FC<Props> = ({ isLoading }) => {
  return (
    <div>
      <H5 isBold className="mb-2 mt-4">
        Api Keys
      </H5>
      <div className="inline-flex gap-x-3 items-center relative">
        <P className="text-tertiary">Externally access the Tempo API</P>
        <div className="absolute right-[-32px] top-[-4px]">
          {isLoading && <Spinner color={blueColorPalette["500"]} radius={20} />}
        </div>
      </div>
    </div>
  );
};

export default PageTitle;
