export const REACT_APP_AWS_CLOUDFRONT_URL = process.env.REACT_APP_AWS_CLOUDFRONT_URL;
export const REACT_APP_AWS_S3_BUCKET_NAME = process.env.REACT_APP_AWS_S3_BUCKET_NAME;
export const REACT_APP_AWS_S3_REGION = process.env.REACT_APP_AWS_S3_REGION;
export const REACT_APP_AWS_ACCESS_ID = process.env.REACT_APP_AWS_ACCESS_ID;
export const REACT_APP_AWS_ACCESS_KEY = process.env.REACT_APP_AWS_ACCESS_KEY;

const getConfig = () => {
  if (REACT_APP_AWS_S3_REGION === "us-east-1") {
    return {
      bucketName: REACT_APP_AWS_S3_BUCKET_NAME,
      region: REACT_APP_AWS_S3_REGION,
      accessKeyId: REACT_APP_AWS_ACCESS_ID,
      secretAccessKey: REACT_APP_AWS_ACCESS_KEY,
      s3Url: "https://" + REACT_APP_AWS_S3_BUCKET_NAME + ".s3.amazonaws.com",
      dirName: "images",
    };
  } else {
    return {
      bucketName: REACT_APP_AWS_S3_BUCKET_NAME,
      region: REACT_APP_AWS_S3_REGION,
      accessKeyId: REACT_APP_AWS_ACCESS_ID,
      secretAccessKey: REACT_APP_AWS_ACCESS_KEY,
      // s3Url:
      //   "https://" +
      //   REACT_APP_AWS_S3_BUCKET_NAME +
      //   ".s3.amazonaws.com",
      dirName: "images",
    };
  }
};

export default getConfig;
