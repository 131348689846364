import { Window } from "@tempoplatform/tpds/elements/layout";
import { Button } from "@tempoplatform/tpds/elements/buttons";
import { H4, H6, P, PNano } from "@tempoplatform/tpds/elements/typography";
import Select from "@tempoplatform/tpds/components/select";
import Table from "@tempoplatform/tpds/components/table";
import Loading from "../loading/Loading";
import React, { useState } from "react";
import { getDataForNumWeeks } from "./demoData";
import { spendColor, impressionsColor, cpmColor, sessionsWithClickColor } from "./colors";
import MainStatWindow from "./sub_components/MainStatWindow";
import { getMainChartLegend, getSessionsWithClickLegend } from "./generateLegends";
import { generateMainChartData, generateSessionsWithClickChartData } from "./generateChartData";
import { generateTooltipForDayMainChart, generateTooltipForDaySWCChart } from "./generateTooltip";
import { DownloadOutlined } from "@ant-design/icons";
import ThemeContext from "../../color/ThemeContext";
import AccessTokenContext from "../../auth/AccessTokenContext";
import { useHistory, useLocation } from "react-router-dom";
import { toGetAdvertiserCharts } from "../api/api";
import { tableColumns, generateTableData } from "./table";
import { generateMainChart, generateSessionsWithClickChart } from "./generateCharts";
import {
  getCsvContent,
  getUniqueCampaigns,
  generateMultiSelectItems,
  getSelectedIndexesForMultiSelect,
  getCampaignIdsFromUrlQuery,
  generateTotals,
} from "./utils";
import TPDSMultiSelect from "@tempoplatform/tpds/components/select-multi";

import { useQuery } from "react-query";
import clsx from "clsx";

const defaultWeeksOfData = 2;
const weeksOfDataOptions = [
  { label: "Past Week", value: 1 },
  { label: "Past 2 Weeks", value: 2 },
  { label: "Past 3 Weeks", value: 3 },
  { label: "Past 4 Weeks", value: 4 },
  { label: "Past 5 Weeks", value: 5 },
  { label: "Past 6 Weeks", value: 6 },
];

const chartWindowStyle = "pt-16 pb-10 !rounded-lg !border-transparent shadow";

const getDateBehindInWeeks = (weeksBehind: number) => {
  const date = new Date();
  date.setDate(date.getDate() - weeksBehind * 7);
  return date;
};

const formatStartEndDateToReadable = (startDate: Date, endDate: Date) => {
  const startDayOfMonth = startDate.getDate();
  const endDayOfMonth = endDate.getDate();
  const startMonth = startDate.toLocaleString("default", { month: "long" });
  const endMonth = endDate.toLocaleString("default", { month: "long" });
  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();
  return `${startMonth} ${startDayOfMonth} ${startYear} - ${endMonth} ${endDayOfMonth} ${endYear}`;
};

const AdvertiserReports: React.FC = () => {
  const { currentTheme } = React.useContext(ThemeContext);
  const { accessToken } = React.useContext(AccessTokenContext);
  const [selectedAdType, setSelectedAdType] = useState(0);
  const [mainLegendsState, setMainLegendsState] = useState([true, true, true]);
  const [sessionsWithClickLegendsState, setSessionsWithClickLegendsState] = useState([
    true,
    true,
    true,
    true,
    true,
  ]);
  const history = useHistory();
  const location = useLocation();
  // get remote data
  const query = useQuery("advertiser_chart_data", () => toGetAdvertiserCharts(2, accessToken), {
    enabled: !!accessToken,
  });

  // end date is always today
  const endDate = new Date();

  // get URL query params
  const params = new URLSearchParams(location.search);

  // get weeks_of_Data from URL query string
  const weeksOfDataQuery = params.get("weeks_of_data");

  // set weeks_of_Data from URL query string or use default
  const weeksOfData = weeksOfDataQuery ? parseInt(weeksOfDataQuery) : defaultWeeksOfData;

  // wether to use demo data
  let useDummyData = false;
  const demoDataQuery = params.get("useDemoData");
  if (demoDataQuery === "true") {
    useDummyData = true;
  }

  // get campaign ids from URL query string
  let selectedCampaignIds = getCampaignIdsFromUrlQuery(params.get("campaign"));

  // get start date based on weeks of data
  const startDate = getDateBehindInWeeks(weeksOfData);
  const readableTimeSpanSelection = formatStartEndDateToReadable(startDate, endDate);
  const readableTimeSpanSelectionForFileName = readableTimeSpanSelection
    .replace(/ /g, "_")
    .toLowerCase();

  // show loading state if using remote data & still loading
  if (!useDummyData && (query.isLoading || query.isIdle || query.isFetching)) return <Loading />;

  // return empty state if no data, when we're not using useDummyData
  if (!useDummyData && (!query.data || !query.data.data || query.data.data.length === 0))
    return (
      <div className="mb-20">
        <H4 isBold>Reports</H4>
        <P isMedium className="text-tertiary mt-2 mb-6">
          It seems you don't have any data yet.
        </P>
        <Button
          variant="info"
          onClick={() =>
            history.push({
              search: "&useDemoData=true",
            })
          }
        >
          View using demo data
        </Button>
      </div>
    );

  // attribute data to local variable, depending on whether we are using dummy data or remote data
  let data = useDummyData ? getDataForNumWeeks(weeksOfData) : query.data.data;
  console.log("data in use", data);
  // create new array to store all the unique campaigns names & ids
  const uniqueCampaigns = getUniqueCampaigns(data);

  // filter data to include only the ids in selectedCampaignIds
  if (selectedCampaignIds.length > 0) {
    data = data.filter((item: any) => selectedCampaignIds.includes(item.campaign_id));
  }

  // generate totals to display in MainStatWindow components
  const totals = generateTotals(data);

  // generate multi select items
  const multiSelectItems = generateMultiSelectItems(uniqueCampaigns);

  // get selected indexes for multi select
  const campaignsSelectedIndexesForMultiSelect = getSelectedIndexesForMultiSelect(
    multiSelectItems,
    selectedCampaignIds,
  );

  // logs
  console.log("- - - - - - - - -");
  console.log("weeksOfData", weeksOfData);
  console.log("query", query);
  console.log("data in use (FILTERED)", data);
  console.log("uniqueCampaigns", uniqueCampaigns);
  console.log("selectedCampaignIds", selectedCampaignIds);
  console.log("multiSelectItems", multiSelectItems);
  console.log("campaignsSelectedIndexesForMultiSelect", campaignsSelectedIndexesForMultiSelect);

  // generate data for the two charts
  const mainChartData = generateMainChartData(data, mainLegendsState);
  const sessionsWithClicksChartData = generateSessionsWithClickChartData(
    data,
    sessionsWithClickLegendsState,
  );

  return (
    <div className="mb-20">
      <H4 isBold>Reports</H4>
      {useDummyData && (
        <P isMedium className="text-tertiary mt-2 mb-6">
          Viewing demo data
        </P>
      )}
      <Window
        className={clsx(
          chartWindowStyle,
          "!pb-5 !pt-6 !px-6 mt-8 mb-10 !grid grid-cols-12 gap-x-2.5",
        )}
      >
        <div className="col-span-3 flex flex-col gap-y-2">
          <PNano isUppercase isBold className="text-tertiary">
            TIME SPAN
          </PNano>
          <Select
            handleIndexSelection={(index: number) => {
              let updatedSearch = `?weeks_of_data=${index + 1}`;
              if (useDummyData) {
                updatedSearch += "&useDemoData=true";
              }
              if (selectedCampaignIds.length > 0) {
                updatedSearch += `&campaign=${selectedCampaignIds.join(",")}`;
              }
              history.push({
                search: updatedSearch,
              });
            }}
            selectedIndex={weeksOfData - 1}
            labelProp="label"
            idProp="value"
            useKeyboard={false}
            options={weeksOfDataOptions}
          />
        </div>
        <div className="col-span-9 flex flex-col gap-y-2 w-full">
          <PNano isUppercase isBold className="text-tertiary">
            FILTER BY CAMPAIGN
          </PNano>
          <TPDSMultiSelect
            options={multiSelectItems}
            placeholder="Filter by campaign"
            labelProp="label"
            idProp="label"
            selectedIndexes={campaignsSelectedIndexesForMultiSelect}
            truncateAfterNumItems={5}
            omitSelectedInDropdown={false}
            handleSelectionUpdate={(array: any) => {
              let updatedSearch = `?weeks_of_data=${weeksOfData}`;
              if (useDummyData) {
                updatedSearch += "&useDemoData=true";
              }
              if (array.length > 0) {
                const updatedSelectedCampaignIds = array.map(
                  (item: number) => multiSelectItems[item].value,
                );
                updatedSearch += `&campaign=${updatedSelectedCampaignIds.join(",")}`;
              }
              history.push({
                search: updatedSearch,
              });
            }}
          />
        </div>
      </Window>
      <div className="flex justify-between items-end">
        <H6>{readableTimeSpanSelection}</H6>
        <Button
          variant="secondary"
          onClick={() => {
            const encodedUri = encodeURI(getCsvContent(uniqueCampaigns));
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute(
              "download",
              `tempo_report_${readableTimeSpanSelectionForFileName}.csv`,
            );
            document.body.appendChild(link); // Required for FF
            link.click(); // This will download the data file named "report.csv".
          }}
        >
          <DownloadOutlined /> Download report
        </Button>
      </div>
      <div className="bg-transparent mt-4 mb-4">
        <div className="flex gap-x-4 justify-between">
          <MainStatWindow
            color={spendColor}
            label="Spend"
            value={`${totals.spend.toLocaleString(undefined, { maximumFractionDigits: 2 })}`}
          />
          <MainStatWindow
            color={impressionsColor}
            label="Impressions"
            value={totals.impressions.toLocaleString()}
          />
          <MainStatWindow
            color={sessionsWithClickColor}
            label="Sessions with click"
            value={totals.sessionsWithClick.toLocaleString()}
          />
          <MainStatWindow
            color={cpmColor}
            label="AVERAGE CPM"
            value={`${(totals.spend / (1000 * totals.impressions)).toFixed(2)}`}
          />
        </div>
      </div>

      <div className="my-10" />
      <H6>Highlights</H6>
      <div className="my-4" />

      <Window className={chartWindowStyle}>
        {getMainChartLegend(mainLegendsState, setMainLegendsState)}
        <div className="w-full h-[600px]">
          {generateMainChart(
            data,
            mainChartData,
            sessionsWithClickLegendsState,
            generateTooltipForDayMainChart,
            currentTheme,
          )}
        </div>
      </Window>

      <div className="my-10" />
      <H6>Sessions with click</H6>
      <div className="my-4" />

      <Window className={chartWindowStyle}>
        {getSessionsWithClickLegend(
          sessionsWithClickLegendsState,
          setSessionsWithClickLegendsState,
        )}
        <div className="w-full h-[600px]">
          {generateSessionsWithClickChart(
            data,
            sessionsWithClicksChartData,
            sessionsWithClickLegendsState,
            generateTooltipForDaySWCChart,
            currentTheme,
          )}
        </div>
      </Window>

      <div className="my-10" />
      <H6>Summary</H6>
      <div className="my-4" />

      <Window className="!border-transparent shadow !rounded-lg !p-0">
        <div className="p-2.5 pt-6 pb-0">
          <Table
            columns={tableColumns}
            data={generateTableData(data)}
            //data={generateTableData(data, filterText)}
            pagination={true}
            rowsPerPage={50}
            density="medium"
            customRowClass="border !border-transparent"
            customHeaderClass="font-medium text-tertiary opacity-60"
            rowSpacing="none"
            router={history}
            //rowClick={(item: any, index: number) => alert("clicked item with id: " + item.id)}
            page={0}
          />
        </div>
      </Window>
    </div>
  );
};

export default AdvertiserReports;
