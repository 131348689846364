import React from "react";
import { PSmall } from "@tempoplatform/tpds/elements/typography";
import { ArrowRightIcon } from "@tempoplatform/tpds/assets/svgs/arrows";
import ReviewBox from "./sub_components/ReviewBox";
import dayjs from "dayjs";
import ReviewsSummary from "./sub_components/ReviewsSummary";
import { simplifyLongNumber } from "../../../helpers/number";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectPreviewProductReviews } from "../../campaignCreateSlice";

interface ComponentProps {
  productIndex: number;
}
const RatingsAndReviews: React.FC<ComponentProps> = ({ productIndex }) => {
  const dispatch = useAppDispatch();
  const products = useAppSelector(state => state.campaignCreate.products);
  const reviews = useAppSelector(state => state.campaignCreate.productReviews);
  if (productIndex === -1) return null; // no product selected
  let thisProductReviews = reviews[productIndex];
  const thisProductAddReviews = products[productIndex].add_reviews;
  const product = products[productIndex];
  const bar5_percent = product.review_chart_bar_5_percent;
  const bar4_percent = product.review_chart_bar_4_percent;
  const bar3_percent = product.review_chart_bar_3_percent;
  const bar2_percent = product.review_chart_bar_2_percent;
  const bar1_percent = product.review_chart_bar_1_percent;
  const storedReviewCount = product && product.review_count ? product.review_count : 0;
  const reviewCount = storedReviewCount ? storedReviewCount : 1400;
  const simplifiedCount = String(simplifyLongNumber(reviewCount));

  if (!thisProductAddReviews) return null;
  let showMore = false;
  if (thisProductReviews && thisProductReviews.length > 2) {
    showMore = true;
    thisProductReviews = thisProductReviews.slice(0, 2);
  }
  return (
    <div className="w-full p-4">
      <PSmall>
        <span className="font-medium">Ratings & Reviews</span> ({simplifiedCount})
      </PSmall>
      <br />
      <ReviewsSummary
        productIndex={productIndex}
        formattedReviewCount={simplifiedCount}
        percentFiveStars={bar5_percent}
        percentFourStars={bar4_percent}
        percentThreeStars={bar3_percent}
        percentTwoStars={bar2_percent}
        percentOneStar={bar1_percent}
      />
      {thisProductReviews &&
        thisProductReviews.length > 0 &&
        thisProductReviews.map((review, index) => (
          <ReviewBox
            key={index}
            rating={review.stars}
            username={review.username || "Username"}
            date={dayjs(review.date).format("YYYY/MM/DD") || "Date"}
            title={review.title || "Title"}
            description={review.description || "Description"}
          />
        ))}
      {showMore && (
        <div
          className="flex gap-x-2 justify-end items-center mb-2 cursor-pointer"
          onClick={() => dispatch(selectPreviewProductReviews(productIndex + 1))}
        >
          <PSmall isMedium className="relative top-[1px]">
            All reviews
          </PSmall>
          <ArrowRightIcon className="w-2" />
        </div>
      )}
    </div>
  );
};
export default RatingsAndReviews;
