import React, { useEffect, useState } from "react";
import Loading from "../loading/Loading";
import PublisherAppsList from "./PublisherAppsList";
import { Route, Switch } from "react-router-dom";
import PublisherAppCreate from "./PublisherAppCreate";
import PublisherAppsAnalytics from "./PublisherAppsAnalytics";
import { useAuth0 } from "@auth0/auth0-react";
import { toGetApps } from "../api/api";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

interface Props {
  accountId: number;
}

const PublisherApps: React.FC<Props> = ({ accountId }) => {
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<any>(true);
  const [error, setError] = useState<any>(null);

  const { getIdTokenClaims } = useAuth0();

  useEffect(() => {
    // ensure we only call getApps if we are at the appropriate path
    if (location.pathname === "/") {
      history.push("/pub/applications");
    } else {
      callGetApps();
    }
  }, [accountId]);

  const callGetApps = async () => {
    const claims = await getIdTokenClaims();
    const id_token = claims?.__raw;
    try {
      const response = await toGetApps(accountId, id_token);
      setData(response);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setError(error);
      throw new Error(error);
    }
  };

  // render null if we aren't at the component's specific route
  if (location.pathname === "/") return null;

  if (loading) {
    return <Loading />;
  }

  if (error || !data) {
    return <div>ERROR</div>;
  }

  return (
    <Switch>
      <Route
        exact
        path="/pub/applications/create"
        render={() => <PublisherAppCreate accountId={accountId} />}
      />
      <Route
        exact
        path="/pub/applications"
        render={() => <PublisherAppsList data={data} accountId={accountId} />}
      />
      <Route
        exact
        path="/pub/reports"
        render={() => <PublisherAppsAnalytics accountId={accountId} />}
      />
      <Route
        exact
        path="/pub"
        render={() => <PublisherAppsList data={data} accountId={accountId} />}
      />
      <Route exact path="" render={() => <PublisherAppsList data={data} accountId={accountId} />} />
    </Switch>
  );
};
export default PublisherApps;
