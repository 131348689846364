import { Switch, Tooltip } from "antd";
import Table from "@tempoplatform/tpds/components/table";
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import PublisherAppsEmpty from "./PublisherAppsEmpty";
import { Button } from "@tempoplatform/tpds/elements/buttons";
import { PSmall } from "@tempoplatform/tpds/elements/typography";

const getColumns = () => {
  const columns = [
    {
      label: "AppId",
      propName: "id",
      key: "id",
    },
    {
      label: "Name",
      propName: "name",
      key: "name",
    },
    {
      label: "Bundle ID – iOS",
      propName: "bundle_ios",
      key: "bundle_ios",
    },
    {
      label: "Bundle ID – Android",
      propName: "bundle_android",
      key: "bundle_android",
    },
    {
      label: "Designated for Kids?",
      propName: "is_for_kids",
      key: "is_for_kids",
      render: (item: any) => <PSmall>{item.is_for_kids ? "Yes" : "No"}</PSmall>,
    },
    {
      label: "Test Mode Active?",
      propName: "is_test_mode",
      key: "is_test_mode",
      render: (item: any) => {
        return (
          <Tooltip title="To enable Live Ads contact your Tempo representative.">
            <Switch disabled={true} defaultChecked={item.is_test_mode} />
          </Tooltip>
        );
      },
    },
  ];
  return columns;
};

interface Props {
  data: any;
  accountId: number;
}

const PublisherAppsList: React.FC<Props> = ({ accountId, data }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  let dataSource = data.map((item: any) => {
    let bundleIos = item.bundles.find((bundle: any) => bundle.platform.toLowerCase() === "ios");
    let bundleAndroid = item.bundles.find(
      (bundle: any) => bundle.platform.toLowerCase() === "android",
    );

    let { bundles: _, ...itemWithoutBundles } = item;

    return {
      key: item.id,
      bundle_ios: bundleIos ? bundleIos.name : null,
      bundle_android: bundleAndroid ? bundleAndroid.name : null,
      ...itemWithoutBundles,
    };
  });

  const history = useHistory();

  // @ts-ignore
  if (dataSource.length === 0) return <PublisherAppsEmpty />;

  const urlPageParameter = params.get("page");
  const page = urlPageParameter ? parseInt(urlPageParameter) : 1;

  return (
    <div>
      <Button
        style={{ marginBottom: "1em", marginLeft: "auto" }}
        variant="info"
        onClick={() => {
          history.push("/pub/applications/create");
        }}
      >
        Add New App
      </Button>
      <Table
        columns={getColumns()}
        data={dataSource}
        pagination={true}
        rowKey="key"
        router={history}
        page={page}
      />
    </div>
  );
};

export default PublisherAppsList;
