import React from "react";
import { useAppDispatch } from "../../../app/hooks";
import { Button } from "@tempoplatform/tpds/elements/buttons";
import { updateProductReview, removeProductReview } from "../campaignCreateSlice";
import { GreyBox } from "../../../shared/atoms";
import { FormLabelSmall, Red } from "@tempoplatform/tpds/elements/typography";
import { TextInput } from "@tempoplatform/tpds/elements/input";
import NumberInput from "@tempoplatform/tpds/components/input/number";
import { DatePicker } from "antd";
import moment from "moment";

interface Props {
  productIndex: number;
  reviewIndex: number;
  productReview: any;
}

const ProductReview = (props: Props) => {
  const { productIndex, reviewIndex, productReview } = props;
  const dispatch = useAppDispatch();

  if (!productReview) return null;

  const callUpdateProductReview = (updatedProductReview: any) => {
    dispatch(
      updateProductReview({
        productIndex: productIndex,
        productReviewIndex: reviewIndex,
        value: updatedProductReview,
      }),
    );
  };
  const updateProductReviewUsername = (value: string) => {
    const updatedProductReview = {
      ...productReview,
      username: value,
    };
    callUpdateProductReview(updatedProductReview);
  };
  const updateProductReviewStars = (value: string) => {
    const parsedValue = parseFloat(value);
    const updatedProductReview = {
      ...productReview,
      stars: !isNaN(parsedValue) ? parsedValue : 0,
    };
    callUpdateProductReview(updatedProductReview);
  };
  const updateProductReviewDescription = (value: string) => {
    const updatedProductReview = {
      ...productReview,
      description: value,
    };
    callUpdateProductReview(updatedProductReview);
  };
  const updateProductReviewTitle = (value: string) => {
    const updatedProductReview = {
      ...productReview,
      title: value,
    };
    callUpdateProductReview(updatedProductReview);
  };
  const updateReviewDate = (value: string) => {
    const updatedProductReview = {
      ...productReview,
      date: value,
    };
    callUpdateProductReview(updatedProductReview);
  };

  return (
    <GreyBox className="mt-4 bg-grey-light-scale-200 dark:bg-grey-dark-scale-500">
      <div className="flex items-start justify-between gap-x-4">
        <div>
          <FormLabelSmall className="mb-2">
            <Red>*</Red> Username
          </FormLabelSmall>
          <TextInput
            className="bg-white"
            value={productReview.username}
            placeholder="Display name"
            onChange={(e: any) => updateProductReviewUsername(e.target.value)}
          />
        </div>
        <div>
          <FormLabelSmall className="mb-2">
            <Red>*</Red> Stars
          </FormLabelSmall>
          <NumberInput
            className="bg-white"
            initialValue={productReview.stars}
            maxValue={5}
            onChangeCallback={(value: any) => updateProductReviewStars(value)}
          />
        </div>
      </div>
      <div className="mt-2 grid grid-cols-2 gap-4">
        <div>
          <FormLabelSmall className="mb-2">
            <Red>*</Red> Title
          </FormLabelSmall>
          <TextInput
            className="bg-white"
            value={productReview.title}
            placeholder="Review title"
            onChange={(e: any) => updateProductReviewTitle(e.target.value)}
          />
        </div>
        <div>
          <FormLabelSmall className="mb-2">
            <Red>*</Red> Date
          </FormLabelSmall>
          <DatePicker
            format={"YYYY/MM/DD"}
            // @ts-ignore
            defaultValue={productReview.date ? moment(productReview.date) : moment()}
            style={{ width: "100%" }}
            onChange={(a: any, b: any) => {
              updateReviewDate(b);
            }}
          />
        </div>
      </div>

      <div>
        <FormLabelSmall className="my-2">
          <Red>*</Red> Description
        </FormLabelSmall>
        <textarea
          className="border border-transparent bg-white text-primary dark:bg-grey-dark-scale-300 w-full px-2 !py-1 min-h-[40px] rounded-[3px]"
          value={productReview.description}
          placeholder="Review text"
          onChange={(e: any) => updateProductReviewDescription(e.target.value)}
        />
      </div>
      <div className="flex justify-end">
        <Button
          variant="secondary"
          onClick={() => {
            dispatch(
              removeProductReview({
                productIndex: productIndex,
                productReviewIndex: reviewIndex,
                value: null,
              }),
            );
          }}
        >
          Remove
        </Button>
      </div>
    </GreyBox>
  );
};

export default ProductReview;
