import { Tooltip } from "antd";
import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  updateVariantValues,
  removeVariantBox,
  addNewVariantBoxWithTitle,
  toggleVariantSetIndices,
} from "./campaignCreateSlice";
import { Button } from "@tempoplatform/tpds/elements/buttons";
import LabelWithTooltip from "../../shared/LabelWithTooltip";
import { GreyBox } from "../../shared/atoms";
import { PSmall, Medium } from "@tempoplatform/tpds/elements/typography";
import { TextInput } from "@tempoplatform/tpds/elements/input";
import Checkbox from "@tempoplatform/tpds/components/checkbox";
import TPDSSelect from "@tempoplatform/tpds/components/select";
import Cross from "@tempoplatform/tpds/assets/svgs/16x16/Cross";
import VariantColorPicker from "../../shared/VariantColorPicker";

interface Props {
  id: number;
}

const VariantBox = (props: Props) => {
  const { id } = props;

  const productIndex = id - 1;

  const thisProductVariantBoxes = useAppSelector(state => state.campaignCreate.variantBoxes)[
    productIndex
  ];
  const dispatch = useAppDispatch();

  const presetVariantTypes = ["Color", "Size"];
  const [newVariantTypeName, setNewVariantTypeName] = useState("");

  const handleToggleSetIndices = (variantIndex: number, value: boolean) => {
    dispatch(
      toggleVariantSetIndices({
        productIndex: productIndex,
        variantIndex: variantIndex,
        value: value,
      }),
    );
  };

  const getVariantByIndex = (variantIndex: number) => {
    return thisProductVariantBoxes[variantIndex];
  };

  const addVariantValue = (variantIndex: number) => {
    const variant = getVariantByIndex(variantIndex);
    const variantValues = variant.values?.map(value => value);
    variantValues?.push({ title: "" });
    dispatch(
      updateVariantValues({
        productIndex: productIndex,
        variantIndex: variantIndex,
        // @ts-ignore
        values: variantValues,
      }),
    );
  };

  const filterUniqueVariantTypesForSelect = presetVariantTypes.filter(variantType => {
    if (!thisProductVariantBoxes || thisProductVariantBoxes.length === 0) return true;
    const found = thisProductVariantBoxes.find(variantBox => variantBox.title === variantType);
    return !found;
  });

  const dispatchVariantValuesUpdate = (
    productIndex: number,
    variantIndex: number,
    variantValues: any,
  ) => {
    dispatch(
      updateVariantValues({
        productIndex: productIndex,
        variantIndex: variantIndex,
        values: variantValues,
      }),
    );
  };

  const handleUpdateVariantValue = (
    variantIndex: number,
    valueIndex: number,
    inputValue: string,
  ) => {
    const variant = getVariantByIndex(variantIndex);
    const variantValues = variant.values?.map((value, index) => {
      if (valueIndex === index) {
        return {
          ...value,
          title: inputValue,
        };
      }
      return value;
    });
    dispatchVariantValuesUpdate(productIndex, variantIndex, variantValues);
  };

  const handleUpdateVariantValueIndex = (
    variantIndex: number,
    valueIndex: number,
    inputValue: string,
  ) => {
    let newIndex = !isNaN(parseInt(inputValue)) ? parseInt(inputValue) : null;
    const variant = getVariantByIndex(variantIndex);
    const variantValues = variant.values?.map((value, index) => {
      if (valueIndex === index) {
        return {
          ...value,
          image_index: newIndex,
        };
      }
      return value;
    });
    dispatchVariantValuesUpdate(productIndex, variantIndex, variantValues);
  };
  const handleUpdateVariantValueHexColor = (
    variantIndex: number,
    valueIndex: number,
    inputValue: string,
  ) => {
    const variant = getVariantByIndex(variantIndex);
    const variantValues = variant.values?.map((value, index) => {
      if (valueIndex === index) {
        return {
          ...value,
          hex_color: inputValue,
        };
      }
      return value;
    });
    dispatchVariantValuesUpdate(productIndex, variantIndex, variantValues);
  };
  const validControlKeys = [8, 37, 38, 39, 40, 91, 93];
  const testIndiceKeyDown = (e: any) => {
    const keyCode = e.keyCode;
    if (keyCode === 188) return false; // prevent comma
    if (validControlKeys.indexOf(keyCode) !== -1) return true; // allow control keys
    if (e.target.value.length > 1) return false; // limit to 2 digits
    if (keyCode >= 48 && keyCode <= 57) return true; // alow numbers
    if (keyCode >= 96 && keyCode <= 105) return true; // alow keypad numbers
    return false;
  };
  const restrictIndiceValueInput = (e: any) => {
    if (!testIndiceKeyDown(e)) e.preventDefault();
  };

  const removeVariantValue = (variantIndex: number, valueIndex: number) => {
    const variant = getVariantByIndex(variantIndex);
    const variantValues = variant.values?.map(value => value);
    variantValues?.splice(valueIndex, 1);
    dispatch(
      updateVariantValues({
        productIndex: productIndex,
        variantIndex: variantIndex,
        // @ts-ignore
        values: variantValues,
      }),
    );
  };

  const prepareIndexDefaultValue = (image_index: any) => {
    if (image_index === null || image_index === undefined || isNaN(parseInt(image_index)))
      return "";
    return image_index;
  };

  const variantsMaxReached = thisProductVariantBoxes?.length >= 3;
  let variantWithSetIndicesIndex = -1;
  for (let i = 0; i < thisProductVariantBoxes?.length; i++) {
    if (thisProductVariantBoxes[i].set_indices) {
      variantWithSetIndicesIndex = i;
      break;
    }
  }

  const createNewVariant = (newVariantTypeName: string) => {
    const itemWithSameName = thisProductVariantBoxes.find(
      item => item.title === newVariantTypeName,
    );
    if (itemWithSameName) {
      // TODO: show toast notification that info box with same name already exists
      return;
    }
    setNewVariantTypeName("");
    dispatch(
      addNewVariantBoxWithTitle({
        productIndex: productIndex,
        title: newVariantTypeName,
      }),
    );
  };

  return (
    <>
      <LabelWithTooltip
        label="Product Variants"
        tooltipText="Optional. For example, if you are selling a t-shirt, you can enter the name of the variant as 'Size' or 'Color'."
      />
      {!variantsMaxReached && (
        <div className="flex flex-col gap-y-2">
          {filterUniqueVariantTypesForSelect && filterUniqueVariantTypesForSelect.length > 0 && (
            <TPDSSelect
              options={filterUniqueVariantTypesForSelect}
              placeholder="Select preset variant"
              selectedIndex={null}
              labelProp={null}
              handleIndexSelection={(index: number) =>
                dispatch(
                  addNewVariantBoxWithTitle({
                    productIndex: productIndex,
                    title: filterUniqueVariantTypesForSelect[index],
                  }),
                )
              }
            />
          )}
          <div className="flex gap-x-2">
            <TextInput
              placeholder="New variant name"
              value={newVariantTypeName}
              onChange={(e: any) => setNewVariantTypeName(e.target.value)}
            />
            <Button variant="secondary" onClick={() => createNewVariant(newVariantTypeName)}>
              Create
            </Button>
          </div>
        </div>
      )}
      {variantsMaxReached && (
        <PSmall className="mt-2">You have reached the maximum number of variants (3).</PSmall>
      )}
      <div className="flex flex-col gap-y-2 mt-4">
        {thisProductVariantBoxes?.map((variant, variantIndex) => {
          if (!variant || !variant.title) return null;
          return (
            <GreyBox key={variant.title}>
              <PSmall className="leading-none mb-1">
                Variant: <Medium>{variant.title}</Medium>
              </PSmall>
              {variant.values?.map((value, valueIndex) => {
                return (
                  <div className="flex items-center gap-x-1" key={valueIndex}>
                    <TextInput
                      placeholder={`${variant.title} name`}
                      style={{ width: "100%" }}
                      className="bg-white dark:bg-grey-dark-scale-300"
                      value={value.title}
                      onChange={(e: any) => {
                        handleUpdateVariantValue(variantIndex, valueIndex, e.target.value);
                      }}
                    />
                    {variant.set_indices && (
                      <TextInput
                        placeholder="Index"
                        className="bg-white dark:bg-grey-dark-scale-300"
                        style={{ width: "55px" }}
                        defaultValue={prepareIndexDefaultValue(value.image_index)}
                        onKeyDown={(e: any) => restrictIndiceValueInput(e)}
                        onChange={(e: any) => {
                          handleUpdateVariantValueIndex(variantIndex, valueIndex, e.target.value);
                        }}
                      />
                    )}
                    {variant.title === "Color" && (
                      <VariantColorPicker
                        hex_color={value.hex_color || "#aabbcc"}
                        onChangeHandler={(hexColorValue: string) => {
                          handleUpdateVariantValueHexColor(variantIndex, valueIndex, hexColorValue);
                        }}
                      />
                    )}
                    <Button
                      className="!p-[7px] rounded border-2"
                      variant="secondary"
                      onClick={(e: any) => removeVariantValue(variantIndex, valueIndex)}
                    >
                      <Cross />
                    </Button>
                  </div>
                );
              })}
              <Button
                variant="secondary"
                onClick={() => addVariantValue(variantIndex)}
                style={{
                  width: "100%",
                  borderStyle: "dashed",
                  borderWidth: "2px",
                  borderRadius: "4px",
                }}
              >
                <PlusOutlined /> Add variant value
              </Button>
              {variantWithSetIndicesIndex !== -1 &&
              variantWithSetIndicesIndex !== variantIndex ? null : (
                <div className="flex items-center mt-1 justify-between">
                  <div className="flex items-center">
                    <Checkbox
                      active={variant.set_indices}
                      onClick={() => handleToggleSetIndices(variantIndex, !variant.set_indices)}
                    />
                    <Tooltip title="Identify the respective image indexes for each variant value.">
                      <PSmall isMedium className="ml-1.5 relative top-[-1px]">
                        Set indices
                      </PSmall>
                    </Tooltip>
                  </div>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      dispatch(
                        removeVariantBox({
                          productIndex: productIndex,
                          variantIndex: variantIndex,
                        }),
                      );
                    }}
                  >
                    Remove
                  </Button>
                </div>
              )}
            </GreyBox>
          );
        })}
      </div>
    </>
  );
};
export default VariantBox;
