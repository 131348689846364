import styled from "@emotion/styled";
import React, { useState, useRef, useEffect } from "react";
import { TextInput } from "@tempoplatform/tpds/elements/input";
import { HexAlphaColorPicker } from "react-colorful";
import clsx from "clsx";

const classColorSquare = clsx(`w-[40px] h-[33px] rounded`, `border-2 border-input`);

interface Props {
  hex_color: string;
  onChangeHandler: any;
}

const RootDiv = styled.div`
  .react-colorful {
    width: 200px;
    height: auto;
  }
  .react-colorful__hue {
    height: 12px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .react-colorful__saturation {
    height: 40px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom: 0;
  }
  .react-colorful__alpha {
    height: 12px;
  }
  .react-colorful__alpha-pointer {
    width: 10px !important;
    height: 10px !important;
  }
  .react-colorful__saturation-pointer {
    width: 16px;
    height: 16px;
    border-radius: 3px;
  }
  .react-colorful__hue-pointer,
  .react-colorful__alpha-pointer {
    width: 10px !important;
    height: 10px !important;
    border-radius: 3px;
  }
`;

const ColorPickerInput = (props: Props) => {
  const { onChangeHandler, hex_color } = props;

  const wrapperRef = useRef(null);
  //useOutsideAlerter(wrapperRef);

  const [colorPickerActive, setColorPickerActive] = useState(false);
  // setColorPickerActive(false);
  const handlePickerColorChange = (color: string) => {
    onChangeHandler(color);
  };
  const onInputChangeHandler = (e: any) => {
    onChangeHandler(e.target.value);
  };

  useEffect(() => {
    function handleClickOutside(event: any) {
      // @ts-ignore
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setColorPickerActive(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <RootDiv className="flex w-[100%] items-center gap-x-1 relative" ref={wrapperRef}>
      <div
        className={classColorSquare}
        style={{ backgroundColor: hex_color }}
        onClick={() => setColorPickerActive(!colorPickerActive)}
      />
      <TextInput placeholder="Hex" value={hex_color} onChange={onInputChangeHandler} />
      {colorPickerActive && (
        <div className="absolute top-[35px] left-0 z-10">
          <HexAlphaColorPicker color={hex_color} onChange={handlePickerColorChange} />
        </div>
      )}
    </RootDiv>
  );
};
export default ColorPickerInput;
