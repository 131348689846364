import React, { useState } from "react";
import { PSmall, PTiny, P, H6 } from "@tempoplatform/tpds/elements/typography";
import { Separator } from "@tempoplatform/tpds/elements/layout";
import InfoCircle from "@tempoplatform/tpds/assets/svgs/icons/InfoCircle";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { setPreviewBrandAwarenessDialogOpen } from "../../../campaignCreateSlice";
import validator from "validator";
import clsx from "clsx";

const classButtonBase = clsx(
  `flex justify-center items-center gap-x-1`,
  `w-[50%] py-2 rounded-lg outline-none border-none font-medium`,
  `bg-blue-scale-400 text-white`,
);

const classInput = clsx(
  `w-full mb-2 py-2 px-4`,
  `rounded-lg border-none outline-none`,
  `bg-grey-light-scale-300`,
);

const classPuller = clsx("w-[36px] h-[6px] bg-[#ccc] rounded text-center mx-auto");

const BrandAwarenessCTADialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const [submittedOnce, setSubmittedOnce] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const preview_selectedProduct = useAppSelector(
    state => state.campaignCreate.preview_selectedProduct,
  );
  const products = useAppSelector(state => state.campaignCreate.products);

  let productName = "Product name";
  if (preview_selectedProduct !== -1) {
    try {
      const selectedProduct = products[preview_selectedProduct - 1];
      // @ts-ignore
      productName = selectedProduct.name;
    } catch (err) {
      // failed ot catch product name, using default
    }
  }
  const preview_brandAwarenessDialogOpen = useAppSelector(
    state => state.campaignCreate.preview_brandAwarenessDialogOpen,
  );

  const [emailAddress, setEmailAddress] = useState("");

  const submitForm = () => {
    if (validator.isEmail(emailAddress)) {
      setEmailInvalid(false);
    } else {
      setEmailInvalid(true);
    }
    setSubmittedOnce(true);
  };
  const closeDialog = () => {
    dispatch(setPreviewBrandAwarenessDialogOpen(false));
  };

  const submissionSuccess = submittedOnce && !emailInvalid;

  const classRootDiv = clsx(
    `w-full h-full absolute top-0 left-0`,
    preview_brandAwarenessDialogOpen ? `pointer-events-auto` : `pointer-events-none`,
  );

  const classCustomDrawer = clsx(
    `bg-white absolute left-0`,
    `mx-[4px] px-4`,
    `rounded-lg transition-all duration-300 bottom-[-360px]`,
    preview_brandAwarenessDialogOpen ? `!bottom-4` : `bottom-[-360px]`,
  );

  const classBlackTransparentLayer = clsx(
    `absolute top-0 left-0 w-full h-[830px]`,
    `transition-all duration-300`,
    preview_brandAwarenessDialogOpen
      ? `bg-black/50 pointer-events-auto`
      : `bg-transparent pointer-events-none`,
  );

  return (
    <div className={classRootDiv} style={{ zIndex: 9999999999 }}>
      <div className={classBlackTransparentLayer} onMouseDown={() => closeDialog()} />
      <div className={classCustomDrawer} style={{ width: "calc(100% - 8px)" }}>
        <div className="pb-8">
          <div className="pt-8" onMouseDown={() => closeDialog()}>
            <div
              onClick={() => closeDialog()}
              className={classPuller}
              style={{ left: "calc(50% - 15px)" }}
            />
            <H6 isMedium className="leading-none mb-1 select-none">
              {productName}
            </H6>
          </div>
          <Separator />
          {!submissionSuccess && (
            <P isMedium className="mb-1 mt-2 select-none leading-normal">
              Enter your email to learn more about this product!
            </P>
          )}
          {submissionSuccess && (
            <P isMedium className="mb-1 mt-2 select-none leading-normal">
              Thank you for your interest in this product!{" "}
              <span className="font-normal">We will e-mail you with more information. 💌</span>
            </P>
          )}
          <br />
          {!submissionSuccess && (
            <>
              <P isMedium className="leading-none mb-2 select-none">
                Your email
              </P>
              <input
                className={classInput}
                type="text"
                placeholder="your.email@host.com"
                value={emailAddress}
                onChange={e => setEmailAddress(e.target.value)}
                tabIndex={-1}
              />
              <button
                className={classButtonBase}
                tabIndex={-1}
                style={{ width: "100%" }}
                onClick={() => submitForm()}
              >
                Send!
              </button>
              {submittedOnce && emailInvalid && (
                <PSmall isMedium className="text-orange mt-2">
                  Please provide a valid email address
                </PSmall>
              )}
            </>
          )}
          <div className="flex justify-center items-center gap-x-1.5 mt-2.5">
            <PTiny isMedium className="leading-none text-center text-tertiary select-none">
              Your data is protected.
            </PTiny>
            <InfoCircle className="text-blue" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandAwarenessCTADialog;
